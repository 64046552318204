@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&amp;display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@400;500;600;700&amp;display=swap");

@font-face {
  font-family: "rt-icons.ttf";
  src: url(./fonts/rt-icons.ttf);
}

@font-face {
  font-family: "fa-brands-400.ttf";
  src: url(./fonts/fa-brands-400.ttf);
}

* {
  margin: 0;
  padding: 0;
}

html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: oswald;
  position: relative;
  background-color: black;
}

*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

a {
  -webkit-transition: color 200ms;
  transition: color 200ms;
  color: #040404;
  text-decoration: none;
}

a:hover {
  color: unset;
}

a,
span {
  display: inline-block;
}

h1,
h2,
h3,
h4,
h5,
h6,
span,
a {
  color: #111111;
  font-weight: 700;
  margin: 0;
}

p {
  margin-bottom: 0;
  color: #999999;
  font-size: 16px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
}

@media (max-width: 1550px) {
  p {
    font-size: 16px !important;
  }
}

:root[data-theme=dark] body {
  background: black;
}

:root[data-theme=dark] .logo {
  display: none;
}

:root[data-theme=dark] a {
  color: #fff;
}

:root[data-theme=dark] a:hover {
  color: unset;
}

:root[data-theme=dark] h1,
:root[data-theme=dark] h2,
:root[data-theme=dark] h3,
:root[data-theme=dark] h4,
:root[data-theme=dark] h5,
:root[data-theme=dark] h6,
:root[data-theme=dark] p {
  color: #fff;
}

:root[data-theme=dark] .header-catagory-bar {
  background: #121416;
}

:root[data-theme=dark] .rts-post {
  border-bottom: 1px solid rgb(37, 37, 37) !important;
}

:root[data-theme=dark] .rts-post .contents .post-title a {
  color: #fff;
}

button {
  outline: none;
  border: none;
  background: unset;
}

input {
  border: none;
  outline: none;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

img {
  -webkit-transition: -webkit-transform 0.6s ease;
  transition: -webkit-transform 0.6s ease;
  transition: transform 0.6s ease;
  transition: transform 0.6s ease, -webkit-transform 0.6s ease;
  max-width: 100%;
}

i {
  -webkit-transition: all 100ms;
  transition: all 100ms;
}

.anywere,
.anywere-home {
  background: #0E1013;
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 500ms ease-in-out;
  transition: opacity 500ms ease-in-out;
  pointer-events: none;
  z-index: 50;
}

.anywere.show,
.anywere-home.show {
  visibility: visible;
  pointer-events: visible;
}

.anywere.bgshow,
.anywere-home.bgshow {
  background: #0E1013;
  opacity: 70%;
  visibility: visible;
  pointer-events: visible;
  z-index: 60;
}

.rts-post .post-picture img,
.rts-post-medium .post-picture img,
.rts-post-small .post-picture img,
.side-post .post-picture img {
  width: 100%;
}

.rts-post .post-title a,
.rts-post .side-post-title a,
.rts-post-medium .post-title a,
.rts-post-medium .side-post-title a,
.rts-post-small .post-title a,
.rts-post-small .side-post-title a,
.side-post .post-title a,
.side-post .side-post-title a {
  /* fill effect */
  background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, #1a1a1a), color-stop(50%, transparent));
  background-image: linear-gradient(to right, #1a1a1a 50%, transparent 50%);
  background-size: 200% 2px;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  -webkit-transition: background-position 500ms ease-out;
  transition: background-position 500ms ease-out;
}

.rts-post .post-title a:hover,
.rts-post .side-post-title a:hover,
.rts-post-medium .post-title a:hover,
.rts-post-medium .side-post-title a:hover,
.rts-post-small .post-title a:hover,
.rts-post-small .side-post-title a:hover,
.side-post .post-title a:hover,
.side-post .side-post-title a:hover {
  background-position: 0% 100%;
  color: #111111;
}

.rts-post p,
.rts-post-medium p,
.rts-post-small p,
.side-post p {
  color: #404040;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.post-bottom-info .item,
.action-bar .item,
.post-info .item {
  color: #4E4E4E;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  padding-right: 10px;
  margin-right: 10px;
  position: relative;
}

.post-bottom-info .item img,
.action-bar .item img,
.post-info .item img {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
}

.post-bottom-info .item::before,
.action-bar .item::before,
.post-info .item::before {
  content: "";
  position: absolute;
  top: 2px;
  right: -4px;
  width: 2px;
  height: 77%;
  background: rgb(186, 186, 186);
}

.post-bottom-info .item:last-child,
.action-bar .item:last-child,
.post-info .item:last-child {
  padding-right: 0;
  margin-right: 0;
}

.post-bottom-info .item:last-child::before,
.action-bar .item:last-child::before,
.post-info .item:last-child::before {
  display: none;
}

@media (max-width: 450px) {

  .post-bottom-info a::before,
  .post-bottom-info span::before,
  .action-bar a::before,
  .action-bar span::before,
  .post-info a::before,
  .post-info span::before {
    display: none;
  }
}

.author {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.author .author-dp {
  margin-right: 10px;
}

.author .content {
  margin-bottom: -1px;
}

.author .content .author-name {
  font-size: 16px;
  line-height: 18px;
  color: #031424;
}

.author .content .title {
  font-size: 12px;
  color: #777777;
  font-weight: 400;
}

.author-box {
  padding: 40px 25px;
  padding-top: 50px;
  background: #F5F5F5;
  text-align: center;
}

.author-box .author-dp {
  margin-bottom: 25px;
}

.author-box .author-dp img {
  border-radius: 50%;
  padding: 15px;
  border: 1px solid #E7E7E7;
}

.author-box .author-name {
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
}

.author-box p {
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 20px;
}

.recent-post {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 25px;
}

.recent-post:last-child {
  margin-bottom: 0;
}

.recent-post .picture {
  margin-right: 20px;
  overflow: hidden;
  -webkit-transform: scale(110%);
  transform: scale(110%);
}

.recent-post .picture:hover img {
  -webkit-transform: scale(110%);
  transform: scale(110%);
}

.recent-post .recent-post-title {
  margin-bottom: 5px;
}

.recent-post .recent-post-title a {
  color: #fff;
}

.recent-post .recent-post-title a:hover {
  color: #FF1856;
}

.recent-post span,
.recent-post a {
  color: #B9BCC0;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

.recent-post span img,
.recent-post a img {
  margin-right: 5px;
  -webkit-transform: translateY(-2px);
  transform: translateY(-2px);
}

.recent-post .span-icon {
  -webkit-filter: brightness(2);
  filter: brightness(2);
}

.trending-news-area {
  border: 1px solid #E7E7E7;
  border-radius: 4px;
  padding: 10px 23px;
  padding-left: 0;
  position: relative;
}

.trending-news-area .trending-post {
  border-bottom: 1.7px solid #E7E7E7;
  padding-bottom: 13px;
  margin-bottom: 9px;
  padding-left: 23px;
}

.trending-news-area .trending-post:last-child {
  border-bottom: none;
  margin-bottom: 0;
}

.trending-news-area .trending-post .post-title {
  position: relative;
}

.trending-news-area .trending-post .post-title a {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  background-size: 200% 1px;
}

.trending-news-area .trending-post .post-title img {
  margin-right: 5px !important;
}

.trending-news-area .trending-post .post-title::before {
  content: "";
  position: absolute;
  top: -4px;
  left: -24px;
  width: 4px;
  height: 0;
  background: #FF1856;
  -webkit-transition: height 500ms;
  transition: height 500ms;
}

.trending-news-area .trending-post .post-title:hover::before {
  height: 110%;
}

.trending-news-area .trending-post .post-author {
  color: #4E4E4E;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

.trending-news-area .swiper-pag {
  padding-left: 23px;
  padding-bottom: 5px;
}

.trending-news-area .swiper-pagination-bullet {
  padding: 0px 3px;
  font-size: 5px;
  border-radius: 50%;
  background: #CFD4DB;
  margin-right: 7px;
}

.trending-news-area .swiper-pagination-bullet-active {
  background: #FF1856;
  padding: 0px 4.5px;
  font-size: 7px;
}

.blog-catagory-tag {
  font-size: 12px;
  color: #fff;
  font-weight: 600;
  background: var(--theme-color);
  padding: 2px 10px;
  border-radius: 0;
  display: inline;
}

.news-catagory-tag {
  color: #1D72FF;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  text-transform: uppercase;
  position: relative;
  padding-left: 10px;
  margin-bottom: 10px;
}

.news-catagory-tag::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0px;
  width: 2px;
  height: 85%;
  background: #1D72FF;
}

.news-catagory-tag-2 {
  color: #fff;
  font-weight: 700;
  font-size: 13px;
  line-height: 15px;
  background: #FF1856;
  padding: 5px 15px;
  display: -ms-grid;
  display: grid;
  place-items: center;
  margin-bottom: 15px;
  display: inline-block;
  text-transform: none;
}

.news-catagory-tag-2:hover {
  color: #fff;
}

.news-catagory-tag-2::before {
  display: none;
}

.side-stories .type-tag {
  background: #0E1013;
  width: 150px;
  height: 30px;
  margin-top: 7px;
  margin-bottom: 33px;
}

.post-age {
  color: #4E4E4E;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
}

.post-age-2 {
  color: #868686 !important;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
}

.post-age-2::before {
  display: none;
}

.social-popularity .platform {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #FFFFFF;
  -webkit-box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.1);
  margin-bottom: 8px;
  padding: 6px;
  -webkit-transition: -webkit-transform 500ms;
  transition: -webkit-transform 500ms;
  transition: transform 500ms;
  transition: transform 500ms, -webkit-transform 500ms;
}

.social-popularity .platform:hover {
  -webkit-transform: scale(110%);
  transform: scale(110%);
}

.social-popularity .platform span {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.social-popularity .platform i {
  color: #fff;
  width: 50px;
  aspect-ratio: 1;
  display: -ms-grid;
  display: grid;
  place-items: center;
  margin-right: 15px;
}

.social-popularity .platform i.facebook {
  background: #3B5998;
}

.social-popularity .platform i.twitter {
  background: #1DA1F2;
}

.social-popularity .platform i.linkedin {
  background: #0077B5;
}

.social-popularity .platform i.dribbble {
  background: #EA4C89;
}

.social-popularity .platform i.youtube {
  background: #EC1D1D;
}

@media (max-width: 1200px) {
  .social-popularity .platform:hover {
    -webkit-transform: scale(101%);
    transform: scale(101%);
  }
}

.social-popularity-2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.social-popularity-2 .platform {
  padding: 0;
  width: 135px;
  color: #fff;
  margin-bottom: 7px;
}

.social-popularity-2 .platform i {
  aspect-ratio: 1;
  margin-right: 0;
  background: transparent;
  background: #3C5A98;
  margin-right: 10px;
}

.social-popularity-2 .platform .followers-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.social-popularity-2 .platform .followers-info .number {
  font-weight: 500;
  font-size: 16px;
  line-height: 14px;
}

.social-popularity-2 .platform .followers-info .type {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

.social-popularity-2 .platform.facebook {
  background: #496FBF;
}

.social-popularity-2 .platform.facebook i {
  background: #3C5A98;
}

.social-popularity-2 .platform.twitter {
  background: #1DA1F2;
}

.social-popularity-2 .platform.twitter i {
  background: #0089DD;
}

.social-popularity-2 .platform.linkedin {
  background: #0077B5;
}

.social-popularity-2 .platform.linkedin i {
  background: #005582;
}

.social-popularity-2 .platform.youtube {
  background: #FF3A3A;
}

.social-popularity-2 .platform.youtube i {
  background: #FF0000;
}

@media (max-width: 1230px) {
  .social-popularity-2 {
    -ms-flex-wrap: unset;
    flex-wrap: unset;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .social-popularity-2 .platform {
    width: 100%;
  }
}

.news-topbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 25px;
}

@media (max-width: 768px) {
  .news-topbar {
    margin-bottom: 15px !important;
  }
}

@media (max-width: 400px) {
  .news-topbar {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .news-topbar .section-title,
  .news-topbar .section-title-2 {
    margin-bottom: 15px;
  }
}

.filter-buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #52565C;
  cursor: pointer;
}

.filter-buttons .filter-btn {
  padding: 8px 20px;
  background: #F5F5F5;
  color: #111111;
  margin-right: 5px;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  -webkit-transition: background 500ms;
  transition: background 500ms;
}

.filter-buttons .filter-btn:hover,
.filter-buttons .filter-btn.active {
  background: #1D72FF;
  color: #fff;
}

.filter-buttons .filter-btn:last-child {
  margin-right: 0;
}

.filter-button-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.filter-button-group .filter-btn {
  margin-right: 20px;
}

.filter-button-group .filter-btn:last-child {
  margin-right: 0;
}

.post-pagination-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.post-pagination-area .pag-item {
  width: 45px;
  aspect-ratio: 1;
  display: -ms-grid;
  display: grid;
  place-items: center;
  background: #F5F5F5;
  color: #111111;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin-right: 10px;
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

.post-pagination-area .pag-item:hover {
  background: #1D72FF;
  color: #fff;
}

.type-tag {
  color: #fff;
  font-weight: 700;
  font-size: 15px;
  line-height: 26px;
  width: 135px;
  height: 25px;
  padding-right: 15px;
  padding-left: 5px;
  display: -ms-grid;
  display: grid;
  place-items: center;
  background: #FF1856;
  border-radius: 5px 18px 0 0;
  -webkit-clip-path: polygon(0 0, 92% 0, 100% 100%, 0% 100%);
  clip-path: polygon(0 0, 92% 0, 100% 100%, 0% 100%);
}

.section-type-tag {
  color: #fff;
  font-weight: 700;
  font-size: 30px;
  padding-right: 40px;
  padding-left: 20px;
  display: -ms-grid;
  display: grid;
  place-items: center;
  background: #0E1013;
  border-radius: 5px 5px 0px 5px;
  -webkit-clip-path: polygon(0 0, 92% 0, 100% 100%, 0% 100%);
  clip-path: polygon(0 0, 92% 0, 100% 100%, 0% 100%);
}

@media (max-width: 900px) {
  .section-type-tag {
    font-size: 20px;
    padding: 5px 10px;
    padding-right: 20px;
  }
}

.section-header {
  margin-bottom: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.section-header .section-title {
  margin-bottom: 0;
}

@media (max-width: 980px) {
  .section-header {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: start;
    margin-bottom: 20px;
  }

  .section-header .section-title {
    margin-bottom: 13px;
  }

  .section-header .section-header-item {
    margin-bottom: 20px;
  }
}

.section-header.section-header2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.section-header.section-header3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.section-title-2 {
  color: #040404;
  font-size: 40px;
  line-height: 52px;
  margin-bottom: 40px;
}

@media (max-width: 768px) {
  .section-title-2 {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 15px;
  }
}

.section-title-3::before {
  background: #1D72FF;
}

.top-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .top-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 30px;
  }

  .top-wrapper .section-title-area {
    margin-bottom: 20px !important;
  }
}

.section-title-area {
  margin-bottom: 40px;
}

.section-title-area.text-start .pretitle {
  left: 0;
  -webkit-transform: translate(0);
  transform: translate(0);
}

.section-title-area.text-start p {
  margin: 0;
}

.section-title-area .pretitle {
  position: absolute;
  top: -100px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  font-size: 160px;
  font-weight: 700;
  -webkit-text-stroke-width: 0.3px;
  -webkit-text-fill-color: #f7f7f7;
  -webkit-text-stroke-color: #f7f7f7;
  z-index: -1;
}

.section-title-area .pretitle.pretitlebg {
  -webkit-text-fill-color: #F5F5F5;
}

@media (max-width: 768px) {
  .section-title-area .pretitle {
    top: -50px;
  }
}

@media (max-width: 481px) {
  .section-title-area .pretitle {
    top: -32px;
  }
}

.section-title-area .section-title {
  position: relative;
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 30px;
  color: #111111;
  text-transform: uppercase;
  z-index: 1;
}

.section-title-area p {
  margin: 0 auto;
  max-width: 580px;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
}

.section-title-area.section-title-area2 .section-title {
  font-size: 60px;
  font-weight: 600;
  letter-spacing: -2px;
  line-height: 60px;
}

.section-title-area.section-title-area3 .section-title {
  font-size: 40px;
  font-weight: 600;
  letter-spacing: -2px;
  line-height: inherit;
}

.section-title-area.section-title-area-inner .section-title {
  font-size: 48px;
  font-weight: 600;
  letter-spacing: -2px;
  line-height: 40px;
  margin-bottom: 35px;
}

.section-title-area.section-title-area-inner p {
  color: #777777;
}

@media (max-width: 768px) {
  .section-title-area .pretitle {
    font-size: 90px !important;
  }

  .section-title-area .section-title {
    font-size: 40px !important;
  }
}

@media (max-width: 480px) {
  .section-title-area .pretitle {
    font-size: 60px !important;
  }

  .section-title-area .section-title {
    font-size: 30px !important;
    margin-bottom: 18px !important;
  }
}

.section-header4 {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 10px;
  margin-bottom: 50px;
}

@media (max-width: 768px) {
  .section-header4 {
    margin-bottom: 25px;
  }

  .section-header4 .section-title {
    margin-bottom: 7px;
  }
}

.section-header4 .section-title {
  font-size: 24px;
  color: #031424;
}

.section-header4 .go-btn {
  font-size: 14px;
  color: #071e34;
}

.section-header4 .go-btn:hover i {
  -webkit-transform: translateX(5px);
  transform: translateX(5px);
}

.section-header4 .go-btn i {
  margin-left: 5px;
}

.section-pretitle {
  font-size: 14px;
  line-height: 18px;
  color: var(--theme-color);
}

.section-pretitle2 {
  font-size: 14px;
  line-height: 18px;
  color: var(--theme-color);
  background: #fff;
  padding: 3px 15px;
  -webkit-box-shadow: -16px 0px 32px 0px rgba(0, 0, 0, 0.06);
  box-shadow: -16px 0px 32px 0px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
}

.page-title {
  color: #111111;
  font-weight: 700;
  font-size: 50px;
  line-height: 54px;
}

.post-type-tag {
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  padding: 7px 0;
  padding-right: 40px;
  padding-left: 20px;
  display: -ms-grid;
  display: grid;
  place-items: center;
  background: #0E1013;
  color: #fff;
  border-radius: 5px 5px 0px 5px;
  -webkit-clip-path: polygon(0 0, 92% 0, 100% 100%, 0% 100%);
  clip-path: polygon(0 0, 92% 0, 100% 100%, 0% 100%);
}

.wide-border {
  width: 35%;
  height: 1px;
  background: #E7E7E7;
  margin-right: auto;
  margin-left: 30px;
}

.sticky-content {
  top: -120px;
  position: sticky;
}

@media (max-width: 1200px) {
  .sticky-content {
    top: 0;
  }
}

@media (max-width: 1200px) {
  .side-content {
    -webkit-transform: translate(0);
    transform: translate(0);
    margin-top: 20px;
  }
}

.side-content-title {
  color: #111111;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  margin-bottom: 30px;
  padding-left: 10px;
  position: relative;
}

.side-content-title::before {
  position: absolute;
  left: 0;
  content: "";
  width: 4px;
  height: 100%;
  background: #FF1856;
}

.rts-tags ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  padding-left: 0;
}

.rts-tags .tag-item {
  display: inline-block;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  padding: 5px 13px;
  border-radius: 2px;
  border: 1px solid #E7E7E7;
  margin-right: 7px;
  margin-bottom: 7px;
}

.rts-tags .tag-item:hover {
  background: #FF1856;
  color: #fff;
  border: 1px solid #FF1856;
}

.more-btn i {
  margin-left: 5px;
}

@media (max-width: 400px) {
  .more-btn {
    padding: 9px 10px;
    font-size: 12px;
  }
}

.social-links {
  margin-top: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 1;
  position: relative;
}

.social-links .platform {
  width: 40px;
  aspect-ratio: 1;
  display: -ms-grid;
  display: grid;
  place-items: center;
  margin-right: 7px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 6px;
  color: #fff;
  -webkit-transition: background 500ms;
  transition: background 500ms;
}

.social-links .platform i {
  font-size: 14px;
}

.social-links .platform:hover {
  background: #1D72FF;
}

.side-bar-social-links {
  margin-top: 70px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 1;
  position: relative;
}

.side-bar-social-links .platform {
  width: 40px;
  aspect-ratio: 1;
  display: -ms-grid;
  display: grid;
  place-items: center;
  margin-right: 7px;
  border-radius: 50%;
  background: #1d1d1d;
  margin-right: 10px;
  -webkit-transition: background 400ms;
  transition: background 400ms;
}

.side-bar-social-links .platform i {
  color: #fff;
}

.side-bar-social-links .platform:hover {
  background: var(--theme-color);
}

.social-links-2 .platform {
  width: 0;
  margin-right: 20px;
}

.social-links-2 .platform i {
  color: #868686;
}

.social-links-2 .platform:hover {
  background: none;
}

.social-links-2 .platform:hover i {
  color: #FF1856 !important;
}

.social-links-noborder .platform {
  border: none !important;
  width: 0 !important;
  height: 0 !important;
  padding-right: 15px !important;
}

.rts-post-2 {
  border: 1px solid #E7E7E7;
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

.rts-post-2:hover {
  -webkit-box-shadow: 0px 3px 5px rgba(14, 16, 19, 0.14);
  box-shadow: 0px 3px 5px rgba(14, 16, 19, 0.14);
}

.rts-post-2 .post-picture {
  overflow: hidden;
}

.rts-post-2 .post-picture img {
  width: 100%;
}

.rts-post-2:hover .post-picture img {
  -webkit-transform: scale(110%);
  transform: scale(110%);
}

.rts-post-2 .contents {
  padding-top: 30px;
  padding-bottom: 40px;
  padding-left: 40px;
  padding-right: 30px;
}

.rts-post-2 .contents p {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.rts-post-2 .news-catagory-tag-2 {
  color: #fff;
  font-weight: 700;
  font-size: 13px;
  line-height: 15px;
  background: #FF1856;
  padding: 5px 15px;
  display: -ms-grid;
  display: grid;
  place-items: center;
  margin-bottom: 15px;
  display: inline-block;
  text-transform: none;
}

.rts-post-2 .news-catagory-tag-2::before {
  display: none;
}

.rts-post-2 .post-title {
  max-width: 500px;
  margin-bottom: 10px;
}

.rts-post-2 .post-title a {
  font-weight: 700;
  font-size: 26px;
  line-height: 32px;
}

.rts-post-2 .post-title a {
  background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, #111111), color-stop(50%, transparent));
  background-image: linear-gradient(to right, #111111 50%, transparent 50%);
  background-size: 200% 2px;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  -webkit-transition: background-position 500ms ease-out;
  transition: background-position 500ms ease-out;
}

.rts-post-2 .post-title a:hover {
  background-position: 0% 100%;
}

.rts-post-2 .post-bottom-info,
.rts-post-2 .post-info {
  margin-bottom: 20px;
}

.rts-post-2 .post-bottom-info .item,
.rts-post-2 .post-info .item {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  padding-right: 10px;
  margin-right: 10px;
  position: relative;
}

.rts-post-2 .post-bottom-info .item img,
.rts-post-2 .post-info .item img {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
}

.rts-post-2 .post-bottom-info .item::before,
.rts-post-2 .post-info .item::before {
  content: "";
  position: absolute;
  top: 2px;
  right: -4px;
  width: 2px;
  height: 77%;
  background: rgb(186, 186, 186);
  display: none;
}

.rts-post-2 .post-bottom-info .item:last-child,
.rts-post-2 .post-info .item:last-child {
  padding-right: 0;
  margin-right: 0;
}

.rts-post-2 .post-bottom-info .item:last-child::before,
.rts-post-2 .post-info .item:last-child::before {
  display: none;
}

.rts-post-2 .post-bottom-info .post-author::before,
.rts-post-2 .post-info .post-author::before {
  display: none;
}

.rts-post-2 .read-more {
  margin-top: 20px;
  background: transparent;
  border: 2px solid rgba(255, 255, 255, 0.1);
  padding: 10px 25px;
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

.rts-post-2 .read-more a {
  color: #fff;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
}

.rts-post-2 .read-more:hover {
  background: #FF1856;
  border: 2px solid #FF1856;
}

.main_logo {
  width: 300px;
  height: 300px;
  margin: 10px 0;
}
.news-letter-logo{
  width: 300px;
  margin-left: 25vw;
}
@media (max-width: 520px) {
  .news-letter-logo{
    height: 10vh;
    margin: 0px 0;
  }

}

@media (max-width: 520px) {
  #rtsHeader.rts-header1 .navbar-part {
    margin-top: 20px;
  }

  .main_logo {
    height: 60px;
    margin: 0px 0;
  }

  .rts-post-2 .contents {
    padding: 20px;
  }

  .rts-post-2 .contents .post-title a {
    font-size: 24px;
    line-height: 30px;
  }
}

@media (max-width: 420px) {
  .rts-post-2 .contents .post-title a {
    font-size: 20px;
    line-height: 25px;
  }
}

.border-btn {
  display: inline-block;
  background: transparent;
  padding: 10px 25px;
  border: 2px solid #E7E7E7;
  color: #111111;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

.border-btn:hover {
  color: #fff;
  background: #FF1856;
  border: 2px solid #FF1856;
}

.border-btn i {
  margin-left: 5px;
}

.view-all-link {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
}

.view-all-link i {
  margin-left: 10px;
}

.view-all-link:hover {
  color: var(--theme-color);
}

.rts-post-2-medium .post-title a {
  font-size: 20px;
  line-height: 24px;
  background-size: 200% 1px;
}

.rts-post-2-small .post-title a {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  background-size: 200% 1px;
}

.rts-post-2-small .post-bottom-info {
  margin-top: 4px;
}

.rts-post-2-inside {
  position: relative;
  border: none;
}

.rts-post-2-inside::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80%;
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(black));
  background-image: linear-gradient(transparent, black);
  z-index: 1;
  -webkit-transition: all 500ms;
  transition: all 500ms;
  pointer-events: none;
}

.rts-post-2-inside:hover:before {
  height: 100%;
}

.rts-post-2-inside .post-title a {
  color: #fff;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, #ffffff), color-stop(50%, transparent));
  background-image: linear-gradient(to right, #ffffff 50%, transparent 50%);
  background-size: 200% 2px;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  -webkit-transition: background-position 500ms ease-out;
  transition: background-position 500ms ease-out;
}

.rts-post-2-inside .post-title a:hover {
  background-position: 0% 100%;
}

.rts-post-2-inside .contents {
  padding: 0;
  position: absolute;
  bottom: 30px;
  left: 30px;
  z-index: 1;
}

.rts-post-2-inside .contents .post-bottom-info .item {
  color: #fff;
}

.rts-post-2-inside .contents .post-bottom-info .item img {
  -webkit-filter: brightness(100);
  filter: brightness(100);
}

@media (max-width: 1200px) {
  .rts-post-2-inside:before {
    height: 100%;
  }
}

.rts-post-2-inside.rts-post-2-medium .contents {
  left: 10px;
  bottom: 10px;
}

@media (max-width: 859px) {
  .rts-post-2-inside .contents .post-title {
    font-size: 25px;
  }
}

@media (max-width: 500px) {
  .rts-post-2-inside .contents .post-title {
    font-size: 23px;
    line-height: 30px;
  }
}

@media (max-width: 440px) {
  .rts-post-2-inside .contents {
    padding-left: 0 !important;
    left: 10px;
    padding-left: 0;
  }

  .rts-post-2-inside .contents .post-title {
    font-size: 20px;
    line-height: 27px;
  }
}

@media (max-width: 400px) {
  .rts-post-2-inside .contents .post-title a {
    font-size: 17px;
    line-height: 25px;
  }
}

.rts-post-2-wide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.rts-post-2-wide .post-picture {
  max-width: 370px;
  width: 100%;
}

.rts-post-2-wide .contents {
  padding: 0 25px;
}

.rts-post-2-wide .contents .post-title {
  margin-bottom: 10px;
  max-width: unset;
}

.rts-post-2-wide .contents .post-title a {
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
}

.rts-post-2-wide .contents p {
  margin-bottom: 10px;
}

.rts-post-2-wide .contents .read-more-btn {
  margin-top: 15px;
}

@media (max-width: 1080px) {
  .rts-post-2-wide .contents .post-title a {
    font-size: 20px;
    line-height: 30px;
  }
}

@media (max-width: 768px) {
  .rts-post-2-wide {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .rts-post-2-wide .post-picture {
    max-width: unset;
    width: 100%;
    margin-bottom: 20px;
  }

  .rts-post-2-wide .read-more-btn {
    margin-top: 15px;
    margin-bottom: 30px;
  }
}

@media (max-width: 400px) {
  .rts-post-2-wide .contents {
    padding: 0 17px;
  }

  .rts-post-2-wide .contents .post-title a {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    display: inline-block;
  }
}

@media (max-width: 330px) {
  .rts-post-2-wide .post-bottom-info-3 .content .post-date {
    margin-top: 5px;
    display: block !important;
  }

  .rts-post-2-wide .post-bottom-info-3 .content .post-date .post-age::before {
    display: none;
  }
}

@media (max-width: 860px) {
  .popular-stories .rts-post-small .contents .post-title a {
    font-size: 18px !important;
  }
}

@media (max-width: 440px) {
  .popular-stories .rts-post-small .contents .post-title a {
    font-size: 16px !important;
  }
}

.shop-now-box {
  display: inline-block;
  overflow: hidden;
  position: relative;
}

.shop-now-box .picture {
  position: relative;
}

.shop-now-box .picture::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #040404;
  opacity: 0;
  z-index: 1;
  -webkit-transition: opacity 500ms;
  transition: opacity 500ms;
}

.shop-now-box:hover img {
  -webkit-transform: scale(110%);
  transform: scale(110%);
}

.shop-now-box:hover::before {
  opacity: 40%;
}

.shop-now-box .contents {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 60px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 3;
}

.shop-now-box .contents .collection-text {
  font-size: 12px;
  line-height: 16px;
  color: #111111;
}

.shop-now-box .contents .title {
  font-size: 30px;
  line-height: 39px;
  color: #fff;
  margin-bottom: 45px;
}

@media (max-width: 1200px) {
  .shop-now-box {
    width: 100%;
  }

  .shop-now-box .picture {
    width: 100%;
  }

  .shop-now-box .picture img {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .shop-now-box .contents .title {
    font-size: 24px;
  }
}

.shop-now-box.shop-now-box2 {
  position: relative;
}

.shop-now-box.shop-now-box2 .collection-text {
  min-width: 200px;
  position: absolute;
  top: -5px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background: #fff;
  padding: 15px 20px;
  border-radius: 7px;
  z-index: 2;
}

.shop-now-box.shop-now-box2 .contents .title {
  margin-bottom: 20px;
}

.collection-box-wide {
  overflow: hidden;
  position: relative;
}

.collection-box-wide:hover img {
  -webkit-transform: scale(110%);
  transform: scale(110%);
}

.collection-box-wide:hover::before {
  opacity: 0.4;
}

.collection-box-wide .box-bg {
  width: 705px;
  position: relative;
}

.collection-box-wide .box-bg::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #040404;
  opacity: 0;
  z-index: 1;
  -webkit-transition: opacity 500ms;
  transition: opacity 500ms;
}

.collection-box-wide .box-bg img {
  width: 100%;
}

.collection-box-wide .contents {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1;
  text-align: center;
}

.collection-box-wide .contents .pretitle {
  color: #fff;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
}

.collection-box-wide .contents .title {
  color: #fff;
  font-weight: 700;
  font-size: 30px;
  line-height: 39px;
}

@media (max-width: 768px) {
  .collection-box-wide .contents .title {
    font-size: 24px;
  }
}

.filterd-items {
  visibility: visible;
  opacity: 1;
  position: relative;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: all 1.2s;
  transition: all 1.2s;
  height: auto;
}

.filterd-items.hide {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  -webkit-transform: translateY(50px);
  transform: translateY(50px);
  -webkit-transition: none;
  transition: none;
  height: 0;
  overflow: hidden;
}

.filterd-items2.hide {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  -webkit-transform: translateY(50px);
  transform: translateY(50px);
  -webkit-transition: none;
  transition: none;
  height: 0;
  overflow: hidden;
}

.newsletter {
  position: relative;
  background: #F5F5F5;
  padding: 40px 20px;
}

.newsletter .newsletter-icon {
  margin-bottom: 10px;
  max-width: 60px;
}

.newsletter .newsletter-title {
  color: #111111;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
}

.newsletter p {
  color: #111111;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  margin-bottom: 20px;
}

.newsletter form {
  width: 100%;
}

.newsletter .input-div {
  width: 100%;
  margin-bottom: 15px;
}

.newsletter input {
  width: 100%;
  color: #737373;
  font-size: 14px;
  padding: 15px;
  -webkit-box-shadow: 0px 2px 4px rgba(14, 16, 19, 0.1);
  box-shadow: 0px 2px 4px rgba(14, 16, 19, 0.1);
}

.newsletter .subscribe-btn {
  background: #1D72FF;
  color: #fff;
  padding: 10px 20px;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  -webkit-transition: background 500ms;
  transition: background 500ms;
}

.newsletter .subscribe-btn:hover {
  background: #4e92ff;
}

.newsletter .foating-elements {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.instagram-moments {
  margin-left: 15px;
}

.instagram-moments .col-xl-4 {
  padding: 0;
}

.instagram-moments .moment-picture {
  position: relative;
  margin-bottom: 10px;
  margin-right: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.instagram-moments .moment-picture::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0%;
  -webkit-transition: opacity 500ms;
  transition: opacity 500ms;
}

.instagram-moments .moment-picture img {
  width: 100%;
}

.instagram-moments .moment-picture:hover .ig-icon {
  -webkit-transform: translate(-50%, -50%) scale(100%);
  transform: translate(-50%, -50%) scale(100%);
  opacity: 1;
}

.instagram-moments .moment-picture:hover::before {
  opacity: 30%;
}

.instagram-moments .moment-picture .ig-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #fff;
  font-size: 20px;
  -webkit-transform: translate(-50%, -50%) scale(60%);
  transform: translate(-50%, -50%) scale(60%);
  opacity: 0;
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

@media (max-width: 1200px) {
  .news-box-container {
    max-width: 700px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.scroll-top-btn {
  display: inline-block;
  background: #01B241;
  width: 40px;
  height: 60px;
  text-align: center;
  border-radius: 20px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  -webkit-transition: all 500ms;
  transition: all 500ms;
  visibility: hidden;
  -webkit-transform: translateY(100px);
  transform: translateY(100px);
  z-index: 99;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-shadow: -3px 3px 7px 0px rgba(0, 0, 0, 0.076);
  box-shadow: -3px 3px 7px 0px rgba(0, 0, 0, 0.076);
}

.scroll-top-btn i {
  color: #fff;
}

.scroll-top-btn .arrow-up {
  margin-top: 5px;
  margin-bottom: 10px;
}

.scroll-top-btn:hover {
  bottom: 40px;
}

.scroll-top-btn1 {
  background: #01B241;
}

.scroll-top-btn4 {
  background: var(--theme-color);
}

.scroll-top-btn4 i {
  color: #111111;
}

.navbar-sticky {
  -webkit-animation: none;
  animation: none;
}

.sticked-navbar .logo {
  display: none;
}

.sticky-menu,
.sticky-menu-mobile {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  -webkit-box-shadow: 0 7px 12px 0 rgba(0, 0, 0, 0.162);
  box-shadow: 0 7px 12px 0 rgba(0, 0, 0, 0.162);
  z-index: 50;
  -webkit-animation: slideDownn 500ms ease;
  animation: slideDownn 500ms ease;
}

.sticky-menu .navbar-part1 .rts-menu,
.sticky-menu-mobile .navbar-part1 .rts-menu {
  margin-top: 0;
}

.sticky-menu .navbar-part1 .rts-menu .menu-item,
.sticky-menu-mobile .navbar-part1 .rts-menu .menu-item {
  color: #111111;
  background: transparent;
}

.sticky-menu .sticked-navbar .logo,
.sticky-menu-mobile .sticked-navbar .logo {
  display: block;
}

.sticky-menu .quick-weather,
.sticky-menu-mobile .quick-weather {
  display: none;
}

.sticky-menu .navbar-part,
.sticky-menu-mobile .navbar-part {
  margin-bottom: 0 !important;
}

.sticky-menu .header-action-items,
.sticky-menu-mobile .header-action-items {
  margin-top: 0 !important;
}

.sticky-menu-mobile {
  display: none;
}

@media (max-width: 900px) {
  .sticky-menu-mobile {
    display: block;
  }
}

.navbar-coupon-code {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 20px 0;
}

.navbar-coupon-code .icon {
  margin-right: 10px;
}

.navbar-coupon-code .content span {
  display: block;
}

.navbar-coupon-code .content .title {
  color: #6f8294;
  font-size: 10px;
  line-height: 18px;
}

.navbar-coupon-code .content .code {
  color: #fff;
  font-size: 14px;
  line-height: 18px;
}

.scroll-top-btn.jumpTop {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.slider-div {
  position: relative;
  min-width: 130%;
  right: 0;
}

.product-item {
  position: relative;
  padding: 5px;
  border-radius: 6px;
  background: #fff;
  -webkit-filter: drop-shadow(0px 16px 16px rgba(0, 0, 0, 0.04));
  filter: drop-shadow(0px 16px 16px rgba(0, 0, 0, 0.04));
  overflow: hidden;
  margin-bottom: 30px;
}

.product-item:hover .product-thumb img {
  -webkit-transform: scale(110%);
  transform: scale(110%);
}

.product-item:hover .product-action-area .product-price {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px);
}

.product-item:hover .product-action-area .addto-cart {
  font-size: 15px;
  font-weight: 600;
  color: var(--theme-color);
  opacity: 1;
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px);
  visibility: visible;
}

.product-item .product-thumb {
  margin-bottom: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 65px;
  background-color: #ffffff;
  border: 1px solid #e9e9e9;
  height: 273px;
}

.product-item .product-thumb img {
  width: 100%;
}

.product-item .rating-area {
  margin-bottom: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.product-item .rating-area .rating-stars-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.product-item .rating-area .rating-stars-group .rating-star i {
  font-size: 14px;
  color: #ff9c00;
  margin-right: 5px;
}

.product-item .rating-area .rating-stars-group .rating-star.blank-star i {
  color: #CDCDCD;
}

.product-item .product-name {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 5px;
}

.product-item .product-price {
  font-size: 14px;
  font-weight: 600;
  color: var(--theme-color);
  font-family: "Roboto";
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.product-item .product-price .prev-price {
  color: #777777;
  position: relative;
}

.product-item .product-price .prev-price::before {
  content: "";
  position: absolute;
  background: #777777;
  width: 100%;
  height: 1px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.product-item .old-price {
  color: #d1d1d1;
  text-decoration: line-through;
  margin-left: 5px;
}

.product-item .addto-cart {
  opacity: 0;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  visibility: hidden;
}

.product-item .product-tag-area {
  position: absolute;
  top: 30px;
  left: 30px;
}

.product-item .sale-tag {
  width: 70px;
  height: 25px;
  background: var(--theme-color);
  font-family: "Roboto";
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
}

.product-item .product-features {
  position: absolute;
  top: 5%;
  left: 10%;
  -webkit-transition: all 300ms;
  transition: all 300ms;
  z-index: 1;
}

.product-item .product-features .product-tag {
  width: 50px;
  height: 25px;
  font-size: 12px;
  font-weight: 600;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 6px;
  background: #fff;
  color: #fff;
  margin-bottom: 10px;
}

.product-item .product-features .product-tag.new-tag {
  background: #72a398;
}

.product-item .product-features .product-tag.hot-tag {
  background: #4b46b9;
}

.product-item .product-features .product-tag.discount-tag {
  background: #d83232;
}

.product-item .product-actions {
  position: absolute;
  top: 5%;
  right: 10%;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateX(10px);
  transform: translateX(10px);
  -webkit-transition: all 300ms;
  transition: all 300ms;
  z-index: 1;
}

.product-item .product-actions .product-action {
  width: 40px;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 6px;
  background: #fff;
  color: #040404;
  margin-bottom: 10px;
  -webkit-box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.108);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.108);
}

.product-item .product-actions.product-actions2 .product-action {
  border-radius: 50%;
}

.product-item:hover .product-actions {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

@media (max-width: 576px) {
  .product-item .product-thumb {
    height: inherit;
  }
}

.cart-bar .product-item .product-thumb {
  max-width: 90px;
  height: 90px;
  padding: 10px;
  margin-bottom: 0;
}

.image-hover-variations {
  margin-bottom: 0 !important;
}

.image-hover-variations .image-vari2 {
  max-width: 0;
  opacity: 0;
  position: absolute;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.image-hover-variations .image-vari1 {
  opacity: 1;
  position: relative;
}

.image-hover-variations .image-vari {
  height: auto;
  overflow: hidden;
  -webkit-transition: opacity 1s, -webkit-transform 1s;
  transition: opacity 1s, -webkit-transform 1s;
  transition: opacity 1s, transform 1s;
  transition: opacity 1s, transform 1s, -webkit-transform 1s;
}

.image-hover-variations .image-vari img {
  width: 100%;
}

.image-hover-variations {
  display: block;
  overflow: hidden;
  background: #E5E5E5;
}

.image-hover-variations:hover .image-vari1 {
  opacity: 0;
  max-width: 0;
  position: absolute;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.image-hover-variations:hover .image-vari2 {
  opacity: 1;
  max-width: 100%;
  position: relative;
  -webkit-transform: scale(105%);
  transform: scale(105%);
}

.image-slider-variations {
  display: block;
}

.image-slider-variations .swiper {
  margin-bottom: -23px;
}

.image-slider-variations .slider-buttons {
  visibility: hidden;
  opacity: 0;
  -webkit-transform: scale(90%);
  transform: scale(90%);
  position: absolute;
  top: 130px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 15px;
  -webkit-transition: all 500ms;
  transition: all 500ms;
  z-index: 10;
}

.image-slider-variations .slider-buttons .slider-btn {
  background: #fff;
  padding: 10px 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.image-slider-variations .slider-buttons .slider-btn i {
  color: #111111;
}

.image-slider-variations3 .swiper {
  margin-bottom: 0;
}

.image-gallery-variations {
  display: block;
  overflow: visible;
  -webkit-transition: -webkit-transform 0.7s;
  transition: -webkit-transform 0.7s;
  transition: transform 0.7s;
  transition: transform 0.7s, -webkit-transform 0.7s;
}

.image-gallery-variations .swiper {
  margin-bottom: -23px;
}

.image-gallery-variations3 .swiper {
  margin-bottom: 0;
}

.thumbs-area {
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  position: absolute;
  bottom: 107px;
  left: 60px;
  width: 100%;
  z-index: 20;
  -webkit-transition: opacity 0.7s, visibility 0.7s ease, -webkit-transform 0.7s;
  transition: opacity 0.7s, visibility 0.7s ease, -webkit-transform 0.7s;
  transition: transform 0.7s, opacity 0.7s, visibility 0.7s ease;
  transition: transform 0.7s, opacity 0.7s, visibility 0.7s ease, -webkit-transform 0.7s;
}

.thumbs-area .swiper-slide {
  border: 3px solid #F5F5F5;
  cursor: pointer;
}

.element-item2:hover .slider-buttons {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.element-item3:hover .image-gallery-variations {
  -webkit-transform: translateY(-30px);
  transform: translateY(-30px);
}

.element-item3:hover .thumbs-area {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.action-item .icon {
  position: relative;
}

.icon-dot {
  width: 18px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: var(--theme-color);
  color: #fff;
  font-size: 12px;
  font-weight: normal;
  position: absolute;
  top: -11px;
  right: -14px;
  display: -ms-grid;
  display: grid;
  place-items: center;
}

.cart-bar.show {
  visibility: visible;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.cart-bar {
  position: fixed;
  top: 0;
  right: 0;
  background: #fff;
  width: 360px;
  height: 100%;
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  visibility: hidden;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 100;
}

.cart-bar .cart-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid #E7E7E7;
}

.cart-bar .cart-header .cart-heading {
  font-size: 17px;
  font-weight: 500;
  color: #404040;
}

.cart-bar .product-area3 .product-item .bottom-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cart-bar .product-item {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid #E7E7E7;
}

.cart-bar .product-item:last-child {
  margin-bottom: 0;
}

.cart-bar .product-detail {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.cart-bar .product-detail span {
  display: inline-block;
  line-height: 19px !important;
}

.cart-bar .product-detail .product-thumb {
  margin-right: 15px;
  max-width: 75px;
}

.cart-bar .product-detail .product-name {
  font-size: 14px;
  font-weight: 400;
  color: #111111;
}

.cart-bar .product-detail .product-variation span {
  color: #868686;
  font-family: roboto;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
}

.cart-bar .product-detail .product-qnty {
  margin-right: 5px;
}

.cart-bar .product-detail .product-qnty,
.cart-bar .product-detail .product-price {
  color: #404040;
  font-weight: 500;
  font-size: 13px;
  font-family: roboto;
}

.cart-bar .cart-edit {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.cart-bar .cart-edit .item-wrapper {
  display: inline-block;
}

.cart-bar .cart-edit .quantity-edit {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: 1px solid #E7E7E7;
  padding: 3px 10px;
  border-radius: 5px;
  margin-bottom: 3px;
}

.cart-bar .cart-edit .quantity-edit input {
  text-align: center;
  max-width: 40px;
}

.cart-bar .cart-edit .quantity-edit button {
  background: none;
  font-size: 0;
}

.cart-bar .cart-edit .quantity-edit button i {
  font-size: 14px;
  color: #C0C0C0;
}

.cart-bar .cart-edit .product-edit {
  margin-right: 15px;
}

.cart-bar .cart-edit .product-edit i {
  font-size: 13px;
  color: #868686;
}

.cart-bar .cart-edit .delete-cart i {
  font-size: 16px;
  color: #4E4E4E;
  margin-top: 5px;
}

.cart-bar .cart-bottom-area {
  margin-top: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.cart-bar .cart-bottom-area .spend-shipping {
  margin-bottom: 30px;
  background: #F5F5F5;
  padding: 10px 15px;
  font-size: 12px;
  font-weight: 500;
  color: #404040;
  text-align: center;
}

.cart-bar .cart-bottom-area .spend-shipping .amount {
  font-weight: 700;
  color: #040404;
}

.cart-bar .cart-bottom-area .spend-shipping i {
  font-size: 15px;
  margin-right: 7px;
}

.cart-bar .cart-bottom-area .total-price {
  font-size: 18px;
  color: #868686;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 20px;
}

.cart-bar .cart-bottom-area .cart-btn {
  width: 100%;
  padding: 10px 20px;
  border-radius: 7px;
  font-size: 14px;
  text-align: center;
  -webkit-transition: all 300ms;
  transition: all 300ms;
}

.cart-bar .cart-bottom-area .checkout-btn {
  border: 1px solid #040404;
  margin-bottom: 10px;
}

.cart-bar .cart-bottom-area .checkout-btn:hover {
  background: #040404;
  color: var(--theme-color);
}

.cart-bar .cart-bottom-area .view-btn {
  border: 1px solid transparent;
  background: #040404;
  color: #fff;
}

.cart-bar .cart-bottom-area .view-btn:hover {
  background: var(--theme-color);
}

@media (max-width: 768px) {
  .cart-bar {
    width: 100%;
  }
}

.close-cart {
  cursor: pointer;
}

.rating-stars-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.rating-stars-group .rating-star i {
  font-size: 14px;
  color: #111111;
  margin-right: 5px;
}

.rating-stars-group .rating-star.blank-star i {
  color: #CDCDCD;
}

.product-item4 {
  padding: 40px;
  border: 1px solid #eeeeee;
  border-radius: 6px;
}

.product-item2 .product-item4:hover .product-image img {
  -webkit-transform: scale(110%);
  transform: scale(110%);
}

.product-item2 .product-item4 .product-image {
  overflow: hidden;
  margin-bottom: 20px !important;
}

.product-item2 .product-item4 .bottom-content {
  text-align: center;
}

.product-item2 .product-item4 .bottom-content .product-catagory {
  display: block;
  font-size: 12px;
  color: #999999;
  margin-bottom: 5px;
}

.product-item2 .product-item4 .bottom-content .product-name {
  font-weight: 700 !important;
  font-size: 16px !important;
}

.product-item2 .product-item4 .bottom-content .action-wrap .product-price {
  color: var(--theme-color);
}

.product-item2 .product-item4.element-item3 .thumbs-area {
  bottom: 102px;
}

.cart-bar-inner .checkout-btn:hover {
  color: #fff !important;
}

.product-item.product-item4:hover .action-wrap .addto-cart {
  -webkit-transform: translateY(-27px);
  transform: translateY(-27px);
}

.product-item.product-item4 .product-catagory {
  font-size: 12px;
  color: #777777;
  display: block;
}

.product-item.product-item4 .product-name {
  font-size: 16px;
  color: #031424;
  margin-bottom: 10px;
}

.product-item.product-item4 .action-wrap {
  max-height: 25px;
}

.product-item.product-item4 .product-price {
  font-size: 16px;
  color: #0070dc;
}

.product-item.product-item4 .wishlist-btn {
  display: inline-block;
  margin-left: auto;
  background: none;
  -webkit-transition: color 200ms !important;
  transition: color 200ms !important;
}

.product-item.product-item4 .wishlist-btn:hover {
  color: red;
}

.product-item.product-item4 .flex-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.product-box {
  /* background-image: url("../public/images/background/productbg.html"); */
  background-size: cover;
  background-repeat: no-repeat;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 7px;
  overflow: hidden;
}

.product-box:hover .product-thumb {
  -webkit-transform: scale(105%);
  transform: scale(105%);
}

.product-box .product-thumb {
  -webkit-transition: -webkit-transform 300ms;
  transition: -webkit-transform 300ms;
  transition: transform 300ms;
  transition: transform 300ms, -webkit-transform 300ms;
}

.product-box.product-box-large {
  padding: 146px 80px;
  margin-right: 10px;
  position: relative;
}

.product-box.product-box-large .product-thumb {
  max-width: 530px;
  position: absolute;
  right: 10%;
}

.product-box.product-box-large .product-thumb img {
  max-width: 100%;
}

.product-box.product-box-large .pretitle {
  font-size: 14px;
  padding: 4px 20px;
  border-radius: 5px;
  margin-bottom: 25px;
  background: var(--theme-color);
}

.product-box.product-box-large .product-title {
  font-size: 50px;
  line-height: 60px;
  font-weight: 400;
  color: #031424;
  margin-bottom: 30px;
}

.product-box.product-box-large .product-title span {
  font-weight: 700;
}

.product-box.product-box-large .product-price {
  padding: 10px 40px;
  padding-bottom: 20px;
  background: #fff;
  border-radius: 7px;
  font-size: 30px;
  line-height: 30px;
  color: #ef262c;
}

.product-box.product-box-large .product-price span {
  font-size: 12px;
  color: #031424;
  display: block;
}

.product-box.product-box-medium {
  padding: 40px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 15px;
}

.product-box.product-box-medium:hover .product-thumb {
  -webkit-transform: scale(105%);
  transform: scale(105%);
}

.product-box.product-box-medium.product-box-bg {
  /* background-image: url("../public/images/background/productbghover.html"); */
  background-size: 120%;
}

.product-box.product-box-medium.product-box-bg .contents .pretitle {
  color: #c7c7c7;
}

.product-box.product-box-medium.product-box-bg .contents .product-title {
  color: #fff;
}

.product-box.product-box-medium.product-box-bg .contents .product-title span {
  color: #fff;
}

.product-box.product-box-medium.product-box-bg .contents p {
  color: #fff;
}

.product-box.product-box-medium.product-box-bg .contents .go-btn {
  color: #fff;
}

.product-box.product-box-medium .product-thumb {
  max-width: 95px;
}

.product-box.product-box-medium .contents .pretitle {
  font-size: 14px;
  padding: 2px 20px;
  border-radius: 5px;
  margin-bottom: 20px;
  background: var(--theme-color);
}

.product-box.product-box-medium .contents .product-title {
  font-size: 24px;
  line-height: 34px;
  color: #c9c9c9;
  font-weight: 500;
}

.product-box.product-box-medium .contents .product-title span {
  color: #031424;
  font-weight: 700;
}

.product-box.product-box-medium .contents p {
  font-size: 14px;
  line-height: 34px;
  color: #777777;
  margin-bottom: 0;
}

.product-box.product-box-medium .go-btn {
  font-size: 14px;
  line-height: 34px;
  color: #777777;
}

.product-box.product-box-medium .go-btn:hover {
  color: #040404;
}

.product-box.product-box-medium .go-btn:hover i {
  margin-left: 7px;
}

.product-box.product-box-medium .go-btn i {
  margin-left: 3px;
}

.product-box.product-box-medium.product-box-medium2 .pretitle {
  background: none;
  padding: 0;
  font-size: 20px;
  line-height: 32px;
  color: #ef262c;
  margin-bottom: 0;
}

.product-box.product-box-medium.product-box-medium2 .product-title {
  font-size: 24px;
  line-height: 32px;
  font-weight: 400;
  color: #031424;
  margin-bottom: 25px;
}

.product-box.product-box-medium.product-box-medium3 .pretitle {
  background: none;
  padding: 0;
  font-size: 12px;
  line-height: 34px;
  color: #ef262c;
  margin-bottom: 0;
  letter-spacing: 3px;
}

.product-box.product-box-medium.product-box-medium3 .product-title {
  font-size: 30px;
  line-height: 34px;
  color: #031424;
  margin-bottom: 25px;
}

.detail-product {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.detail-product .product-thumb img {
  width: 100%;
}

.detail-product .side-box .product {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 46px 40px;
  border: 1px solid #e7e7e7;
}

.detail-product .side-box .product .product-thumb {
  max-width: 410px;
  margin-bottom: 10px;
}

.detail-product .side-box .product .contents {
  width: 100%;
  position: relative;
}

.detail-product .side-box .product .contents .product-catagory {
  font-size: 12px;
  color: #777777;
  margin-bottom: 0;
  font-weight: 400;
}

.detail-product .side-box .product .contents .product-title {
  font-size: 16px;
  line-height: 20px;
  color: #031424;
  margin-bottom: 5px;
}

.detail-product .side-box .product .contents .product-price {
  font-size: 16px;
  color: #0070dc;
  margin-bottom: 0;
}

.detail-product .side-box .product .contents .product-price .old-price {
  font-size: 16px;
  margin-left: 10px;
}

.detail-product .side-box .product .contents .cart-btn {
  position: absolute;
  bottom: 10px;
  right: 30px;
  background: #00a046;
}

.detail-product .side-box .product .contents .cart-btn i {
  color: #fff;
}

.detail-product .section-header .flex-wrapper {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.detail-product .section-header .countdown {
  margin-left: 10px;
}

.detail-product .section-header .go-btn {
  color: #0070dc;
}

.detail-product .contents .rating-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 15px;
}

.detail-product .contents .rating-area .rating-qnty {
  font-size: 14px;
  color: #777777;
}

.detail-product .contents .rating-area .rating-stars-group .rating-star i {
  color: #ffbd27;
}

.detail-product .contents .product-title {
  font-size: 24px;
  line-height: 34px;
  color: #031424;
  margin-bottom: 30px;
  max-width: 315px;
}

.detail-product .contents .product-price {
  font-size: 50px;
  line-height: 34px;
  color: #0070dc;
  margin-bottom: 35px;
}

.detail-product .contents .product-price .old-price {
  font-size: 30px;
  color: #cad0d6;
  font-weight: 400;
  text-decoration: line-through;
}

.detail-product .product-buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.detail-product button {
  display: block;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 6px;
  padding: 0 15px;
  background: none;
  border: 1px solid #e7e7e7;
  -webkit-box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
}

.detail-product button:hover {
  -webkit-box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.169);
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.169);
}

.detail-product .select-option-btn {
  font-size: 16px;
  line-height: 34px;
  padding: 0 20px;
  font-weight: 700;
  margin-right: 10px;
}

.detail-product .wishlist-btn {
  margin-right: 10px;
}

.flex-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.countdown {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.countdown .countdown-el {
  background: #0070dc;
  width: 37px;
  height: 37px;
  padding: 4px;
  border-radius: 5px;
  margin-right: 5px;
  padding-left: 9px;
}

.countdown .countdown-el .value {
  margin-top: 9px;
  margin-bottom: 1px;
  font-size: 14px;
  color: #fff;
  font-weight: 700;
  line-height: 0;
}

.countdown .countdown-el span {
  font-size: 10px;
  font-weight: 400;
  color: #94caff;
  line-height: 0;
}

.cart-edit {
  margin-right: 20px;
}

.cart-edit .quantity-edit {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 3px 10px;
  border-radius: 5px;
}

.cart-edit .quantity-edit input {
  text-align: center;
  max-width: 55px;
  font-size: 16px;
  font-weight: 700;
  color: #040404;
}

.cart-edit .quantity-edit button {
  background: none;
  font-size: 0;
}

.cart-edit .quantity-edit button i {
  font-size: 16px;
  color: #d3d3d3;
}

.side-sticky {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
}

.menu-tag {
  color: #fff;
  border-radius: 4px;
  padding: 1px 5px;
  font-size: 10px;
  margin-left: 7px;
}

.new-tag {
  background: var(--theme-color);
}

.hot-tag {
  background: #111111;
}

.rts-newsletter-popup {
  position: fixed;
  top: 20%;
  left: 50%;
  max-width: 605px;
  width: 90%;
  border-radius: 6px;
  background: #fff;
  z-index: 99;
  visibility: hidden;
  -webkit-transform: translateX(-50%) scale(70%);
  transform: translateX(-50%) scale(70%);
  opacity: 0;
  -webkit-transition: all 300ms;
  transition: all 300ms;
  -webkit-animation: pop 300ms;
  animation: pop 300ms;
}

.rts-newsletter-popup.popup {
  visibility: visible;
  -webkit-transform: translateX(-50%) scale(1);
  transform: translateX(-50%) scale(1);
  opacity: 1;
}

.rts-newsletter-popup .newsletter-inner {
  padding: 75px 50px;
}

.rts-newsletter-popup .newsletter-inner .newsletter-heading {
  font-size: 30px;
  margin-bottom: 10px;
}

.rts-newsletter-popup .newsletter-inner p {
  margin-bottom: 30px;
}

.rts-newsletter-popup .newsletter-inner .input-div {
  width: 100%;
  border-radius: 6px;
  border: 1px solid #f0f0f0;
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
}

.rts-newsletter-popup .newsletter-inner input {
  padding: 20px 25px;
  width: 100%;
  padding-right: 90px;
}

.rts-newsletter-popup .newsletter-inner .input-icon {
  position: absolute;
  right: 0;
  top: 0;
  width: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-left: 1px solid #f0f0f0;
  height: 100%;
}

.rts-newsletter-popup .newsletter-inner .subscribe-btn {
  padding: 20px 0;
  width: 100%;
  border-radius: 6px;
  background: #040404;
  color: #fff;
  font-size: 16px;
}

.rts-newsletter-popup .newsletter-inner .subscribe-btn:hover {
  background: #111111;
}

.rts-newsletter-popup .newsletter-close-btn {
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  top: -50px;
  right: -50px;
  cursor: pointer;
}

.rts-newsletter-popup .newsletter-close-btn:hover i {
  color: red;
}

.rts-newsletter-popup .newsletter-close-btn i {
  color: #040404;
  font-size: 18px;
}

@media (max-width: 768px) {
  .rts-newsletter-popup .newsletter-close-btn {
    top: -60px;
    right: -10px;
  }

  .rts-newsletter-popup .newsletter-inner {
    padding: 40px 20px;
  }

  .rts-newsletter-popup .newsletter-inner .newsletter-heading {
    font-size: 23px;
  }

  .rts-newsletter-popup .newsletter-inner input {
    padding: 15px 20px;
  }
}

.products-area3 .product-item .bottom-content {
  text-align: center;
}

.products-area3 .product-item .bottom-content .product-catagory {
  display: block;
  font-size: 12px;
  color: #999999;
}

.products-area3 .product-item .bottom-content .product-name {
  font-size: 16px;
  color: #040404;
  font-weight: 700;
}

.products-area3 .product-item .bottom-content .product-price {
  color: var(--theme-color);
}

.match-single {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.match-single .match-date {
  font-size: 14px;
  font-weight: 600;
  padding: 3px 10px;
  color: #fff;
  background: var(--theme-color);
  margin-bottom: 15px;
}

.match-single .match-scores {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 20px;
}

.match-single .club {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.match-single .club .club-logo {
  max-width: 70px;
}

.match-single .club .block {
  text-align: center;
}

.match-single .club .score-number {
  font-size: 60px;
  font-weight: 700;
}

.match-single .club .club-name {
  font-size: 14px;
  font-weight: 400;
  color: #777777;
}

.match-single .colon {
  font-size: 60px;
  font-weight: 300;
  color: #c5c5c5;
  margin: 0 20px;
}

.match-single .match-review {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-top: 15px;
  border-top: 1px solid #F5F5F5;
}

.match-single .match-review .review-link {
  font-size: 14px;
  color: #b2b1b1;
  font-weight: 400;
}

.match-single .match-review .review-link:hover {
  color: var(--theme-color);
  text-decoration: underline;
}

.match-single .match-review .review-link.smry {
  margin-right: 20px;
}

.rts-match-result-section2 .club {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.search-input-area {
  z-index: 101;
}

.search-input-area .select .select-styled::after {
  position: absolute;
  display: block;
  content: "";
  width: 5px;
  height: 5px;
  top: 50%;
  right: 15px;
  left: unset;
  margin-top: -2px;
  z-index: 10000;
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
  -webkit-transform: rotate(45deg) translateY(-50%);
  transform: rotate(45deg) translateY(-50%);
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
}

.search-input-area input:-moz-placeholder-shown {
  font-family: "Roboto";
}

.search-input-area input:-ms-input-placeholder {
  font-family: "Roboto";
}

.search-input-area input:placeholder-shown {
  font-family: "Roboto";
}

/**------------------------------------------------------------------
 * Animation Start Here
 */
@-webkit-keyframes zoom-in-out-bg {
  0% {
    background-size: 100%;
  }

  50% {
    background-size: 150%;
  }

  100% {
    background-size: 100%;
  }
}

@keyframes zoom-in-out-bg {
  0% {
    background-size: 100%;
  }

  50% {
    background-size: 150%;
  }

  100% {
    background-size: 100%;
  }
}

@-webkit-keyframes slide-down {
  0% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-down {
  0% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes slide-up {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
  }
}

@keyframes slide-up {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
  }
}

@-webkit-keyframes slideDownn {
  0% {
    -webkit-transform: translateY(-80px);
    transform: translateY(-80px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slideDownn {
  0% {
    -webkit-transform: translateY(-80px);
    transform: translateY(-80px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes marquee {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  to {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

@keyframes marquee {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  to {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

@-webkit-keyframes pop {
  0% {
    -webkit-transform: scale(0) translateX(-50%);
    transform: scale(0) translateX(-50%);
  }

  60% {
    -webkit-transform: scale(105%) translateX(-50%);
    transform: scale(105%) translateX(-50%);
  }

  100% {
    -webkit-transform: scale(1) translateX(-50%);
    transform: scale(1) translateX(-50%);
  }
}

@keyframes pop {
  0% {
    -webkit-transform: scale(0) translateX(-50%);
    transform: scale(0) translateX(-50%);
  }

  60% {
    -webkit-transform: scale(105%) translateX(-50%);
    transform: scale(105%) translateX(-50%);
  }

  100% {
    -webkit-transform: scale(1) translateX(-50%);
    transform: scale(1) translateX(-50%);
  }
}

@-webkit-keyframes hero-move {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  50% {
    -webkit-transform: translateX(-150px);
    transform: translateX(-150px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes hero-move {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  50% {
    -webkit-transform: translateX(-150px);
    transform: translateX(-150px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@-webkit-keyframes hero-move2 {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  50% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes hero-move2 {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  50% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@-webkit-keyframes text-height {
  0% {
    max-height: 0;
  }

  100% {
    max-height: 400px;
  }
}

@keyframes text-height {
  0% {
    max-height: 0;
  }

  100% {
    max-height: 400px;
  }
}

@-webkit-keyframes pulse-border {
  0% {
    -webkit-transform: scale(100%);
    transform: scale(100%);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(150%);
    transform: scale(150%);
    opacity: 0;
  }
}

@keyframes pulse-border {
  0% {
    -webkit-transform: scale(100%);
    transform: scale(100%);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(150%);
    transform: scale(150%);
    opacity: 0;
  }
}

.marquee {
  position: relative;
  width: 100vw;
  max-width: 100%;
  height: 200px;
  overflow-x: hidden;
}

.track {
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  -webkit-animation: marquee 32s linear infinite;
  animation: marquee 32s linear infinite;
}

/**------------------------------------------------------------------
 * Animation End Here
 */
/**------------------------------------------------------------------
 * Fucntion Start Here
 */
.check-option {
  display: block;
  margin-bottom: 15px;
}

.check-option input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.check-option label {
  position: relative;
  cursor: pointer;
}

.check-option label:before {
  position: absolute;
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #BFC3C7;
  padding: 7px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 10px;
  border-radius: 4px;
  -webkit-transform: translateY(-2px);
  transform: translateY(-2px);
  -webkit-transition: background 500ms;
  transition: background 500ms;
}

.check-option input:checked+label:after {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 6px;
  width: 4px;
  height: 9px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.check-option input:checked+label:before {
  background: #1D72FF;
  border: 1px solid #1D72FF;
}

.select-hidden {
  display: none;
  visibility: hidden;
  padding-right: 10px;
}

.select {
  cursor: pointer;
  display: inline-block;
  position: relative;
  font-size: 14px;
  color: #040404;
  width: 173px;
  height: 53px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.select-styled {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--theme-color);
  padding: 10px 15px;
  padding-top: 15px;
  border-radius: 0;
  max-width: 150px;
  color: #fff;
  border: none;
}

.select-styled:after {
  position: absolute;
  display: block;
  content: "";
  width: 5px;
  height: 5px;
  top: 50%;
  right: -11px;
  left: unset;
  margin-top: -2px;
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
  -webkit-transform: rotate(45deg) translateY(-50%);
  transform: rotate(45deg) translateY(-50%);
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
}

.select-styled.active {
  background-color: var(--theme-color);
  color: #fff;
}

.select-styled.active:after {
  -webkit-transform: rotate(223deg) translateX(-3px) translateY(-5px);
  transform: rotate(223deg) translateX(-3px) translateY(-5px);
}

.select-options {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  z-index: 999;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: #fff;
  -webkit-box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.142);
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.142);
}

.select-options li {
  margin: 0;
  padding: 12px 0;
  padding-left: 30px;
  text-indent: 0;
}

.select-options li:hover,
.select-options li.is-selected {
  color: #fff;
  background: var(--theme-color);
}

.select-options li[rel=hide] {
  display: none;
}

.noContent {
  color: #000 !important;
  background-color: transparent !important;
  pointer-events: none;
}

.rts-latest-section .content {
  display: none;
}

.zoom {
  background-position: 50% 50%;
  background-size: 150%;
  position: relative;
  overflow: hidden;
  cursor: move;
}

.zoom:hover {
  width: 100%;
}

.zoom img:hover {
  opacity: 0;
}

.zoom img {
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
  display: block;
  width: 100%;
}

.nstSlider {
  background: none;
  width: 100%;
  height: 3px;
  background: #f0f0f0;
  margin-bottom: 13px;
}

.nstSlider .bar {
  max-width: 100% !important;
  background: #040404;
  height: 3px;
  top: 0;
}

.nstSlider .price-range-grip {
  background: #040404;
  width: 11px;
  height: 11px;
  top: -3.6px;
}

.range-label-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 5px;
}

.range-label-area .range-lbl {
  font-size: 14px;
  color: #999999;
  font-weight: 500;
  margin-right: 5px;
}

.range-label-area .currency-symbol {
  font-weight: 500;
}

.range-label-area .price-range-label {
  font-weight: 500;
}

.swiper-pagination {
  top: 10px;
  right: 5px;
  width: auto !important;
  left: auto !important;
  margin: 0;
}

.swiper-pagination-bullet {
  margin: 0 !important;
  padding: 0px 10px;
  border-radius: 0;
  width: 100px;
  height: 4px;
  text-align: center;
  line-height: 0px;
  font-size: 5px;
  color: #000;
  background: rgba(255, 255, 255, 0.61);
  -webkit-transition: all 1000ms;
  transition: all 1000ms;
}

.swiper-pagination-bullet-active {
  color: #fff;
  background: #fff;
}

.hamburger-menu-inner {
  position: relative;
  width: 16px;
  height: 14px;
  cursor: pointer;
}

.hamburger-menu-inner:hover span {
  background: #ffffff;
}

.hamburger-menu-inner span {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 2px;
  -webkit-transition: all 0.2s, background 0s;
  transition: all 0.2s, background 0s;
  background: rgb(255, 255, 255);
}

.hamburger-menu-inner span:nth-child(2) {
  top: 6px;
}

.hamburger-menu-inner span:last-child {
  top: 12px;
}

.nav-open span:first-child,
.nav-open span:last-child {
  top: 8px;
}

.nav-open span:first-child {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.nav-open span:last-child {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.nav-open span:nth-child(2) {
  opacity: 0;
  visibility: hidden;
}

/**------------------------------------------------------------------
 * Fucntion End Here
 */
.rts-menu li .menu-item.active1 {
  color: var(--theme-color);
}

.rts-menu li:hover .menu-item {
  color: var(--theme-color);
}

.rts-menu li ul .has-dropdown:hover a i {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.rts-menu .dropdown-ul {
  padding: 0;
  background: #fff;
  border-top: 3px solid var(--theme-color);
  -webkit-box-shadow: 9px 9px 22px -1px rgba(0, 0, 0, 0.12);
  box-shadow: 9px 9px 22px -1px rgba(0, 0, 0, 0.12);
}

.rts-menu .dropdown-li {
  margin: 0;
  padding: 0;
}

.rts-menu .dropdown-link,
.rts-menu .dropdown-sub-link {
  padding: 15px 20px;
  padding-right: 100px;
  background: #fff;
  border-bottom: 1px solid rgb(242, 242, 242);
  -webkit-transition: padding 400ms ease;
  transition: padding 400ms ease;
}

.rts-menu .dropdown-link::before,
.rts-menu .dropdown-sub-link::before {
  content: "";
  position: absolute;
  top: 25px;
  left: 12px;
  width: 6px;
  height: 2px;
  background: var(--theme-color);
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.rts-menu .dropdown-link:hover,
.rts-menu .dropdown-sub-link:hover {
  padding: 15px 30px;
  padding-right: 90px;
  color: var(--theme-color);
}

.rts-menu .dropdown-link:hover::before,
.rts-menu .dropdown-sub-link:hover::before {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.rts-menu .sub-dropdown {
  left: 100%;
  top: -1px;
  border-top: 3px solid var(--theme-color);
}

.rts-menu .has-dropdown.second-level a i {
  margin-left: 10px;
}

.side-mobile-menu1 ul li a:hover {
  color: var(--theme-color);
}

.side-mobile-menu1 .has-dropdown .mm-link:hover {
  color: var(--theme-color);
}

.side-mobile-menu1 .has-dropdown.firstlvl.mm-active .mm-link {
  color: var(--theme-color);
}

.side-mobile-menu1 .has-dropdown.thirdlvl.mm-active .mm-link-3 {
  color: var(--theme-color);
}

.slider-navigation {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.navigation-btn {
  background: #fff;
  border: 2px solid rgba(14, 16, 19, 0.1);
  border-radius: 6px;
  padding: 10px 18px;
  -webkit-transition: 500ms;
  transition: 500ms;
}

.navigation-btn:hover {
  background: #FF1856;
  color: #fff;
}

.navigation-btn i {
  font-size: 18px;
  -webkit-transform: translateY(2px);
  transform: translateY(2px);
}

.swiper-button-prev {
  margin-right: 7px;
}

.hamburger {
  display: none;
  position: relative;
  right: unset;
  padding: 10px 25px 11px 0px;
  margin-left: 30px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .hamburger {
    display: unset;
  }
}

@media (max-width: 400px) {
  .hamburger {
    margin-left: 15px;
  }
}

.hamburger.move {
  z-index: 10;
}

.hamburger-1 {
  display: none;
  position: relative;
  right: unset;
  padding: 10px 25px 11px 0px;
  margin-left: 30px;
  cursor: pointer;
}

@media (max-width: 900px) {
  .hamburger-1 {
    display: unset;
  }
}

@media (max-width: 400px) {
  .hamburger-1 {
    margin-left: 15px;
  }
}

.hamburger-1.move {
  z-index: 10;
}

.slide-bar-close {
  position: absolute;
  top: -13px;
  right: -20px;
  -webkit-transform: scale(70%);
  transform: scale(70%);
  padding: 15px 25px;
  background: #fff;
  display: -ms-grid;
  display: grid;
  place-items: center;
}

.slide-bar-close i {
  font-size: 30px;
}

.slide-bar-close:hover i {
  color: var(--theme-color);
}

.mobile-hamburger-1 {
  position: absolute;
  top: 6px;
  right: -7px;
  -webkit-transform: scale(70%);
  transform: scale(70%);
  padding: 25px 30px;
  background: rgba(255, 255, 255, 0.2509803922);
  -webkit-box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.152);
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.152);
  display: -ms-grid;
  display: grid;
  place-items: center;
}

.mobile-hamburger-1:hover span,
.mobile-hamburger-1:hover span:before,
.mobile-hamburger-1:hover span:after {
  background: #FF1856;
}

.hamburger span,
.hamburger span:before,
.hamburger span:after {
  cursor: pointer;
  border-radius: 1px;
  height: 2px;
  width: 35px;
  background: #0E1013;
  position: absolute;
  display: inline-block;
  content: "";
}

@media (max-width: 1200px) {

  .hamburger span,
  .hamburger span:before,
  .hamburger span:after {
    width: 25px;
  }
}

.hamburger-1 span,
.hamburger-1 span:before,
.hamburger-1 span:after {
  cursor: pointer;
  border-radius: 1px;
  height: 2px;
  width: 35px;
  background: #0E1013;
  position: absolute;
  display: inline-block;
  content: "";
}

@media (max-width: 1200px) {

  .hamburger-1 span,
  .hamburger-1 span:before,
  .hamburger-1 span:after {
    width: 25px;
  }
}

.hamburger span:before {
  top: -7px;
}

.hamburger span:after {
  bottom: -7px;
}

#hamburger span,
#hamburger span:before,
#hamburger span:after {
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}

.hamburger span,
.hamburger span:before,
.hamburger span:after {
  -webkit-transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  /* easeInOutCubic */
}

.hamburger.active span {
  background-color: transparent;
}

.hamburger.active span:before,
.hamburger.active span:after {
  top: 0;
}

.hamburger.active span:before {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.hamburger.active span:after {
  -webkit-transform: translateY(-10px) rotate(-45deg);
  transform: translateY(-10px) rotate(-45deg);
  top: 10px;
}

.hamburger-1 span:before {
  top: -7px;
}

.hamburger-1 span:after {
  bottom: -7px;
}

#hamburger-1 span,
#hamburger-1 span:before,
#hamburger-1 span:after {
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}

.hamburger-1 span,
.hamburger-1 span:before,
.hamburger-1 span:after {
  -webkit-transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  /* easeInOutCubic */
}

.hamburger-1.active span {
  background-color: transparent;
}

.hamburger-1.active span:before,
.hamburger-1.active span:after {
  top: 0;
}

.hamburger-1.active span:before {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.hamburger-1.active span:after {
  -webkit-transform: translateY(-10px) rotate(-45deg);
  transform: translateY(-10px) rotate(-45deg);
  top: 10px;
}

.slide-bar {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: -100%;
  width: 350px;
  padding: 30px 30px;
  padding-top: 20px;
  height: 100%;
  display: block;
  background-color: #111111;
  z-index: 1900;
  -webkit-transition: all 600ms ease;
  transition: all 600ms ease;
  -webkit-box-shadow: -5px 0 20px -5px rgba(0, 0, 0, 0.5);
  box-shadow: -5px 0 20px -5px rgba(0, 0, 0, 0.5);
  overflow: scroll;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.slide-bar::-webkit-scrollbar {
  display: none;
}

@media (max-width: 500px) {
  .slide-bar {
    width: 100%;
  }
}

.slide-bar .header-action-items {
  display: none;
  margin-top: auto;
}

@media (max-width: 370px) {
  .slide-bar .header-action-items {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.slide-bar.show {
  right: 0;
}

.open-mobile-menu {
  display: inline-block;
}

.open-mobile-menu a {
  display: block;
  width: 50px;
  height: 50px;
  background: #212121;
  text-align: center;
  line-height: 50px;
  font-size: 16px;
  margin-left: 20px;
  color: #fff;
}

.close-mobile-menu {
  position: absolute;
  top: 10px;
  left: 10px;
  background: gray;
  padding: 10px 20px;
}

.close-mobile-menu a {
  position: relative;
  z-index: 2;
  font-size: 16px;
  left: 0;
}

.close-mobile-menu a:hover {
  color: #ff0000;
}

.on-side {
  overflow: hidden;
}

.body-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1010;
  left: 0;
  opacity: 0;
  display: none;
}

.body-overlay.active {
  opacity: 1;
  display: block;
}

.side-mobile-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.side-mobile-menu ul li a {
  padding: 20px 0;
  color: #fff;
  display: block;
  border-bottom: 1px solid rgba(40, 40, 40, 0.5);
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  position: relative;
  -webkit-transition: all 300ms;
  transition: all 300ms;
}

.side-mobile-menu ul li a:hover {
  padding-left: 5px;
}

.side-mobile-menu ul li a a:after {
  position: absolute;
  content: "\e80a";
  width: 8px;
  height: 8px;
  border-width: 2px 0 0 2px;
  border-style: solid;
  border-color: initial;
  right: 0;
  top: 50%;
  -webkit-transform: rotate(-45deg) translateY(-50%);
  transform: rotate(-45deg) translateY(-50%);
  -webkit-transform-origin: top;
  transform-origin: top;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.side-mobile-menu ul li ul {
  padding-left: 0;
  list-style: none;
}

.side-mobile-menu ul li ul li {
  padding-left: 15px;
}

.side-mobile-menu ul li ul li:hover>a {
  padding-left: 20px;
}

.side-mobile-menu ul li ul li a {
  position: relative;
  padding-left: 15px;
  text-transform: capitalize;
  font-size: 14px;
}

.side-mobile-menu ul li ul li a:before {
  content: "";
  width: 8px;
  height: 8px;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  border-radius: 50%;
}

.side-mobile-menu ul li.has-has-dropdown>a:after {
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  border-width: 2px 0 0 2px;
  border-style: solid;
  border-color: initial;
  right: 0;
  top: 50%;
  -webkit-transform: rotate(-45deg) translateY(-50%);
  transform: rotate(-45deg) translateY(-50%);
  -webkit-transform-origin: top;
  transform-origin: top;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.offset-sidebar {
  position: relative;
}

.offset-sidebar .scrollbar-hidden::-webkit-scrollbar {
  display: none !important;
}

.offset-sidebar .offset-sidebar-bg {
  position: absolute;
  bottom: -250px;
  left: 65%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 170%;
  height: 380px;
}

.offset-sidebar .offset-sidebar-bg img {
  max-width: 100%;
}

.offset-widget.offset-logo {
  padding-top: 90px;
  padding-bottom: 50px;
  text-align: center;
}

.offset-widget .info-widget .offset-title {
  font-size: 20px;
  font-weight: 800;
}

.offset-widget .info-widget p {
  color: #999999;
}

.offset-widget .info-widget.info-widget2 p {
  margin-bottom: 35px;
}

.offset-widget .info-widget.info-widget2 p i {
  margin-right: 7px;
}

.offset-widget .info-widget .city-widget-item i {
  width: 50px;
  color: red;
}

.offset-widget .offset-social-link {
  margin-bottom: 80px;
}

.offset-widget .social-icons {
  width: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: start;
}

.offset-widget .social-icons a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 40px;
  aspect-ratio: 1;
  border-radius: 50%;
  margin-right: 10px;
}

.offset-widget .social-icons a:last-child {
  margin-right: inherit;
}

.offset-widget .social-icons a.fb {
  background: #1877f2;
}

.offset-widget .social-icons a.ttr {
  background: #1da1f2;
}

.offset-widget .social-icons a.yh {
  background: #410093;
}

.offset-widget .social-icons a.yt {
  background: #cd201f;
}

.offset-widget .social-icons i {
  color: white;
  font-size: 14px;
}

.offset-widget .slidebar-address {
  margin-bottom: 40px;
}

.offset-widget .slidebar-address span {
  font-size: 20px;
  font-weight: 600;
  line-height: 10px;
}

.offset-widget .slidebar-address p {
  margin-top: 16px;
  max-width: 180px;
  font-size: 16px;
}

.offset-widget .offset-sidebar-btn {
  width: 100%;
  text-align: center;
}

.offset-widget .offset-sidebar-btn i {
  margin-left: 10px;
}

.mm-link {
  font-size: 15px;
  position: relative;
}

.mm-link i {
  position: absolute;
  background: var(--theme-color);
  color: #fff;
  top: 23%;
  font-size: 16px;
  padding: 5px;
  right: 0;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.mm-active .mm-link i {
  top: 23%;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.side-mobile-menu1 .firstlvl.mm-active .mm-link {
  color: var(--theme-color);
}

.side-mobile-menu1 .thirdlvl.mm-active .mm-link-3 {
  color: var(--theme-color);
}

.side-mobile-menu1 .thirdlvl.mm-active .mm-link-3::after {
  -webkit-transform: rotate(225deg) !important;
  transform: rotate(225deg) !important;
}

.sub-menu a:hover {
  color: var(--theme-color);
}

/**
 * Clear inner floats
 */
.clearfix::after {
  clear: both;
  content: "";
  display: table;
}

/**
 * Main content containers
 * 1. Make the container full-width with a maximum width
 * 2. Center it in the viewport
 * 3. Leave some space on the edges, especially valuable on small screens
 */
.container {
  max-width: 1240px;
  /* 1 */
  margin-left: auto;
  /* 2 */
  margin-right: auto;
  /* 2 */
  padding-left: 20px;
  /* 3 */
  padding-right: 20px;
  /* 3 */
  width: 100%;
  /* 1 */
}

/**
 * Hide text while making it readable for screen readers
 * 1. Needed in WebKit-based browsers because of an implementation bug;
 *    See: https://code.google.com/p/chromium/issues/detail?id=457146
 */
.hide-text {
  overflow: hidden;
  padding: 0;
  /* 1 */
  text-indent: 101%;
  white-space: nowrap;
}

/**
 * Hide element while making it readable for screen readers
 * Shamelessly borrowed from HTML5Boilerplate:
 * https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L119-L133
 */
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/**------------------------------------------------------------------
 * Preloader Start Here
 */
#rts__preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 9999;
}

.main-fader .loader img {
  height: 100%;
  width: 100%;
}

.main-fader .loader img:nth-child(2) {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes preloader {
  0% {
    -webkit-transform: scale(60%) rotate(0deg);
    transform: scale(60%) rotate(0deg);
  }

  50% {
    -webkit-transform: scale(60%) rotate(90deg);
    transform: scale(60%) rotate(90deg);
  }

  100% {
    -webkit-transform: scale(60%) rotate(360deg);
    transform: scale(60%) rotate(360deg);
  }
}

@keyframes preloader {
  0% {
    -webkit-transform: scale(60%) rotate(0deg);
    transform: scale(60%) rotate(0deg);
  }

  50% {
    -webkit-transform: scale(60%) rotate(90deg);
    transform: scale(60%) rotate(90deg);
  }

  100% {
    -webkit-transform: scale(60%) rotate(360deg);
    transform: scale(60%) rotate(360deg);
  }
}

@-webkit-keyframes preloaderbg {
  0% {
    background: #fff;
    -webkit-backdrop-filter: blur(9px);
    backdrop-filter: blur(9px);
  }

  100% {
    background: rgba(255, 255, 255, 0);
  }
}

@keyframes preloaderbg {
  0% {
    background: #fff;
    -webkit-backdrop-filter: blur(9px);
    backdrop-filter: blur(9px);
  }

  100% {
    background: rgba(255, 255, 255, 0);
  }
}

.main-loader {
  height: 100vh;
}

.main-fader {
  width: 100%;
  height: 100vh;
  position: absolute;
  background-color: #000;
}

.main-fader .loader {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.main-fader .loader svg {
  height: 300px;
  display: block;
  margin: 0 auto;
}

.main-fader .loader svg path {
  fill: var(--theme-color);
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-name: pulse;
  animation-name: pulse;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  color: var(--theme-color);
}

.main-fader .loader svg path.path-7 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.main-fader .loader svg path.path-6 {
  -webkit-animation-delay: -0.875s;
  animation-delay: -0.875s;
}

.main-fader .loader svg path.path-5 {
  -webkit-animation-delay: -0.75s;
  animation-delay: -0.75s;
}

.main-fader .loader svg path.path-4 {
  -webkit-animation-delay: -0.625s;
  animation-delay: -0.625s;
}

.main-fader .loader svg path.path-3 {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.main-fader .loader svg path.path-2 {
  -webkit-animation-delay: -0.375s;
  animation-delay: -0.375s;
}

.main-fader .loader svg path.path-1 {
  -webkit-animation-delay: -0.25s;
  animation-delay: -0.25s;
}

.main-fader .loader svg path.path-0 {
  -webkit-animation-delay: -0.125s;
  animation-delay: -0.125s;
}

@-webkit-keyframes pulse {
  0% {
    opacity: 0.1;
  }

  30% {
    opacity: 0.8;
  }

  100% {
    opacity: 0.1;
  }
}

@keyframes pulse {
  0% {
    opacity: 0.1;
  }

  30% {
    opacity: 0.8;
  }

  100% {
    opacity: 0.1;
  }
}

/**------------------------------------------------------------------
 * Reset Start Here
 */
.container {
  max-width: 1330px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
}

.header-container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 70;
  width: 100%;
}

@media (max-width: 1644px) {
  .header-container {
    padding: 0 100px;
  }
}

@media (max-width: 1080px) {
  .header-container {
    padding: 0 20px;
  }
}

.col-xs-15,
.col-sm-15,
.col-md-15,
.col-lg-15 {
  position: relative;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
}

.col-xs-15 {
  width: 20%;
  float: left;
}

@media (min-width: 768px) {
  .col-sm-15 {
    width: 20%;
    float: left;
  }
}

@media (min-width: 992px) {
  .col-md-15 {
    width: 20%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .col-lg-15 {
    width: 20%;
    float: left;
  }
}

.col-xs-13,
.col-sm-13,
.col-md-13,
.col-lg-13 {
  position: relative;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
}

@media (min-width: 768px) {
  .col-sm-13 {
    width: 13%;
    float: left;
  }
}

@media (min-width: 992px) {
  .col-md-13 {
    width: 13%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .col-lg-13 {
    width: 13%;
    float: left;
  }
}

.col-xs-25,
.col-sm-25,
.col-md-25,
.col-lg-25 {
  position: relative;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
}

@media (min-width: 768px) {
  .col-sm-25 {
    width: 20%;
    float: left;
  }
}

@media (min-width: 992px) {
  .col-md-25 {
    width: 20%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .col-lg-25 {
    width: 20%;
    float: left;
  }
}

.col-xs-32,
.col-sm-32,
.col-md-32,
.col-lg-32 {
  position: relative;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
}

@media (min-width: 768px) {
  .col-sm-32 {
    width: 32%;
    float: left;
  }
}

@media (min-width: 992px) {
  .col-md-32 {
    width: 32%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .col-lg-32 {
    width: 32%;
    float: left;
  }
}

.reduced-col-gap {
  padding: 0;
}

.no-col-gap {
  padding: 0;
}

.section-bg {
  background: #F5F5F5;
}

.brand-bg {
  background: #FF1856 !important;
}

.highlight-bg {
  background: #1D72FF !important;
}

.text-color1 {
  color: #040404;
}

.ad-area {
  display: block !important;
}

.mt--60 {
  margin-top: 60px;
}

.mt--50 {
  margin-top: 50px;
}

.mt--40 {
  margin-top: 40px;
}

.mt--30 {
  margin-top: 30px;
}

.mt--20 {
  margin-top: 20px;
}

.mt--10 {
  margin-top: 10px !important;
}

.m-0 {
  margin: 0 !important;
}

.mb-0 {
  margin-bottom: 0;
}

.mb--5 {
  margin-bottom: 5px;
}

.mb--15 {
  margin-bottom: 15px;
}

.mb--20 {
  margin-bottom: 20px !important;
}

.mb--25 {
  margin-bottom: 25px;
}

.mb--30 {
  margin-bottom: 30px;
}

.mb--35 {
  margin-bottom: 35px;
}

.mb--40 {
  margin-bottom: 40px !important;
}

.mb--50 {
  margin-bottom: 50px !important;
}

.mb--60 {
  margin-bottom: 60px;
}

.mb--70 {
  margin-bottom: 70px;
}

.mb--80 {
  margin-bottom: 80px !important;
}

.mb--100 {
  margin-bottom: 100px;
}

.mb--125 {
  margin-bottom: 125px;
}

.mr--5 {
  margin-right: 5px;
}

.mr--15 {
  margin-right: 15px;
}

.mr--20 {
  margin-right: 20px;
}

.ml-auto {
  margin-left: auto;
}

.ml--0 {
  margin-left: 0;
}

.ml--5 {
  margin-left: 5px;
}

.ml--10 {
  margin-left: 10px;
}

.ml--15 {
  margin-left: 15px;
}

.ml--20 {
  margin-left: 20px;
}

.ml--30 {
  margin-left: 30px;
}

.ml--40 {
  margin-left: 30px;
}

.mr--0 {
  margin-right: 0 !important;
}

.mr--5 {
  margin-right: 5px;
}

.mr--10 {
  margin-right: 10px;
}

.mr--15 {
  margin-right: 15px;
}

.mr--40 {
  margin-right: 40px;
}

.p-0 {
  padding: 0 !important;
}

.pt--100 {
  padding-top: 100px;
}

.pt--150 {
  padding-top: 150px;
}

.pt--70 {
  padding-top: 70px;
}

.ptb--60 {
  padding: 60px 0;
}

.ptb--70 {
  padding: 70px 0;
}

.pb--30 {
  padding-bottom: 30px;
}

.pb--100 {
  padding-bottom: 100px !important;
}

.pb--150 {
  padding-bottom: 150px !important;
}

.border-none {
  border: none;
}

.section-gap {
  padding: 120px 0;
}

@media (max-width: 768px) {
  .section-gap {
    padding: 60px 0;
  }
}

.section-space {
  margin: 50px 0;
}

@media (max-width: 768px) {
  .section-space {
    margin: 25px 0;
  }
}

.section-gap-2 {
  padding: 40px 0;
}

.section-gap-100 {
  padding: 100px 0;
}

@media (max-width: 768px) {
  .section-gap-100 {
    padding: 50px 0;
  }
}

.gap-b-100 {
  padding-bottom: 100px;
}

@media (max-width: 768px) {
  .gap-b-100 {
    padding-bottom: 30px;
  }
}

.social-links .platform {
  width: 35px;
  height: 35px;
  border: 2px solid rgba(14, 16, 19, 0.1);
  border-radius: 0px;
  margin-right: 7px;
}

.social-links .platform:hover i {
  color: #fff;
}

.social-links .platform i {
  color: #0E1013;
}

.transform-none {
  -webkit-transform: none;
  transform: none;
}

.table-area::-webkit-scrollbar {
  height: 3px;
}

.table-area::-webkit-scrollbar-track {
  background: #f5f5f5;
}

.table-area::-webkit-scrollbar-thumb {
  border-radius: 20px;
  border: 3px solid var(--theme-color);
}

@media (max-width: 768px) {
  .table-area {
    overflow-x: scroll;
  }

  .table-area table {
    margin-bottom: 0;
  }

  .table-area table .long-text {
    min-width: 150px;
  }

  .table-area table .text {
    min-width: 100px;
  }
}

.head-tr {
  background: #F5F5F5;
  border-width: 0;
  border-width: 0 !important;
}

.head-tr th {
  font-size: 16px;
}

.head-tr th:first-child {
  border-radius: 4px 0 0 4px;
}

.head-tr th:last-child {
  border-radius: 0 4px 4px 0;
}

td,
th {
  border-style: none;
  vertical-align: middle;
  padding: 20px 0 !important;
  padding-left: 40px;
}

td span,
td h4,
th span,
th h4 {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
}

td span,
th span {
  color: #777777;
  font-weight: 400;
}

td .position-number,
th .position-number {
  color: #111111;
  font-weight: 600;
}

td .pts-count,
th .pts-count {
  color: var(--theme-color);
  font-weight: 600;
}

tr {
  border-color: #e7e7e7;
  padding: 10px 30px;
}

tr:first-child {
  border-style: none;
}

.table-bordered> :not(caption)>* {
  border-width: 1px;
  border-top-width: 0;
}

.table> :not(caption)>*>* {
  padding: 0.5rem 0;
  border-bottom-width: 1px;
}

.table-bordered> :not(caption)>*>* {
  border-width: 0;
}

.table-full.inner {
  background: #fff;
  -webkit-filter: drop-shadow(0 10px 5px #f7f7f7);
  filter: drop-shadow(0 10px 5px #f7f7f7);
}

.table-full td,
.table-full th {
  border-style: none;
  vertical-align: middle;
  padding: 20px 0;
}

.table-full .two {
  text-align: center;
}

.table-full .two td {
  border-right: 1px solid #e7e7e7;
}

/**------------------------------------------------------------------
 * Reset End Here
 */
#rtsHeader {
  position: relative;
}

#rtsHeader .styled-logo-area {
  width: 40%;
  height: auto;
  padding-right: 40px;
  background: #111111;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 11;
}

#rtsHeader .styled-logo-area .logo {
  margin-left: auto;
}

#rtsHeader .inline-wrapper {
  position: relative;
}

#rtsHeader .header-wrapper {
  width: 100%;
}

#rtsHeader .header-wrapper .container-hw {
  padding-right: 20%;
}

@media (max-width: 1440px) {
  #rtsHeader .header-wrapper .container-hw {
    padding-right: 10%;
  }
}

#rtsHeader .header-topbar {
  padding: 9px 0;
}

#rtsHeader .header-topbar .quick-contact .contact-item {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  margin-right: 20px;
}

#rtsHeader .header-topbar .quick-contact .contact-item i {
  margin-right: 10px;
}

#rtsHeader .header-topbar .topbar-select-area {
  margin-right: -15px;
}

#rtsHeader .sticky-menu .container-hw {
  padding-right: 20px;
}

#rtsHeader .sticky-menu .navbar-part {
  padding-left: 0;
}

#rtsHeader .sticky-menu .navbar-part .navbar-part .navbar-inner .rts-menu .dropdown-ul {
  top: 110px;
}

#rtsHeader .sticky-menu .navbar-part .navbar-part .navbar-inner .header-action-items .action-item .cart-icon i {
  color: #111111;
}

#rtsHeader .navbar-part .rts-menu .menu-item {
  padding: 20px 40px;
  letter-spacing: 1px;
}

#rtsHeader .navbar-part .rts-menu .menu-item:first-child {
  padding-left: 0 !important;
}

#rtsHeader .logo-sticky {
  display: none;
}

#rtsHeader .sticky-menu .logo-sticky {
  display: block;
}

#rtsHeader .sticky-menu .logo {
  display: none;
}

#rtsHeader .sticky-menu .hamburger-menu span {
  background: #111111;
}

#rtsHeader .hamburger-menu {
  display: none;
}

@media (max-width: 900px) {
  #rtsHeader .logo {
    padding: 10px 0;
  }

  #rtsHeader .logo-sticky {
    padding: 10px 0;
  }

  #rtsHeader .hamburger-menu {
    display: block;
  }
}

@media (max-width: 1200px) {
  #rtsHeader .styled-logo-area {
    display: none;
  }

  #rtsHeader .header-wrapper .container-hw {
    padding-right: 20px;
  }
}

#rtsHeader .hamburger-1 span,
#rtsHeader .hamburger-1 span:before,
#rtsHeader .hamburger-1 span:after {
  background: #fff;
}

#rtsHeader .mobile-hamburger-1 span {
  background: transparent !important;
}

#rtsHeader .mobile-hamburger-1 span:before,
#rtsHeader .mobile-hamburger-1 span:after {
  background: #111111;
}

#rtsHeader.rts-header1 .hamburger-menu {
  display: block;
}

#rtsHeader.rts-header1 .navbar-part {
  margin-top: -70px;
}

#rtsHeader.rts-header1 .navbar-part .navbar-inner {
  /* background-image: -webkit-gradient(linear, left bottom, left top, from(rgb(17, 17, 17)), to(rgba(17, 17, 17, 0.30196)));
  background-image: linear-gradient(0deg, rgb(17, 17, 17) 0%, rgba(17, 17, 17, 0.30196) 100%);
  background-image: linear-gradient(0deg, rgb(17, 17, 17) 0%, rgba(17, 17, 17, 0.30196) 100%);
  background-image: -webkit-gradient(linear, left top, right top, from(rgb(17, 17, 17)), to(rgba(17, 17, 17, 0.30196))); */
  /* background-image: linear-gradient(90deg, rgb(17, 17, 17) 0%, rgba(17, 17, 17, 0.30196) 100%); */
  background-image: linear-gradient(188deg, rgba(7, 86, 134, 0), rgba(0, 0, 0, 0));

  padding: 0 30px;
}
.banner-btn-area img {
  height: 60px;
  width: 100px;
  object-fit: cover; /* Yeh property image ko scale karegi, lekin aspect ratio maintain karegi */
}

@media  (max-width: 1200px) {
  #rtsHeader.rts-header1 .navbar-part .navbar-inner {
    margin-top: 9svh;
    justify-content: center;
    align-items: center;
  }
} 
#rtsHeader.rts-header1 .navbar-part .navbar-inner .dropdown-ul {
  top: 110px;
  left: -22px;
}

#rtsHeader.rts-header1 .navbar-part .navbar-inner .mega-dropdown {
  left: -240px;
}

#rtsHeader.rts-header1 .navbar-part .navbar-inner .rts-menu {
  margin-left: auto;
}

#rtsHeader.rts-header1 .navbar-part .navbar-inner .header-action-items {
  margin-left: auto;
}

@media (max-width: 500px) {
  #rtsHeader.rts-header1 .navbar-part .navbar-inner .header-action-items .search-part {
    display: none;
  }
}

#rtsHeader.rts-header1 .navbar-part .navbar-inner .header-action-items .search-part .search-icon {
  background: #6575e1;
}

@media (max-width: 500px) {
  #rtsHeader.rts-header1 .navbar-part .navbar-inner .header-action-items .cart {
    display: none;
  }
}

#rtsHeader.rts-header1 .sticky-menu {
  background: #000 !important;
}

#rtsHeader.rts-header1 .sticky-menu .container-hw {
  padding-right: 20px;
}

#rtsHeader.rts-header1 .sticky-menu .navbar-part {
  margin-top: 0;
}

#rtsHeader.rts-header1 .sticky-menu .navbar-part .navbar-inner {
  background: none;
  padding: 0 30px;
}

@media (max-width: 400px) {
  #rtsHeader.rts-header1 .sticky-menu .navbar-part .navbar-inner {
    padding: 0;
  }
}

#rtsHeader.rts-header1 .sticky-menu .navbar-part .navbar-inner .rts-menu .menu-toggle .nav__menu li .menu-item {
  color: #fff;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

#rtsHeader.rts-header1 .sticky-menu .navbar-part .navbar-inner .rts-menu .menu-toggle .nav__menu li .menu-item:hover {
  color: var(--theme-color);
}

#rtsHeader.rts-header1 .sticky-menu .navbar-part .navbar-inner .rts-menu .menu-toggle .nav__menu li .menu-item.active1 {
  color: var(--theme-color);
}

#rtsHeader.rts-header1 .sticky-menu .header-action-items .action-item .cart-icon i {
  color: #fff;
}

#rtsHeader.rts-header1 .sticky-menu .hamburger-menu .hamburger-menu-inner span {
  background: #fff;
}

#rtsHeader.rts-header2 {
  background: #fff;
}

#rtsHeader.rts-header2 .navbar-wrapper {
  background: #fff !important;
  position: unset !important;
}

#rtsHeader.rts-header2 .navbar-part {
  border-bottom: 1px solid #f5f5f5 !important;
}

#rtsHeader.rts-header2 .navbar-part.navbar-lower {
  border: none !important;
}

#rtsHeader.rts-header2 .navbar-part .flex-wrapper .cart-nav {
  background: #111111 !important;
  border-radius: 0 !important;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

#rtsHeader.rts-header2 .navbar-part .flex-wrapper .cart-nav:hover {
  background: var(--theme-color) !important;
  border: 1px solid var(--theme-color) !important;
}

#rtsHeader.rts-header2 .navbar-part .navbar-inner .dropdown-ul {
  top: 68px;
  left: 0;
}

#rtsHeader.rts-header2 .navbar-part .navbar-inner .mega-dropdown {
  left: -240px;
}

#rtsHeader.rts-header2 .navbar-part .navbar-inner .logo {
  display: none;
}

#rtsHeader.rts-header2 .navbar-sticky .navbar-part .navbar-inner .rts-menu .menu-item {
  color: #111111;
  padding: 25px 40px;
  font-size: 600;
}

#rtsHeader.rts-header2 .navbar-sticky .navbar-part .navbar-inner .rts-menu .menu-item.active1 {
  color: var(--theme-color);
}

#rtsHeader.rts-header2 .navbar-sticky .navbar-part .navbar-inner .rts-menu .menu-item:hover {
  color: var(--theme-color);
}

#rtsHeader.rts-header2 .navbar-sticky .navbar-part .navbar-inner .search-part i {
  color: #111111 !important;
}

#rtsHeader.rts-header2 .hamburger-menu {
  background-color: #111111 !important;
  border-radius: 0 !important;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  cursor: pointer;
}

#rtsHeader.rts-header2 .hamburger-menu:hover {
  background: var(--theme-color) !important;
  border: 1px solid var(--theme-color) !important;
}

#rtsHeader.rts-header2 .sticky-menu .navbar-part {
  background: #fff;
}

#rtsHeader.rts-header2 .sticky-menu .navbar-part .basket-icon i {
  color: #111111;
}

#rtsHeader.rts-header2 .sticky-menu .navbar-part .navbar-inner .rts-menu .dropdown-ul {
  top: 72px;
}

#rtsHeader.rts-header2 .sticky-menu .hamburger-menu {
  background-color: #111111 !important;
  border-radius: 0 !important;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

#rtsHeader.rts-header2 .sticky-menu .hamburger-menu:hover {
  background: var(--theme-color) !important;
  border: 1px solid var(--theme-color) !important;
}

#rtsHeader.rts-header2 .sticky-menu .hamburger-menu.item {
  width: 50px;
  height: 50px;
  border-radius: 0;
  border: 1px solid #2a2a2a;
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: none;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

#rtsHeader.rts-header2 .sticky-menu .hamburger-menu.item:hover {
  background: var(--theme-color);
  border: 1px solid var(--theme-color);
}

#rtsHeader.rts-header2 .sticky-menu .hamburger-menu.item i {
  color: #fff;
}

#rtsHeader.rts-header2 .sticky-menu .hamburger-menu.item .hamburger-menu-inner span {
  background: #fff;
}

#rtsHeader.rts-header2 .navbar-part {
  position: relative;
  background: #222222;
}

@media (max-width: 1200px) {
  #rtsHeader.rts-header2 .navbar-part .navbar-inner .logo {
    display: block;
  }

  #rtsHeader.rts-header2 .navbar-part .navbar-inner .rts-menu .menu-item {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  #rtsHeader.rts-header2 .sticky-menu .navbar-part .navbar-inner .logo {
    display: none;
  }

  #rtsHeader.rts-header2 .sticky-menu .navbar-part .navbar-inner .rts-menu .menu-item {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}

#rtsHeader.rts-header3 {
  position: relative;
}

#rtsHeader.rts-header3 .navbar-wrapper {
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 2;
  background: #111111;
}

#rtsHeader.rts-header3 .navbar-part {
  width: 100%;
  background: transparent;
  border-bottom: none;
}

#rtsHeader.rts-header3 .navbar-part .navbar-inner {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

#rtsHeader.rts-header3 .navbar-part .navbar-inner .logo {
  position: absolute;
  left: 50%;
  top: 0;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  padding: 0 !important;
}

@media (max-width: 1300px) {
  #rtsHeader.rts-header3 .navbar-part .navbar-inner .logo {
    width: 235px;
  }
}

@media (max-width: 1024px) {
  #rtsHeader.rts-header3 .navbar-part .navbar-inner .logo {
    display: none;
  }
}

#rtsHeader.rts-header3 .navbar-part .navbar-inner .logo2 {
  display: none;
}

@media (max-width: 1024px) {
  #rtsHeader.rts-header3 .navbar-part .navbar-inner .logo2 {
    display: block;
  }
}

#rtsHeader.rts-header3 .navbar-part .rts-menu .menu-item {
  padding: 20px 40px;
}

#rtsHeader.rts-header3 .navbar-part .rts-menu .menu-item:first-child {
  padding-left: 1px;
}

#rtsHeader.rts-header3 .navbar-part .subscribe-btn {
  border: 1px solid #2a2a2a;
  border-radius: 0;
  padding: 10px 25px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #fff;
  -webkit-transition: background 400ms;
  transition: background 400ms;
}

#rtsHeader.rts-header3 .navbar-part .subscribe-btn:hover {
  background: var(--theme-color);
}

#rtsHeader.rts-header3 .navbar-part.navbar-upper {
  border-bottom: 1px solid #2a2a2a;
}

#rtsHeader.rts-header3 .navbar-part.navbar-upper .navbar-inner {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0;
  padding-top: 30px;
  padding-bottom: 20px;
}

#rtsHeader.rts-header3 .navbar-part.navbar-upper .navbar-inner .header-action-items {
  margin-left: unset;
}

#rtsHeader.rts-header3 .navbar-part.navbar-upper .navbar-inner .item {
  width: 50px;
  height: 50px;
  border-radius: 0;
  border: 1px solid #2a2a2a;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: none;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

#rtsHeader.rts-header3 .navbar-part.navbar-upper .navbar-inner .item:hover {
  background: var(--theme-color);
  border: 1px solid var(--theme-color);
}

#rtsHeader.rts-header3 .navbar-part.navbar-upper .navbar-inner .item i {
  color: #fff;
}

#rtsHeader.rts-header3 .navbar-part.navbar-upper .navbar-inner .item .hamburger-menu-inner span {
  background: #fff;
}

@media (max-width: 900px) {
  #rtsHeader.rts-header3 .navbar-part.navbar-upper .hamburger-menu {
    display: block;
  }

  #rtsHeader.rts-header3 .navbar-part.navbar-upper .navbar-inner {
    padding-top: 20px;
  }
}

@media (max-width: 630px) {
  #rtsHeader.rts-header3 .navbar-part.navbar-upper .subscribe-btn {
    display: none;
  }
}

#rtsHeader.rts-header3 .navbar-part.navbar-lower .navbar-inner .search-part .logo-sticky {
  display: none;
}

#rtsHeader.rts-header3 .navbar-part.navbar-lower .navbar-inner .search-part i {
  margin-right: 5px;
  color: #fff;
}

#rtsHeader.rts-header3 .navbar-part.navbar-lower .navbar-inner .search-part input {
  background: transparent;
  color: #fff;
}

#rtsHeader.rts-header3 .navbar-part.navbar-lower .navbar-inner .search-part input:-moz-placeholder-shown {
  font-weight: 600;
  width: 100px;
}

#rtsHeader.rts-header3 .navbar-part.navbar-lower .navbar-inner .search-part input:-ms-input-placeholder {
  font-weight: 600;
  width: 100px;
}

#rtsHeader.rts-header3 .navbar-part.navbar-lower .navbar-inner .search-part input:placeholder-shown {
  font-weight: 600;
  width: 100px;
}

@media (max-width: 900px) {
  #rtsHeader.rts-header3 .navbar-part.navbar-lower {
    display: none;
  }
}

#rtsHeader.rts-header3 .sticky-menu .navbar-part {
  display: block;
}

#rtsHeader.rts-header3 .sticky-menu .navbar-part .navbar-inner {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border: none;
}

#rtsHeader.rts-header3 .sticky-menu .navbar-part .navbar-inner .logo-sticky {
  display: block;
}

#rtsHeader.rts-header3 .sticky-menu .navbar-part .navbar-inner .rts-menu .menu-item {
  color: #111111;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

#rtsHeader.rts-header3 .sticky-menu .navbar-part .navbar-inner .rts-menu .menu-item:hover {
  color: var(--theme-color);
}

#rtsHeader.rts-header3 .sticky-menu .navbar-part .navbar-inner .rts-menu .menu-item.active1 {
  color: var(--theme-color);
}

#rtsHeader.rts-header3 .sticky-menu .navbar-part .navbar-inner .dropdown-ul {
  top: 67px;
  left: -22px;
}

#rtsHeader.rts-header3 .sticky-menu .navbar-part .navbar-inner .dropdown-ul.mega-dropdown {
  left: -200px;
}

#rtsHeader.rts-header3 .sticky-menu .navbar-part .navbar-inner .search-part {
  margin-left: 0;
  border: 1px solid rgb(183, 183, 183);
  border-radius: 0;
  padding: 10px 10px;
}

#rtsHeader.rts-header3 .sticky-menu .navbar-part .navbar-inner .search-part i {
  color: #111111;
}

#rtsHeader.rts-header3 .sticky-menu .navbar-part .navbar-inner .search-part .search-input-div input::-webkit-input-placeholder {
  color: #111111;
}

#rtsHeader.rts-header3 .sticky-menu .navbar-part .navbar-inner .search-part .search-input-div input::-moz-placeholder {
  color: #111111;
}

#rtsHeader.rts-header3 .sticky-menu .navbar-part .navbar-inner .search-part .search-input-div input:-ms-input-placeholder {
  color: #111111;
}

#rtsHeader.rts-header3 .sticky-menu .navbar-part .navbar-inner .search-part .search-input-div input::-ms-input-placeholder {
  color: #111111;
}

#rtsHeader.rts-header3 .sticky-menu .navbar-part .navbar-inner .search-part .search-input-div input::placeholder {
  color: #111111;
}

#rtsHeader.rts-header3 .sticky-menu .navbar-part .navbar-inner .subscribe-btn {
  color: #111111;
}

#rtsHeader.rts-header3 .sticky-menu .navbar-part .navbar-inner .subscribe-btn:hover {
  color: #fff;
  border: 1px solid var(--theme-color);
}

#rtsHeader.rts-header3 .sticky-menu .navbar-part .navbar-inner .header-action-items .action-item i {
  color: #111111;
}

#rtsHeader.rts-header3 .sticky-menu .navbar-part .navbar-inner .header-action-items .action-item .hamburger-menu span {
  background: #111111;
}

@media (max-width: 900px) {
  #rtsHeader.rts-header3 .sticky-menu .navbar-part .search-part {
    display: none !important;
  }

  #rtsHeader.rts-header3 .sticky-menu .navbar-part .hamburger-menu {
    display: block;
  }
}

.header-topbar {
  background: var(--theme-color);
  padding-top: 16px;
  padding-bottom: 20px;
  text-align: center;
}

.header-topbar .header-top-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-topbar .welcome-text {
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  line-height: 0;
}

.header-topbar .welcome-text a {
  color: #fff;
}

.header-topbar .welcome-text a:hover {
  text-decoration: underline;
}

.header-topbar .welcome-text a i {
  font-size: 13px;
  margin-left: 9px;
  margin-bottom: -5px;
}

.header-topbar.header-topbar1 {
  background: #040404;
}

.header-topbar.header-topbar2 {
  padding-top: 8px;
  padding-bottom: 12px;
}

.header-topbar.header-topbar2 .header-top-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-topbar.header-topbar2 .header-top-inner .newsletter-link {
  color: #fff;
  font-weight: 300;
  font-size: 14px;
  line-height: 14px;
  margin-right: 25px;
}

.header-topbar.header-topbar2 .header-top-inner .newsletter-link i {
  margin-right: 5px;
  font-size: 16px;
}

.header-topbar.header-topbar2 .header-top-inner .welcome-text {
  color: #fff;
}

.header-topbar.header-topbar2 .header-top-inner .welcome-text a {
  color: #fff;
}

.header-topbar.header-topbar2 .header-top-inner .welcome-text i {
  font-size: 18px;
  margin-right: 4px;
}

.header-topbar.header-topbar2 .header-top-inner .topbar-select-area {
  margin-left: auto;
}

.header-topbar.header-topbar2 .header-top-inner .topbar-select-area .select-div {
  position: relative;
}

.header-topbar.header-topbar2 .header-top-inner .topbar-select-area .select-div:focus i {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.header-topbar.header-topbar2 .header-top-inner .topbar-select-area .select-div i {
  position: absolute;
  top: 0;
  right: 10px;
}

.header-topbar.header-topbar2 .header-top-inner .topbar-select-area .select {
  width: 75px;
  height: 0;
  position: relative;
}

.header-topbar.header-topbar2 .header-top-inner .topbar-select-area .select .select-styled {
  display: inherit;
  background: transparent;
  position: relative;
  top: unset;
  left: unset;
  bottom: unset;
  right: unset;
  padding: 0;
  color: #fff;
}

.header-topbar.header-topbar2 .header-top-inner .topbar-select-area .select .select-styled.active {
  background: transparent;
}

.header-topbar.header-topbar2 .header-top-inner .topbar-select-area .select .select-options li {
  padding-left: 0 !important;
}

.header-topbar.header-topbar2 .header-top-inner .topbar-select-area .select-options {
  width: 100px;
  margin-top: 40px;
  overflow: hidden;
}

.header-topbar.header-topbar2 .header-top-inner select {
  background: none;
  border: none;
  padding: 0;
  color: #fff;
  outline: none;
  -webkit-appearance: none;
}

.header-topbar.header-topbar2 .header-top-inner select option {
  background: #fff;
  color: #040404;
}

.header-topbar.header-topbar3 {
  background: #f2e5e5;
  padding-top: 8px;
  padding-bottom: 12px;
}

.header-topbar.header-topbar3 .container {
  padding: 0;
}

@media (max-width: 1480px) {
  .header-topbar.header-topbar3 .container {
    padding: 0 20px;
  }
}

.header-topbar.header-topbar3 .header-top-inner .welcome-text {
  color: #040404;
}

.header-topbar.header-topbar3 .header-top-inner .welcome-text .value {
  font-weight: 700;
}

.header-topbar.header-topbar3 .header-top-inner .welcome-text i {
  color: #985034;
}

.header-topbar.header-topbar3 .header-top-inner .topbar-action {
  margin-left: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.header-topbar.header-topbar3 .header-top-inner .topbar-action .action-item {
  position: relative;
  font-size: 14px;
}

.header-topbar.header-topbar3 .header-top-inner .topbar-action .action-item i {
  color: #985034;
}

.header-topbar.header-topbar4 {
  background: #010d18;
}

.header-topbar.header-topbar4 .header-top-inner .welcome-text {
  color: #fff;
}

.header-topbar.header-topbar4 .header-top-inner .welcome-text i {
  color: var(--theme-color);
}

.header-topbar.header-topbar4 .header-top-inner .welcome-text .value {
  color: #fff;
}

.header-topbar.header-topbar4 .header-top-inner .topbar-action .separator {
  background: #0a243c;
}

.header-topbar.header-topbar4 .header-top-inner .topbar-action .action-item {
  color: #fff;
}

.header-topbar.header-topbar4 .header-top-inner .topbar-action .action-item i {
  color: var(--theme-color);
}

@media (max-width: 700px) {
  .header-topbar .welcome-text {
    font-size: 12px;
  }
}

@media (max-width: 560px) {
  .header-topbar {
    display: none;
  }
}

.sticky-menu .navbar-part1 .rts-menu .dropdown-ul {
  top: 95px;
}

.sticky-menu .navbar-part1 .menu-item {
  padding-top: 20px;
  padding-bottom: 20px;
}

.sticky-menu .navbar-part1 .menu-item.active1 {
  color: var(--theme-color);
}

.rts-menu .menu-item {
  padding: 20px 35px;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 500;
}

@media (max-width: 1200px) {
  .rts-menu .menu-item {
    padding: 20px 20px;
  }
}

@media (max-width: 900px) {
  .rts-menu {
    display: flex; /* Make sure the menu is displayed as a flex container */
    flex-direction: column; /* Stack the icons vertically */
    align-items: center; /* Center the icons horizontally */
  }
  }
  /* Media query for screens with a maximum width of 900px */
@media (max-width: 900px) {
  .header-social-links  {
    display: grid;
    grid-template-columns: 35px 35px 35px 35px 35px 35px;
  }
}
@media (max-width: 900px) {
  .header-social-links  a {
    font-size: 25px !important; 
    margin-right: 0; /* Remove the right margin to avoid extra spacing */
  }
}
.navbar-part1 {
  position: absolute;
  width: 100%;
}

.navbar-part1 .menu-item {
  padding-top: 50px;
  padding-bottom: 50px;
  letter-spacing: 2px;
}

.navbar-part1 .header-action-items .action-item i {
  font-size: 15px;
  color: #fff;
}

.navbar-part1 .header-action-items .search-icon {
  width: 40px;
  height: 40px;
  display: -ms-grid;
  display: grid;
  place-items: center;
  background: #111111;
  border-radius: 50%;
}

.navbar-part2 {
  position: relative;
  max-width: 1544px !important;
  margin: 0 auto;
  background: #fff;
  padding: 0 50px;
  border-radius: 0px 0px 10px 10px;
}

.navbar-part2 .navbar-inner {
  background: #fff;
}

.navbar-part2 .navbar-inner .menu-item {
  padding-top: 35px;
  padding-bottom: 35px;
}

.navbar-part3 {
  background: #fff;
  border-bottom: 1px solid #f4f4f4;
}

.navbar-part3 .select .select-styled {
  padding-left: 25px;
}

.navbar-part3 .navbar-inner {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: start;
}

.navbar-part3 .navbar-inner .header-action-items {
  margin-left: auto;
}

.navbar-part3 .navbar-inner .header-action-items .action-item {
  margin-right: 10px;
}

.navbar-part3 .navbar-inner .rts-menu .menu-item {
  padding-top: 0;
  padding-bottom: 0;
}

@media (max-width: 900px) {
  .navbar-part3 .navbar-inner .heading {
    display: none;
  }
}

.navbar-part3 .navbar-inner .heading p a {
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.navbar-part3 .navbar-inner .heading p a:hover {
  color: var(--theme-color);
}

@media (max-width: 900px) {
  .navbar-part3 .navbar-inner {
    padding: 0;
  }
}

.lower-navbar-sticky {
  top: 0;
}

.lower-navbar {
  background: #fff;
  max-width: 100% !important;
}

.lower-navbar .container {
  padding: 0;
}

@media (max-width: 1480px) {
  .lower-navbar .container {
    padding: 0 20px;
  }
}

.lower-navbar .navbar-inner {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: start;
}

.lower-navbar .navbar-inner .logo {
  display: none;
}

.lower-navbar .navbar-inner .select {
  width: 200px;
}

.lower-navbar .navbar-inner .select-styled {
  max-width: unset;
  height: 113%;
  padding: 10px 40px;
  padding-top: 19px;
  border-radius: 0;
  text-align: center;
  background: var(--theme-color);
  color: #fff;
  font-weight: 700;
}

.lower-navbar .navbar-inner .select-styled::after {
  position: relative;
  left: unset;
  right: unset;
  top: 12px;
  margin-left: 5px;
  margin-bottom: -20px;
  border-color: #fff transparent transparent transparent;
}

.lower-navbar .navbar-inner .header-action-items {
  display: none;
}

.lower-navbar .navbar-inner .rts-menu .menu-item {
  padding-top: 0;
  padding-bottom: 0;
}

.lower-navbar .navbar-inner .rts-menu .dropdown-ul {
  top: 42px;
}

.lower-navbar .quick-contact {
  margin-left: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.lower-navbar .quick-contact .contact-item {
  font-size: 14px;
  font-weight: 700;
  color: #040404;
}

.lower-navbar .quick-contact .contact-item.phone {
  margin-right: 40px;
  position: relative;
}

@media (max-width: 1260px) {
  .lower-navbar .rts-menu .menu-item {
    padding: 20px 20px;
  }
}

@media (max-width: 1110px) {
  .lower-navbar .quick-contact {
    display: none;
  }
}

@media (max-width: 900px) {
  .lower-navbar .rts-menu {
    display: block;
  }
}

@media (max-width: 768px) {
  .lower-navbar {
    display: none;
  }

  .lower-navbar .rts-menu {
    display: none;
  }
}

@media (max-width: 768px) {
  .sticky-menu .lower-navbar {
    display: block;
  }
}

.lower-navbar-inner {
  -webkit-box-shadow: 0px 6px 12px -5px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 6px 12px -5px rgba(0, 0, 0, 0.08);
}

.navbar-sticky4 .navbar-inner {
  background: none !important;
}

.navbar-part4 {
  background: #031424;
  border-bottom: 1px solid #0a2239;
  padding: 0 !important;
}

.navbar-part4 .rts-menu .dropdown-link::before {
  background: #fff !important;
}

.navbar-part4 .navbar-search-area {
  margin-left: 0;
}

.navbar-part4 .navbar-search-area .select .select-styled {
  padding-left: 18px;
}

.navbar-part4 .navbar-search-area .search-input-inner input {
  background: #071e34;
  max-width: 375px;
  color: #47637d;
}

.navbar-part4 .navbar-search-area .search-input-inner input::-webkit-input-placeholder {
  color: #47637d;
}

.navbar-part4 .navbar-search-area .search-input-inner input input::-ms-input-placeholder {
  color: #47637d;
}

.navbar-part4 .navbar-search-area .search-input-inner input input:-ms-input-placeholder {
  color: #47637d;
}

.navbar-part4 .navbar-search-area .search-input-inner .search-input-icon {
  background: none;
  color: #fff;
}

.navbar-part4 .search-input-area .search-input-icon i {
  color: #040404;
}

.navbar-part4 .logo {
  margin-left: 60px;
}

.navbar-part4 .navbar-select-area {
  margin-left: auto;
}

.navbar-part4 .header-action-items {
  margin-left: 0 !important;
}

.navbar-part4 .navbar-search-area .select .select-styled {
  background: var(--theme-color);
  font-weight: 700;
}

.navbar-part4 .navbar-select-area .select .select-styled {
  background: var(--theme-color);
  border: none;
}

.navbar-part4 .header-action-items i {
  color: #fff;
}

@media (max-width: 1050px) {
  .navbar-part4 .header-action-items {
    margin-left: auto !important;
  }
}

@media (max-width: 900px) {
  .navbar-part4 {
    padding: 20px 0 !important;
  }

  .navbar-part4 .logo {
    margin-left: 0;
  }

  .navbar-part4 .hamburger span,
  .navbar-part4 .hamburger span:before,
  .navbar-part4 .hamburger span:after {
    background: #fff;
  }
}

.lower-navbar-sticky4 .navbar-inner {
  background: none !important;
}

.lower-navbar4 {
  background: #031424;
  border-radius: 0;
  padding: 0 !important;
}

.lower-navbar4 .navbar-inner {
  background: none;
}

.lower-navbar4 .navbar-inner .rts-menu .mega-dropdown {
  background: #031424;
}

.lower-navbar4 .navbar-inner .rts-menu .mega-dropdown .parent-page-title {
  color: #fff;
}

.lower-navbar4 .navbar-inner .rts-menu .dropdown-ul {
  top: 50px;
}

.lower-navbar4 .navbar-inner .rts-menu .dropdown-link::before {
  background: var(--theme-color);
}

.lower-navbar4 .rts-menu {
  margin-left: auto;
}

.lower-navbar4 .rts-menu .menu-item {
  color: #fff;
}

.lower-navbar4 .rts-menu .dropdown-ul li a {
  background: #031424;
  color: #fff;
  border-bottom: 1px solid #0a2239;
}

.lower-navbar4 .contact-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.lower-navbar4 .contact-info .title {
  font-size: 12px;
  line-height: 20px;
  color: var(--theme-color);
}

.lower-navbar4 .contact-info .email-address {
  font-size: 14px;
  line-height: 20px;
  color: #fff;
}

.navbar-sticky2 {
  background: #F4F6F9;
}

@media (max-width: 1200px) {
  .navbar-sticky2 {
    background: none;
  }
}

.navbar-sticky2.sticky-menu .navbar-part2 {
  padding: 0;
}

.navbar-sticky2.sticky-menu .navbar-part2 .navbar-inner {
  padding: 0;
}

@media (max-width: 900px) {
  .navbar-sticky2.sticky-menu .navbar-part2 .navbar-inner {
    padding: 20px 0;
  }
}

.navbar-sticky.sticky-menu {
  background: #fff !important;
}

.navbar-sticky.sticky-menu .navbar-part {
  position: relative;
  top: unset;
}

.navbar-sticky.sticky-menu .navbar-inner {
  background: #fff;
}

.navbar-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 900px) {
  .navbar-inner {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: start;
  }

  .navbar-inner .header-action-items {
    margin-left: auto;
  }
}

.header-action-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-action-items .action-item {
  cursor: pointer;
  margin-right: 20px;
}

.header-action-items .action-item i {
  font-size: 18px;
}

.header-action-items .cart .cart-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-action-items .cart .cart-nav .cart-icon {
  margin-right: 5px;
  position: relative;
}

.navbar-part {
  z-index: 10;
}

@media (max-width: 560px) {
  .navbar-part {
    top: 28px;
  }
}

.navbar-part.navbar-part2 {
  top: 0;
}

@media (max-width: 900px) {
  .navbar-part.navbar-part2 {
    padding: 0;
  }

  .navbar-part.navbar-part2 .navbar-inner {
    padding: 20px 0;
  }
}

@media (max-width: 560px) {
  .navbar-part {
    top: 0;
  }
}

@media (max-width: 430px) {
  .navbar-part .header-action-items .action-item {
    margin-right: 10px;
  }

  .navbar-part .header-action-items .action-item i {
    font-size: 15px;
  }
}

@media (max-width: 370px) {
  .navbar-part .navbar-inner {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .navbar-part .header-action-items {
    display: none;
  }

  .navbar-part .hamburger {
    margin-left: auto;
  }
}

@media (max-width: 1200px) {
  .navbar-part.navbar-part2 {
    padding: 0;
  }
}

.search-input-area {
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  visibility: hidden;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 80px 0;
  background: #fff;
  -webkit-box-shadow: 1px 1px 50px rgba(0, 0, 0, 0.46);
  box-shadow: 1px 1px 50px rgba(0, 0, 0, 0.46);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.search-input-area.show {
  visibility: visible;
  -webkit-transform: inherit;
  transform: inherit;
  opacity: 1;
}

@media (max-width: 550px) {
  .search-input-area .select {
    font-size: 12px;
  }
}

.search-input-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

.search-input-inner .input-div {
  width: 100%;
  position: relative;
}

.search-input-inner input {
  background: #F8F8F8;
  padding: 14px 20px;
  padding-left: 40px;
  border-radius: 0;
  width: 100%;
  font-size: 15px;
  position: relative;
}

.search-input-inner input:focus {
  border: 1px solid var(--theme-color);
}

@media (max-width: 991px) {
  .search-input-inner input {
    width: 95%;
  }
}

@media (max-width: 330px) {
  .search-input-inner input {
    font-size: 12px;
  }
}

.search-input-inner input::-webkit-input-placeholder {
  color: #c6c6c6;
}

.search-input-inner .search-input-icon {
  position: absolute;
  top: 50%;
  left: 10px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
}

.search-input-inner .search-close-icon i {
  color: #040404;
  font-size: 21px;
  cursor: pointer;
}

.search-input-inner .search-close-icon:hover i {
  color: #FF1856;
}

.search-input-inner::before {
  content: "";
  position: absolute;
  bottom: -320px;
  left: 0px;
  width: 152px;
  height: 304px;
  background: none;
}

.navbar-search-area {
  margin-left: 120px;
}

.navbar-search-area .search-input-inner {
  width: 100%;
}

.navbar-search-area .search-input-inner input {
  width: 464px;
  padding-left: 30px;
}

.navbar-search-area .select-styled {
  padding-left: 30px;
}

.navbar-search-area .select-styled::after {
  position: relative;
  left: unset;
  right: unset;
  top: 12px;
  margin-left: 5px;
  margin-bottom: -20px;
}

.navbar-search-area .search-input-icon {
  left: unset;
  right: 40px;
  font-size: 14px;
  color: #040404;
  font-style: normal;
  font-weight: 700;
  background: #F8F8F8;
  padding: 15px 30px;
  padding-right: 30px;
}

.navbar-search-area .search-input-icon i {
  margin-right: 3px;
}

@media (max-width: 1300px) {
  .navbar-search-area {
    margin-left: 50px;
  }

  .navbar-search-area .search-input-inner .search-input {
    width: 300px;
  }
}

@media (max-width: 910px) {
  .navbar-search-area {
    display: none;
  }
}

.navbar-select-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.navbar-select-area .select {
  width: 80px;
  margin-right: 10px;
}

.navbar-select-area .select .select-styled {
  border-radius: 5px;
  background: none;
  border: 1px solid #E7E7E7;
  font-size: 14px;
  font-weight: 700;
  color: #040404;
}

.navbar-select-area .select .select-styled::after {
  position: relative;
  left: unset;
  right: unset;
  top: 12px;
  margin-left: 5px;
  margin-bottom: -20px;
}

.navbar-select-area .select .select-options ul li {
  font-size: 14px;
  font-weight: 700;
  color: #040404;
  padding: 20px;
}

@media (max-width: 1050px) {
  .navbar-select-area {
    display: none;
  }
}

.header-topbar5 {
  background: var(--theme-color);
}

.header-topbar5.close {
  display: none;
}

.header-topbar5 .header-top-inner .welcome-text .value {
  color: #fff;
}

.header-topbar5 .header-top-inner .welcome-text .value i {
  font-size: 15px;
  margin-right: 0;
  margin-left: 5px;
  -webkit-transform: translateY(2px);
  transform: translateY(2px);
}

.header-topbar5 .header-top-inner .topbar-action .close {
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
}

.header-topbar5 .header-top-inner .topbar-action .close i {
  font-size: 16px;
  -webkit-transform: translateY(0.5px);
  transform: translateY(0.5px);
}

.header-lower-topbar5 {
  background: #fff;
  border-bottom: 1px solid #ebebeb;
}

.header-lower-topbar5 .topbar-navs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.header-lower-topbar5 .topbar-navs a {
  font-size: 14px;
  line-height: 28px;
  font-weight: 500;
  color: #777777;
  padding-right: 40px;
}

.header-lower-topbar5 .topbar-navs a:hover {
  color: var(--theme-color);
}

.header-lower-topbar5 .header-top-inner .topbar-select-area {
  margin-right: -15px;
}

.header-lower-topbar5 .header-top-inner .topbar-select-area .select .select-styled {
  color: #040404 !important;
}

.header-lower-topbar5 .header-top-inner .topbar-select-area .select .select-styled::after {
  border-color: #040404 transparent transparent transparent !important;
}

.header-lower-topbar5 .header-top-inner .topbar-select-area .select .select-options li {
  padding-left: 0 !important;
}

@media (max-width: 768px) {
  .header-lower-topbar5 .topbar-navs a {
    font-size: 13px;
    padding-right: 25px;
  }
}

@media (max-width: 900px) {
  .lower-navbar .navbar-inner {
    padding: 0 !important;
  }

  .lower-navbar .navbar-inner .hamburger {
    margin-left: auto;
    margin-right: 20px;
  }

  .sticky-menu .lower-navbar .navbar-inner {
    padding: 20px 0 !important;
  }

  .sticky-menu .lower-navbar .navbar-inner .logo {
    display: block;
    margin-left: 20px;
  }

  .sticky-menu .lower-navbar .navbar-inner .catagory-select-area {
    display: none !important;
  }

  .sticky-menu .lower-navbar .navbar-inner .hamburger {
    margin-left: auto;
    margin-right: 20px;
  }
}

.navbar-part5 {
  border-bottom: none;
}

.navbar-part5 .navbar-search-area .search-input-icon {
  right: 10px;
}

.navbar-part5 .navbar-search-area .search-input-inner input {
  width: 585px;
  border-radius: 6px;
}

@media (max-width: 1510px) {
  .navbar-part5 {
    padding: 0;
  }
}

.lower-navbar-sticky5 {
  position: relative;
  height: 30px;
  top: unset !important;
}

@media (max-width: 900px) {
  .lower-navbar-sticky5 {
    height: 0;
  }
}

@media (max-width: 900px) {
  .navbar-part5 {
    padding: 25px 0;
  }
}

.sticky-menu.lower-navbar-sticky5 {
  top: 0 !important;
  height: auto;
}

.sticky-menu.lower-navbar-sticky5 .container {
  padding: 0;
}

.sticky-menu.lower-navbar-sticky5.lower-navbar5 {
  -webkit-box-shadow: 0;
  box-shadow: 0;
  position: relative;
  top: unset;
  padding: 0;
}

.sticky-menu.lower-navbar-sticky5 .select .select-styled {
  border-radius: 0;
}

.sticky-menu.lower-navbar-sticky5 .navbar-coupon-code {
  border-radius: 0;
}

.lower-navbar5 {
  -webkit-box-shadow: 0px 3px 32px 0px rgba(0, 0, 0, 0.04);
  box-shadow: 0px 3px 32px 0px rgba(0, 0, 0, 0.04);
  position: relative;
  top: 30px;
  padding: 0;
}

.lower-navbar5 .navbar-inner {
  border-radius: 6px;
}

.lower-navbar5 .select .select-styled {
  border-radius: 6px;
  height: 115%;
}

.lower-navbar5 .navbar-coupon-code {
  background: #000;
  margin-left: auto;
  padding: 12px 20px;
  border-radius: 0 6px 6px 0;
}

@media (max-width: 900px) {
  .sticky-menu .lower-navbar-inner .navbar-inner .category-select-area {
    display: none;
  }
}

.sticky-menu .lower-navbar5 {
  display: block;
  padding: 0;
  padding-left: 0;
}

.sticky-menu .lower-navbar5 .navbar-inner {
  padding: 0;
}

@media (max-width: 900px) {
  .sticky-menu .lower-navbar-inner {
    display: block;
  }

  .sticky-menu .lower-navbar-inner .navbar-inner .logo {
    display: block;
  }

  .sticky-menu .lower-navbar-inner .navbar-inner .catagory-select-area {
    display: none !important;
  }

  .sticky-menu .lower-navbar-inner .navbar-inner .hamburger {
    margin-left: auto;
  }

  .sticky-menu .lower-navbar-inner .navbar-inner .contact-info {
    display: none;
  }

  .sticky-menu .lower-navbar4 {
    display: block;
  }

  .sticky-menu .lower-navbar4 .navbar-inner .logo {
    display: block;
  }

  .sticky-menu .lower-navbar4 .navbar-inner .hamburger {
    margin-left: auto;
  }

  .sticky-menu .lower-navbar4 .navbar-inner .hamburger span {
    background: #fff;
  }

  .sticky-menu .lower-navbar4 .navbar-inner .hamburger span::before {
    background: #fff;
  }

  .sticky-menu .lower-navbar4 .navbar-inner .hamburger span::after {
    background: #fff;
  }

  .sticky-menu .lower-navbar4 .navbar-inner .contact-info {
    display: none;
  }

  .sticky-menu .navbar-coupon-code {
    display: none;
  }

  .sticky-menu .hamburger {
    margin-right: 20px;
  }
}

.page-path {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0;
  margin-bottom: 5px;
}

.page-path ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.page-path a {
  display: block;
  font-size: 16px;
  letter-spacing: 2px;
  color: #fff;
  font-weight: 300;
  text-transform: uppercase;
  margin-right: 20px;
  position: relative;
}

.page-path a::before {
  content: "";
  position: absolute;
  height: 66%;
  width: 1px;
  background: #773435;
  top: 4px;
  right: -10px;
}

.page-path a.current-page {
  margin-right: 0;
}

.page-path a.current-page::before {
  display: none;
}

.page-path a i {
  margin-left: 3px;
}

.page-path a.home-page-link {
  color: #e6a3a1;
}

.page-path a.current-page {
  color: #fff;
}

@media (max-width: 768px) {
  .page-path {
    padding: 15px 0;
  }

  .page-path li a {
    font-size: 14px;
  }
}

.rts-menu .mega-dropdown {
  padding: 30px;
  width: 590px;
  left: -240px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.rts-menu .mega-dropdown .mega-dropdown-li {
  width: 33.333%;
}

.rts-menu .mega-dropdown .parent-page-title {
  font-size: 19px;
  margin-bottom: 20px;
}

.rts-menu .mega-dropdown a {
  padding: 7px 0;
  padding-right: 60px;
  border: none;
}

.rts-menu .mega-dropdown a::before {
  top: 16px;
  left: 4px;
}

.rts-menu .mega-dropdown a:hover {
  padding: 7px 0;
  padding-left: 15px;
}

.side-mobile-menu .mega-dropdown-mobile {
  padding: 7px 0;
  padding-left: 20px;
}

.side-mobile-menu .mega-dropdown-mobile .list-item {
  margin-bottom: 15px;
}

.side-mobile-menu .mega-dropdown-mobile .parent-page-title {
  margin-bottom: 10px;
}

.side-mobile-menu .mega-dropdown-mobile li {
  padding: 0;
}

.side-mobile-menu .mega-dropdown-mobile a {
  padding: 8px 0;
  padding-left: 20px;
  border-bottom: none;
}

.banner {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.banner .swiper-pagination {
  position: relative;
  bottom: inherit;
}

@media (max-width: 800px) {
  .banner .banner-content {
    width: 400px;
    margin-top: 40vh;
    margin-left: 0.1vw;
    padding: 0 !important;
  }
}
@media (max-width: 900px) {
  .banner .banner-content {
    width: 400px;
    margin-top: 30vh;
    margin-left: 0.1vw;
    padding: 0 !important;
  }
}
.banner .banner-content {
  -webkit-transition: all 400ms;
  transition: all 400ms;
  overflow: hidden;
  height: 600px;
}

.banner .banner-content .banner-heading {
  overflow: hidden;
}

@keyframes text-width {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.banner .swiper-slide-active .banner-heading {
  -webkit-animation: text-width 2s;
  animation: text-width 2ls;
}


.banner1 .slider-pagination-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  right: 0;
  bottom: 80px;
  left: unset !important;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
  
}

@media (max-width: 1400px) {
  .banner1 .slider-pagination-area {
    right: -85px;
  }
}

@media (max-width: 600px) {
  .banner1 .slider-pagination-area {
    display: none;
  }
}

.banner1 .slider-pagination-area .slide-range {
  font-size: 16px;
  font-weight: 500;
  margin: 0 15px;
  color: #fff;
  line-height: 0;
}

.banner1 .slider-pagination-area .swiper-pagination {
  top: 0;
  width: 270px !important;
  display: -ms-grid;
  display: grid;
}

.footer-logo img {
  height: 100px;
}

.banner1 .slider-pagination-area .swiper-pagination .swiper-pagination-bullet {
  width: 50px;
  height: 50px;
  padding: 0;
  border-radius: 50%;
  margin: 10px auto !important;
  opacity: 1;
  position: relative;
  /* background-image: url(../public/images/background/megabg.jpg); */
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  background-color: rgb(109 99 201);
  background-blend-mode: multiply;
  background-position: center;
  background-size: cover;
}

.banner1 .slider-pagination-area .swiper-pagination .swiper-pagination-bullet::before {
  content: "";
  position: absolute;
  background: rgba(161, 161, 161, 0.5490196078);
  width: 50px;
  height: 50px;
  line-height: 20px;
  border-radius: 50%;
  left: 50%;
  bottom: 50%;
  opacity: 0.7;
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.banner1 .slider-pagination-area .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active::before {
  background: none;
}

.banner1 .slider-pagination-area .swiper-pagination .swiper-pagination-bullet:hover::before {
  background: none;
}

.banner1 .slider-pagination-area .swiper-pagination .swiper-pagination-bullet:first-child {
  /* background-image: url(../public/images/banner/bannerbg1.jpg); */
  background-position: center;
  background-size: cover;
}

.banner1 .slider-pagination-area .swiper-pagination .swiper-pagination-bullet:last-child {
  /* background-image: url(../public/images/background/slide-3.jpg); */
  background-position: center;
  background-size: cover;
}

.banner1 .slider-pagination-area .swiper-pagination .swiper-pagination-progressbar-fill {
  background: #fff;
}

@media (max-width: 800px) {
  .banner1 .slider-pagination-area .slide-range {
    font-size: 14px;
  }

  .banner1 .slider-pagination-area .swiper-pagination-bullet {
    width: 45px;
    height: 3px;
  }
}

@media only screen and (max-width: 934px) {
  .banner1 .banner-single {
    height: auto; /* Set height to auto to allow content to determine height */
    width: auto;
    padding-top: 30px; /* Adjust top padding for better spacing */
    padding-bottom: 50px; /* Adjust bottom padding for better spacing */

  }
  .banner1 .slider-pagination-area {
    bottom: 60px;
  }
}
@media (max-width: 800px) {
  .hitw-1 .hitw-1-s {
    height: 190px;
    padding-top: 30px;
    padding-bottom: 20px;
  }
}
@media (max-width: 800px) {
  .hitw-2 .hitw-2-s {
    height: 190px;
    padding-top: 30px;
    padding-bottom: 20px;
  }
}

.banner2 {
  position: relative;
  background: #fff;
}

.banner2 .banner-single {
  padding-bottom: 225px;
}

.banner2 .banner-content {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  min-height: 640px;
  position: relative;
}

@media (max-width: 991px) {
  .banner2 .banner-content {
    min-height: 500px;
  }
}

@media (max-width: 576px) {
  .banner2 .banner-content {
    min-height: 400px;
  }
}

.banner2 .banner-content .content {
  position: absolute;
  bottom: 70px;
  left: 70px;
}

@media (max-width: 991px) {
  .banner2 .banner-content .content {
    left: 20px;
  }
}

@media (max-width: 576px) {
  .banner2 .banner-content .content {
    left: 15px;
    bottom: 35px;
  }
}

.banner2 .banner-content .pretitle {
  color: #fff;
  font-size: 16px;
  letter-spacing: 2px;
  font-weight: 300;
}

@media (max-width: 400px) {
  .banner2 .banner-content .pretitle {
    letter-spacing: 1px;
    font-size: 15px;
  }
}

.banner2 .banner-content .banner-heading {
  text-align: start;
  line-height: 70px;
  font-size: 60px;
}

@media (max-width: 991px) {
  .banner2 .banner-content .banner-heading {
    font-size: 50px;
    line-height: 60px;
  }
}

@media (max-width: 768px) {
  .banner2 .banner-content .banner-heading {
    font-size: 42px;
    line-height: 55px;
  }
}

@media (max-width: 576px) {
  .banner2 .banner-content .banner-heading {
    font-size: 28px;
    line-height: 40px;
  }
}

@media (max-width: 400px) {
  .banner2 .banner-content .banner-heading {
    font-size: 22px;
    line-height: 32px;
  }
}

.banner2 .banner-content .banner-btn-area .banner-btn.team-btn {
  background: var(--theme-color);
}

.banner2 .banner-hero {
  position: absolute;
  top: 100px;
  right: 10%;
  -webkit-animation: hero-move 50s infinite linear;
  animation: hero-move 50s infinite linear;
}

@media (max-width: 1366px) {
  .banner2 .banner-hero {
    display: none;
  }
}

@media (max-width: 768px) {
  .banner2 .banner-single {
    padding-top: 120px;
    padding-bottom: 200px;
  }
}

@media (max-width: 550px) {
  .banner2 .banner-single {
    padding-top: 120px;
    padding-bottom: 300px;
  }

  .banner2 .banner-single .banner-content {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .banner2 .banner-single .banner-content .pretitle {
    font-size: 14px;
  }

  .banner2 .banner-single .banner-content .banner-heading {
    text-align: center;
  }
}

.banner2 .slider-button {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 170px;
  height: 50px;
  top: 7%;
  right: 3%;
  z-index: 1;
  background: #111111;
  color: #fff;
  text-align: center;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  border-radius: 0;
}

.banner2 .slider-button::before {
  content: "";
  position: absolute;
  width: 2px;
  height: 50%;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: rgb(34, 34, 34);
}

.banner2 .slider-button .swiper-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.banner2 .slider-button .swiper-btn.slide-prev i {
  margin-right: 10px;
}

.banner2 .slider-button .swiper-btn.slide-next i {
  margin-left: 10px;
}

.banner3 .banner-bg {
  /* background-image: url("../public/images/banner/bannerbg3.jpg"); */
  background-position: center;
  padding-top: 365px;
  padding-bottom: 250px;
}

.banner3 .banner-bg::before {
  display: none;
}

.banner3 .banner-bg-2 {
  /* background-image: url("../public/images/banner/banner-bg4.jpg"); */
  background-position: center;
  padding-top: 365px;
  padding-bottom: 250px;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
}

.banner3 .banner-bg-2::before {
  display: none;
}

.banner3 .banner-content .flex-wrap {
  margin-bottom: 13px;
}

.banner3 .banner-content .flex-wrap.post {
  margin-bottom: 600px;
}

.banner3 .banner-content .flex-wrap .post-date {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  margin-right: 40px;
  font-family: "Roboto";
}

.banner3 .banner-content .flex-wrap .post-date i {
  margin-right: 3px;
}

.banner3 .banner-content .flex-wrap .post-by {
  font-size: 14px;
  font-weight: 400;
  font-family: "Roboto";
  color: #fff;
}

.banner3 .banner-content .flex-wrap .post-by i {
  margin-right: 3px;
}

.banner3 .banner-content .banner-btn-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.banner3 .banner-content .banner-btn-area .more-btn {
  font-size: 16px;
  font-weight: 600;
  color: #111111;
  background: #111111;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  text-transform: uppercase;
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  margin-right: 15px;
  border-radius: 0;
  border: none;
}

.banner3 .banner-content .banner-btn-area .more-btn::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  background: #fff;
}

.banner3 .banner-content .banner-btn-area .more-btn:hover {
  color: #fff;
}

.banner3 .banner-content .banner-btn-area .more-btn:hover::before {
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}

.banner3 .banner-content .banner-btn-area .more-btn.one {
  color: #fff;
}

.banner3 .banner-content .banner-btn-area .more-btn.one::before {
  background: var(--theme-color);
}

.banner3 .banner-content .blog-catagory-tag {
  margin-right: 13px;
  font-family: "Roboto";
  font-weight: 400;
}

.banner3 .banner-content .blog-date {
  font-size: 14px;
  font-weight: 300;
  color: #fff;
}

.banner3 .banner-content .blog-date i {
  margin-right: 3px;
}

.banner3 .banner-content .banner-heading {
  font-size: 50px;
  letter-spacing: -1px;
  line-height: 60px;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  max-width: 600px;
  margin-bottom: 30px;
}

.banner3 .banner-content .news-details-btn {
  font-size: 16px;
  letter-spacing: 2px;
  color: #fff;
  border: 2px solid rgba(255, 255, 255, 0.1);
  font-weight: 600;
  padding: 15px 30px;
}

.banner3 .banner-content .news-details-btn:hover {
  background: #111111;
}

.banner3 .banner-blog-thumbs {
  position: absolute;
  right: 0;
  -webkit-transform: translateY(-150%);
  transform: translateY(-150%);
  z-index: 1;
  cursor: pointer;
}

.banner3 .banner-blog-thumbs .banner-single-thumb {
  opacity: 20%;
  -webkit-transition: opacity 500ms;
  transition: opacity 500ms;
}

.banner3 .banner-blog-thumbs .content {
  width: 100%;
}

.banner3 .banner-blog-thumbs .content .blog-catagory-tag {
  margin-bottom: 12px;
  display: inline-block;
}

.banner3 .banner-blog-thumbs .content .blog-title {
  font-size: 20px;
  letter-spacing: -1px;
  line-height: 30px;
  color: #fff;
  font-weight: 600;
  max-width: 275px;
}

.banner3 .banner-blog-thumbs .thumb-slider-pagination {
  margin-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.banner3 .banner-blog-thumbs .thumb-slider-pagination .thumb-progress {
  width: 30.333333%;
  height: 2px;
  background: #fff;
  opacity: 10%;
  -webkit-transition: opacity 500ms;
  transition: opacity 500ms;
}

.banner3 .SlideThumb {
  max-width: 400px;
  height: 400px;
}

@media (max-width: 1324px) {
  .banner3 .banner-blog-thumbs {
    right: 100px;
  }
}

@media (max-width: 900px) {
  .banner3 .banner-bg {
    background-position: left;
  }

  .banner3 .banner-single {
    padding-top: 200px;
    padding-bottom: 120px;
  }

  .banner3 .banner-blog-thumbs {
    display: none;
  }
}

@media (max-width: 768px) {
  .banner3 .banner-content .banner-heading {
    font-size: 40px;
    line-height: 50px;
  }

  .banner3 .banner-content .more-btn {
    font-size: 14px;
    padding: 14px 19px;
  }
}

@media (max-width: 410px) {
  .banner3 .banner-content .banner-heading {
    font-size: 30px;
    line-height: 40px;
  }
}

.banner-single {
  padding-top: 345px;
  padding-bottom: 300px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.banner-content {
  padding: 0 20px;
  position: relative;
  /* margin-top: -20vh;
  margin-left: -14vh; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
  text-align: start;
}

.banner-content .pretitle {
  font-size: 26px;
  color: #fff;
  background-color: #60ae44;
  font-weight: 200;
  padding: 5px 10px;
  letter-spacing: 2px;
  margin-bottom: 10px;
}

.banner-content .banner-heading {
  /* margin-top: 7vw; */
  font-size: 100px;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: inherit;
}
  /* margin-bottom: 50px; */

.sub-heding p {
  color: white;
  font-weight: bold;
  font-size: 30px !important;
  /* Add !important to ensure higher specificity */
}

.second-para {
  margin-bottom: 5vh;
}

@media (max-width: 670px) {
  .sub-heding p {
    color: white;
    font-weight: bold;
    font-size: 15px !important;
    /* Add !important to ensure higher specificity */
  }

  .second-para {
    margin-bottom: 2vh;
  }
}

.banner-content .banner-btn-area .banner-btn {
  padding: 13px 30px;
  color: #fff;
  font-weight: 400;
  letter-spacing: 2px;
  border: 1px solid transparent;
  -webkit-transition: all 500ms;
  transition: all 500ms;
}
/*  */
.banner-content .banner-btn-area .bannar-btn {
  padding: 1px 120px 1px 120px;
  color: #fff;
  font-weight: 400;
  letter-spacing: 2px;
  border: 1px solid transparent;
  -webkit-transition: all 500ms;
  transition: all 500ms;
}
.banner-content .banner-btn-area .banner-btn i {
  color: #fff;
}

.banner-content .banner-btn-area .banner-btn.team-btn {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background: transparent;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  text-transform: uppercase;
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  margin-right: 15px;
  border: 1px solid #111111;
}

.banner-content .banner-btn-area .banner-btn.team-btn::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  background: #111111;
}

.banner-content .banner-btn-area .banner-btn.team-btn:hover {
  border: 1px solid #fff;
}

.banner-content .banner-btn-area .banner-btn.team-btn:hover::before {
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}

.banner-content .banner-btn-area .banner-btn.nxt-match-btn {
  font-size: 20px;
  font-weight: 600;
  color: white;
  border: 2px solid #01bf61;
  /* background: #111111; */
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  text-transform: uppercase;
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  margin-right: 15px;
}
/* button start */
@media only screen and (max-width: 900px) {
  .banner-content .banner-btn-area .banner-btn.nxt-match-btn {
    font-size: 13px; 
    padding: 20px 0px 20px 0px;
  }

  .banner-content .banner-btn-area .bannar-btn.nxt-match-btn {
    font-size: 14px; 
    padding: 0px 70px 0px 70px;
    margin-top: 10px;
  }
}

@media (max-width: 400px) {
  .banner-content .banner-heading {
    font-size: 30px;
    line-height: 40px;
  }

  .banner-content .banner-btn-area .bannar-btn-btn-area{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .banner-content .banner-btn-area .banner-btn {
    font-size: 10px;
    margin-bottom: 15px;
    margin-right: 0 !important;
  }
}

@media (max-width: 768px) {
  .banner-content .banner-heading {
    font-size: 55px;
    line-height: 60px;
    margin-bottom: 25px;
  }

  .banner-content .banner-btn-area .banner-btn {
    padding: 10px 15px;
    font-size: 14px;
  }
}


.banner-btn-area .banner-btn img {
  vertical-align: middle; 
  margin-left: 5px;      
}


.banner-content .banner-btn-area .bannar-btn.nxt-match-btn {
  font-size: 20px;
  font-weight: 600;
  color: white;
  border: 2px solid #01bf61;
  /* background: #111111; */
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  text-transform: uppercase;
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  margin-right: 15px;
}


.banner-content .banner-btn-area .banner-btn.nxt-match-btn::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  /* background: #fff; */
}

.banner-content .banner-btn-area .banner-btn.nxt-match-btn i {
  color: #01bf61;
}

.banner-content .banner-btn-area .banner-btn.nxt-match-btn:hover {
  color: #fff;
}

.banner-content .banner-btn-area .banner-btn.nxt-match-btn:hover::before {
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}

.banner-content .banner-btn-area .banner-btn.nxt-match-btn:hover i {
  color: #fff;
}

@media (max-width: 1200px) {
  .banner-content .banner-heading {
    font-size: 80px;
  }
}



@media (max-width: 600px) {
  .banner-content .banner-heading {
    font-size: 40px;
    line-height: 50px;
    font-weight: 500;
  }
}



.header-social-links a {
  font-size: 35px;
  /* Adjust the font size to make the icons bigger */
  margin-right: 10px;
  /* Add some spacing between the icons */
  color: #01bf61;
  /* Set the color of the icons to white */
}

/* .banner-bg {

  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;
} */

/* this css is for banner 1  */
.banner1-bg {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;
}

/* .banner-bg::before {
  content: "";
  mix-blend-mode: multiply;
  background-color: rgb(7 86 134 / 80%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5; 
} */


/* .banner-bg::after {
  content: "";
  background-image: url(../public/images/shape1.png);
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 0;
  width: 100%;
  height: 80%;
} */
.swiper-slide {
  background-image: url("../public/images/banner-image-new2.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;
}
@media (max-width: 1024px) {
  .swiper-slide {
    background-image: url("../public/images/mobile-banner.png");
  }
}

/* Adjust height for tablets */
/* @media (max-width: 1024px) {
  .swiper-slide {
    background-image: url("../public/images/Mobile-banner-image.jpg");
  }
} */

.banner-single-1 {
  height:30vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-size: contain;
  background-repeat: no-repeat;
}



/* .banner-single-1::after {
  content: "";
  position: absolute;
   background-image: url("../public/images/coming_soon_animated_text_2.gif");
  background-size: contain;
  background-repeat: no-repeat;
  width: 400px;
  height: 400px;
  right:30vh;
  top:15vh;
} */

@media (max-width: 1024px) {
  .banner-single-1::after {
    display: none;
  }

  .banner-single-1 {
    margin-top: 1vh;
    background-image: url("../public/images/perks-mobile-view.svg");
  }
}


.banner-single-1_2 {
  background-image: url("../public/images/image2.png");
}
@media (max-width: 900px) {
  .banner-single-1_2 {
    background-size: contain; /* or contain, depending on your preference */
    background-position: center; /* adjust the position as needed */
    height: auto; /* Let the height adjust based on the content */
    max-width: 100%; /* Ensure the image doesn't exceed its container */
    overflow: hidden; /* Hide overflowing content */
  }
}
.banner-single-3 {
  background-image: url("../public/images/image3-edited.png");
}
@media (max-width: 900px) {
  .banner-single-3 {
    background-size: contain; /* or contain, depending on your preference */
    background-position: center; /* adjust the position as needed */
    height: auto; /* Let the height adjust based on the content */
    max-width: 100%; /* Ensure the image doesn't exceed its container */
    overflow: hidden; /* Hide overflowing content */
  }
}

.banner-single-2 {
  /* background-image: url("../public/images/banner/bannerbg2.jpg"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}

.banner-single-2::before {
  content: "";
  position: absolute;
  mix-blend-mode: unset;
  background: unset;
  background-image: -webkit-gradient(linear, left top, right top, from(#000000), to(rgba(0, 0, 0, 0)));
  background-image: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.banner-single-2 .video-section-inner {
  position: absolute;
  top: 44%;
  left: 79%;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
  z-index: 1;
}

@media (max-width: 991px) {
  .banner-single-2 .video-section-inner {
    left: 90%;
    top: 50%;
  }
}

@media (max-width: 576px) {
  .banner-single-2 .video-section-inner {
    display: none;
  }
}

.banner-single-2 .video-section-inner .play-video .popup-video {
  background: #111111;
  color: #fff;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
  display: block;
  border-radius: 50%;
  position: relative;
  z-index: 1;
  font-size: 20px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

@media (max-width: 991px) {
  .banner-single-2 .video-section-inner .play-video .popup-video {
    width: 80px;
    height: 80px;
    line-height: 80px;
  }
}

.banner-single-2 .video-section-inner .play-video .popup-video:hover {
  background-color: var(--theme-color);
}

.banner-single-2 .video-section-inner .play-video .popup-video:hover::before {
  border-color: var(--theme-color);
}

.banner-single-2 .video-section-inner .play-video .popup-video::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  border: 1px solid #111111;
  left: 0;
  z-index: -1;
  -webkit-animation: pulse-border 2s ease-out infinite;
  animation: pulse-border 2s ease-out infinite;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.banner-single-4 {
  /* background-image: url("../public/images/banner/bannerbg6.jpg"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}

.banner-single-4::before {
  content: "";
  background-color: rgb(1, 1, 1);
  opacity: 0.6;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
}

/* .banner-single-4::after {
  position: absolute;
  content: "EXPLORE";
  right: 35%;
  bottom: 23%;
  color: rgba(255, 255, 255, 0);
  background-color: rgba(255, 255, 255, 0.1);
  background-size: 100% 100%;
  -webkit-background-clip: text;
  -webkit-text-stroke: 0.05px rgba(255, 255, 255, 0.08);
  -webkit-filter: drop-shadow(-1px 1px 0 rgb(0, 0, 0));
  filter: drop-shadow(-1px 1px 0 rgb(0, 0, 0));
  font-size: 70px;
  -moz-transform: matrix(3.415835036, 0, 0, 3.415835036, 0, 0);
  -webkit-transform: matrix(3.415835036, 0, 0, 3.415835036, 0, 0);
  -ms-transform: matrix(3.415835036, 0, 0, 3.415835036, 0, 0);
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content;
  max-height: -webkit-max-content;
  max-height: -moz-max-content;
  max-height: max-content;
  font-weight: 900;
  z-index: -1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  opacity: 1;
  font-family: var(--font-secondary);
  z-index: 0;
} */

/* @media (max-width: 1100px) {
  .banner-single-4::after {
    font-size: 50px;
    bottom: 25%;
  }
} */

/* @media (max-width: 768px) {
  .banner-single-4::after {
    font-size: 35px;
  }
} */

/* @media (max-width: 576px) {
  .banner-single-4::after {
    display: none;
  }
} */

.inner-page-banner {
  /* background-image: url("../public/images/banner/bannerbg-inner.jpg"); */
  background-size: cover;
  background-position: bottom;
  padding-top: 260px;
  padding-bottom: 60px;
}

.inner-page-banner::after {
  display: none;
}

.inner-page-banner .banner-content {
  height: auto;
}

.inner-page-banner .banner-heading {
  font-size: 80px;
  letter-spacing: -2px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  padding: 0;
}

@media (max-width: 1200px) {
  .inner-page-banner {
    padding-top: 200px;
    padding-bottom: 100px;
  }
}

@media (max-width: 768px) {
  .inner-page-banner {
    padding-top: 180px;
    padding-bottom: 20px;
  }

  .inner-page-banner .banner-heading {
    font-size: 60px;
    line-height: 60px;
  }

  .inner-page-banner .page-path {
    margin-bottom: 10px;
  }
}

@media (max-width: 520px) {
  .inner-page-banner {
    padding-top: 180px;
  }

  .inner-page-banner .page-path {
    margin-bottom: 5px;
  }
}

@media (max-width: 440px) {
  .inner-page-banner .banner-heading {
    font-size: 40px;
    line-height: 55px;
  }
}

.rts-latest-match {
  background: #f6f6f6;
}

.rts-latest-match .latest-match-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 165px 0 190px 0;
  position: relative;
  z-index: 1;
}

.rts-latest-match .latest-match-inner::after {
  content: "LIVE";
  position: absolute;
  color: #fff;
  font-size: 300px;
  font-weight: 600;
  z-index: -1;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

@media (max-width: 576px) {
  .rts-latest-match .latest-match-inner::after {
    font-size: 230px;
    top: 20%;
  }
}

@media (max-width: 400px) {
  .rts-latest-match .latest-match-inner::after {
    font-size: 170px;
    top: 28%;
  }
}

.rts-latest-match .club-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.rts-latest-match .club-area .club-logo {
  margin-right: 30px;
  max-width: 120px;
}

.rts-latest-match .club-area .content {
  margin-right: 40px;
}

.rts-latest-match .club-area .content .club-text {
  color: #111111;
  font-size: 24px;
  line-height: 35px;
  font-weight: 600;
  letter-spacing: inherit;
  margin-bottom: 25px;
}

.rts-latest-match .club-area .match-type {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  color: #fff;
  padding: 3px 10px;
  background: var(--theme-color);
}

@media (max-width: 900px) {
  .rts-latest-match .club-area .match-type {
    display: none;
  }
}

.rts-latest-match .match-countdown-area {
  margin: 0 auto;
}

.rts-latest-match .countdown {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.rts-latest-match .countdown .countdown-el {
  background: none;
  width: 80px;
  height: 0;
  padding: 4px;
  border-radius: 5px;
  margin-right: 10px;
  padding-left: 9px;
}

.rts-latest-match .countdown .countdown-el .value {
  margin-top: 9px;
  margin-bottom: 1px;
  font-size: 60px;
  color: #111111;
  font-weight: 700;
  line-height: 0;
}

.rts-latest-match .countdown .letter {
  font-size: 15px;
  font-weight: 600;
  color: #636363;
  line-height: 0;
  -webkit-transform: translateY(10px) translateX(-10px);
  transform: translateY(10px) translateX(-10px);
}

.rts-latest-match .ticket-btn {
  background: var(--theme-color);
  padding: 15px 25px;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  border: 1px solid transparent;
  -webkit-transition: all 400ms;
  transition: all 400ms;
  margin-left: auto;
}

.rts-latest-match .ticket-btn:hover {
  background: transparent;
  border: 1px solid var(--theme-color);
}

@media (max-width: 1200px) {
  .rts-latest-match .countdown .countdown-el {
    width: 60px;
  }

  .rts-latest-match .countdown .countdown-el .value {
    font-size: 40px;
  }
}

@media (max-width: 1030px) {
  .rts-latest-match .latest-match-inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .rts-latest-match .latest-match-inner .club-area {
    margin: 0;
    margin-bottom: 30px;
  }

  .rts-latest-match .latest-match-inner .match-countdown-area {
    margin: 0;
    margin-bottom: 40px;
  }

  .rts-latest-match .latest-match-inner .ticket-btn {
    margin: 0;
  }
}

@media (max-width: 768px) {
  .rts-latest-match .latest-match-inner {
    padding: 60px 0;
  }

  .rts-latest-match .latest-match-inner .content {
    margin-right: 0;
    margin-left: 0 !important;
  }

  .rts-latest-match .latest-match-inner .club-area {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .rts-latest-match .latest-match-inner .club-area .club-logo {
    margin-bottom: 20px;
    margin-right: 0;
  }

  .rts-latest-match .latest-match-inner .club-area .club-text {
    margin-right: 0;
    margin-bottom: 20px;
    text-align: center;
  }

  .rts-latest-match .latest-match-inner .club-area .club-text br {
    display: none;
  }

  .rts-latest-match .latest-match-inner .match-countdown-area {
    margin-bottom: 75px;
  }
}

.rts-point-table-section {
  padding-bottom: 105px;
}

.rts-point-table-section .section-title-area {
  position: relative;
}

.rts-point-table-section .section-title-area .pretitle {
  top: -150px;
  font-size: 220px;
  -webkit-text-fill-color: #f7f7f7;
  -webkit-text-stroke-color: #f7f7f7;
}

@media (max-width: 768px) {
  .rts-point-table-section .section-title-area .pretitle {
    top: -50px;
  }
}

.rts-point-table-section .section-title-area .section-title {
  margin-bottom: 15px;
}

.rts-point-table-section .title-area {
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .rts-point-table-section .table-area {
    overflow-x: scroll;
  }

  .rts-point-table-section .table-area td span,
  .rts-point-table-section .table-area td h4,
  .rts-point-table-section .table-area th span,
  .rts-point-table-section .table-area th h4 {
    font-size: 14px;
  }

  .rts-point-table-section .table-area td,
  .rts-point-table-section .table-area th {
    padding: 15px 0;
    padding-left: 10px !important;
  }

  .rts-point-table-section .table-area .head-tr th {
    font-size: 14px;
    padding-left: 10px !important;
  }

  .rts-point-table-section .table-area .player-name {
    min-width: 100px;
  }
}

@media (max-width: 470px) {
  .rts-point-table-section .table-area table {
    width: 100%;
  }
}

.rts-point-table-section .table-area .head-tr {
  background: var(--theme-color) !important;
}

.rts-point-table-section .table-area .head-tr.two {
  background: #f5f5f5 !important;
}

.rts-point-table-section .table-area .head-tr.two th {
  border-radius: 0 !important;
  color: #111111;
  border-right: 1px solid #e7e7e7;
}

.rts-point-table-section .table-area .head-tr th {
  border-radius: 0;
}

.rts-point-table-section .table-area .head-tr th {
  color: #fff;
}

.rts-point-table-section .table-area .table tr {
  text-align: center;
}

.rts-point-table-section .button-area {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.rts-point-table-section .full-table-btn {
  font-size: 16px;
  margin-top: 20px;
  width: 150px;
  height: 50px;
  line-height: 50px;
  font-weight: 500;
  color: #fff;
  background: #111111;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  text-transform: uppercase;
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

@media (max-width: 1100px) and (min-width: 900px) {
  .rts-point-table-section .full-table-btn {
    display: block;
  }
}

@media (max-width: 450px) {
  .rts-point-table-section .full-table-btn {
    display: block;
    margin: 0 auto;
  }
}

.rts-point-table-section .full-table-btn::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  background: var(--theme-color);
}

.rts-point-table-section .full-table-btn:hover {
  color: #fff;
}

.rts-point-table-section .full-table-btn:hover::before {
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}

@media (max-width: 768px) {
  .rts-point-table-section td {
    margin-bottom: 20px;
  }
}

.rts-player-details-section {
  /* background: url(../public/images/background/team-details-bg.jpg); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;
}

.rts-player-details-section .player-details-single {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 50px;
}

@media (max-width: 1400px) {
  .rts-player-details-section .player-details-single {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.rts-player-details-section .player-details-single .player-picture img {
  background: #1e1e1e;
  padding: 50px;
}

@media (max-width: 1300px) and (min-width: 991px) {
  .rts-player-details-section .player-details-single .player-picture img {
    padding: 20px;
  }
}

@media (max-width: 500px) {
  .rts-player-details-section .player-details-single .player-picture img {
    padding: 20px;
  }
}

.rts-player-details-section .player-details-single .player-status-area .status-box {
  background: #1e1e1e;
  border-radius: 0;
}

.rts-player-details-section .player-details-single .player-status-area .status-box .box-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}

.rts-player-details-section .player-details-single .player-status-area .status-box .status-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 19px 30px;
  border-bottom: 1px solid #282525;
}

.rts-player-details-section .player-details-single .player-status-area .status-box .status-item:last-child {
  border: none;
}

.rts-player-details-section .player-details-single .player-status-area .status-box .status-item .status-name {
  font-size: 16px;
  line-height: 35px;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
}

.rts-player-details-section .player-details-single .player-status-area .status-box .status-item .status-number {
  font-size: 14px;
  color: #999999;
  font-weight: 500;
  font-family: "Roboto";
}

.rts-player-details-section .player-details-single .player-status-area .status-box .status-item .status-number.club {
  color: var(--theme-color);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.rts-player-details-section .player-details-single .player-status-area .status-box .status-item .status-number.club:hover {
  color: #999999;
}

.rts-player-details-section .player-details-single .player-status-area .status-percentage {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .rts-player-details-section .player-details-single .player-status-area .status-percentage {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
}

.rts-player-details-section .player-details-single .player-status-area .status-percentage .sp-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.rts-player-details-section .player-details-single .player-status-area .status-percentage .sp-item .per {
  font-size: 24px;
  font-weight: 700;
  color: #111111;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #fff;
  margin-bottom: 10px;
}

.rts-player-details-section .player-details-single .player-status-area .status-percentage .sp-item .per span {
  font-size: 14px;
  font-weight: 500;
  color: var(--theme-color);
  -webkit-transform: translateY(5px);
  transform: translateY(5px);
}

.rts-player-details-section .player-details-single .player-status-area .status-percentage .sp-item .title {
  font-size: 12px;
  letter-spacing: 1px;
  color: #111111;
  font-weight: 600;
  text-align: center;
}

@media (max-width: 1200px) {
  .rts-player-details-section .player-details-single .player-picture {
    margin: 0 auto;
    margin-bottom: 30px;
  }

  .rts-player-details-section .player-details-single .contents {
    margin: 0 auto;
    margin-bottom: 30px;
  }

  .rts-player-details-section .player-details-single .player-status-area .status-percentage {
    margin: 0 auto;
    max-width: 400px;
  }
}

.rts-player-details-section .contents {
  padding: 0 25px 100px 0;
}

@media (max-width: 1200px) {
  .rts-player-details-section .contents {
    padding: 0 25px 10px 0;
  }
}

@media (max-width: 768px) {
  .rts-player-details-section .contents {
    padding: 0;
  }
}

.rts-player-details-section .contents .player-position-number {
  font-size: 40px;
  font-weight: 600;
  color: var(--theme-color);
}

.rts-player-details-section .contents .player-name {
  font-size: 50px;
  margin-bottom: 25px;
  color: #fff;
}

.rts-player-details-section .contents .player-role {
  font-size: 14px;
  color: var(--theme-color);
  font-weight: 400;
  font-family: "Roboto";
  margin-bottom: 5px;
}

.rts-player-details-section .contents p {
  margin-bottom: 18px;
  font-weight: 400;
}

@media (max-width: 991px) {
  .rts-player-details-section .contents p {
    margin-bottom: 20px !important;
  }
}

@media (max-width: 768px) {
  .rts-player-details-section .contents p {
    margin-bottom: 10px !important;
  }
}

.rts-player-details-section .contents .more-details-btn {
  background: var(--theme-color);
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  padding: 15px 30px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.rts-player-details-section .contents .more-details-btn:hover {
  background: #fff;
  color: var(--theme-color);
}

.rts-player-details-section .contents .more-details-btn:hover i {
  -webkit-transform: translateX(5px);
  transform: translateX(5px);
}

@media (max-width: 768px) {
  .rts-player-details-section .top-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 30px;
  }

  .rts-player-details-section .top-wrapper .section-title-area {
    margin-bottom: 20px !important;
  }

  .rts-player-details-section .top-wrapper .swiper-navigation .slide-nav {
    width: 50px;
    height: 50px;
  }

  .rts-player-details-section .top-wrapper .swiper-navigation .slide-nav i {
    font-size: 14px;
  }

  .rts-player-details-section .player-details-single .contents {
    width: 100%;
    text-align: start;
  }

  .rts-player-details-section .player-details-single .contents .player-name {
    font-size: 40px;
  }

  .rts-player-details-section .player-details-single .contents p {
    max-width: unset;
    margin-bottom: 0;
  }

  .rts-player-details-section .player-details-single .player-status-area .status-box {
    width: 100%;
    margin: 0;
  }
}

.rts-blog-section .section-title-area {
  position: relative;
}

.rts-blog-section .section-title-area .pretitle {
  top: -150px;
  font-size: 220px;
  -webkit-text-fill-color: #f7f7f7;
  -webkit-text-stroke-color: #f7f7f7;
}

@media (max-width: 9991px) {
  .rts-blog-section .section-title-area .pretitle {
    font-size: 170px;
  }
}

.rts-blog-section .section-title-area .section-title {
  margin-bottom: 15px;
}

.rts-blog-section .blog-item {
  position: relative;
}

.rts-blog-section .blog-item:hover .blog-picture img {
  -webkit-transform: scale(110%);
  transform: scale(110%);
}

.rts-blog-section .blog-item .blog-picture {
  overflow: hidden;
  position: relative;
  overflow: hidden;
}

.rts-blog-section .blog-item .blog-picture::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 1;
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(30%, rgb(0, 0, 0)), to(rgba(0, 0, 0, 0)));
  background-image: linear-gradient(0deg, rgb(0, 0, 0) 30%, rgba(0, 0, 0, 0) 100%);
  opacity: 92%;
}

.rts-blog-section .blog-item .blog-picture img {
  -webkit-transition: -webkit-transform 400ms;
  transition: -webkit-transform 400ms;
  transition: transform 400ms;
  transition: transform 400ms, -webkit-transform 400ms;
}

.rts-blog-section .blog-item .contents {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 40px;
  z-index: 2;
}

.rts-blog-section .blog-item .contents .blog-catagory {
  font-size: 12px;
  font-weight: 400;
  padding: 3px 10px;
  border-radius: 0;
  background: var(--theme-color);
  font-family: "Roboto";
  color: #fff;
  display: inline-block;
  margin-bottom: 10px;
}

.rts-blog-section .blog-item .contents .blog-title {
  font-size: 20px;
  letter-spacing: -1px;
  line-height: 30px;
  color: #fff;
  font-weight: 600;
  max-width: 310px;
  display: block;
  margin-bottom: 30px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.rts-blog-section .blog-item .contents .blog-title:hover {
  color: var(--theme-color);
}

.rts-blog-section .blog-item .contents .author-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.rts-blog-section .blog-item .contents .author-info .author-dp {
  margin-right: 10px;
}

.rts-blog-section .blog-item .contents .author-info .author-name {
  font-size: 16px;
  line-height: 20px;
  color: #fff;
  font-weight: 600;
}

.rts-blog-section .blog-item .contents .author-info .blog-date {
  font-size: 14px;
  font-weight: 400;
  color: #c4c4c4;
  font-family: "Roboto";
}

@media (max-width: 1200px) {
  .rts-blog-section .blog-item .blog-picture {
    margin-bottom: 25px;
    width: 100%;
  }

  .rts-blog-section .blog-item .blog-picture img {
    width: 100%;
  }
}

@media (max-width: 470px) {
  .rts-blog-section .blog-item .contents {
    padding: 20px;
  }

  .rts-blog-section .blog-item .contents .blog-title {
    font-size: 18px;
    line-height: 28px;
    font-weight: 500;
    margin-bottom: 20px;
  }
}

.rts-blog-section3 {
  background: #111111;
}

.rts-blog-section3 .section-title-area .section-title {
  margin-top: -8px;
  color: #fff;
  font-size: 30px;
  font-weight: 600;
}

.rts-blog-section3 .blog-area .blog-item {
  position: relative;
}

@media (max-width: 991px) {
  .rts-blog-section3 .blog-area .blog-item {
    margin-bottom: 30px;
  }
}

.rts-blog-section3 .blog-area .blog-item:hover .blog-picture img {
  -webkit-transform: scale(110%);
  transform: scale(110%);
}

.rts-blog-section3 .blog-area .blog-item .blog-picture {
  overflow: hidden;
  position: relative;
  overflow: hidden;
}

.rts-blog-section3 .blog-area .blog-item .blog-picture::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 1;
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgb(0, 0, 0)), to(rgba(0, 0, 0, 0)));
  background-image: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
  opacity: 100%;
}

.rts-blog-section3 .blog-area .blog-item .blog-picture img {
  -webkit-transition: -webkit-transform 400ms;
  transition: -webkit-transform 400ms;
  transition: transform 400ms;
  transition: transform 400ms, -webkit-transform 400ms;
  width: 100%;
}

.rts-blog-section3 .blog-area .blog-item .contents-wrapper {
  -webkit-transition: opacity 0.2s ease-in-out, all 0.3s ease-in-out;
  transition: opacity 0.2s ease-in-out, all 0.3s ease-in-out;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
  z-index: 1;
  overflow: hidden;
}

.rts-blog-section3 .blog-area .blog-item .contents-wrapper .contents {
  padding-top: 25px;
}

.rts-blog-section3 .blog-area .blog-item .contents-wrapper .contents .heading {
  margin-bottom: 10px;
}

.rts-blog-section3 .blog-area .blog-item .contents-wrapper .contents .heading .tag {
  background: var(--theme-color);
  font-size: 12px !important;
  color: #fff;
  padding: 0 5px;
}

.rts-blog-section3 .blog-area .blog-item .contents-wrapper .contents .heading .blog-date {
  font-size: 12px;
  color: #fff;
  font-weight: 400;
  margin-left: 20px;
  font-family: "Roboto";
}

.rts-blog-section3 .blog-area .blog-item .contents-wrapper .contents .gallery-title {
  margin-bottom: 15px;
}

.rts-blog-section3 .blog-area .blog-item .contents-wrapper .contents .gallery-title a {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  display: inline;
  background-size: 200% 2px;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, var(--theme-color)), color-stop(50%, transparent));
  background-image: linear-gradient(to right, var(--theme-color) 50%, transparent 50%);
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

.rts-blog-section3 .blog-area .blog-item .contents-wrapper .contents .gallery-title a:hover {
  color: var(--theme-color);
  background-position: 0% 100%;
}

.rts-blog-section3 .blog-area .blog-item .contents-wrapper .contents .author-info .content .read-more {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  padding-bottom: 5px;
}

.rts-blog-section3 .blog-area .blog-item .contents-wrapper .contents .author-info .content .read-more::before {
  position: absolute;
  content: "";
  width: 50%;
  height: 1px;
  background: #fff;
  left: 0;
  bottom: 0;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.rts-blog-section3 .blog-area .blog-item .contents-wrapper .contents .author-info .content .read-more:hover {
  color: var(--theme-color);
}

.rts-blog-section3 .blog-area .blog-item .contents-wrapper .contents .author-info .content .read-more:hover::before {
  width: 100%;
  background: var(--theme-color);
}

.rts-blog-section3.section4 {
  background: none;
  padding-top: 90px;
}

.rts-blog-section3.section4 .section-title-area .section-title {
  margin-top: 0;
  color: #111111;
  font-size: 30px;
  font-weight: 600;
}

.rts-blog-section3.section4 .blog-area .blog-item .blog-picture::before {
  display: none;
}

.rts-blog-section3.section4 .blog-area .blog-item .blog-picture img {
  -webkit-transition: -webkit-transform 400ms;
  transition: -webkit-transform 400ms;
  transition: transform 400ms;
  transition: transform 400ms, -webkit-transform 400ms;
}

.rts-blog-section3.section4 .blog-area .blog-item .contents-wrapper .contents .heading {
  margin-bottom: 10px;
}

.rts-blog-section3.section4 .blog-area .blog-item .contents-wrapper .contents .heading .blog-date {
  font-size: 12px;
  color: #777777;
  font-weight: 400;
  margin-left: 0;
  font-family: "Roboto";
}

.rts-blog-section3.section4 .blog-area .blog-item .contents-wrapper .contents .gallery-title {
  margin-bottom: 10px;
}

.rts-blog-section3.section4 .blog-area .blog-item .contents-wrapper .contents .gallery-title a {
  color: #111111;
}

.rts-blog-section3.section4 .blog-area .blog-item .contents-wrapper .contents .gallery-title a:hover {
  color: var(--theme-color);
  background-position: 0% 100%;
}

.rts-blog-section3.section4 .blog-area .blog-item .contents-wrapper .contents .author-info {
  margin-top: 15px;
}

.rts-blog-section3.section4 .blog-area .blog-item .contents-wrapper .contents .author-info .content .read-more {
  color: #111111;
  font-weight: 600;
}

.rts-blog-section3.section4 .blog-area .blog-item .contents-wrapper .contents .author-info .content .read-more::before {
  background: #111111;
}

.rts-blog-section3.section4 .blog-area .blog-item .contents-wrapper .contents .author-info .content .read-more:hover {
  color: var(--theme-color);
}

.rts-blog-section3.section4 .blog-area .blog-item .contents-wrapper .contents .author-info .content .read-more:hover::before {
  background: var(--theme-color);
}

.rts-match-result-section {
  overflow: hidden;
}

.rts-match-result-section .col-item {
  border-right: 1px solid #F5F5F5;
  border-top: 1px solid #F5F5F5;
  padding-top: 60px;
  padding-bottom: 60px;
}

.rts-match-result-section .play-btn a {
  position: relative;
  top: unset;
  left: unset;
  -webkit-transform: unset;
  transform: unset;
  width: unset;
  height: unset;
  border-radius: unset;
  display: inherit;
  -webkit-box-pack: unset;
  -ms-flex-pack: unset;
  justify-content: unset;
  -webkit-box-align: unset;
  -ms-flex-align: unset;
  align-items: unset;
  background: unset;
  -webkit-transition: all 400ms;
  transition: all 400ms;
  color: #111111 i;
  color-font-size: unset;
}

.rts-match-result-section .play-btn a:hover {
  background: unset;
  color: unset;
}

.rts-match-result-section.inner .match-single .club .club-logo {
  max-width: unset;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.rts-match-result-section.inner .match-single .club .club-logo .club-name {
  font-size: 32px;
  line-height: 1.1;
  font-weight: 600;
  margin: 0 20px;
  color: #FFFFFF !important;
}

@media (max-width: 768px) {
  .rts-match-result-section.inner .match-single .club .club-logo .club-name {
    font-size: 24px;
  }
}

@media (max-width: 500px) {
  .rts-match-result-section.inner .match-single .club .club-logo .club-name {
    font-size: 18px;
  }
}

.rts-match-result-section.inner .colon {
  font-size: 48px;
  font-weight: 600;
}

@media (max-width: 576px) {
  .rts-match-result-section.inner .colon {
    margin-bottom: 15px !important;
    font-size: 36px;
  }
}

@media (max-width: 576px) {
  .rts-match-result-section.inner .match-scores {
    display: block;
  }
}

.rts-match-result-section.inner .match-single.sm .match-single-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  padding: 20px 30px;
}

@media (max-width: 768px) {
  .rts-match-result-section.inner .match-single.sm .match-single-content {
    display: block;
  }
}

.rts-match-result-section.inner .match-single.sm .match-single-content .block-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: unset;
  -webkit-box-direction: unset;
  -ms-flex-direction: unset;
  flex-direction: unset;
}

@media (max-width: 768px) {
  .rts-match-result-section.inner .match-single.sm .match-single-content .block-wrap {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

.rts-match-result-section.inner .match-single.sm .match-single-content .block-wrap .match-date {
  margin: 0 10px 0 0;
}

.rts-match-result-section.inner .match-single.sm .match-single-content .block-wrap .prefix {
  color: #FFFFFF;
  font-size: 14px;
  padding-right: 10px;
}

.rts-match-result-section.inner .match-single.sm .match-single-content .block-wrap .stadium-name {
  font-size: 14px;
}

.rts-match-result-section.inner .match-single.sm .club .club-logo .club-name {
  font-size: 16px;
  line-height: 1.1;
}

.rts-match-result-section.inner .match-single.sm .match-scores {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .rts-match-result-section.inner .match-single.sm .match-scores {
    margin-bottom: 10px;
  }
}

.rts-match-result-section.inner .match-single.sm .colon {
  font-size: 20px;
  font-weight: 600;
}

.rts-match-result-section2 {
  background: #111111;
}

.rts-match-result-section2 .section-title-area .section-title {
  margin-top: -15px;
  color: #fff;
}

.rts-match-result-section2 .match-single {
  background: #171717;
  padding: 45px 30px;
}

.rts-match-result-section2 .match-single .colon {
  margin: 0 15px;
}

.rts-match-result-section2 .match-single .score-number {
  color: #fff;
}

.rts-match-result-section2 .match-single .stadium-name {
  font-size: 18px;
  line-height: 30px;
  font-weight: 400;
  color: #fff;
}

.rts-match-result-section2 .match-date {
  margin-bottom: 8px;
  line-height: 1;
}

@media (max-width: 1200px) {
  .rts-match-result-section2 .match-single {
    margin-bottom: 30px;
  }
}

@media (max-width: 900px) {
  .rts-match-result-section2 .match-single .club {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .rts-match-result-section2 .match-single .club .club-logo {
    margin-bottom: 10px;
  }

  .rts-match-result-section2 .match-single .club.club2 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
}

@media (max-width: 600px) {
  .rts-match-result-section2 .match-single .score-number {
    font-size: 40px;
  }
}

.rts-about-section {
  background: #111111;
}

.rts-about-section .about-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.rts-about-section .about-inner .about-thumb {
  margin-right: 0;
}

.rts-about-section .about-inner .contents {
  padding: 120px;
  padding-bottom: 0;
}

.rts-about-section .about-inner .contents .section-title-area {
  margin-bottom: 25px;
}

.rts-about-section .about-inner .contents .section-title-area .pretitle {
  -webkit-text-fill-color: #111111;
  -webkit-text-stroke-color: #858585;
}

.rts-about-section .about-inner .contents .section-title-area .section-title {
  color: #fff;
}

@media (max-width: 1550px) {
  .rts-about-section .about-inner .contents .section-title-area .pretitle {
    font-size: 100px;
  }
}

.rts-about-section .about-inner .contents .quote-box {
  padding: 30px;
  border-radius: 6px;
  background: #1c1c1c;
  max-width: 550px;
  margin-bottom: 25px;
}

.rts-about-section .about-inner .contents .quote-box p {
  margin-bottom: 10px;
}

.rts-about-section .about-inner .contents .quote-box .quote-author {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
}

.rts-about-section .about-inner .contents p {
  max-width: 550px;
  margin-bottom: 40px;
}

.rts-about-section .about-inner .contents .more-btn {
  padding: 15px 30px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background: #b14b4d;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  text-transform: uppercase;
  position: relative;
  -webkit-transition-property: color;
  border: none;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  letter-spacing: 2px;
  transition-duration: 0.3s;
}

.rts-about-section .about-inner .contents .more-btn:hover {
  box-shadow: 0px 0px 12px 2px rgb(177 75 77);
}

.more-btn:hover {
  background: #b14b4d !important;
  box-shadow: 0px 0px 12px 2px rgb(177 75 77);
}

.rts-about-section .about-inner .contents .more-btn::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  background: var(--theme-color);
}

.rts-about-section .about-inner .contents .more-btn:hover {
  color: #fff;
}

.rts-about-section .about-inner .contents .more-btn:hover::before {
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}

@media (max-width: 1550px) {
  .rts-about-section .about-inner .contents {
    padding: 120px 60px;
    padding-bottom: 60px;
  }
}

@media (max-width: 1200px) {
  .rts-about-section .about-inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .rts-about-section .about-inner .about-thumb {
    width: 100%;
  }

  .rts-about-section .about-inner .about-thumb img {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .rts-about-section .about-inner .contents {
    padding: 120px 25px;
  }
}

.rts-shop-section {
  padding-bottom: 60px;
}

@media (max-width: 768px) {
  .rts-shop-section {
    padding-bottom: 0;
  }
}

.rts-shop-section .top-wrapper {
  margin-bottom: 0;
}

.rts-shop-section .product-item {
  -webkit-filter: unset;
  filter: unset;
}

.rts-shop-section .product-item:hover .social-area {
  opacity: 1;
  bottom: 130px;
}

.rts-shop-section .product-item .product-thumb {
  height: 350px;
  padding: 0;
  padding: 0 40px;
}

.rts-shop-section .product-item .product-thumb img {
  width: unset;
}

.rts-shop-section .product-item .social-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #111111;
  width: 220px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 12px 0;
  position: absolute;
  opacity: 0;
  bottom: 110px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.rts-shop-section .product-item .social-area li a {
  color: #fff;
  margin: 0 10px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.rts-shop-section .product-item .social-area li a:hover {
  color: var(--theme-color);
}

@media (max-width: 768px) {
  .rts-shop-section .product-item .product-thumb {
    margin-bottom: 20px;
  }

  .rts-shop-section .product-item .contents .product-name {
    font-size: 18px;
  }
}

@media (max-width: 500px) {
  .rts-shop-section .product-item .product-thumb {
    height: 270px;
  }
}

.rts-sponsors-section {
  padding-top: 110px;
}

.rts-sponsors-section .section-title-area {
  position: relative;
}

.rts-sponsors-section .section-title-area .pretitle {
  top: -150px;
  font-size: 220px;
  -webkit-text-fill-color: #f7f7f7;
  -webkit-text-stroke-color: #f7f7f7;
}

@media (max-width: 991px) {
  .rts-sponsors-section .section-title-area .pretitle {
    font-size: 180px;
  }
}

@media (max-width: 768px) {
  .rts-sponsors-section .section-title-area .pretitle {
    top: -50px;
  }
}

.rts-sponsors-section .sponsor-single {
  width: 100%;
  text-align: center;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.rts-sponsors-section .sponsor-single:hover .sponsors-logo {
  opacity: 1;
}

.sponsors-logo img {
  height: 130px;
  width: 206px;
  margin: auto;
}

.rts-sponsors-section .sponsor-single .sponsors-logo {
  opacity: 0.5;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.rts-sponsors-section .sponsor-single .author {
  display: block;
  line-height: 1;
  margin-top: -20px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.rts-sponsors-section .sponsor-single .author .club-name {
  margin-bottom: 10px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.rts-sponsors-section .sponsor-single .author p {
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

@media (max-width: 1200px) {
  .rts-sponsors-section .sponsor-single {
    margin-bottom: 30px;
  }
}

.rts-sponsors-section2 {
  /* background-image: url("../public/images/background/megabg.jpg"); */
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.rts-sponsors-section2 .section-title-area .section-title {
  color: #fff;
}

.rts-sponsors-section2::before {
  content: "";
  mix-blend-mode: multiply;
  background: #e8141b;
  opacity: 90%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: all 1s;
  transition: all 1s;
}

.rts-sponsors-section2 .sponsor-single {
  margin-bottom: 50px;
  opacity: 100%;
  position: relative;
}

.rts-sponsors-section2 .row.last-child {
  margin-bottom: -50px;
}

.rts-blog-section2 {
  background: #111111;
}

.rts-blog-section2 .section-title-area.section-title-area2 .section-title {
  margin-top: -8px;
  color: #fff;
  font-size: 30px;
  font-weight: 600;
}

.rts-blog-section2 .rts-post-area .rts-post-wide {
  padding: 31px 20px;
}

.rts-blog-section2 .col-xl-6 {
  padding: 0 10px;
}

.rts-blog-section2 .gallery-title {
  margin-bottom: 15px;
}

.rts-blog-section2 .gallery-title a {
  color: #fff;
  display: inline;
  font-size: 20px;
  font-weight: 600;
  background-size: 200% 2px;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, var(--theme-color)), color-stop(50%, transparent));
  background-image: linear-gradient(to right, var(--theme-color) 50%, transparent 50%);
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

.rts-blog-section2 .gallery-title a:hover {
  color: var(--theme-color);
  background-position: 0% 100%;
}

.rts-blog-section2 .video-post-area {
  margin-bottom: 100px;
  position: relative;
}

.rts-blog-section2 .video-post-area .slider-navigation {
  position: absolute;
  padding: 0 35px;
  z-index: 1;
}

@media (max-width: 1200px) {
  .rts-blog-section2 .video-post-area {
    margin-bottom: 50px;
  }
}

@media (max-width: 768px) {
  .rts-blog-section2 .video-post-area {
    margin-bottom: 30px;
  }
}

.rts-blog-section2 .top-news-area .area-title {
  font-size: 60px;
  letter-spacing: -2px;
  color: #111111;
  font-weight: 600;
  margin-bottom: 50px;
}

.rts-blog-section2 .top-news-area .rts-blog-medium {
  margin-bottom: 20px;
}

@media (max-width: 1200px) {
  .rts-blog-section2 .top-news-area .area-title {
    margin-bottom: 30px;
    font-size: 40px;
  }

  .rts-blog-section2 .top-news-area .load-more-btn {
    margin-top: 20px;
    margin-bottom: 30px;
  }
}

@media (max-width: 768px) {
  .rts-blog-section2 .top-news-area .area-title {
    font-size: 30px;
    margin-bottom: 20px;
  }
}

.rts-blog-section2 .side-content .side-item {
  position: relative;
  border-radius: 6px;
  margin-bottom: 25px;
  overflow: hidden;
  background: #fff;
}

.rts-blog-section2 .side-content .side-item-inner {
  padding: 35px;
}

.rts-blog-section2 .side-content .side-table-item:before {
  content: "";
  position: absolute;
  top: 98px;
  left: 0;
  width: 100%;
  height: 50px;
  background: #F5F5F5;
}

@media (max-width: 440px) {
  .rts-blog-section2 .side-content .side-table-item:before {
    top: 80px;
  }
}

.rts-blog-section2 .side-content .side-content-title {
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  margin-bottom: 35px;
  padding-left: 0;
  position: relative;
}

.rts-blog-section2 .side-content .full-table-btn {
  font-size: 16px;
  letter-spacing: 2px;
  color: #fff;
  font-weight: 600;
  background: var(--theme-color);
  border: 1px solid transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 60px;
  border-radius: 6px;
}

.rts-blog-section2 .side-content .full-table-btn:hover {
  color: var(--theme-color);
  background: transparent;
  border: 1px solid var(--theme-color);
}

.rts-blog-section2 .side-content .premier-league-area {
  border: 2px solid #f6f6f6;
  background: #fff;
  position: relative;
  border-radius: 0;
  margin-bottom: 30px;
  overflow: hidden;
  z-index: 1;
}

.rts-blog-section2 .side-content .premier-league-area::before {
  content: "";
  position: absolute;
  top: 80px;
  left: 0;
  width: 100%;
  height: 45px;
  background: #F5F5F5;
  z-index: -1;
}

.rts-blog-section2 .side-content .premier-league-area .side-item-inner {
  padding: 30px;
}

.rts-blog-section2 .side-content .premier-league-area .side-item-inner .side-content-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  padding-left: 50px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.rts-blog-section2 .side-content .premier-league-area .side-item-inner .side-content-title::before {
  content: "";
  position: absolute;
  width: 25px;
  background-color: var(--theme-color);
  height: 3px;
  left: 0px;
  top: 50%;
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.rts-blog-section2 .side-content .premier-league-area .side-item-inner .side-content-title::after {
  content: "";
  position: absolute;
  background-color: var(--theme-color);
  width: 12px;
  height: 3px;
  left: 30px;
  top: 50%;
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.rts-blog-section2 .side-content .premier-league-area .side-item-inner .table thead .head-tr {
  background: none;
}

.rts-blog-section2 .side-content .premier-league-area .side-item-inner .table thead .head-tr th {
  text-align: center;
}

.rts-blog-section2 .side-content .premier-league-area .side-item-inner .table tbody tr:first-child {
  border-style: solid;
}

.rts-blog-section2 .side-content .premier-league-area .side-item-inner .table tbody tr:last-child {
  border-style: solid !important;
}

.rts-blog-section2 .side-content .premier-league-area .side-item-inner .table tbody tr td {
  padding: 10px 0 !important;
  text-align: center;
}

.rts-blog-section2 .side-content .premier-league-area .side-item-inner .table tbody tr td .player-name-area .player-name {
  font-size: 14px;
  font-weight: 500;
}

.rts-blog-section2 .side-content .premier-league-area .side-item-inner .table tbody tr td .position-number {
  font-size: 14px;
  color: #999999;
  font-weight: 400;
}

.rts-blog-section2 .side-content .premier-league-area .side-item-inner .table-bordered> :not(caption)>* {
  border-top-width: 1px;
}

.rts-blog-section2 .side-content .premier-league-area .side-item-inner .more-btn {
  font-size: 14px;
  width: 100%;
  height: 45px;
  line-height: 45px;
  border: none;
  padding: 0;
  border-radius: 0;
  font-weight: 500;
  color: #fff;
  background: #111111;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  text-transform: uppercase;
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  letter-spacing: 2px;
}

@media (max-width: 1100px) and (min-width: 900px) {
  .rts-blog-section2 .side-content .premier-league-area .side-item-inner .more-btn {
    display: block;
  }
}

@media (max-width: 450px) {
  .rts-blog-section2 .side-content .premier-league-area .side-item-inner .more-btn {
    display: block;
    margin: 0 auto;
  }
}

.rts-blog-section2 .side-content .premier-league-area .side-item-inner .more-btn::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  background: var(--theme-color);
}

.rts-blog-section2 .side-content .premier-league-area .side-item-inner .more-btn:hover {
  color: #fff;
}

.rts-blog-section2 .side-content .premier-league-area .side-item-inner .more-btn:hover::before {
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}

.rts-blog-section2 .side-content thead {
  position: relative;
}

.rts-blog-section2 .side-content table td span,
.rts-blog-section2 .side-content table td h4,
.rts-blog-section2 .side-content table th span,
.rts-blog-section2 .side-content table th h4 {
  font-size: 14px;
  padding-left: 0 !important;
}

.rts-blog-section2 .side-content table td,
.rts-blog-section2 .side-content table th {
  padding: 14px 0 !important;
}

.rts-blog-section2 .side-content table tr:first-child {
  border-style: inherit !important;
}

.rts-blog-section2 .side-content table tr:last-child {
  border-style: none !important;
}

.rts-blog-section2 .side-content table tr {
  background: none;
}

.rts-blog-section2 .side-content .next-match-area .side-content-title {
  margin-left: 0;
  padding: 0 0 0 50px;
  margin-bottom: 25px;
  position: relative;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.rts-blog-section2 .side-content .next-match-area .side-content-title::before {
  content: "";
  position: absolute;
  width: 25px;
  background-color: var(--theme-color);
  height: 3px;
  left: 0px;
  top: 50%;
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  z-index: 1;
}

.rts-blog-section2 .side-content .next-match-area .side-content-title::after {
  content: "";
  position: absolute;
  background-color: var(--theme-color);
  width: 12px;
  height: 3px;
  left: 30px;
  top: 50%;
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.rts-blog-section2 .side-content .next-match-area .side-item-inner {
  padding-bottom: 0;
}

.rts-blog-section2 .side-content .next-match-area .match-single {
  width: 100%;
  background: none;
  border-radius: 6px;
  border-top: 1px solid #e7e7e7;
  padding: 25px 0 0 0;
  margin-bottom: 20px;
}

.rts-blog-section2 .side-content .next-match-area .match-single .side-content-title {
  padding-bottom: 15px;
}

.rts-blog-section2 .side-content .next-match-area .match-single .match-scores {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
}

.rts-blog-section2 .side-content .next-match-area .match-single .club {
  text-align: center;
}

.rts-blog-section2 .side-content .next-match-area .match-single .club .club-logo {
  margin-bottom: 0;
}

.rts-blog-section2 .side-content .next-match-area .match-single .club .club-name {
  margin-top: 5px;
  font-size: 14px;
  color: #111111;
  font-weight: 400;
}

.rts-blog-section2 .side-content .next-match-area .match-single .block {
  margin-top: 12px;
  text-align: center;
  max-width: 135px;
}

.rts-blog-section2 .side-content .next-match-area .match-single .block .match-date {
  margin-bottom: 10px;
}

.rts-blog-section2 .side-content .next-match-area .match-single .block .stadium-name {
  color: #111111;
  font-weight: 500;
}

.rts-blog-section2 .side-content .next-match-area .match-single .colon {
  margin: 0 15px;
}

.rts-blog-section2 .side-content .next-match-area .match-single .score-number {
  color: #fff;
}

.rts-blog-section2 .side-content .next-match-area .match-single .stadium-name {
  font-size: 18px;
  line-height: 30px;
  font-weight: 400;
  color: #fff;
}

.rts-blog-section2 .side-content .next-match-area .countdown-box {
  background: #1d1d1d;
  width: 100%;
  height: 130px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

.rts-blog-section2 .side-content .next-match-area .countdown {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

.rts-blog-section2 .side-content .next-match-area .countdown .countdown-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.rts-blog-section2 .side-content .next-match-area .countdown .colon {
  font-size: 60px;
  letter-spacing: -2px;
  color: #424242;
  font-weight: 300;
  -webkit-transform: translateY(-5px) translateX(-4px);
  transform: translateY(-5px) translateX(-4px);
}

.rts-blog-section2 .side-content .next-match-area .countdown .countdown-el {
  background: none;
  width: 80px;
  height: auto;
  padding: 4px;
  border-radius: 5px;
  margin-right: 5px;
  padding-left: 9px;
}

.rts-blog-section2 .side-content .next-match-area .countdown .countdown-el .value {
  margin-top: 9px;
  margin-bottom: 1px;
  font-size: 60px;
  color: #fff;
  font-weight: 700;
  line-height: 0;
}

.rts-blog-section2 .side-content .next-match-area .countdown .letter {
  font-size: 14px;
  font-weight: 600;
  color: #999999;
  line-height: 0;
  -webkit-transform: translateY(10px) translateX(-5px);
  transform: translateY(10px) translateX(-5px);
}

@media (max-width: 768px) {
  .rts-blog-section2 .side-content .next-match-area .countdown {
    margin-bottom: 15px;
  }

  .rts-blog-section2 .side-content .next-match-area .countdown .countdown-el {
    width: 61px;
  }

  .rts-blog-section2 .side-content .next-match-area .countdown .countdown-el .value {
    font-size: 40px;
  }

  .rts-blog-section2 .side-content .next-match-area .countdown .letter {
    font-size: 13px;
    font-weight: 400;
  }

  .rts-blog-section2 .side-content .next-match-area .countdown .colon {
    font-size: 45px;
    -webkit-transform: translateY(-5px) translateX(-3px);
    transform: translateY(-5px) translateX(-3px);
  }
}

@media (max-width: 440px) {
  .rts-blog-section2 .side-content .next-match-area .countdown .countdown-el {
    width: 50px;
  }

  .rts-blog-section2 .side-content .next-match-area .countdown .countdown-el .value {
    font-size: 35px;
  }

  .rts-blog-section2 .side-content .next-match-area .match-single .match-scores {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .rts-blog-section2 .side-content .next-match-area .match-single .match-scores .club .club-logo {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .rts-blog-section2 .side-content .next-match-area .match-single .match-scores .club.club-top .club-logo {
    margin-bottom: 0px !important;
  }

  .rts-blog-section2 .side-content .next-match-area .match-single .match-scores .club.club-bottom .club-logo {
    margin-top: 10px !important;
  }

  .rts-blog-section2 .side-content .next-match-area .match-single .match-scores .club .club-logo {
    margin-right: 0 !important;
  }

  .rts-blog-section2 .side-content .next-match-area .match-single .match-scores .block .match-date {
    font-size: 12px;
    margin-bottom: 5px;
  }

  .rts-blog-section2 .side-content .next-match-area .match-single .match-scores .block .stadium-name {
    font-size: 16px;
  }
}

.rts-blog-section2 .side-content .goal-status-area .side-content-title {
  margin-left: 0;
  padding: 0 0 0 90px;
  margin-bottom: 25px;
  position: relative;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.rts-blog-section2 .side-content .goal-status-area .side-content-title::before {
  content: "";
  position: absolute;
  width: 25px;
  background-color: var(--theme-color);
  height: 3px;
  left: 35px;
  top: 50%;
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  z-index: 1;
}

.rts-blog-section2 .side-content .goal-status-area .side-content-title::after {
  content: "";
  position: absolute;
  background-color: var(--theme-color);
  width: 12px;
  height: 3px;
  right: 81%;
  top: 50%;
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.rts-blog-section2 .side-content .goal-status-area::before {
  display: none;
}

.rts-blog-section2 .side-content .goal-status-area .side-item-inner {
  padding: 35px 0;
}

.rts-blog-section2 .side-content .goal-status-area .table-flow {
  width: 100%;
  padding: 0 35px;
}

.rts-blog-section2 .side-content .goal-status-area .table-flow .table-flow-inner {
  max-height: 300px;
  overflow-y: scroll;
  padding-right: 20px;
}

.rts-blog-section2 .side-content .goal-status-area .table-flow .table-flow-inner::-webkit-scrollbar {
  width: 4px;
  -webkit-transform: translateX(-35px);
  transform: translateX(-35px);
}

.rts-blog-section2 .side-content .goal-status-area .table-flow .table-flow-inner::-webkit-scrollbar-track {
  background-color: #ebebeb;
  border-radius: 2px;
}

.rts-blog-section2 .side-content .goal-status-area .table-flow .table-flow-inner::-webkit-scrollbar-thumb {
  -webkit-border-radius: 4px;
  width: 4px;
  height: 50px;
  border-radius: 2px;
  background-color: var(--theme-color);
}

.rts-blog-section2 .side-content .goal-status-area .custom-thead ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #F5F5F5;
  padding: 15px 35px;
  padding-right: 57px;
}

.rts-blog-section2 .side-content .goal-status-area .custom-thead ul li {
  font-size: 14px;
  font-weight: 600;
  color: #111111;
}

.rts-blog-section2 .side-content .goal-status-area .custom-thead ul li.gl {
  margin-left: auto;
  margin-right: 30px;
}

.rts-blog-section2 .side-content .goal-status-area .custom-thead ul li .as {
  margin-left: auto;
}

.rts-blog-section2 .side-content .goal-status-area table tbody tr td .goal-count {
  padding-right: 11px;
}

.rts-blog-section2 .side-content .goal-status-area table tbody tr td .assist-count {
  margin-right: 10px;
}

@media (max-width: 1200px) {
  .rts-blog-section2 .side-content .goal-status-area .custom-thead .head-tr li.gl {
    width: 18%;
  }

  .rts-blog-section2 .side-content .goal-status-area .custom-thead .head-tr li.as {
    width: 15%;
  }
}

@media (max-width: 768px) {
  .rts-blog-section2 .side-content .goal-status-area .side-content-title {
    padding-left: 20px;
  }

  .rts-blog-section2 .side-content .goal-status-area .table-flow {
    padding: 0 20px;
  }

  .rts-blog-section2 .side-content .goal-status-area .custom-thead .head-tr {
    padding: 15px 20px;
    padding-right: 35px;
  }
}

.rts-blog-section2 .side-content .subscription-area .area-inner {
  position: relative;
}

.rts-blog-section2 .side-content .subscription-area .area-inner .img img {
  width: 100%;
}

.rts-blog-section2 .side-content .subscription-area .area-inner .logo {
  position: absolute;
  top: 40px;
  left: 40px;
}

.rts-blog-section2 .side-content .subscription-area .area-inner .content {
  position: absolute;
  bottom: 50px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 80%;
}

.rts-blog-section2 .side-content .subscription-area .area-inner .content .title {
  color: #fff;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 30px;
}

.rts-blog-section2 .side-content .subscription-area .area-inner .content .button {
  font-size: 14px;
  width: 100%;
  height: 45px;
  line-height: 45px;
  border: none;
  padding: 0;
  border-radius: 0;
  font-weight: 500;
  background: #fff;
  color: #fff;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  text-transform: uppercase;
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  letter-spacing: 2px;
}

@media (max-width: 1100px) and (min-width: 900px) {
  .rts-blog-section2 .side-content .subscription-area .area-inner .content .button {
    display: block;
  }
}

@media (max-width: 450px) {
  .rts-blog-section2 .side-content .subscription-area .area-inner .content .button {
    display: block;
    margin: 0 auto;
  }
}

.rts-blog-section2 .side-content .subscription-area .area-inner .content .button::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  background: #1c4cae;
  color: #fff;
}

.rts-blog-section2 .side-content .subscription-area .area-inner .content .button:hover {
  color: #1c4cae;
}

.rts-blog-section2 .side-content .subscription-area .area-inner .content .button:hover::before {
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}

.rts-blog-section2 .side-content .instagram-feeds-area .instagram-inner {
  margin: 0 -5px;
}

.rts-blog-section2 .side-content .instagram-feeds-area .side-content-title {
  padding-bottom: 15px;
}

.rts-blog-section2 .side-content .instagram-feeds-area .row {
  width: 100%;
  margin: 0 auto;
}

.rts-blog-section2 .side-content .instagram-feeds-area .col-xl-4 {
  padding: 0 5px;
}

.rts-blog-section2 .side-content .instagram-feeds-area .image-item {
  width: 100%;
  margin-bottom: 10px;
  margin-right: 10px;
  position: relative;
}

.rts-blog-section2 .side-content .instagram-feeds-area .image-item img {
  width: 100%;
}

.rts-blog-section2 .side-content .instagram-feeds-area .image-item i {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
  z-index: 1;
  color: #fff;
  -webkit-transition: all 400ms;
  transition: all 400ms;
}

.rts-blog-section2 .side-content .instagram-feeds-area .image-item:hover::before {
  opacity: 70%;
}

.rts-blog-section2 .side-content .instagram-feeds-area .image-item:hover i {
  opacity: 1;
}

.rts-blog-section2 .side-content .instagram-feeds-area .image-item::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #111111;
  opacity: 0;
  -webkit-transition: opacity 400ms;
  transition: opacity 400ms;
  border-radius: 7px;
}

.rts-blog-section2 .side-content .newsletter-area .side-content-title {
  border-bottom: 1px solid #efefef;
  padding-bottom: 15px;
}

.rts-blog-section2 .side-content .newsletter-area input,
.rts-blog-section2 .side-content .newsletter-area button {
  width: 100%;
  padding: 0 25px;
  border-radius: 6px;
  height: 60px;
}

.rts-blog-section2 .side-content .newsletter-area input {
  background: #f7f7f7;
  margin-bottom: 10px;
}

.rts-blog-section2 .side-content .newsletter-area input:focus {
  background: #fff;
  -webkit-box-shadow: inset 0 0px 0px 1px var(--theme-color);
  box-shadow: inset 0 0px 0px 1px var(--theme-color);
}

.rts-blog-section2 .side-content .newsletter-area input:-moz-placeholder-shown {
  font-size: 16px;
  letter-spacing: 2px;
  font-weight: 600;
  text-align: center;
  color: #b6b6b6;
}

.rts-blog-section2 .side-content .newsletter-area input:-ms-input-placeholder {
  font-size: 16px;
  letter-spacing: 2px;
  font-weight: 600;
  text-align: center;
  color: #b6b6b6;
}

.rts-blog-section2 .side-content .newsletter-area input:placeholder-shown {
  font-size: 16px;
  letter-spacing: 2px;
  font-weight: 600;
  text-align: center;
  color: #b6b6b6;
}

.rts-blog-section2 .side-content .newsletter-area .subscribe-btn {
  background: var(--theme-color);
  color: #fff;
  font-size: 16px;
  letter-spacing: 2px;
  font-weight: 600;
  text-align: center;
}

.rts-blog-section2 .side-content .newsletter-area .subscribe-btn:hover {
  background: #171717;
}

@media (max-width: 768px) {
  .rts-blog-section2 .side-content .side-content-title {
    font-size: 18px;
  }
}

@media (max-width: 440px) {
  .rts-blog-section2 .side-content .side-item-inner {
    padding: 20px;
  }
}

@media (max-width: 1400px) {
  .rts-quick-blog .banner-blog-area {
    right: 0;
    padding: 0 20px;
  }
}

@media (max-width: 991px) {
  .rts-quick-blog .banner-blog-area {
    width: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    right: 50%;
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
  }
}

@media (max-width: 768px) {
  .rts-quick-blog .banner-blog-area .blog-picture {
    max-width: 115px;
  }
}

@media (max-width: 700px) {
  .rts-quick-blog .banner-blog-area .content {
    padding: 0 20px;
  }

  .rts-quick-blog .banner-blog-area .content .blog-title {
    font-size: 16px;
    line-height: 26px;
  }
}

@media (max-width: 550px) {
  .rts-quick-blog .banner-blog-area {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    top: -200px;
  }

  .rts-quick-blog .banner-blog-area .rts-blog-small {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }
}

.rts-top-news-section {
  overflow: hidden;
  margin-bottom: -15px;
}

.rts-top-news-section .top-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 50px;
}

.rts-top-news-section .section-title-area {
  margin-bottom: 0;
}

.rts-top-news-section .section-title-area .section-title {
  margin-bottom: 0;
}

.rts-top-news-section .filter-button-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 16px;
  line-height: 24px;
  color: #b9b9b9;
  font-weight: 600;
}

.rts-top-news-section .filter-button-group .filter-btn {
  margin-right: 40px;
  -webkit-transition: all 400ms;
  transition: all 400ms;
  cursor: pointer;
}

.rts-top-news-section .filter-button-group .filter-btn:last-child {
  margin-right: 0;
}

.rts-top-news-section .filter-button-group .filter-btn:hover {
  color: var(--theme-color);
  text-decoration: underline;
}

.rts-top-news-section .filter-button-group .filter-btn.active {
  color: var(--theme-color);
  text-decoration: underline;
}

@media (max-width: 991px) {
  .rts-top-news-section .filter-button-group .filter-btn {
    margin-right: 25px;
    font-size: 15px;
  }
}

@media (max-width: 800px) {
  .rts-top-news-section .top-wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 20px;
  }

  .rts-top-news-section .top-wrap .section-title-area {
    margin-bottom: 20px;
  }

  .rts-top-news-section .top-wrap .filter-button-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .rts-top-news-section .top-wrap .filter-button-group .filter-btn {
    margin-bottom: 10px;
  }
}

@media (max-width: 768px) {
  .rts-top-news-section .rts-news-card .contents {
    padding: 20px;
  }

  .rts-top-news-section .rts-news-card .contents .post-title {
    margin-bottom: 10px;
  }

  .rts-top-news-section .rts-news-card .contents .read-more-btn {
    font-size: 14px;
  }
}

@media (max-width: 410px) {
  .rts-top-news-section .rts-news-card .contents .post-title {
    font-size: 20px;
    line-height: 30px;
  }
}

@media (max-width: 600px) {
  .rts-top-news-section .top-wrap .filter-button-group {
    margin-right: -25px;
  }

  .rts-top-news-section .top-wrap .filter-button-group .filter-btn {
    margin-bottom: 10px;
  }
}

.rts-live-matches-section {
  background: #111111;
}

.rts-live-matches-section .top-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 50px;
}

.rts-live-matches-section .section-title-area {
  margin-bottom: 0;
}

.rts-live-matches-section .section-title-area .section-title {
  color: #fff;
  margin-bottom: 0;
}

.rts-live-matches-section .flex-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.rts-live-matches-section .filter-button-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 16px;
  line-height: 24px;
  color: #b9b9b9;
  font-weight: 600;
}

.rts-live-matches-section .filter-button-group .filter-btn {
  margin-right: 40px;
  -webkit-transition: all 400ms;
  transition: all 400ms;
  cursor: pointer;
}

.rts-live-matches-section .filter-button-group .filter-btn:last-child {
  margin-right: 0 !important;
}

.rts-live-matches-section .filter-button-group .filter-btn:hover,
.rts-live-matches-section .filter-button-group .filter-btn.active {
  color: #fff;
  text-decoration: underline;
}

.rts-live-matches-section .slider-navigation {
  position: relative;
  width: unset;
  bottom: unset;
  right: unset;
}

.rts-live-matches-section .slider-navigation .slide-btn {
  width: 60px;
  height: 60px;
  border-radius: 6px;
  background-color: #111111;
  border: 2px solid #252525;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
}

.rts-live-matches-section .slider-navigation .slide-btn.slide-prev {
  margin-right: 10px;
}

.rts-live-matches-section .slider-navigation .slide-btn:hover {
  background: var(--theme-color);
}

.rts-live-matches-section .video-card {
  position: relative;
}

.rts-live-matches-section .video-card .video-thumb {
  border-radius: 8px;
  overflow: hidden;
}

.rts-live-matches-section .video-card .video-thumb img {
  width: 100%;
}

.rts-live-matches-section .video-card .play-btn a {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #fff;
  -webkit-transition: all 400ms;
  transition: all 400ms;
  color: #111111 i;
  color-font-size: 18px;
}

.rts-live-matches-section .video-card .play-btn a:hover {
  background: var(--theme-color);
  color: #fff;
}

@media (max-width: 850px) {
  .rts-live-matches-section .top-wrap .filter-button-group .filter-btn {
    margin-right: 25px;
    font-size: 14px;
  }

  .rts-live-matches-section .top-wrap .slider-navigation .slide-btn {
    width: 45px;
    height: 45px;
  }
}

@media (max-width: 768px) {
  .rts-live-matches-section .top-wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 30px;
  }

  .rts-live-matches-section .top-wrap .section-title-area {
    margin-bottom: 10px;
  }

  .rts-live-matches-section .top-wrap .filter-button-group {
    margin-bottom: 15px;
  }
}

@media (max-width: 768px) {
  .rts-live-matches-section .top-wrap .filter-button-group .filter-btn {
    margin-right: 18px;
  }

  .rts-live-matches-section .video-card .play-btn .popup-video {
    width: 60px;
    height: 60px;
  }
}

.rts-awards-section .section-title-area {
  position: relative;
}

.rts-awards-section .section-title-area .pretitle {
  top: -150px;
  font-size: 220px;
  -webkit-text-fill-color: #f7f7f7;
  -webkit-text-stroke-color: #f7f7f7;
}

.rts-awards-section .section-title-area .section-title {
  margin-bottom: 15px;
}

.rts-awards-section .award-box {
  width: 99%;
  min-height: 350px;
  -webkit-box-shadow: 0px 0px 0px 1px #f0f0f0;
  box-shadow: 0px 0px 0px 1px #f0f0f0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.rts-awards-section .award-box:hover .award-picture {
  -webkit-transform: scale(110%);
  transform: scale(110%);
}

.rts-awards-section .award-box:hover h3 {
  z-index: 0;
  opacity: 01;
  color: rgb(59 156 214 / 75%);
  -webkit-text-stroke: 1px #01B241;


}

.award-picture img {
  height: 200px;
}

.rts-awards-section .award-box .award-picture {
  margin-bottom: 30px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.rts-awards-section .award-box .content {
  text-align: center;
}

.award-box {
  position: relative;
}

.award-box h3 {
  font-size: 76px;
  position: absolute;
  opacity: 0.2;
  top: -60px;
  left: 0px;
  color: transparent;
  -webkit-text-stroke: 1px black;
  -webkit-text-stroke-color: black;
  transition: all 0.2s ease-in-out;
}

.rts-awards-section .award-box .award-title {
  font-size: 20px;
  letter-spacing: 0px;
  line-height: 26px;
  color: #111111;
  font-weight: 600;
  margin-bottom: 10px;
}

.video-section-inner .section-title-area .section-title {
  color: #Fff;
}

.video-section-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.video-section-inner .section-title-area p {
  color: #ffffffc4;
}

.video-section-inner .section-title-area {
  margin-bottom: 0;
}

.rts-awards-section .award-box p {
  font-size: 14px;
  letter-spacing: 1px;
  color: #777777;
  font-weight: 300;
}

@media (max-width: 1200px) {
  .rts-awards-section .award-box {
    margin-bottom: 30px;
  }
}

.rts-events-section .event-single {
  margin-bottom: 30px;
  overflow: hidden;
}

.rts-events-section .event-single:hover .event-picture img {
  -webkit-transform: scale(110%);
  transform: scale(110%);
}

.rts-events-section .event-single .event-picture {
  position: relative;
  display: block;
  width: 100%;
  overflow: hidden;
}

.rts-events-section .event-single .event-picture img {
  width: 100%;
}

.rts-events-section .event-single .event-date-floating {
  position: absolute;
  width: 100px;
  height: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding-left: 20px;
  bottom: 0;
  left: 0;
  z-index: 1;
  text-align: center;
  overflow: hidden;
}

.rts-events-section .event-single .event-date-floating::before {
  background-color: #111111;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.rts-events-section .event-single .event-date-floating span {
  z-index: 1;
  position: relative;
}

.rts-events-section .event-single .event-date-floating .day {
  font-size: 30px;
  line-height: 44px;
  color: #fff;
  font-weight: 600;
}

.rts-events-section .event-single .event-date-floating .month {
  font-size: 14px;
  color: #fff;
  line-height: 16px;
  font-weight: 700;
  font-family: "Roboto";
}

.rts-events-section .event-single .event-date-floating .year {
  font-size: 14px;
  color: #fff;
  line-height: 16px;
  font-weight: 700;
  font-family: "Roboto";
}

.rts-events-section .event-single .contents {
  padding: 20px 0;
}

.rts-events-section .event-single .contents .event-title {
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.rts-events-section .event-single .contents .event-title:hover {
  color: var(--theme-color);
}

.rts-events-section .event-single .contents p {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: #777777;
}

.rts-events-section .product-pagination-area {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: start;
}

.rts-events-section .product-pagination-area button {
  border-radius: 0;
  font-family: "Roboto";
  font-weight: 600;
}

.rts-gallery-section .top-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 40px;
}

@media (max-width: 550px) {
  .rts-gallery-section .top-wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 25px;
  }

  .rts-gallery-section .top-wrap .filter-button-group {
    margin-bottom: 5px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .rts-gallery-section .top-wrap .filter-button-group .filter-btn {
    margin-bottom: 15px;
  }

  .rts-gallery-section .top-wrap .slider-navigation .slide-btn {
    width: 50px;
    height: 50px;
  }
}

.rts-gallery-section .filter-button-group .filter-btn {
  font-size: 16px;
  line-height: 24px;
  color: #111111;
  font-weight: 600;
  margin-right: 30px;
}

.rts-gallery-section .filter-button-group .filter-btn:last-child {
  margin-right: 0;
}

.rts-gallery-section .filter-button-group .filter-btn:hover {
  color: var(--theme-color);
  text-decoration: underline;
}

.rts-gallery-section .filter-button-group .filter-btn.active {
  color: var(--theme-color);
  text-decoration: underline;
}

.rts-gallery-section .gallery-item {
  margin-bottom: 30px;
}

.rts-gallery-section .gallery-item .gallery-image {
  position: relative;
  width: 100%;
  border-radius: 6px;
  overflow: hidden;
  cursor: pointer;
}

.rts-gallery-section .gallery-item .gallery-image:hover::before {
  opacity: 90%;
}

.rts-gallery-section .gallery-item .gallery-image:hover .pop-btn {
  -webkit-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
}

.rts-gallery-section .gallery-item .gallery-image:hover img {
  -webkit-transform: scale(110%);
  transform: scale(110%);
}

.rts-gallery-section .gallery-item .gallery-image::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--theme-color);
  mix-blend-mode: darken;
  opacity: 0;
  -webkit-transition: opacity 400ms;
  transition: opacity 400ms;
  z-index: 2;
}

.rts-gallery-section .gallery-item .gallery-image img {
  width: 100%;
}

.rts-gallery-section .gallery-item .gallery-image .pop-btn {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #fff;
  color: #111111;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) scale(0);
  transform: translate(-50%, -50%) scale(0);
  z-index: 2;
  -webkit-transition: all 300ms;
  transition: all 300ms;
}

.rts-gallery-section .gallery-item .gallery-image .pop-btn:hover {
  width: 70px;
  height: 70px;
}

.rts-history-section .history-inner {
  position: relative;
  margin-top: 100px;
  margin-bottom: 100px;
}

@media (max-width: 1200px) {
  .rts-history-section .history-inner {
    margin: 0 !important;
  }
}

.rts-history-section .timeline {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: #ededed;
  height: 120%;
  width: 1px;
}

.rts-history-section .timeline li.in-view {
  background: red;
  width: 1px;
  height: 100px;
}

.rts-history-section .timeline-icon {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #111111;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.rts-history-section .timeline-icon.top-icon {
  top: 0;
}

.rts-history-section .timeline-icon.down-icon {
  bottom: 0;
  background: var(--theme-color);
}

.rts-history-section .history-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 40px 0;
  position: relative;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.rts-history-section .history-item.item-left .content {
  text-align: right;
  position: relative;
}

.rts-history-section .history-item.item-left .content::before {
  content: "";
  position: absolute;
  width: 3px;
  height: 100%;
  background: var(--theme-color);
}

.rts-history-section .history-item.item-left.one:hover .content::before {
  opacity: 1;
}

.rts-history-section .history-item.item-left.one:hover::before {
  width: 15px;
  height: 15px;
  background: var(--theme-color);
}

.rts-history-section .history-item.item-left.one:hover::after {
  width: 30px;
  height: 30px;
  border: 1px solid var(--theme-color);
}

.rts-history-section .history-item.item-left.one .content::before {
  right: 0;
  top: 0;
  opacity: 0;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.rts-history-section .history-item.item-left.three:hover .content::before {
  opacity: 1;
}

.rts-history-section .history-item.item-left.three:hover::before {
  width: 15px;
  height: 15px;
  background: var(--theme-color);
}

.rts-history-section .history-item.item-left.three:hover::after {
  width: 30px;
  height: 30px;
  border: 1px solid var(--theme-color);
}

.rts-history-section .history-item.item-left.three .content::before {
  right: 0;
  top: 0;
  opacity: 0;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.rts-history-section .history-item.item-right .content {
  text-align: left;
  position: relative;
}

.rts-history-section .history-item.item-right .content::before {
  content: "";
  position: absolute;
  width: 3px;
  height: 100%;
  background: var(--theme-color);
}

.rts-history-section .history-item.item-right.two:hover .content::before {
  opacity: 1;
}

.rts-history-section .history-item.item-right.two:hover::before {
  width: 15px;
  height: 15px;
  background: var(--theme-color);
}

.rts-history-section .history-item.item-right.two:hover::after {
  width: 30px;
  height: 30px;
  border: 1px solid var(--theme-color);
}

.rts-history-section .history-item.item-right.two .content::before {
  left: 0;
  top: 0;
  opacity: 0;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.rts-history-section .history-item.item-right.four:hover .content::before {
  opacity: 1;
}

.rts-history-section .history-item.item-right.four:hover::before {
  width: 15px;
  height: 15px;
  background: var(--theme-color);
}

.rts-history-section .history-item.item-right.four:hover::after {
  width: 30px;
  height: 30px;
  border: 1px solid var(--theme-color);
}

.rts-history-section .history-item.item-right.four .content::before {
  left: 0;
  top: 0;
  opacity: 0;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.rts-history-section .history-item.in-view::before {
  background: red;
}

.rts-history-section .history-item::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  background: #111111;
  border-radius: 50%;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.rts-history-section .history-item::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 15px;
  height: 15px;
  border: 1px solid #111111;
  border-radius: 50%;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

@media (max-width: 1200px) {
  .rts-history-section .history-item::after {
    display: none;
  }
}

.rts-history-section .history-item .content {
  padding: 30px;
  border: 1px solid #ebebeb;
}

.rts-history-section .history-item .content .history-title {
  font-size: 30px;
  letter-spacing: -1px;
  color: #111111;
  font-weight: 600;
  margin-bottom: 10px;
}

.rts-history-section .history-item .content p {
  max-width: 410px;
  font-size: 18px;
  color: #777777;
  font-weight: 400;
}

.rts-history-section .history-item .history-year {
  font-size: 120px;
  letter-spacing: -4px;
  font-weight: 600;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #e41b22;
  -webkit-text-fill-color: #ffffff;
  width: 473px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.rts-history-section .history-item .history-year.text-start {
  -webkit-transform: translateX(-20px);
  transform: translateX(-20px);
}

@media (max-width: 1200px) {
  .rts-history-section .history-item {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .rts-history-section .history-item::before {
    display: none;
  }

  .rts-history-section .history-item .history-year {
    width: unset;
  }

  .rts-history-section .history-item .history-year.text-start {
    margin-left: 50px;
  }

  .rts-history-section .history-item .history-year.text-end {
    margin-right: 50px;
  }

  .rts-history-section .timeline {
    display: none;
  }
}

@media (max-width: 768px) {
  .rts-history-section .history-item {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 30px;
  }

  .rts-history-section .history-item.item-left {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .rts-history-section .history-item .history-year {
    margin-left: 0;
    margin-right: 0;
    font-size: 100px;
    -webkit-transform: unset !important;
    transform: unset !important;
  }

  .rts-history-section .history-item .history-year.text-start {
    margin-left: 0;
  }

  .rts-history-section .history-item .history-year.text-end {
    margin-right: 0;
  }

  .rts-history-section .history-item .content .history-title {
    font-size: 30px;
  }
}

@media (max-width: 768px) and (max-width: 400px) {
  .rts-history-section .history-item .content {
    padding: 25px;
  }

  .rts-history-section .history-item .content .history-title {
    font-size: 24px;
  }
}

@media (max-width: 768px) {
  .rts-history-section .timeline {
    display: none;
  }
}

.rts-team-details-section {
  padding-bottom: 0;
}

.rts-team-details-section .player-details-single .player-picture .contents p {
  max-width: 677px;
}

@media (max-width: 1200px) {
  .rts-team-details-section {
    padding-bottom: 120px;
  }
}

@media (max-width: 768px) {
  .rts-team-details-section {
    padding-bottom: 60px;
  }
}

.rts-event-details-section {
  margin-bottom: -50px;
}

@media (max-width: 768px) {
  .rts-event-details-section {
    margin-bottom: 0;
  }
}

.rts-event-details-section .col-xl-8 {
  padding-right: 30px;
}

.rts-event-details-section .event-info-part {
  margin-bottom: 50px;
}

.rts-event-details-section .event-info-part .event-info-title {
  font-size: 30px;
  letter-spacing: -1px;
  line-height: 34px;
  color: #111111;
  font-weight: 600;
  margin-bottom: 20px;
}

.rts-event-details-section .event-info-part p {
  font-size: 16px;
  line-height: 26px;
  color: #777777;
  font-weight: 400;
}

.rts-event-details-section .event-info-part .p1 {
  margin-bottom: 30px;
}

.rts-event-details-section .event-info-part .map iframe {
  width: 100%;
  border-radius: 6px;
}

.rts-event-details-section .event-location .event-info-title {
  margin-bottom: 30px;
}

.rts-event-details-section .overview-image {
  position: relative;
  margin-bottom: 50px;
  width: 100%;
}

.rts-event-details-section .overview-image img {
  width: 100%;
}

.rts-event-details-section .overview-image .event-date-floating {
  position: absolute;
  width: 100px;
  height: 120px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding-left: 20px;
  bottom: 0;
  left: 0;
  z-index: 1;
  text-align: center;
  overflow: hidden;
}

.rts-event-details-section .overview-image .event-date-floating::before {
  background-color: #111111;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.rts-event-details-section .overview-image .event-date-floating span {
  z-index: 1;
  position: relative;
}

.rts-event-details-section .overview-image .event-date-floating .day {
  font-size: 30px;
  line-height: 44px;
  color: #fff;
  font-weight: 600;
}

.rts-event-details-section .overview-image .event-date-floating .month {
  font-size: 14px;
  color: #fff;
  line-height: 16px;
  font-weight: 700;
  font-family: "Roboto";
}

.rts-event-details-section .overview-image .event-date-floating .year {
  font-size: 14px;
  color: #fff;
  line-height: 16px;
  font-weight: 700;
  font-family: "Roboto";
}

.rts-event-details-section .facilities-picture-area .picture {
  width: 100%;
}

.rts-event-details-section .facilities-picture-area .picture img {
  width: 100%;
}

@media (max-width: 1200px) {
  .rts-event-details-section .col-xl-8 {
    padding-right: 15px;
  }
}

@media (max-width: 768px) {
  .rts-event-details-section .event-info-part .p1 {
    margin-bottom: 25px;
  }

  .rts-event-details-section .event-overview .overview-image {
    margin-bottom: 25px;
  }

  .rts-event-details-section .event-overview .event-date {
    font-size: 12px;
  }

  .rts-event-details-section .facilities-picture-area .picture {
    margin-bottom: 25px;
  }
}

.event-side-info .info-box {
  padding: 30px;
  border: 1px solid #f0f0f0;
  margin-bottom: 30px;
}

.event-side-info .info-box .info-top {
  padding-bottom: 10px;
  margin-bottom: 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #f0f0f0;
}

.event-side-info .info-box .info-top .info-title {
  font-size: 24px;
  line-height: 40px;
  font-weight: 600;
}

.event-side-info .info-box .info-body .info-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 17px;
}

.event-side-info .info-box .info-body .info-item .icon {
  margin-right: 15px;
  width: 40px;
  height: 40px;
  text-align: center;
  display: block;
  line-height: 35px;
  border-radius: 50%;
  background-color: rgb(255, 255, 255);
  -webkit-box-shadow: 0px 7px 43px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 7px 43px 0px rgba(0, 0, 0, 0.15);
}

.event-side-info .info-box .info-body .info-item .icon i {
  font-size: 14px;
  color: var(--theme-color);
}

.event-side-info .info-box .info-body .info-item span,
.event-side-info .info-box .info-body .info-item a {
  font-size: 14px;
  font-weight: 700;
  font-family: "Roboto";
}

.event-side-info .info-box .info-body .info-item a:hover {
  color: var(--theme-color);
}

.event-side-info .info-box .info-body .info-item .q-span {
  margin-right: 3px;
  font-size: 16px;
  font-weight: 700;
}

.event-side-info .info-box .info-body .info-item .a-span {
  color: #777777;
  font-weight: 400;
  font-size: 16px;
}

.event-side-info .side-action-btn {
  display: block;
  width: 100%;
  padding: 15px 30px;
  font-size: 16px;
  letter-spacing: 2px;
  color: #fff;
  font-weight: 600;
  margin-bottom: 15px;
  text-align: center;
  -webkit-transition: all 400ms;
  transition: all 400ms;
}

.event-side-info .side-action-btn.book-seat-btn {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  background: #111111;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  text-transform: uppercase;
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  letter-spacing: 2px;
}

@media (max-width: 1100px) and (min-width: 900px) {
  .event-side-info .side-action-btn.book-seat-btn {
    display: block;
  }
}

@media (max-width: 450px) {
  .event-side-info .side-action-btn.book-seat-btn {
    display: block;
    margin: 0 auto;
  }
}

.event-side-info .side-action-btn.book-seat-btn::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  background: var(--theme-color);
}

.event-side-info .side-action-btn.book-seat-btn:hover {
  color: #fff;
}

.event-side-info .side-action-btn.book-seat-btn:hover::before {
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}

.event-side-info .side-action-btn.add-cal-btn {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  background: var(--theme-color);
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  text-transform: uppercase;
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  letter-spacing: 2px;
}

@media (max-width: 1100px) and (min-width: 900px) {
  .event-side-info .side-action-btn.add-cal-btn {
    display: block;
  }
}

@media (max-width: 450px) {
  .event-side-info .side-action-btn.add-cal-btn {
    display: block;
    margin: 0 auto;
  }
}

.event-side-info .side-action-btn.add-cal-btn::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  background: #111111;
}

.event-side-info .side-action-btn.add-cal-btn:hover {
  color: #fff;
}

.event-side-info .side-action-btn.add-cal-btn:hover::before {
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}

.rts-match-recap-section {
  margin-bottom: -20px;
}

.rts-match-recap-section .section-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
}

.rts-match-recap-section .section-inner:hover .recap-picture img {
  -webkit-transform: scale(110%);
  transform: scale(110%);
}

.rts-match-recap-section .section-inner .recap-picture {
  overflow: hidden;
  margin-right: 30px;
}

.rts-match-recap-section .section-inner .recap-picture img {
  -webkit-transition: -webkit-transform 1000ms;
  transition: -webkit-transform 1000ms;
  transition: transform 1000ms;
  transition: transform 1000ms, -webkit-transform 1000ms;
}

.rts-match-recap-section .section-inner .contents {
  max-width: 660px;
}

.rts-match-recap-section .section-inner .section-title {
  font-size: 48px;
  letter-spacing: -2px;
  line-height: 34px;
  color: #111111;
  font-weight: 600;
  margin-bottom: 35px;
}

@media (max-width: 500px) {
  .rts-match-recap-section .section-inner .section-title {
    font-size: 26px !important;
  }
}

.rts-match-recap-section .section-inner .section-title span {
  background: var(--theme-color);
  color: #fff;
  position: relative;
  margin: 0 5px;
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  border-radius: 50%;
}

@media (max-width: 500px) {
  .rts-match-recap-section .section-inner .section-title span {
    width: 50px;
    height: 50px;
    line-height: 50px;
  }
}

.rts-match-recap-section .section-inner p {
  font-size: 18px;
  line-height: 28px;
  color: #777777;
  font-weight: 400;
  font-family: "Roboto";
}

.rts-match-recap-section .section-inner .match-result {
  border: 1px solid #e7e7e7;
  margin-bottom: 45px;
}

.rts-match-recap-section .section-inner .match-result .header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background: var(--theme-color);
  padding: 10px 20px;
}

.rts-match-recap-section .section-inner .match-result .header .date {
  color: #fff;
  font-size: 14px;
  font-family: "Roboto";
}

.rts-match-recap-section .section-inner .match-result .header .full-time {
  color: #fff;
  font-size: 14px;
  font-family: "Roboto";
}

.rts-match-recap-section .section-inner .match-result .scoreboard {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 30px;
}

@media (max-width: 500px) {
  .rts-match-recap-section .section-inner .match-result .scoreboard {
    display: block;
  }
}

.rts-match-recap-section .section-inner .match-result .scoreboard .team-logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 500px) {
  .rts-match-recap-section .section-inner .match-result .scoreboard .team-logo {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

.rts-match-recap-section .section-inner .match-result .scoreboard .team-logo span {
  margin: 0 25px;
  border: 1px solid #e7e7e7;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  display: block;
  border-radius: 50%;
}

.rts-match-recap-section .section-inner .match-result .scoreboard .score {
  font-size: 48px;
}

@media (max-width: 500px) {
  .rts-match-recap-section .section-inner .match-result .scoreboard .score {
    text-align: center;
  }
}

.rts-match-recap-section .section-inner .highlight-btn {
  padding: 15px 30px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background: #000000;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  text-transform: uppercase;
  position: relative;
  -webkit-transition-property: color;
  border: none;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  letter-spacing: 2px;
  transition-duration: 0.3s;
}

.rts-match-recap-section .section-inner .highlight-btn::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  background: var(--theme-color);
}

.rts-match-recap-section .section-inner .highlight-btn:hover {
  color: #fff;
}

.rts-match-recap-section .section-inner .highlight-btn:hover::before {
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}

.rts-match-recap-section .section-inner .p1 {
  margin-bottom: 30px;
}

@media (max-width: 1200px) {
  .rts-match-recap-section .section-inner .section-title {
    font-size: 50px;
    margin-bottom: 25px;
  }

  .rts-match-recap-section .section-inner .p1 {
    margin-bottom: 15px;
  }

  .rts-match-recap-section .section-inner .p2 {
    margin-bottom: 30px;
  }
}

@media (max-width: 1080px) {
  .rts-match-recap-section .section-inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .rts-match-recap-section .section-inner .recap-picture {
    width: 100%;
    margin-right: 0;
    margin-bottom: 40px;
  }

  .rts-match-recap-section .section-inner .recap-picture img {
    width: 100%;
  }

  .rts-match-recap-section .section-inner .contents {
    max-width: unset;
  }
}

@media (max-width: 768px) {
  .rts-match-recap-section .section-inner .section-title {
    font-size: 40px;
  }

  .rts-match-recap-section .section-inner .recap-picture {
    margin-bottom: 30px;
  }
}

.rts-match-insights-section {
  position: relative;
  padding-bottom: 0;
}

.rts-match-insights-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: #F5F5F5;
}

.rts-match-insights-section p {
  position: relative;
  z-index: 1;
}

.rts-match-insights-section .video-thumb {
  position: relative;
  z-index: 1;
}

.rts-match-insights-section .video-thumb::before {
  content: "";
  position: absolute;
  /* background: url(../public/images/details/insgt.jpg); */
  width: 100%;
  height: 100%;
  background-color: rgb(228, 27, 35);
  background-blend-mode: multiply;
}

.rts-match-insights-section .play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.rts-match-insights-section .table-area {
  -webkit-box-shadow: 0px 15px 24px 0px rgba(206, 206, 206, 0.4);
  box-shadow: 0px 15px 24px 0px rgba(206, 206, 206, 0.4);
}

.rts-match-insights-section .table-area .table tbody .head-tr.inner {
  border-width: 1px !important;
  border-style: solid !important;
}

.rts-match-insights-section .table-area .table tbody .head-tr.inner th {
  text-align: center;
}

.rts-match-insights-section .table-area .table tbody tr td {
  text-align: center;
}

.rts-location-section {
  padding-bottom: 0;
}

.rts-location-section .top-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.rts-location-section .top-wrap .section-title-area {
  margin-bottom: 40px !important;
}

.rts-location-section .top-wrap .section-title-area .section-title {
  margin-bottom: 0;
}

.rts-location-section iframe {
  width: 100%;
}

.rts-location-section .direction-btn {
  padding: 15px 30px;
  margin-bottom: 50px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background: #000000;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  text-transform: uppercase;
  position: relative;
  -webkit-transition-property: color;
  border: none;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  border-radius: 0;
  letter-spacing: 2px;
  transition-duration: 0.3s;
}

.rts-location-section .direction-btn::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  background: var(--theme-color);
}

.rts-location-section .direction-btn:hover {
  color: #fff;
}

.rts-location-section .direction-btn:hover::before {
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}

@media (max-width: 900px) {
  .rts-location-section .section-title-area p {
    max-width: 400px;
  }

  .rts-location-section .direction-btn {
    display: inline;
    font-size: 14px;
    padding: 15px 20px;
  }
}

@media (max-width: 625px) {
  .rts-location-section .top-wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 40px;
  }

  .rts-location-section .top-wrap .direction-btn {
    margin-bottom: 0;
  }

  .rts-location-section .section-title-area {
    text-align: center !important;
    margin-bottom: 20px !important;
  }

  .rts-location-section .section-title-area p {
    max-width: unset;
  }
}

.rts-match-results-section .table-area {
  margin-bottom: 100px;
}

.rts-match-results-section .table-area .head-tr {
  background: none;
  border-style: solid;
  border-width: 1px !important;
}

.rts-match-results-section .table-area .head-tr th:first-child {
  padding: 20px 30px !important;
}

.rts-match-results-section .table-area tr td:first-child {
  padding: 20px 30px !important;
}

.rts-match-results-section .table-area .name {
  font-weight: 700;
  color: #111111;
}

.rts-match-results-section .table-area .compact-td {
  width: 110px !important;
}

.rts-match-results-section .club-single {
  padding: 50px;
  border-radius: 0;
  background: #fff;
  border: 1px solid #e7e7e7;
}

.rts-match-results-section .club-single .club-name {
  font-size: 30px;
  letter-spacing: -1px;
  font-weight: 600;
  padding-bottom: 15px;
  margin-bottom: 30px;
  border-bottom: 1px solid #f1f1f1;
}

.rts-match-results-section .club-single .player-single {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #f1f1f1;
}

.rts-match-results-section .club-single .player-single:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}

.rts-match-results-section .club-single .player-single .player-number {
  font-size: 14px;
  line-height: 34px;
  font-weight: 600;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #f1f1f1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 15px;
}

.rts-match-results-section .club-single .player-single .player-name {
  font-size: 20px;
  letter-spacing: -1px;
  font-weight: 600;
  display: block;
  line-height: 25px;
}

.rts-match-results-section .club-single .player-single .player-role {
  display: block;
  font-size: 14px;
  letter-spacing: 1px;
  color: #999999;
  font-weight: 300;
}

.rts-match-results-section .club-single .player-single .player-score {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: auto;
}

.rts-match-results-section .club-single .player-single .player-score .action-card {
  width: 20px;
  height: 20px;
  border-radius: 5px;
}

.rts-match-results-section .club-single .player-single .player-score .action-card.ylw-card {
  background: #ffde00;
}

.rts-match-results-section .club-single .player-single .player-score .action-card.red-card {
  background: #ff2b2b;
}

.rts-match-results-section .trainer-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  margin-top: 40px;
}

.rts-match-results-section .trainer-area .trainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.rts-match-results-section .trainer-area .trainer .trainer-dp {
  margin-right: 10px;
}

.rts-match-results-section .trainer-area .trainer .trainer-name {
  font-size: 20px;
  letter-spacing: -1px;
  color: #111111;
  font-weight: 600;
}

.rts-match-results-section .trainer-area .trainer .role {
  font-size: 14px;
  letter-spacing: 1px;
  color: #999999;
  font-weight: 300;
  display: block;
}

@media (max-width: 768px) {
  .rts-match-results-section .table-area {
    margin-bottom: 40px;
  }

  .rts-match-results-section .club-single {
    padding: 35px;
    margin-bottom: 30px;
  }

  .rts-match-results-section .club-single .club-name {
    font-size: 22px;
  }

  .rts-match-results-section .club-single .player-role {
    font-size: 14px;
  }

  .rts-match-results-section .club-single .player-single .player-name {
    font-size: 18px;
  }
}

@media (max-width: 450px) {
  .rts-match-results-section .club-single {
    padding: 20px;
  }

  .rts-match-results-section .club-single .club-name {
    font-size: 22px;
  }

  .rts-match-results-section .club-single .player-single .player-name {
    font-size: 16px;
  }
}

.rts-trainer-section-section {
  padding-top: 0;
}

.rts-trainer-section-section .head-tr {
  background: #f6f6f6;
}

.rts-trainer-section-section .head-tr th {
  padding: 20px 30px !important;
}

.rts-trainer-section-section td {
  width: 30%;
  padding: 20px 30px !important;
}

.trainer-area .rts-match-schedule-section {
  margin-bottom: -30px;
}

.trainer-area .rts-match-schedule-section .match-single {
  overflow: hidden;
  padding: 0;
  margin-bottom: 30px;
}

.trainer-area .rts-match-schedule-section .match-single .match-single-content {
  padding: 45px 30px;
  text-align: center;
}

.trainer-area .rts-match-schedule-section .match-single .match-single-content .block-wrap {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.trainer-area .rts-match-schedule-section .match-single .match-bottom-action {
  width: 100%;
  background: #222222;
  padding: 15px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.trainer-area .rts-match-schedule-section .match-single .match-bottom-action .action-item {
  font-size: 16px;
  line-height: 30px;
  color: #999999;
  font-weight: 400;
}

.trainer-area .rts-match-schedule-section .match-single .match-bottom-action .action-item:hover {
  color: #fff;
}

.trainer-area .rts-match-schedule-section .match-single .match-bottom-action .action-item.first-child {
  position: relative;
  margin-right: 50px;
}

.trainer-area .rts-match-schedule-section .match-single .match-bottom-action .action-item.first-child::before {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: -25px;
  width: 2px;
  height: 50%;
  background: #383838;
}

.rts-match-schedule-section {
  margin-bottom: -30px;
}

.rts-match-schedule-section .match-single {
  overflow: hidden;
  padding: 0;
  margin-bottom: 30px;
}

.rts-match-schedule-section .match-single .match-single-content {
  padding: 45px 30px;
  text-align: center;
}

.rts-match-schedule-section .match-single .match-single-content .block-wrap {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.rts-match-schedule-section .match-single .match-bottom-action {
  width: 100%;
  background: #222222;
  padding: 15px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.rts-match-schedule-section .match-single .match-bottom-action .action-item {
  font-size: 16px;
  line-height: 30px;
  color: #999999;
  font-weight: 400;
}

.rts-match-schedule-section .match-single .match-bottom-action .action-item:hover {
  color: #fff;
}

.rts-match-schedule-section .match-single .match-bottom-action .action-item.first-child {
  position: relative;
  margin-right: 50px;
}

.rts-match-schedule-section .match-single .match-bottom-action .action-item.first-child::before {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: -25px;
  width: 2px;
  height: 50%;
  background: #383838;
}

.rts-gallery-section {
  padding: 175px 0 120px 0;
  margin: 0;
}

.rts-gallery-section.inner {
  padding: 0px 0 120px 0;
}

@media (max-width: 991px) {
  .rts-gallery-section {
    padding: 30px 0 0 0;
  }
}

.rts-gallery-section .container .section-title-area {
  position: relative;
}

.rts-gallery-section .container .section-title-area .pretitle {
  top: -150px;
  font-size: 220px;
  -webkit-text-fill-color: #f7f7f7;
  -webkit-text-stroke-color: #f7f7f7;
}

@media (max-width: 991px) {
  .rts-gallery-section .container .section-title-area .pretitle {
    /* display: none;
     */
    top: -30px;
  }

  .rts-awards-section .section-title-area .pretitle {
    top: -30px !important;
  }
}

.rts-gallery-section .container .section-title-area .section-title {
  margin-bottom: 15px;
}

.rts-gallery-section .container .gallery-area {
  height: 50vh;
  min-height: 22rem;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 1s;
  transition: all 1s;
}

@media (max-width: 991px) {
  .rts-gallery-section .container .gallery-area {
    height: auto;
    min-height: auto;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.rts-gallery-section .container .gallery-area .gallery-item {
  position: relative;
  -webkit-transition: all 1s;
  transition: all 1s;
  margin: 0 10px;
  z-index: 10;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 23%;
  flex: 1 0 23%;
}

@media (max-width: 991px) {
  .rts-gallery-section .container .gallery-area .gallery-item {
    -webkit-box-flex: 1;
    -ms-flex: auto;
    flex: auto;
    width: 46%;
    margin-bottom: 20px;
  }
}

@media (max-width: 700px) {
  .rts-gallery-section .container .gallery-area .gallery-item {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .rts-gallery-section .container .gallery-area .gallery-item.mid {
    -webkit-box-flex: 1;
    -ms-flex: auto;
    flex: auto;
    width: 100%;
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
}

.rts-gallery-section .container .gallery-area .gallery-item .number {
  position: absolute;
  right: 45px;
  top: 15px;
  font-size: 135px;
  opacity: 0;
  z-index: 1;
  -webkit-transform: translateY(30px);
  transform: translateY(30px);
  -webkit-transition: opacity 0.7s ease-in-out, all 0.7s ease-in-out;
  transition: opacity 0.7s ease-in-out, all 0.7s ease-in-out;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
  color: #fff;
}

.rts-gallery-section .container .gallery-area .gallery-item:hover {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 48%;
  flex: 1 0 48%;
}

@media (max-width: 991px) {
  .rts-gallery-section .container .gallery-area .gallery-item:hover {
    -webkit-box-flex: 1;
    -ms-flex: auto;
    flex: auto;
  }
}

.rts-gallery-section .container .gallery-area .gallery-item:hover::before {
  height: 100%;
}

.rts-gallery-section .container .gallery-area .gallery-item:hover .gallery-picture {
  display: block;
}

.rts-gallery-section .container .gallery-area .gallery-item:hover .gallery-picture1 {
  display: none;
}

.rts-gallery-section .container .gallery-area .gallery-item:hover .number {
  opacity: 0.15;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.rts-gallery-section .container .gallery-area .gallery-item:hover .contents-wrapper {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

.rts-gallery-section .container .gallery-area .gallery-item.active {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 48%;
  flex: 1 0 48%;
}

.rts-gallery-section .container .gallery-area .gallery-item.active::before {
  height: 100%;
}

.rts-gallery-section .container .gallery-area .gallery-item.active .gallery-picture {
  display: block;
}

.rts-gallery-section .container .gallery-area .gallery-item.active .gallery-picture1 {
  display: none;
}

.rts-gallery-section .container .gallery-area .gallery-item.active .number {
  opacity: 0.15;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.rts-gallery-section .container .gallery-area .gallery-item.active .contents-wrapper {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

@media (max-width: 991px) {
  .rts-gallery-section .container .gallery-area .gallery-item.active {
    -webkit-box-flex: 1;
    -ms-flex: auto;
    flex: auto;
  }
}

.rts-gallery-section .container .gallery-area .gallery-item.middle {
  margin: 0 40px;
}

.rts-gallery-section .container .gallery-area .gallery-item::before {
  content: "";
  position: absolute;
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgb(0, 0, 0)), to(rgba(0, 0, 0, 0)));
  background-image: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
  height: 0%;
  width: 100%;
  -webkit-transform: scale(1);
  transform: scale(1);
  bottom: 0;
  left: 0;
  pointer-events: none;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  z-index: 1;
}

.rts-gallery-section .container .gallery-area .gallery-item .gallery-picture {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  height: calc(100% - 6rem);
  display: none;
}

@media (max-width: 991px) {
  .rts-gallery-section .container .gallery-area .gallery-item .gallery-picture {
    display: block;
  }
}

.rts-gallery-section .container .gallery-area .gallery-item .gallery-picture img {
  height: 100%;
  width: 100%;
}

.rts-gallery-section .container .gallery-area .gallery-item .gallery-picture1 {
  display: block;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

@media (max-width: 991px) {
  .rts-gallery-section .container .gallery-area .gallery-item .gallery-picture1 {
    display: none;
  }
}

.rts-gallery-section .container .gallery-area .gallery-item .contents-wrapper {
  position: absolute;
  bottom: 50px;
  left: 50px;
  -webkit-transform: translateY(25px);
  transform: translateY(25px);
  opacity: 0;
  -webkit-transition: opacity 0.7s ease-in-out, all 0.7s ease-in-out;
  transition: opacity 0.7s ease-in-out, all 0.7s ease-in-out;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
  z-index: 1;
  overflow: hidden;
}

@media (max-width: 550px) {
  .rts-gallery-section .container .gallery-area .gallery-item .contents-wrapper {
    left: 15px;
    bottom: 15px;
  }
}

.rts-gallery-section .container .gallery-area .gallery-item .contents-wrapper .contents .tag {
  color: #6575e1;
  background: #fff;
  font-size: 12px !important;
  width: 75px;
  text-align: center;
  padding: 3px 0;
  margin-bottom: 10px;
  font-weight: 500;
}

.rts-gallery-section .container .gallery-area .gallery-item .contents-wrapper .contents .gallery-title {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 15px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

@media (max-width: 550px) {
  .rts-gallery-section .container .gallery-area .gallery-item .contents-wrapper .contents .gallery-title {
    font-size: 18px;
  }
}

.rts-gallery-section .container .gallery-area .gallery-item .contents-wrapper .contents .gallery-title:hover {
  color: var(--theme-color);
}

.rts-gallery-section .container .gallery-area .gallery-item .contents-wrapper .contents .author-info .content .read-more {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  padding-bottom: 3px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

@media (max-width: 550px) {
  .rts-gallery-section .container .gallery-area .gallery-item .contents-wrapper .contents .author-info .content .read-more {
    font-size: 12px;
  }
}

.rts-gallery-section .container .gallery-area .gallery-item .contents-wrapper .contents .author-info .content .read-more::before {
  content: "";
  position: absolute;
  background: #fff;
  width: 35px;
  height: 1px;
  left: 0;
  bottom: 0;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.rts-gallery-section .container .gallery-area .gallery-item .contents-wrapper .contents .author-info .content .read-more:hover {
  color: var(--theme-color);
}

.rts-gallery-section .container .gallery-area .gallery-item .contents-wrapper .contents .author-info .content .read-more:hover::before {
  width: 100%;
  background: var(--theme-color);
}

.rts-gallery-section.inner {
  padding-top: 70px;
  padding-bottom: 190px;
}

@media (max-width: 991px) {
  .rts-gallery-section.inner {
    padding-bottom: 70px;
  }
}

@media (max-width: 991px) {
  .rts-gallery-section.inner .gallery-area .gallery-item {
    margin-bottom: 50px;
  }
}

.rts-next-match-section .section-title-area {
  position: relative;
}

.rts-next-match-section .section-title-area .pretitle {
  top: -150px;
  font-size: 220px;
  -webkit-text-fill-color: #f7f7f7;
  -webkit-text-stroke-color: #f7f7f7;
}

.rts-next-match-section .section-title-area .section-title {
  margin-bottom: 15px;
}

.rts-next-match-section .table-area .table-inner {
  background: #f6f6f6;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 40px 45px;
  margin-bottom: 15px;
  position: relative;
}

.rts-next-match-section .table-area .table-inner:last-child {
  margin-bottom: 0;
}

@media (max-width: 900px) {
  .rts-next-match-section .table-area .table-inner {
    display: block;
    text-align: center;
  }
}

@media (max-width: 450px) {
  .rts-next-match-section .table-area .table-inner {
    padding: 30px 15px;
  }
}

.rts-next-match-section .table-area .table-inner::before {
  content: "";
  position: absolute;
  background: #e2e2e2;
  width: 1px;
  height: 100%;
  left: 33%;
}

@media (max-width: 1200px) {
  .rts-next-match-section .table-area .table-inner::before {
    display: none;
  }
}

.rts-next-match-section .table-area .table-inner::after {
  content: "";
  position: absolute;
  background: #e2e2e2;
  width: 1px;
  height: 100%;
  right: 33%;
}

@media (max-width: 1200px) {
  .rts-next-match-section .table-area .table-inner::after {
    display: none;
  }
}

.rts-next-match-section .table-area .table-inner .team-logo-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 900px) {
  .rts-next-match-section .table-area .table-inner .team-logo-area {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

.rts-next-match-section .table-area .table-inner .team-logo-area .versus {
  margin: 0 25px;
  border: 1px solid #e2e2e2;
  background: none;
  color: #111111;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 50%;
  font-size: 14px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.rts-next-match-section .table-area .table-inner .team-logo-area .versus:hover {
  border: 1px solid var(--theme-color);
  background: var(--theme-color);
  color: #fff;
}

@media (max-width: 900px) {
  .rts-next-match-section .table-area .table-inner .team-name {
    margin-bottom: 25px;
  }
}

.rts-next-match-section .table-area .table-inner .team-name .mode {
  margin-bottom: 5px;
}

.rts-next-match-section .table-area .table-inner .team-name .name {
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 17px;
}

.rts-next-match-section .table-area .table-inner .team-name .name span {
  color: #6575e1;
  margin: 0 3px;
}

.rts-next-match-section .table-area .table-inner .team-name .time {
  text-transform: uppercase;
  background: #111111;
  text-align: center;
  width: 230px;
  padding: 5px 0;
  font-size: 14px;
  color: #fff;
}

@media (max-width: 900px) {
  .rts-next-match-section .table-area .table-inner .team-name .time {
    margin: 0 auto;
  }
}

@media (max-width: 900px) {
  .rts-next-match-section .table-area .button-area {
    margin-top: 25px;
  }
}

.rts-next-match-section .table-area .button-area .btn-1 {
  font-size: 14px;
  width: 150px;
  height: 50px;
  line-height: 50px;
  font-weight: 500;
  color: #fff;
  background: var(--theme-color);
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  text-transform: uppercase;
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  margin-right: 15px;
  letter-spacing: 2px;
}

@media (max-width: 1100px) and (min-width: 900px) {
  .rts-next-match-section .table-area .button-area .btn-1 {
    display: block;
    margin-bottom: 10px;
    margin-right: 0;
  }
}

@media (max-width: 450px) {
  .rts-next-match-section .table-area .button-area .btn-1 {
    display: block;
    margin-bottom: 10px !important;
    margin: 0 auto;
  }
}

.rts-next-match-section .table-area .button-area .btn-1::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  background: #111111;
}

.rts-next-match-section .table-area .button-area .btn-1:hover {
  color: #fff;
}

.rts-next-match-section .table-area .button-area .btn-1:hover::before {
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}

.rts-next-match-section .table-area .button-area .btn-2 {
  font-size: 14px;
  width: 150px;
  height: 50px;
  line-height: 50px;
  font-weight: 500;
  color: #fff;
  background: #111111;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  text-transform: uppercase;
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  letter-spacing: 2px;
}

@media (max-width: 1100px) and (min-width: 900px) {
  .rts-next-match-section .table-area .button-area .btn-2 {
    display: block;
  }
}

@media (max-width: 450px) {
  .rts-next-match-section .table-area .button-area .btn-2 {
    display: block;
    margin: 0 auto;
  }
}

.rts-next-match-section .table-area .button-area .btn-2::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  background: var(--theme-color);
}

.rts-next-match-section .table-area .button-area .btn-2:hover {
  color: #fff;
}

.rts-next-match-section .table-area .button-area .btn-2:hover::before {
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}

.rts-video-section {
  background: url(../public/images/rts-video.jpg);
  background-position: center;
  background-attachment: fixed;
  background-color: #e9e9e9;
  background-blend-mode: luminosity;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding: 100px 0;
  z-index: 1;
}

.rts-video-section::before {
  position: absolute;
  background: rgb(17, 17, 17);
  z-index: -1;
  content: "";
  mix-blend-mode: multiply;
  background-color: rgb(7 86 134);
  opacity: 0.902;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* .rts-video-section .video-section-inner {
  padding: 80px 0;
} */
.rts-video-section .video-section-inner .play-video .popup-video {
  background: #fff;
  margin: 0 auto;
  color: var(--theme-color);
  width: 75px;
  height: 75px;
  line-height: 75px;
  text-align: center;
  display: block;
  font-size: 20px;
  position: relative;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.rts-video-section .video-section-inner .play-video .popup-video:hover {
  background: var(--theme-color);
  color: #fff;
}

.rts-video-section .video-section-inner .play-video .popup-video:hover::before {
  border: 1px solid var(--theme-color);
}

.rts-video-section .video-section-inner .play-video .popup-video::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border: 1px solid #fff;
  -webkit-transform: scale(140%);
  transform: scale(140%);
  left: 0;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-animation: pulse-border linear 3s infinite;
  animation: pulse-border linear 3s infinite;
}

.rts-video-section.video-section-2 {
  /* background: url(../public/images/background/video-2.jpg); */
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding: 150px 0;
}

.rts-video-section.video-section-2::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgb(5, 5, 5);
  opacity: 0.749;
}

.rts-video-section.video-section-2 .video-section-inner .title {
  color: #fff;
  font-size: 48px;
  font-weight: 500;
  margin-bottom: 30px;
}

.rts-video-section.video-section-2 .video-section-inner .btn {
  border: 1px solid #3e3e3e;
  padding: 12px 25px;
  letter-spacing: 1px;
  color: #fff;
  font-size: 14px;
  border-radius: 0;
  font-weight: 500;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.rts-video-section.video-section-2 .video-section-inner .btn:hover {
  color: var(--theme-color);
  background: #fff;
}

.rts-video-section.inner {
  background-color: var(--theme-color);
  background-blend-mode: multiply;
}

.rts-team-section.inner {
  padding-bottom: 75px;
}

.rts-team-section .section-title-area {
  position: relative;
}

.rts-team-section .section-title-area .pretitle {
  top: -150px;
  font-size: 220px;
  -webkit-text-fill-color: #f7f7f7;
  -webkit-text-stroke-color: #f7f7f7;
}

@media (max-width: 991px) {
  .rts-team-section .section-title-area .pretitle {
    top: -140px;
    font-size: 170px;
  }
}

.rts-team-section .section-title-area .section-title {
  margin-bottom: 15px;
}

.rts-team-section .team-section-inner.inner .team-wraper {
  margin-bottom: 30px;
}

.rts-team-section .team-section-inner .team-wraper .player-card {
  margin-bottom: 25px;
  position: relative;
  overflow: hidden;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  background: #f6f6f6;
  padding: 20px 35px;
  padding-bottom: 0;
  z-index: 1;
}

.rts-team-section .team-section-inner .team-wraper .player-card:hover .image {
  -webkit-transform: scale(110%);
  transform: scale(110%);
}

.rts-team-section .team-section-inner .team-wraper .player-card:hover .social-area {
  right: 35px;
  opacity: 1;
}

.rts-team-section .team-section-inner .team-wraper .player-card .image {
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.rts-team-section .team-section-inner .team-wraper .player-card .number {
  position: absolute;
  top: 10px;
  left: 40px;
  font-size: 80px;
  color: #e7e7e7;
  font-weight: 700;
  z-index: -1;
}

.rts-team-section .team-section-inner .team-wraper .player-card .social-area {
  position: absolute;
  top: 50px;
  right: -50px;
  opacity: 0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.rts-team-section .team-section-inner .team-wraper .player-card .social-area::before {
  content: "";
  position: absolute;
  background: #111111;
  width: 1px;
  height: 15px;
  top: -25px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.rts-team-section .team-section-inner .team-wraper .player-card .social-area li a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: block;
  text-align: center;
  background: #fff;
  margin-bottom: 10px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.rts-team-section .team-section-inner .team-wraper .player-card .social-area li a:hover {
  color: #fff;
  background: var(--theme-color);
}

.rts-team-section .team-section-inner .team-wraper .profile .position {
  font-size: 12px;
  font-weight: 400;
}

.rts-team-section .team-section-inner .team-wraper .profile .name {
  font-size: 30px;
  font-weight: 600;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.rts-team-section .team-section-inner .team-wraper .profile .name:hover {
  color: var(--theme-color);
}

.rts-team-section .team-section-inner .rts-teamSlider {
  padding-bottom: 60px;
}

.rts-team-section .team-section-inner .rts-teamSlider .swiper-pagination {
  width: 100% !important;
  top: unset;
  bottom: 0;
}

.rts-team-section .team-section-inner .rts-teamSlider .swiper-pagination .swiper-pagination-progressbar-fill {
  background: var(--theme-color);
}

.rts-newsletter-section {
  background: #000 !important;
  background-position: center;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-size: cover;
  overflow: hidden;
  padding: 100px 0;
}

.rts-newsletter-section .newsletter-inner {
  position: relative;
}

.rts-newsletter-section .newsletter-box {
  background: black;
  padding: 60px 55px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

@media (max-width: 375px) {
  .rts-newsletter-section .newsletter-box {
    padding: 70px 20px;
    /* margin-top: 50svh; */
  }
}

.rts-newsletter-section .newsletter-box .shape {
  position: absolute;
  bottom: -35%;
  right: -35%;
  z-index: -1;
}

.rts-newsletter-section .newsletter-box .section-title-area .section-title {
  font-size: 48px;
}

.rts-newsletter-section .newsletter-box form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 576px) {
  .rts-newsletter-section .newsletter-box form {
    display: block;
  }
}

.rts-newsletter-section .newsletter-box form .form {
  width: 65%;
}

.rts-newsletter-section .newsletter-box form .form input {
  padding: 15px;
  border-radius: 0;
  border: none;
  background: #f4f4f4;
}

.rts-newsletter-section .newsletter-box form .form input::-webkit-input-placeholder {
  font-family: "Roboto";
}

.rts-newsletter-section .newsletter-box form .form input::-moz-placeholder {
  font-family: "Roboto";
}

.rts-newsletter-section .newsletter-box form .form input:-ms-input-placeholder {
  font-family: "Roboto";
}

.rts-newsletter-section .newsletter-box form .form input::-ms-input-placeholder {
  font-family: "Roboto";
}

.rts-newsletter-section .newsletter-box form .form input::placeholder {
  font-family: "Roboto";
}

.rts-newsletter-section .newsletter-box form .form input:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 1px solid #6575e1;
}

@media (max-width: 576px) {
  .rts-newsletter-section .newsletter-box form .form {
    width: 90%;
    margin: 0 auto 10px auto;
  }
}

.rts-newsletter-section .newsletter-box form .button {
  width: 35%;
}

@media (max-width: 576px) {
  .rts-newsletter-section .newsletter-box form .button {
    width: 55%;
    margin: 0 auto;
  }
}

.rts-newsletter-section .newsletter-box form .button button {
  width: 100%;
  padding: 15px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background: #000000;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  text-transform: uppercase;
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  border: none;
  border-radius: 20px;
}

.rts-newsletter-section .newsletter-box form .button button::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  background-color: #02BF60;
  border-radius: 20px;
}

.rts-newsletter-section .newsletter-box form .button button:hover {
  color: #fff;
}

.rts-newsletter-section .newsletter-box form .button button:hover::before {
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}

.rts-newsletter-section .hero-image {
  /* position: absolute; */
  bottom: 0px;
  display: flex;
  justify-content: end;
  /* -webkit-transform: scale(1.17); */
  /* transform: scale(1.17); */
  /* right: 108px; */
}

/* .rts-single-news-section {
  background: #111111;
} */
.newsletter-section-title{
  margin-bottom: 25px;
  color: #fff;
}
@media (max-width:1024px) {
  .newsletter-section-title{
    font-size: 20px;
  }
}
.newsletter-section-subHeading{
  font-size: 25px;
  color:#fff;
}
@media (max-width:1024px) {
  .newsletter-section-subHeading{
    font-size: 20px;
  }
}
.rts-single-news-section .container .section-inner .item {
  position: relative;
  z-index: 1;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  overflow: hidden;
}

@media (max-width: 991px) {
  .rts-single-news-section .container .section-inner .item {
    margin-bottom: 25px;
  }
}

.rts-single-news-section .container .section-inner .item:hover .gallery-picture img {
  -webkit-transform: scale(110%);
  transform: scale(110%);
}

.rts-single-news-section .container .section-inner .item .gallery-picture {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.rts-single-news-section .container .section-inner .item .gallery-picture img {
  width: 100%;
  height: 300px;
  object-fit: cover;

}

.rts-single-news-section .container .section-inner .item .gallery-picture::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 1;
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgb(0, 0, 0)), to(rgba(0, 0, 0, 0)));
  background-image: linear-gradient(0deg, rgb(2 2 2 / 80%) 0%, rgb(12 12 12 / 55%) 100%);
  opacity: 100%;
}

.rts-single-news-section .container .section-inner .item .contents-wrapper {
  position: absolute;
  /* bottom: 30px;
  left: 20px; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 1;
  width: 100%;
}

.rts-single-news-section .container .section-inner .item .contents-wrapper .contents .heading {
  margin-bottom: 10px;
}

.rts-single-news-section .container .section-inner .item .contents-wrapper .contents .heading .tag {
  background: var(--theme-color);
  font-size: 12px !important;
  color: #fff;
  padding: 0 5px;
}

.rts-single-news-section .container .section-inner .item .contents-wrapper .contents .heading .blog-date {
  font-size: 12px;
  color: #fff;
  font-weight: 400;
  margin-left: 20px;
}

.rts-single-news-section .container .section-inner .item .contents-wrapper .contents .gallery-title {
  margin-bottom: 15px;
}

.contents-wrapper i {
  color: #fff;
  font-size: 60px;
  margin-bottom: 30px;
}

.rts-single-news-section .container .section-inner .item .contents-wrapper .contents .gallery-title a {
  display: inline;
  color: #fff;
  font-size: 26px;
  font-weight: 600;
  background-size: 200% 2px;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, var(--theme-color)), color-stop(50%, transparent));
  background-image: linear-gradient(to right, var(--theme-color) 50%, transparent 50%);
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

/* .rts-single-news-section .container .section-inner .item .contents-wrapper .contents .gallery-title a:hover {
  color: var(--theme-color);
  background-position: 0% 100%;
} */
.rts-single-news-section .container .section-inner .item .contents-wrapper .contents .author-info .content .read-more {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.rts-single-news-section .container .section-inner .item .contents-wrapper .contents .author-info .content .read-more::before {
  position: absolute;
  content: "";
  width: 50%;
  height: 1px;
  background: #fff;
  left: 0;
  bottom: -5px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.rts-single-news-section .container .section-inner .item .contents-wrapper .contents .author-info .content .read-more:hover {
  color: var(--theme-color);
}

.rts-single-news-section .container .section-inner .item .contents-wrapper .contents .author-info .content .read-more:hover::before {
  width: 100%;
  background: var(--theme-color);
}

.rts-trending-news-section {
  background: #111111;
  margin-bottom: -30px;
}

.rts-trending-news-section .section-title-area .section-title {
  color: #fff;
  font-size: 30px;
}

.rts-trending-news-section .item {
  position: relative;
  z-index: 1;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  overflow: hidden;
  margin-bottom: 30px;
}

.rts-trending-news-section .item:hover .gallery-picture img {
  -webkit-transform: scale(110%);
  transform: scale(110%);
}

.rts-trending-news-section .item .gallery-picture {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.rts-trending-news-section .item .gallery-picture img {
  width: 100%;
}

.rts-trending-news-section .item .gallery-picture::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 1;
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgb(0, 0, 0)), to(rgba(0, 0, 0, 0)));
  background-image: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
  opacity: 100%;
}

.rts-trending-news-section .item .contents-wrapper {
  position: absolute;
  bottom: 30px;
  left: 25px;
  z-index: 1;
}

.rts-trending-news-section .item .contents-wrapper .contents .heading {
  margin-bottom: 10px;
}

.rts-trending-news-section .item .contents-wrapper .contents .heading .tag {
  background: var(--theme-color);
  font-size: 12px !important;
  color: #fff;
  padding: 0 5px;
}

.rts-trending-news-section .item .contents-wrapper .contents .heading .blog-date {
  font-size: 12px;
  color: #fff;
  font-weight: 400;
  margin-left: 20px;
  font-family: "Roboto";
}

.rts-trending-news-section .item .contents-wrapper .contents .gallery-title {
  margin-bottom: 15px;
}

.rts-trending-news-section .item .contents-wrapper .contents .gallery-title a {
  display: inline;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  background-size: 200% 2px;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, var(--theme-color)), color-stop(50%, transparent));
  background-image: linear-gradient(to right, var(--theme-color) 50%, transparent 50%);
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

.rts-trending-news-section .item .contents-wrapper .contents .gallery-title a:hover {
  color: var(--theme-color);
  background-position: 0% 100%;
}

.rts-trending-news-section .item .contents-wrapper .contents .author-info .content .read-more {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.rts-trending-news-section .item .contents-wrapper .contents .author-info .content .read-more::before {
  position: absolute;
  content: "";
  width: 50%;
  height: 1px;
  background: #fff;
  left: 0;
  bottom: -5px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.rts-trending-news-section .item .contents-wrapper .contents .author-info .content .read-more:hover {
  color: var(--theme-color);
}

.rts-trending-news-section .item .contents-wrapper .contents .author-info .content .read-more:hover::before {
  width: 100%;
  background: var(--theme-color);
}

.rts-newsletter-section2 {
  background: #111111;
}

.rts-newsletter-section2 .newsletter-inner {
  /* background: url(../public/images/background/newsletter-bg2.jpg); */
  background-repeat: no-repeat;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 90px 60px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

@media (max-width: 900px) {
  .rts-newsletter-section2 .newsletter-inner {
    display: block;
    text-align: center;
    padding: 65px 60px;
  }
}

@media (max-width: 450px) {
  .rts-newsletter-section2 .newsletter-inner {
    padding: 40px 20px;
  }
}

.rts-newsletter-section2 .newsletter-inner .animated-image {
  position: absolute;
}

.rts-newsletter-section2 .newsletter-inner .title {
  color: #fff;
}

@media (max-width: 900px) {
  .rts-newsletter-section2 .newsletter-inner .title {
    margin-bottom: 20px;
  }
}

.rts-newsletter-section2 .newsletter-inner form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 900px) {
  .rts-newsletter-section2 .newsletter-inner form {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

.rts-newsletter-section2 .newsletter-inner form .form {
  width: 400px;
}

@media (max-width: 1200px) {
  .rts-newsletter-section2 .newsletter-inner form .form {
    width: 350px;
  }
}

@media (max-width: 991px) {
  .rts-newsletter-section2 .newsletter-inner form .form {
    width: 300px;
  }
}

.rts-newsletter-section2 .newsletter-inner form .form input {
  padding: 15px;
  border-radius: 0;
  border: none;
  background: #f4f4f4;
}

.rts-newsletter-section2 .newsletter-inner form .form input:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 1px solid #6575e1;
}

.rts-newsletter-section2 .newsletter-inner form .button {
  width: 150px;
}

@media (max-width: 991px) {
  .rts-newsletter-section2 .newsletter-inner form .button {
    width: 120px;
  }
}

.rts-newsletter-section2 .newsletter-inner form .button button {
  width: 100%;
  padding: 15px;
  background: #6575e1;
  color: #fff;
  border-radius: 0;
}

.rts-newsletter-section2.section3 {
  background: none;
}

.rts-trending-news-section2 {
  background: #111111;
}

.rts-trending-news-section2 .section-inner .section-title-area .section-title {
  color: #fff;
  font-size: 30px;
}

.rts-trending-news-section2 .section-inner .item {
  position: relative;
  z-index: 1;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  overflow: hidden;
  margin-bottom: 30px;
}

.rts-trending-news-section2 .section-inner .item:hover .gallery-picture img {
  -webkit-transform: scale(110%);
  transform: scale(110%);
}

.rts-trending-news-section2 .section-inner .item .gallery-picture {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.rts-trending-news-section2 .section-inner .item .gallery-picture img {
  width: 100%;
}

.rts-trending-news-section2 .section-inner .item .gallery-picture::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 1;
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgb(0, 0, 0)), to(rgba(0, 0, 0, 0)));
  background-image: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
  opacity: 100%;
}

.rts-trending-news-section2 .section-inner .item .contents-wrapper {
  position: absolute;
  bottom: 30px;
  left: 25px;
  z-index: 1;
}

.rts-trending-news-section2 .section-inner .item .contents-wrapper .contents .heading {
  margin-bottom: 10px;
}

.rts-trending-news-section2 .section-inner .item .contents-wrapper .contents .heading .tag {
  background: var(--theme-color);
  font-size: 12px !important;
  color: #fff;
  padding: 0 5px;
}

.rts-trending-news-section2 .section-inner .item .contents-wrapper .contents .heading .blog-date {
  font-size: 12px;
  color: #fff;
  font-weight: 400;
  margin-left: 20px;
  font-family: "Roboto";
}

.rts-trending-news-section2 .section-inner .item .contents-wrapper .contents .gallery-title {
  margin-bottom: 15px;
}

.rts-trending-news-section2 .section-inner .item .contents-wrapper .contents .gallery-title a {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  display: inline;
  background-size: 200% 2px;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, var(--theme-color)), color-stop(50%, transparent));
  background-image: linear-gradient(to right, var(--theme-color) 50%, transparent 50%);
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

.rts-trending-news-section2 .section-inner .item .contents-wrapper .contents .gallery-title a:hover {
  color: var(--theme-color);
  background-position: 0% 100%;
}

.rts-trending-news-section2 .section-inner .item .contents-wrapper .contents .author-info .content .read-more {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.rts-trending-news-section2 .section-inner .item .contents-wrapper .contents .author-info .content .read-more::before {
  position: absolute;
  content: "";
  width: 50%;
  height: 1px;
  background: #fff;
  left: 0;
  bottom: -5px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.rts-trending-news-section2 .section-inner .item .contents-wrapper .contents .author-info .content .read-more:hover {
  color: var(--theme-color);
}

.rts-trending-news-section2 .section-inner .item .contents-wrapper .contents .author-info .content .read-more:hover::before {
  width: 100%;
  background: var(--theme-color);
}

.rts-trending-news-section2 .section-inner .rts-post-small {
  background: #1d1d1d;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px;
  margin-bottom: 32px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.rts-trending-news-section2 .section-inner .rts-post-small:hover .gallery-picture img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.rts-trending-news-section2 .section-inner .rts-post-small .gallery-picture {
  width: 30%;
  overflow: hidden;
}

.rts-trending-news-section2 .section-inner .rts-post-small .gallery-picture img {
  width: 100%;
}

.rts-trending-news-section2 .section-inner .rts-post-small .contents-wrapper {
  margin-left: 20px;
}

.rts-trending-news-section2 .section-inner .rts-post-small .contents-wrapper .contents .tag {
  color: var(--theme-color);
  font-size: 12px !important;
  background: #fff;
  padding: 0 5px;
}

.rts-trending-news-section2 .section-inner .rts-post-small .contents-wrapper .contents .blog-date {
  font-size: 12px;
  color: #fff;
  font-weight: 400;
  margin-left: 20px;
  font-family: "Roboto";
}

.rts-trending-news-section2 .section-inner .rts-post-small .contents-wrapper .contents .gallery-title {
  margin: 10px 0 17px 0;
}

.rts-trending-news-section2 .section-inner .rts-post-small .contents-wrapper .contents .gallery-title a {
  color: #fff;
  font-size: 20px;
  display: inline;
  font-weight: 600;
  background-size: 200% 2px;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, var(--theme-color)), color-stop(50%, transparent));
  background-image: linear-gradient(to right, var(--theme-color) 50%, transparent 50%);
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

.rts-trending-news-section2 .section-inner .rts-post-small .contents-wrapper .contents .gallery-title a:hover {
  color: var(--theme-color);
  background-position: 0% 100%;
}

.rts-trending-news-section2 .section-inner .rts-post-small .contents-wrapper .contents .read-more {
  color: #fff;
  position: relative;
  font-size: 14px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.rts-trending-news-section2 .section-inner .rts-post-small .contents-wrapper .contents .read-more::before {
  position: absolute;
  content: "";
  width: 50%;
  height: 1px;
  background: #fff;
  left: 0;
  bottom: -5px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.rts-trending-news-section2 .section-inner .rts-post-small .contents-wrapper .contents .read-more:hover {
  color: var(--theme-color);
}

.rts-trending-news-section2 .section-inner .rts-post-small .contents-wrapper .contents .read-more:hover::before {
  width: 100%;
  background: var(--theme-color);
}

.rts-trending-news-section2 .section-inner .premier-league-area {
  border: 2px solid #f6f6f6;
  background: #fff;
  position: relative;
  border-radius: 0;
  margin-bottom: 30px;
  overflow: hidden;
  z-index: 1;
}

.rts-trending-news-section2 .section-inner .premier-league-area::before {
  content: "";
  position: absolute;
  top: 80px;
  left: 0;
  width: 100%;
  height: 55px;
  background: #F5F5F5;
  z-index: -1;
}

.rts-trending-news-section2 .section-inner .premier-league-area .side-item-inner {
  padding: 30px;
}

.rts-trending-news-section2 .section-inner .premier-league-area .side-item-inner .side-content-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  padding-left: 50px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.rts-trending-news-section2 .section-inner .premier-league-area .side-item-inner .side-content-title::before {
  content: "";
  position: absolute;
  width: 25px;
  background-color: var(--theme-color);
  height: 3px;
  left: 0px;
  top: 50%;
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.rts-trending-news-section2 .section-inner .premier-league-area .side-item-inner .side-content-title::after {
  content: "";
  position: absolute;
  background-color: var(--theme-color);
  width: 12px;
  height: 3px;
  left: 30px;
  top: 50%;
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

@media (max-width: 768px) {
  .rts-trending-news-section2 .section-inner .premier-league-area .side-item-inner .side-content-title::after {
    right: 94%;
  }
}

.rts-trending-news-section2 .section-inner .premier-league-area .side-item-inner .table thead .head-tr {
  background: none;
}

.rts-trending-news-section2 .section-inner .premier-league-area .side-item-inner .table thead .head-tr th {
  text-align: center;
}

.rts-trending-news-section2 .section-inner .premier-league-area .side-item-inner .table tbody tr:first-child {
  border-style: solid;
}

.rts-trending-news-section2 .section-inner .premier-league-area .side-item-inner .table tbody tr td {
  padding: 10px 0 !important;
  text-align: center;
}

.rts-trending-news-section2 .section-inner .premier-league-area .side-item-inner .table tbody tr td .player-name-area .player-name {
  font-size: 14px;
  font-weight: 500;
}

.rts-trending-news-section2 .section-inner .premier-league-area .side-item-inner .table tbody tr td .position-number {
  font-size: 14px;
  color: #999999;
  font-weight: 400;
}

.rts-trending-news-section2 .section-inner .premier-league-area .side-item-inner .table-bordered> :not(caption)>* {
  border-top-width: 1px;
}

.rts-trending-news-section2 .section-inner .premier-league-area .side-item-inner .more-btn {
  font-size: 14px;
  width: 100%;
  height: 45px;
  line-height: 45px;
  padding: 0;
  border: none;
  border-radius: 0;
  font-weight: 500;
  color: #fff;
  background: #111111;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  text-transform: uppercase;
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  letter-spacing: 2px;
}

@media (max-width: 1100px) and (min-width: 900px) {
  .rts-trending-news-section2 .section-inner .premier-league-area .side-item-inner .more-btn {
    display: block;
  }
}

@media (max-width: 450px) {
  .rts-trending-news-section2 .section-inner .premier-league-area .side-item-inner .more-btn {
    display: block;
    margin: 0 auto;
  }
}

.rts-trending-news-section2 .section-inner .premier-league-area .side-item-inner .more-btn::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  background: var(--theme-color);
}

.rts-trending-news-section2 .section-inner .premier-league-area .side-item-inner .more-btn:hover {
  color: #fff;
}

.rts-trending-news-section2 .section-inner .premier-league-area .side-item-inner .more-btn:hover::before {
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}

.rts-match-highlights-section {
  /* background: url(../public/images/background/highlights-bg.jpg); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.rts-match-highlights-section .section-title-area .section-title {
  color: #fff;
  font-size: 30px;
}

.rts-match-highlights-section .match-highlights-inner .section-wraper {
  background: #1d1d1d;
  padding: 20px 20px 15px 20px;
}

@media (max-width: 991px) {
  .rts-match-highlights-section .match-highlights-inner .section-wraper {
    margin-bottom: 30px;
  }
}

.rts-match-highlights-section .match-highlights-inner .section-wraper .video-area {
  text-align: center;
  position: relative;
  overflow: hidden;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.rts-match-highlights-section .match-highlights-inner .section-wraper .video-area:hover .image {
  -webkit-transform: scale(110%);
  transform: scale(110%);
}

.rts-match-highlights-section .match-highlights-inner .section-wraper .video-area .image {
  -webkit-transform: scale(100%);
  transform: scale(100%);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.rts-match-highlights-section .match-highlights-inner .section-wraper .video-area .image a {
  width: 100%;
}

.rts-match-highlights-section .match-highlights-inner .section-wraper .video-area .image a img {
  width: 100%;
}

.rts-match-highlights-section .match-highlights-inner .section-wraper .video-area .video-section-inner {
  position: absolute;
  top: 40%;
  left: 50%;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
}

.rts-match-highlights-section .match-highlights-inner .section-wraper .video-area .video-section-inner .play-video .popup-video {
  width: 60px;
  height: 60px;
  line-height: 60px;
  font-size: 14px;
  text-align: center;
  border-radius: 50%;
  background: #1d1d1d;
  color: #fff;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.rts-match-highlights-section .match-highlights-inner .section-wraper .video-area .video-section-inner .play-video .popup-video:hover {
  background: var(--theme-color);
}

.rts-match-highlights-section .match-highlights-inner .section-wraper .video-area .time {
  position: absolute;
  background: var(--theme-color);
  bottom: 0;
  left: 0;
  padding: 0 10px 3px 10px;
}

.rts-match-highlights-section .match-highlights-inner .section-wraper .video-area .time a {
  font-size: 12px;
  font-weight: 400;
  color: #fff;
  font-family: "Roboto";
}

.rts-match-highlights-section .match-highlights-inner .section-wraper .video-area .time a i {
  margin-right: 2px;
}

.rts-match-highlights-section .match-highlights-inner .section-wraper .content {
  padding: 25px 0 0 0;
}

.rts-match-highlights-section .match-highlights-inner .section-wraper .content .title {
  border-bottom: 1px solid #282828;
  padding-bottom: 20px;
}

.rts-match-highlights-section .match-highlights-inner .section-wraper .content .title a {
  display: inline;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  color: #fff;
  background-size: 200% 2px;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, var(--theme-color)), color-stop(50%, transparent));
  background-image: linear-gradient(to right, var(--theme-color) 50%, transparent 50%);
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

.rts-match-highlights-section .match-highlights-inner .section-wraper .content .title a:hover {
  color: var(--theme-color);
  background-position: 0% 100%;
}

.rts-match-highlights-section .match-highlights-inner .section-wraper .content .date {
  font-size: 12px;
  color: #fff;
  font-family: "Roboto";
  font-weight: 400;
  padding-top: 10px;
}

.rts-features-section {
  padding-top: 30px;
}

.rts-features-section .features-section-inner .item {
  position: relative;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

@media (max-width: 991px) {
  .rts-features-section .features-section-inner .item {
    margin-bottom: 25px;
  }
}

.rts-features-section .features-section-inner .item:hover .image-section img {
  -webkit-transform: scale(110%);
  transform: scale(110%);
}

.rts-features-section .features-section-inner .item:hover .content .product-price .price {
  -webkit-transform: translateY(-30px);
  transform: translateY(-30px);
}

.rts-features-section .features-section-inner .item:hover .content .product-price .add-to-cart {
  -webkit-transform: translateY(-25px);
  transform: translateY(-25px);
}

.rts-features-section .features-section-inner .item .image-section {
  position: relative;
  overflow: hidden;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  width: 100%;
}

.rts-features-section .features-section-inner .item .image-section::before {
  content: "";
  background-color: rgb(1, 1, 1);
  opacity: 0.6;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.rts-features-section .features-section-inner .item .image-section img {
  -webkit-transform: scale(100%);
  transform: scale(100%);
  width: 100%;
}

.rts-features-section .features-section-inner .item .content {
  position: absolute;
  bottom: 15px;
  left: 35px;
  z-index: 1;
}

@media (max-width: 500px) {
  .rts-features-section .features-section-inner .item .content {
    left: 20px;
  }
}

.rts-features-section .features-section-inner .item .content .product-name {
  color: #fff;
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 10px;
}

@media (max-width: 1200px) and (min-width: 991px) {
  .rts-features-section .features-section-inner .item .content .product-name {
    font-size: 24px;
  }
}

@media (max-width: 450px) {
  .rts-features-section .features-section-inner .item .content .product-name {
    font-size: 24px;
  }
}

.rts-features-section .features-section-inner .item .content .product-price {
  display: -ms-inline-grid;
  display: inline-grid;
  overflow: hidden;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.rts-features-section .features-section-inner .item .content .product-price .price {
  background: var(--theme-color);
  color: #fff;
  font-weight: 400;
  width: 80px;
  text-align: center;
  padding: 3px 12px;
  -webkit-transform: translateY(5px);
  transform: translateY(5px);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.rts-features-section .features-section-inner .item .content .product-price .add-to-cart {
  background: var(--theme-color);
  color: #fff;
  font-weight: 400;
  padding: 3px 12px;
  -webkit-transform: translateY(30px);
  transform: translateY(30px);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.rts-counter-up-section {
  padding-bottom: 145px;
}

.rts-counter-up-section .counter-up-inner .count-up {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.rts-counter-up-section .counter-up-inner .count-up .wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 991px) {
  .rts-counter-up-section .counter-up-inner .count-up .wrapper {
    width: 50%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 80px;
  }
}

@media (max-width: 576px) {
  .rts-counter-up-section .counter-up-inner .count-up .wrapper {
    width: 100%;
  }
}

.rts-counter-up-section .counter-up-inner .count-up .wrapper .counter {
  font-size: 80px;
  font-family: "Oswald";
  font-weight: 600;
  margin-right: 15px;
  position: relative;
}

@media (max-width: 1200px) {
  .rts-counter-up-section .counter-up-inner .count-up .wrapper .counter {
    font-size: 50px;
  }
}

.rts-counter-up-section .counter-up-inner .count-up .wrapper .counter::before {
  position: absolute;
  content: "";
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-radius: 50%;
  opacity: 0.3;
  width: 165px;
  height: 165px;
  left: 50%;
  top: -15%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

@media (max-width: 1200px) {
  .rts-counter-up-section .counter-up-inner .count-up .wrapper .counter::before {
    width: 130px;
    height: 130px;
    top: -30%;
  }
}

.rts-counter-up-section .counter-up-inner .count-up .wrapper .counter-text {
  font-size: 24px;
  font-weight: 700;
}

#timeline {
  overflow: hidden;
  width: 100%;
}

#timeline div {
  height: 231px;
}

#timeline div .date {
  border-right: 1px rgba(179, 175, 175, 0.4) solid;
  text-align: right;
}

#timeline div .date:before {
  content: "";
  width: 12px;
  height: 12px;
  background: #e5e5d1;
  position: absolute;
  left: 99.2%;
  top: 88px;
  border-radius: 100%;
  cursor: pointer;
}

#timeline div.active .date::before {
  content: "";
  width: 20px;
  height: 20px;
  background: #0078FF;
  position: absolute;
  left: 98.7%;
  top: 98px;
  border-radius: 100%;
}

#timeline div .date h2 {
  margin: 72px 28px 0;
  font-size: 45px;
  font-weight: 600;
  cursor: pointer;
}

#timeline div.active .date h2 {
  font-size: 65px;
  color: #0078FF;
}

#timeline div .text p {
  margin: 85px 50px 0;
  width: 50%;
  display: none;
}

#timeline div.active .text p {
  display: inherit;
}

.rts-team-member-details {
  padding-bottom: 115px;
}

.rts-team-member-details .team-details-single {
  margin-bottom: 75px;
}

.rts-team-member-details .team-details-single:last-child {
  margin-bottom: 0;
}

.rts-team-member-details .team-details-single .title {
  position: relative;
  margin: 0 0 32px 0;
  padding: 0 0 20px 30px;
  font-size: 24px;
  font-weight: 600;
  border-bottom: 1px solid #e2e6e8;
}

.rts-team-member-details .team-details-single .title::before {
  position: absolute;
  content: "";
  left: 0;
  top: calc(50% - 10px);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 20px;
  height: 2px;
  background: var(--theme-color);
}

.rts-team-member-details .team-details-single .team-picture {
  height: 250px;
  overflow: hidden;
}

@media (max-width: 991px) {
  .rts-team-member-details .team-details-single .team-picture {
    height: auto;
    margin-bottom: 30px;
  }
}

.rts-team-member-details .team-details-single .team-picture .img {
  width: 100%;
}

.rts-team-member-details .team-details-single .team-status-area .status-box {
  background: none;
  border-radius: 0;
}

.rts-team-member-details .team-details-single .team-status-area .status-box .box-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}

.rts-team-member-details .team-details-single .team-status-area .status-box .status-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 19px 0;
  border-bottom: 1px solid #e2e6e8;
}

.rts-team-member-details .team-details-single .team-status-area .status-box .status-item:first-child {
  padding-top: 0;
}

.rts-team-member-details .team-details-single .team-status-area .status-box .status-item:last-child {
  border: none;
  padding-bottom: 0;
}

.rts-team-member-details .team-details-single .team-status-area .status-box .status-item .status-name {
  font-size: 18px;
  line-height: 35px;
  color: #5b5f64;
  font-weight: 600;
}

.rts-team-member-details .team-details-single .team-status-area .status-box .status-item .status-number {
  font-size: 16px;
  color: #999999;
  font-weight: 400;
  font-family: "Roboto";
}

.rts-team-member-details .team-details-single .team-status-area .status-box .status-item .status-number.club {
  color: var(--theme-color);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.rts-team-member-details .team-details-single .team-status-area .status-box .status-item .status-number.club:hover {
  color: #999999;
}

.rts-team-member-details .team-details-single .team-status-area .status-percentage {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .rts-team-member-details .team-details-single .team-status-area .status-percentage {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
}

.rts-team-member-details .team-details-single .team-status-area .status-percentage .sp-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.rts-team-member-details .team-details-single .team-status-area .status-percentage .sp-item .per {
  font-size: 24px;
  font-weight: 700;
  color: #111111;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #fff;
  margin-bottom: 10px;
}

.rts-team-member-details .team-details-single .team-status-area .status-percentage .sp-item .per span {
  font-size: 14px;
  font-weight: 500;
  color: var(--theme-color);
  -webkit-transform: translateY(5px);
  transform: translateY(5px);
}

.rts-team-member-details .team-details-single .team-status-area .status-percentage .sp-item .title {
  font-size: 12px;
  letter-spacing: 1px;
  color: #111111;
  font-weight: 600;
  text-align: center;
}

.rts-point-table-section.inner .table-bordered> :not(caption)>* {
  border-width: 0;
}

.rts-point-table-section.inner .table-full .two td {
  border: none;
}

.rts-point-table-section.inner .table-area .head-tr.two th {
  border: none;
}

.rts-point-table-section.inner table tr:nth-child(odd) td {
  background: #fbfbfb;
}

.rts-point-table-section.inner .table-full.inner {
  -webkit-filter: none;
  filter: none;
}

/**------------------------------------------------------------------
 * Footer Start Here
 */
.footer {
  background-color: black;
}

.footer.inner {
  background: #151515;
}

.footer .footer-inner {
  padding: 120px 0 105px 0;
}

@media (max-width: 768px) {
  .footer .footer-inner {
    padding: 40px 0 0 0;
  }
}

.footer .footer-logo {
  margin-bottom: 20px;
}

.footer .footer-text {
  max-width: 350px;
  color: white;
  opacity: 0.9;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 25px;
  font-family: "Roboto", sans-serif;
}

.footer .find-us {
  color: #fff;
  margin-bottom: 10px;
  display: inline-block;
}

.footer .find-us i {
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateX(-5px);
  transform: translateX(-5px);
  margin-left: 5px;
  -webkit-transition: 500ms;
  transition: 500ms;
}

.footer .find-us:hover {
  color: #FF1856;
}

.footer .find-us:hover i {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  color: #fff;
}

.footer .social-links .platform {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #1d1d1d;
  margin-right: 10px;
  -webkit-transition: background 400ms;
  transition: background 400ms;
}

.footer .social-links .platform i {
  color: #fff;
}

.footer .social-links .platform:hover {
  background: #02B140;
}

.footer .footer-widget-title {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  margin-bottom: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.footer .footer-widget-title .decorator {
  width: 20px;
  height: 2px;
  background: #02B23D;
  margin: auto 0;
  margin-right: 20px;
}

.footer .widget-list-item {
  margin-bottom: 10px;
}

.footer .widget-list-item a {
  position: relative;
  color: white;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  -webkit-transition: all 200ms;
  transition: all 200ms;
}

.footer .widget-list-item a:hover {
  color: #02B140;
}

.footer .widget-list-item a:hover::before {
  background: #1D72FF;
}

.footer .cata-widget {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  max-height: 220px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.footer .cata-widget .widget-list-item {
  padding-left: 15px;
  position: relative;
  font-family: "Roboto";
}

.footer .cata-widget .widget-list-item a {
  font-size: 14px;
  font-weight: 400;
}

.footer .cata-widget .widget-list-item:hover::before {
  background: #fff;
}

.footer .cata-widget .widget-list-item::before {
  content: "";
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #888888;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.footer .address-widget .widget-list-item {
  max-width: 225px;
  border-bottom: 1px solid #212121;
  margin-bottom: 15px;
  padding-bottom: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.footer .address-widget .widget-list-item a,
.footer .address-widget .widget-list-item span {
  color: #fff;
  font-weight: 400;
}

.footer .address-widget .widget-list-item i {
  margin-right: 15px;
  color: #02B23D;
  font-size: 17px;
  -webkit-transform: translateY(7px);
  transform: translateY(7px);
}

.footer .news-widget .recent-post {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.footer .news-widget .recent-post .picture {
  margin-right: 15px;
  overflow: hidden;
}

.footer .news-widget .recent-post .news-date {
  font-size: 14px;
  font-weight: 300;
  color: #7b7b7b;
  font-family: "Roboto", sans-serif;
  -webkit-transform: translateY(-8px);
  transform: translateY(-8px);
}

.footer .news-widget .recent-post .recent-post-title {
  display: block;
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  color: #fff;
}

.footer .news-widget .recent-post .recent-post-title:hover {
  color: var(--theme-color);
}

.footer .footer-bottom-area {
  padding: 25px 0;
  background: black;
}

.footer .footer-bottom-area .bottom-area-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.footer .footer-bottom-area .copyright {
  color: #8b8b8b;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
}

.footer .footer-bottom-area .copyright .brand {
  color: #fff;
}

.footer .footer-bottom-area .footer-bottom-links a {
  color: #8b8b8b;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  margin-right: 40px;
}

.footer .footer-bottom-area .footer-bottom-links a:last-child {
  margin-right: 0;
}

.footer .footer-bottom-area .footer-bottom-links a:hover {
  color: var(--theme-color);
}

.footer .footer-bottom-area .footer-bottom-links .powered-product {
  color: #fff;
}

.footer .footer-bottom-area.area3 {
  background: #1d1d1d;
}

@media (max-width: 1200px) {
  .footer .footer-widget {
    margin-bottom: 40px;
  }

  .footer .footer-widget .footer-widget-title {
    margin-bottom: 30px;
    font-size: 18px;
  }

  .footer .footer-widget .footer-widget-title .decorator {
    width: 15px;
    margin-right: 12px;
  }

  .footer .footer-widget .widget-list-item {
    font-size: 15px;
    margin-bottom: 7px;
  }

  .footer .footer-widget.address-widget .widget-list-item {
    padding-bottom: 8px;
    margin-bottom: 8px;
  }

  .footer .footer-widget.news-widget a {
    font-size: 15px;
    font-weight: 500;
  }

  .footer .footer-widget .footer-text {
    max-width: 333px;
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .footer .footer-bottom-area .bottom-area-inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .footer .footer-bottom-area .copyright {
    text-align: center;
    line-height: 40px;
    margin-bottom: 20px;
    font-size: 14px;
    margin-bottom: 0;
  }

  .footer .footer-bottom-area .footer-bottom-links a {
    font-size: 14px;
    margin-right: 15px;
  }
}

.footer2 .footer-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 55px 0;
  margin-bottom: -7px;
}

.footer2 .footer-inner .footer-widget .social-links {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}

.footer2 .footer-logo {
  margin-bottom: 0;
}

@media (max-width: 1200px) {
  .footer2 .footer-inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .footer2 .footer-inner .footer-widget {
    margin-bottom: 0;
  }
}

@media (max-width: 768px) {
  .footer2 .footer-inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .footer2 .footer-inner .footer-logo {
    margin-bottom: 25px;
    margin-right: 15px;
  }
}

.footer3 {
  background: #151515;
  position: relative;
}

.footer3 .footer-topbar {
  position: relative;
  top: -47px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: var(--theme-color);
  border-radius: 6px;
  padding: 20px 30px;
}

.footer3 .footer-topbar .footer-logo {
  margin-bottom: 0;
}

.footer3 .footer-topbar .social-links {
  margin-top: 0;
}

.footer3 .footer-topbar .social-links .platform {
  background: #bc1b23;
  border: none;
}

.footer3 .footer-topbar .social-links .platform:hover {
  background: #fff;
}

.footer3 .footer-topbar .social-links .platform:hover i {
  color: var(--theme-color);
}

.footer3 .footer-inner {
  padding: 55px 0;
  padding-bottom: 95px;
}

@media (max-width: 991px) {
  .footer3 .footer-inner {
    padding-bottom: 0;
  }
}

.footer3 .footer-inner .footer-widget .footer-widget-title {
  color: #fff;
}

.footer3 .footer-inner .footer-widget .widget-list-item a {
  color: #bcbcbc;
}

.footer3 .footer-inner .footer-widget .widget-list-item a:hover {
  color: #fff;
}

.footer3 .footer-inner .footer-widget .footer-text {
  color: #777777;
}

.footer3 .footer-inner .footer-widget .submit-email .input-div {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
  border-radius: 6px;
  overflow: hidden;
}

.footer3 .footer-inner .footer-widget .submit-email .input-div .input-envolope-icon {
  position: absolute;
  top: 9px;
  left: 20px;
  color: var(--theme-color);
}

.footer3 .footer-inner .footer-widget .submit-email .input-div input {
  padding: 10px 20px;
  padding-left: 42px;
}

.footer3 .footer-inner .footer-widget .submit-email .input-div input:-moz-placeholder-shown {
  color: #cecdcd;
}

.footer3 .footer-inner .footer-widget .submit-email .input-div input:-ms-input-placeholder {
  color: #cecdcd;
}

.footer3 .footer-inner .footer-widget .submit-email .input-div input:placeholder-shown {
  color: #cecdcd;
}

.footer3 .footer-inner .footer-widget .submit-email .input-div .input-send-icon {
  width: 50px;
  height: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  background: #111111;
  -webkit-transition: background 400ms;
  transition: background 400ms;
}

.footer3 .footer-inner .footer-widget .submit-email .input-div .input-send-icon:hover {
  background: var(--theme-color);
}

@media (max-width: 1200px) {
  .footer3 .footer-inner .social-links {
    margin-bottom: 40px;
  }
}

.footer3 .footer-inner .address-widget .widget-list-item {
  border-bottom: 1px solid #212121;
}

.footer3 .footer-inner .address-widget .widget-list-item a {
  color: #bcbcbc;
}

.footer3 .footer-inner .address-widget .widget-list-item:last-child {
  border-bottom: none;
}

.footer3 .footer-inner .address-widget .widget-list-item a,
.footer3 .footer-inner .address-widget .widget-list-item span {
  color: #bcbcbc;
  font-weight: 500;
}

.footer3 .footer-inner .news-widget .recent-post {
  padding-bottom: 13px;
  margin-bottom: 5px;
}

.footer3 .footer-inner .news-widget .recent-post:last-child {
  border-bottom: none;
}

.footer3 .footer-inner .news-widget .recent-post .recent-post-title {
  color: #111111;
}

@media (max-width: 560px) {
  .footer3 .footer-topbar {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .footer3 .footer-topbar .footer-logo {
    margin-bottom: 20px;
  }

  .footer3 .footer-topbar .social-links {
    margin-right: 0;
  }

  .footer3 .footer-topbar .social-links li:last-child a {
    margin-right: 0;
  }
}

.footer-2 {
  background: #F4F6F9;
}

.footer-2 .widget-text {
  color: #777777;
}

.footer-2 .footer-widget-title {
  color: #040404;
}

.footer-2 .widget-list-item a {
  color: #7A7A7A;
}

.footer-2 .widget-list-item a:hover {
  color: #040404;
}

.footer-2 .quick-contact .contact-info .title {
  color: #868686;
  font-weight: 400;
}

.footer-2 .quick-contact .contact-info .info {
  color: #040404;
}

.footer-2 .social-links-footer .platform {
  color: #777777;
}

.footer-2 .social-links-footer .platform i {
  color: #040404;
}

.footer-2 .footer-bottom-area {
  background: #F5F5F5;
}

.footer-2 .footer-bottom-area .copyright {
  color: #777777;
  text-align: center;
  font-size: 15px;
  line-height: 25px;
}

.footer-2 .footer-bottom-area .copyright .brand,
.footer-2 .footer-bottom-area .copyright .site {
  color: #040404;
}

.footer-features {
  width: 100%;
  display: inline-block;
  padding: 17px 30px;
  background: #fff;
  border-radius: 6px;
  margin-top: 80px;
  margin-bottom: 4px;
}

.footer-features .feature-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-right: 2px solid #F7F7F7;
}

.footer-features .feature-item.last-child {
  border: none;
}

.footer-features .feature-item .icon {
  max-width: 35px;
  margin-right: 15px;
}

.footer-features .feature-item .content .title {
  font-size: 18px;
  line-height: 24px;
}

.footer-features .feature-item .content p {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #777777;
}

@media (max-width: 1200px) {
  .footer-features .feature-item {
    margin-bottom: 25px;
    border: none;
  }

  .footer-features .feature-item.last-child {
    margin-bottom: 0;
  }
}

.footer-3 {
  position: relative;
  padding-top: 80px;
  padding-bottom: 0;
}

.footer-3 .mr--17 {
  margin-right: 17px;
}

.footer-3 .footer-inner {
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: -76px;
}

.footer-3 .box-widget-col {
  margin-right: 40px;
}

.footer-3 .footer-box-widget {
  background: #fff;
  padding: 20px 40px;
  padding-top: 50px;
  border-radius: 6px;
  overflow: hidden;
}

.footer-3 .footer-box-widget .footer-logo {
  margin-bottom: 15px;
}

.footer-3 .footer-box-widget p {
  font-size: 14px;
  padding-bottom: 25px;
  margin-bottom: 30px;
  border-bottom: 1px solid #E7E7E7;
}

.footer-3 .footer-box-widget .quick-contact {
  margin-bottom: 0;
}

.footer-3 .footer-box-widget .social-links-footer2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  max-width: 250px;
}

.footer-3 .footer-box-widget .social-links-footer2 .platform {
  position: relative;
  bottom: -32px;
  width: 50px;
  height: 60px;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding-top: 17px;
  -webkit-transition: bottom 500ms;
  transition: bottom 500ms;
}

.footer-3 .footer-box-widget .social-links-footer2 .platform.fb {
  background: #e7f1fe;
}

.footer-3 .footer-box-widget .social-links-footer2 .platform.fb i {
  color: #1877f2;
}

.footer-3 .footer-box-widget .social-links-footer2 .platform.yt {
  background: #e8f5fe;
}

.footer-3 .footer-box-widget .social-links-footer2 .platform.yt i {
  color: #cd201f;
}

.footer-3 .footer-box-widget .social-links-footer2 .platform.ttr {
  background: #e8f5fe;
}

.footer-3 .footer-box-widget .social-links-footer2 .platform.ttr i {
  color: #1da1f2;
}

.footer-3 .footer-box-widget .social-links-footer2 .platform.lkd {
  background: #e6eff9;
}

.footer-3 .footer-box-widget .social-links-footer2 .platform.lkd i {
  color: #0a66c2;
}

.footer-3 .footer-box-widget .social-links-footer2 .platform:hover {
  bottom: -22px;
  background: var(--theme-color);
}

.footer-3 .footer-box-widget .social-links-footer2 .platform:hover i {
  color: #fff;
}

.footer-3 .widget-text {
  font-size: 18px;
  line-height: 30px;
}

.footer-3 .widget-list-item {
  padding-left: 0;
}

.footer-3 .getin-touch {
  font-size: 14px;
}

.footer-3 .getin-touch i {
  margin-left: 5px;
}

.footer-3 .getin-touch:hover {
  color: var(--theme-color);
}

.footer-3 .getin-touch:hover i {
  -webkit-transform: translateX(5px);
  transform: translateX(5px);
}

.footer-3 .newsletter-widget .widget-text {
  font-size: 14px;
  margin-bottom: 10px;
}

.footer-3 .newsletter-widget .input-div {
  margin-bottom: 10px;
}

.footer-3 .newsletter-widget input {
  width: 100%;
  padding: 10px 30px;
  border-radius: 5px;
}

.footer-3 .newsletter-widget input::-webkit-input-placeholder {
  font-size: 14px;
}

.footer-3 .newsletter-widget .subscribe-btn {
  padding: 10px 30px;
  color: #fff;
  background: #985034;
  font-size: 14px;
  border-radius: 6px;
  -webkit-transition: background 500ms;
  transition: background 500ms;
}

.footer-3 .newsletter-widget .subscribe-btn:hover {
  background: #040404;
}

.footer-3 .newsletter-widget .subscribe-btn i {
  margin-left: 5px;
}

.footer-3 .footer-bottombar {
  position: relative;
  left: 28%;
  bottom: 81px;
  background: #fff;
  padding: 20px 40px;
  border-radius: 6px;
  padding-right: 35px;
  width: 72%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.footer-3 .footer-bottombar .download-text {
  margin-right: 15px;
}

.footer-3 .footer-bottombar .download-store {
  margin-right: 5px;
}

@media (max-width: 1360px) {
  .footer-3 .footer-bottombar {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .footer-3 .footer-bottombar .app-download {
    margin-bottom: 30px;
  }
}

@media (max-width: 1200px) {
  .footer-3 .footer-bottombar {
    display: none;
  }
}

.footer-3 .footer-bottom-area .footer-bottom-inner {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media (max-width: 1200px) {
  .footer-3 .footer-inner {
    margin-bottom: 0;
  }

  .footer-3 .box-widget-col {
    margin-right: 0;
  }

  .footer-3 .footer-box-widget {
    margin-bottom: 40px;
  }
}

@media (max-width: 1200px) {
  .footer-3 .footer-bottom-area .copyright {
    margin-bottom: 0;
  }
}

.footer-4 {
  background: #031424;
}

.footer-4 .footer-inner {
  margin-bottom: -68px;
}

.footer-4 .footer-box-widget {
  background: #06192a;
}

.footer-4 .footer-box-widget p {
  color: #82919f;
  border-bottom: 1px solid #11283d;
}

.footer-4 .footer-box-widget .social-links-footer2 li .platform {
  background: #102232 !important;
}

.footer-4 .footer-box-widget .social-links-footer2 li .platform:hover {
  background: var(--theme-color) !important;
}

.footer-4 .footer-box-widget .social-links-footer2 li .platform:hover i {
  color: #111111 !important;
}

.footer-4 .widget-text {
  color: #82919f;
}

.footer-4 .getin-touch {
  color: #fff;
}

.footer-4 .widget-list-item a {
  color: #82919f;
}

.footer-4 .newsletter-widget input {
  background: #06192a;
}

.footer-4 .newsletter-widget input::-webkit-input-placeholder {
  color: #395269;
}

.footer-4 .newsletter-widget .subscribe-btn {
  background: var(--theme-color);
  color: #031424;
  border: 1px solid transparent;
}

.footer-4 .newsletter-widget .subscribe-btn:hover {
  background: #031424;
  color: #fff;
  border: 1px solid var(--theme-color);
}

.footer-4 .footer-bottombar {
  background: var(--theme-color);
}

.footer-4 .footer-bottombar .payment-methods img {
  -webkit-filter: brightness(0);
  filter: brightness(0);
}

.footer-4 .footer-bottom-area {
  background: transparent;
}

@media (max-width: 1200px) {
  .footer-4 .footer-inner {
    margin-bottom: 0;
  }
}

.footer-5 {
  background: #fff;
}

.footer-5 .footer-features {
  margin-top: 0;
  margin-bottom: 60px;
}

.footer-5 .footer-box-widget {
  background: none;
  padding: 0;
  padding-bottom: 80px;
}

.footer-5 .quick-contact {
  margin-bottom: 20px !important;
}

.footer-5 .social-links-footer2.social-links-footer3 {
  position: relative;
}

.footer-5 .social-links-footer2.social-links-footer3 .platform {
  bottom: -20px;
  height: 50px;
  border-radius: 50%;
}

.footer-5 .social-links-footer2.social-links-footer3 .platform:hover {
  -webkit-transform: scale(105%);
  transform: scale(105%);
  bottom: -20px;
}

.footer-5 .newsletter-widget input {
  background: rgb(245, 245, 245);
}

.footer-5 .newsletter-widget .subscribe-btn {
  background: #d51243;
}

.footer-5 .footer-bottombar {
  border-top: 1px solid #e3e3e3;
  border-radius: 0;
  bottom: 112px;
}

.inner-page-footer .social-links-footer .platform:hover {
  color: #fff;
}

.inner-page-footer .quick-contact .contact-item .info:hover {
  color: #111111;
}

.inner-page-footer .footer-bottom-area {
  background: rgb(9, 9, 9);
}

/**------------------------------------------------------------------
 * Footer End Here
 */
.rts-post-area .rts-post-wide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
  background: #1d1d1d;
  position: relative;
  border-radius: 0;
  overflow: hidden;
  padding: 30px 20px;
}

.rts-post-area .rts-post-wide .gallery-picture {
  overflow: hidden;
}

.rts-post-area .rts-post-wide .gallery-picture:hover img {
  -webkit-transform: scale(110%);
  transform: scale(110%);
}

.rts-post-area .rts-post-wide .gallery-picture img {
  -webkit-transform: scale(100%);
  transform: scale(100%);
}

.rts-post-area .rts-post-wide .contents-wrapper {
  margin-left: 20px;
}

.rts-post-area .rts-post-wide .contents-wrapper .contents .heading {
  margin-bottom: 10px;
}

.rts-post-area .rts-post-wide .contents-wrapper .contents .heading .tag {
  background: var(--theme-color);
  font-size: 12px !important;
  color: #fff;
  padding: 0 5px;
}

.rts-post-area .rts-post-wide .contents-wrapper .contents .heading .blog-date {
  font-size: 12px;
  color: #fff;
  font-weight: 400;
  margin-left: 20px;
  font-family: "Roboto";
}

.rts-post-area .rts-post-wide .contents-wrapper .contents .gallery-title {
  margin-bottom: 15px;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.rts-post-area .rts-post-wide .contents-wrapper .contents .gallery-title:hover {
  color: var(--theme-color);
}

.rts-post-area .rts-post-wide .contents-wrapper .contents .author-info .content .read-more {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.rts-post-area .rts-post-wide .contents-wrapper .contents .author-info .content .read-more::before {
  position: absolute;
  content: "";
  width: 50%;
  height: 1px;
  background: #fff;
  left: 0;
  bottom: -5px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.rts-post-area .rts-post-wide .contents-wrapper .contents .author-info .content .read-more:hover {
  color: var(--theme-color);
}

.rts-post-area .rts-post-wide .contents-wrapper .contents .author-info .content .read-more:hover::before {
  width: 100%;
  background: var(--theme-color);
}

.rts-post-area .rts-post-video {
  border: 2px solid #f6f6f6;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
}

.rts-post-area .rts-post-video .play-btn .popup-video {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 700ms;
  transition: all 700ms;
}

.rts-post-area .rts-post-video .play-btn .popup-video:hover {
  background: var(--theme-color);
}

.rts-post-area .rts-post-video .play-btn .popup-video:hover i {
  color: #fff;
}

.rts-post-area .rts-post-video .play-btn .popup-video i {
  color: #111111;
}

.rts-post-area .rts-post-video .post-picture img {
  -webkit-transition: all 700ms;
  transition: all 700ms;
}

.rts-post-area .rts-post-video .contents {
  padding: 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.rts-post-area .rts-post-video .contents .blog-catagory-tag {
  font-size: 12px;
  color: #fff;
  font-weight: 600;
  background: var(--theme-color);
  padding: 2px 10px;
  border-radius: 0;
  margin-bottom: 7px;
  margin-right: auto;
  display: inline;
}

.rts-post-area .rts-post-video .contents .post-title {
  font-size: 36px;
  letter-spacing: -1px;
  line-height: 46px;
  color: #111111;
  font-weight: 600;
  display: inline;
}

.rts-post-area .rts-post-video .contents .post-title a {
  display: inline;
}

.rts-post-area .rts-post-video .video-area {
  position: relative;
  width: 100%;
}

.rts-post-area .rts-post-video .video-area .slider-navigation {
  width: unset !important;
  left: unset;
  bottom: -30px;
  right: 35px;
}

.rts-post-area .rts-post-video .video-area .slider-navigation .slide-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 60px;
  height: 60px;
  border-radius: 6px;
  margin-right: 15px;
  -webkit-transition: background 400ms;
  transition: background 400ms;
  background: #fff;
  -webkit-filter: drop-shadow(0px 16px 16px rgba(0, 0, 0, 0.06));
  filter: drop-shadow(0px 16px 16px rgba(0, 0, 0, 0.06));
}

.rts-post-area .rts-post-video .video-area .slider-navigation .slide-btn i {
  color: #111111;
  margin: auto;
}

.rts-post-area .rts-post-video .video-area .slider-navigation .slide-btn:hover {
  background: var(--theme-color);
}

.rts-post-area .rts-post-video .video-area .slider-navigation .slide-btn:hover i {
  color: #fff;
}

@media (max-width: 1200px) {
  .rts-post-area .rts-post-video .post-picture a {
    width: 100%;
  }

  .rts-post-area .rts-post-video .contents .post-title {
    font-size: 30px;
    line-height: 40px;
  }

  .rts-post-area .rts-post-video .slider-navigation {
    position: absolute;
  }
}

@media (max-width: 768px) {
  .rts-post-area .rts-post-video .play-btn .popup-video {
    width: 50px;
    height: 50px;
  }

  .rts-post-area .rts-post-video .play-btn .popup-video:hover {
    background: var(--theme-color);
  }

  .rts-post-area .rts-post-video .play-btn .popup-video:hover i {
    color: #fff;
  }

  .rts-post-area .rts-post-video .play-btn .popup-video i {
    color: #111111;
  }

  .rts-post-area .rts-post-video .contents {
    padding: 20px;
  }

  .rts-post-area .rts-post-video .contents .post-title {
    font-size: 21px;
    line-height: 31px;
  }

  .rts-post-area .rts-post-video .video-area .slider-navigation {
    bottom: -10px;
    right: -40px;
  }

  .rts-post-area .rts-post-video .video-area .slider-navigation .slide-btn {
    width: 50px;
    height: 50px;
  }
}

@media (max-width: 440px) {
  .rts-post-area .rts-post-video .contents {
    padding: 20px;
  }

  .rts-post-area .rts-post-video .contents .post-title {
    font-size: 18px;
    line-height: 28px;
  }

  .rts-post-area .rts-post-video .video-area .slider-navigation {
    bottom: -10px;
    right: -60px;
  }

  .rts-post-area .rts-post-video .video-area .slider-navigation .slide-btn {
    width: 45px;
    height: 45px;
  }
}

.rts-blog-medium {
  position: relative;
  border: 2px solid #f6f6f6;
  border-radius: 6px;
  margin-bottom: 15px;
}

.rts-blog-medium:hover .blog-picture img {
  -webkit-transform: scale(110%);
  transform: scale(110%);
}

.rts-blog-medium .blog-picture {
  position: relative;
  overflow: hidden;
  margin-bottom: 0;
}

.rts-blog-medium .blog-picture img {
  -webkit-transition: -webkit-transform 400ms;
  transition: -webkit-transform 400ms;
  transition: transform 400ms;
  transition: transform 400ms, -webkit-transform 400ms;
}

.rts-blog-medium .blog-catagory {
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 12px;
  font-weight: 600;
  padding: 3px 10px;
  border-radius: 0;
  background: var(--theme-color);
  color: #fff;
  display: inline-block;
}

.rts-blog-medium .contents {
  padding: 35px;
  z-index: 2;
}

.rts-blog-medium .contents .blog-title {
  font-size: 20px;
  letter-spacing: -1px;
  line-height: 30px;
  color: #111111;
  font-weight: 600;
  max-width: 310px;
  display: block;
  margin-bottom: 20px;
}

.rts-blog-medium .contents .blog-title:hover {
  color: var(--theme-color);
}

.rts-blog-medium .contents .author-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.rts-blog-medium .contents .author-info .author-dp {
  margin-right: 10px;
}

.rts-blog-medium .contents .author-info .author-name {
  font-size: 16px;
  line-height: 20px;
  color: #111111;
  font-weight: 600;
}

.rts-blog-medium .contents .author-info .blog-date {
  font-size: 14px;
  font-weight: 400;
  color: #c4c4c4;
  font-family: "Roboto";
}

@media (max-width: 1200px) {
  .rts-blog-medium .blog-picture {
    width: 100%;
  }

  .rts-blog-medium .blog-picture img {
    width: 100%;
  }
}

@media (max-width: 470px) {
  .rts-blog-medium .contents {
    padding: 20px;
  }

  .rts-blog-medium .contents .blog-title {
    font-size: 18px;
    line-height: 28px;
    font-weight: 500;
    margin-bottom: 20px;
  }
}

.banner-blog-area {
  position: absolute;
  bottom: -65px;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 1;
}

.rts-blog-small {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: var(--theme-color);
  border-radius: 6px;
  -webkit-transition: -webkit-transform 500ms ease;
  transition: -webkit-transform 500ms ease;
  transition: transform 500ms ease;
  transition: transform 500ms ease, -webkit-transform 500ms ease;
}

.rts-blog-small:hover {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}

.rts-blog-small .content {
  padding: 0 30px;
}

.rts-blog-small .blog-catagory {
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  font-weight: 300;
  font-family: "Roboto";
}

.rts-blog-small .blog-title {
  font-size: 20px;
  letter-spacing: -1px;
  line-height: 30px;
  color: #fff;
  font-weight: 500;
  max-width: 135px;
}

.rts-news-card {
  position: relative;
  border-radius: 6px;
  border: 2px solid #f6f6f6;
  overflow: hidden;
  margin-bottom: 30px;
}

.rts-news-card:hover .post-picture img {
  -webkit-transform: scale(110%);
  transform: scale(110%);
}

.rts-news-card .post-picture {
  width: 100%;
  overflow: hidden;
}

.rts-news-card .post-picture img {
  width: 100%;
}

.rts-news-card .blog-catagory-tag {
  position: absolute;
  top: 20px;
  left: 20px;
}

.rts-news-card .contents {
  padding: 40px;
}

.rts-news-card .contents .post-date,
.rts-news-card .contents .post-by {
  font-size: 14px;
  font-weight: 400;
  font-family: "Roboto";
  color: #777777;
  padding-right: 14px;
  margin-right: 13px;
  position: relative;
  margin-bottom: 10px;
}

.rts-news-card .contents .post-date i {
  margin-right: 5px;
  color: #111111;
}

.rts-news-card .contents .post-date::before {
  content: "";
  position: absolute;
  top: 5px;
  right: 0;
  height: 50%;
  width: 2px;
  background: #e9e9e9;
}

.rts-news-card .contents .post-by span {
  color: var(--theme-color);
}

.rts-news-card .contents .post-title {
  font-size: 24px;
  letter-spacing: -1px;
  line-height: 34px;
  font-weight: 600;
  margin-bottom: 20px;
}

.rts-news-card .contents .post-title:hover {
  color: var(--theme-color);
}

.rts-news-card .contents .read-more-btn {
  font-size: 16px;
  font-weight: 600;
  color: #bbbbbb;
}

.rts-news-card .contents .read-more-btn:hover {
  color: var(--theme-color);
}

.rts-news-card .contents .read-more-btn:hover i {
  -webkit-transform: translateX(5px);
  transform: translateX(5px);
}

.news-feed-section .news-left .feed-item2 {
  position: relative;
  overflow: hidden;
  margin-bottom: 35px;
}

.news-feed-section .news-left .feed-item2:hover .feed-image img {
  -webkit-transform: scale(110%);
  transform: scale(110%);
}

.news-feed-section .news-left .feed-item2:hover .feed-title a {
  text-decoration: underline;
}

.news-feed-section .news-left .feed-item2::before {
  content: "";
  position: absolute;
  top: 13%;
  right: -15px;
  width: 1px;
  height: 70%;
  background: #E8E8E8;
}

.news-feed-section .news-left .feed-item2.last-child::before {
  display: none;
}

@media (max-width: 1200px) {
  .news-feed-section .news-left .feed-item2 {
    margin-bottom: 30px;
  }

  .news-feed-section .news-left .feed-item2.last-child {
    margin-bottom: 0;
  }
}

@media (max-width: 768px) {
  .news-feed-section .news-left .feed-item2::before {
    display: none;
  }
}

.news-feed-section .news-left .feed-item2 .feed-image {
  margin-bottom: 30px;
  overflow: hidden;
  display: block;
}

.news-feed-section .news-left .feed-item2 .feed-image img {
  width: 100%;
}

.news-feed-section .news-left .feed-item2 .feed-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
}

.news-feed-section .news-left .feed-item2 .feed-date {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #777777;
  margin-right: 20px;
}

.news-feed-section .news-left .feed-item2 .feed-bar {
  color: #777777;
  font-weight: 400;
  font-size: 14px;
  margin-right: 20px;
}

.news-feed-section .news-left .feed-item2 .feed-catagory {
  font-size: 14px;
  line-height: 20px;
  color: #000;
}

.news-feed-section .news-left .feed-item2 .feed-title {
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 12px;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

.news-feed-section .news-left .feed-item2 p {
  color: #666666;
  font-size: 14px;
  margin-bottom: 20px;
}

.news-feed-section .news-left .feed-item2 .read-more {
  font-size: 14px;
  line-height: 20px;
  color: rgba(204, 204, 204, 0.8);
  text-decoration: underline;
}

.news-feed-section .news-left .feed-item2 .read-more:hover {
  color: var(--theme-color);
}

.news-feed-section .news-right-widget {
  position: sticky;
  top: 0;
}

.news-feed-section .news-right-widget .widget {
  padding: 0;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  padding-bottom: 40px;
}

.news-feed-section .news-right-widget .widget .widget-title-box {
  position: relative;
  z-index: 1;
}

.news-feed-section .news-right-widget .widget .widget-title-box::before {
  content: "";
  position: absolute;
  background: #e7e7e7;
  width: 100%;
  height: 1px;
  left: 0;
  bottom: 0;
  z-index: 1;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.news-feed-section .news-right-widget .widget .widget-title-box::after {
  content: "";
  position: absolute;
  background: var(--theme-color);
  width: 80px;
  height: 1px;
  left: 0;
  bottom: 0;
  z-index: 1;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.news-feed-section .news-right-widget .widget .widget-sub-title {
  font-size: 20px;
  letter-spacing: -1px;
  color: #111111;
  font-weight: 600;
  text-transform: uppercase;
}

.news-feed-section .news-right-widget .widget .widget-sub-title2 {
  font-size: 20px;
  letter-spacing: -1px;
  color: #111111;
  font-weight: 600;
  text-transform: uppercase;
  padding-bottom: 15px;
  margin-bottom: 40px;
}

.news-feed-section .news-right-widget .widget .widget-categories ul li a {
  color: #999999;
  font-size: 16px;
  padding: 15px 0;
  display: block;
  line-height: 1;
  font-weight: 700;
}

.news-feed-section .news-right-widget .widget .widget-categories ul li a:hover {
  text-decoration: underline;
}

.news-feed-section .news-right-widget .widget .widget-subscribe .contact-form textarea {
  height: 140px;
  padding-top: 15px;
}

.news-feed-section .news-right-widget .widget .widget-subscribe .contact-form .form-btn {
  width: 100%;
}

.news-feed-section .news-right-widget .widget .widget-post .blog-post {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-right: 58px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .news-feed-section .news-right-widget .widget .widget-post .blog-post {
    padding-right: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .news-feed-section .news-right-widget .widget .widget-post .blog-post {
    padding-right: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .news-feed-section .news-right-widget .widget .widget-post .blog-post {
    padding-right: 0;
  }
}

@media (max-width: 767px) {
  .news-feed-section .news-right-widget .widget .widget-post .blog-post {
    padding-right: 0;
  }
}

.news-feed-section .news-right-widget .widget .widget-post .blog-post img {
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

.news-feed-section .news-right-widget .widget .widget-post .blog-post .post-content {
  margin-left: 20px;
}

.news-feed-section .news-right-widget .widget .widget.widget-banner-add {
  border: 0;
  /* background: url(../public/img/services/banne-1.html) no-repeat; */
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 370px;
  padding: 0;
  position: relative;
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

.news-feed-section .news-right-widget .widget .widget.widget-banner-add::before {
  content: "";
  position: absolute;
  background: rgb(8, 61, 88);
  opacity: 0.902;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
}

.news-feed-section .news-right-widget .widget .widget.widget-banner-add .banner-logo {
  display: block;
  padding-bottom: 30px;
  margin-bottom: 28px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.102);
}

.news-feed-section .news-right-widget .widget .widget.widget-banner-add p {
  color: #adbbc2;
}

.news-feed-section .news-right-widget .widget .widget.widget-banner-add .theme_btn {
  padding-left: 50px;
  padding-right: 50px;
}

.news-feed-section .news-right-widget .widget .widget-author-content h5 {
  margin-bottom: 14px;
  margin-top: 22px;
}

.news-feed-section .news-right-widget .widget .widget-author-content p {
  font-size: 16px;
  line-height: 26px;
  color: #777777;
  font-weight: 400;
  max-width: 275px;
  margin-bottom: 20px;
}

.news-feed-section .news-right-widget .widget .widget-author-content .author__social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.news-feed-section .news-right-widget .widget .widget-author-content .author__social a {
  color: #d4d4d4;
  width: 30px;
  aspect-ratio: 1;
  display: -ms-grid;
  display: grid;
  place-items: center;
  border-radius: 50%;
  font-size: 14px;
  line-height: 1;
  margin: 0 5px;
}

.news-feed-section .news-right-widget .widget .widget-author-content .author__social a.fb {
  background: #1877f2;
}

.news-feed-section .news-right-widget .widget .widget-author-content .author__social a.ttr {
  background: #1da1f2;
}

.news-feed-section .news-right-widget .widget .widget-author-content .author__social a.lkdin {
  background: #1877f2;
}

.news-feed-section .news-right-widget .widget .widget-author-content .author__social a.ggl {
  background: #cd201f;
}

.news-feed-section .news-right-widget .widget .widget-author-content .author__social a:hover {
  color: var(--theme-color);
}

.news-feed-section .news-right-widget .widget.widget-search {
  padding-bottom: 45px;
}

.news-feed-section .news-right-widget .widget.widget-search .subscribe-form {
  position: relative;
  z-index: 1;
}

.news-feed-section .news-right-widget .widget.widget-search .subscribe-form input {
  color: #999999;
  height: 59px;
  width: 100%;
  padding-left: 22px;
  background: #fff;
  -webkit-box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
  border: 1px solid var(--theme-color);
}

.news-feed-section .news-right-widget .widget.widget-search .subscribe-form input::-webkit-input-placeholder {
  color: #999999;
  font-family: "Roboto";
}

.news-feed-section .news-right-widget .widget.widget-search .subscribe-form input::-moz-placeholder {
  color: #999999;
  font-family: "Roboto";
}

.news-feed-section .news-right-widget .widget.widget-search .subscribe-form input:-ms-input-placeholder {
  color: #999999;
  font-family: "Roboto";
}

.news-feed-section .news-right-widget .widget.widget-search .subscribe-form input::-ms-input-placeholder {
  color: #999999;
  font-family: "Roboto";
}

.news-feed-section .news-right-widget .widget.widget-search .subscribe-form input::placeholder {
  color: #999999;
  font-family: "Roboto";
}

.news-feed-section .news-right-widget .widget.widget-search .subscribe-form input::-moz-placeholder {
  color: #999999;
}

.news-feed-section .news-right-widget .widget.widget-search .subscribe-form input::-webkit-placeholder {
  color: #999999;
}

.news-feed-section .news-right-widget .widget.widget-search .subscribe-form input::-o-placeholder {
  color: #999999;
}

.news-feed-section .news-right-widget .widget.widget-search .subscribe-form .widget-btn {
  border: 0;
  background: var(--theme-color);
  width: 60px;
  padding: 17px 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.news-feed-section .news-right-widget .widget.widget-search .subscribe-form .widget-btn i {
  font-size: 14px;
  color: #fff;
}

.news-feed-section .news-right-widget .widget.widget-post {
  padding-bottom: 50px;
}

.news-feed-section .news-right-widget .widget.widget-post .post-list {
  padding: 0;
  margin: 0;
}

.news-feed-section .news-right-widget .widget.widget-post .post-list li {
  margin-bottom: 20px;
}

.news-feed-section .news-right-widget .widget.widget-post .post-list li:last-child {
  border-bottom: 0;
  margin-bottom: 10px;
}

.news-feed-section .news-right-widget .widget.widget-post .post-list li .blog-post {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

.news-feed-section .news-right-widget .widget.widget-post .post-list li .blog-post:hover h6 a {
  text-decoration: underline;
  color: var(--theme-color);
}

.news-feed-section .news-right-widget .widget.widget-post .post-list li .blog-post:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.news-feed-section .news-right-widget .widget.widget-post .post-list li .blog-post .post-content {
  padding-left: 20px;
}

.news-feed-section .news-right-widget .widget.widget-post .post-list li .blog-post .post-content h6 {
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 22px;
  color: #111111;
  font-weight: 600;
  text-transform: uppercase;
  max-width: 150px;
  margin-bottom: 7px;
}

.news-feed-section .news-right-widget .widget.widget-post .post-list li .blog-post .post-content span {
  font-size: 14px;
  color: #777777;
  font-weight: 300;
  font-family: "Roboto";
}

.news-feed-section .news-right-widget .widget.widget-post .post-list li .blog-post .post-content span i {
  color: #111111;
  margin-right: 2px;
}

.news-feed-section .news-right-widget .widget.widget-categories-list ul li:hover a i {
  color: #111111;
}

.news-feed-section .news-right-widget .widget.widget-categories-list ul li a {
  position: relative;
  width: 100%;
  display: block;
  font-family: "Roboto";
  font-size: 16px;
  line-height: 46px;
  color: #777777;
  font-weight: 400;
}

.news-feed-section .news-right-widget .widget.widget-categories-list ul li a i {
  font-size: 16px;
  color: #c5c5c5;
  margin-right: 7px;
}

.news-feed-section .news-right-widget .widget.widget-categories-list ul li a span {
  font-size: 14px;
  font-weight: 700;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 0 6px 6px 0;
  -webkit-border-radius: 0 6px 6px 0;
  -moz-border-radius: 0 6px 6px 0;
  -ms-border-radius: 0 6px 6px 0;
  -o-border-radius: 0 6px 6px 0;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
}

.news-feed-section .news-right-widget .widget.widget-categories-list ul li a:hover {
  color: #111111;
}

.news-feed-section .news-right-widget .widget .tag-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.news-feed-section .news-right-widget .widget .tag-list a {
  font-size: 12px;
  color: #111111;
  font-weight: 400;
  display: inline-block;
  font-family: "Roboto";
  background-color: #f4f4f4;
  padding: 5px 22px;
  margin-bottom: 10px;
  margin-right: 10px;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
  text-transform: uppercase;
}

.news-feed-section .news-right-widget .widget .tag-list a:hover {
  color: #fff;
  background: var(--theme-color);
}

.news-feed-section .news-right-widget .widget .tag-list a:hover {
  background: var(--theme-color);
}

.news-feed-section .news-right-widget .widget.widget-sponsors-ad {
  overflow: hidden;
  position: relative;
  padding-bottom: 0;
}

.news-feed-section .news-right-widget .widget.widget-sponsors-ad a {
  display: block;
}

.news-feed-section .news-right-widget .widget.widget-sponsors-ad .ad-thumb {
  width: 100%;
  position: relative;
}

.news-feed-section .news-right-widget .widget.widget-sponsors-ad .ad-thumb::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--theme-color);
  mix-blend-mode: color-burn;
}

.news-feed-section .news-right-widget .widget.widget-sponsors-ad .ad-thumb img {
  width: 100%;
}

.news-feed-section .news-right-widget .widget.widget-sponsors-ad .ad-tag {
  padding: 10px 30px;
  font-size: 18px;
  line-height: 30px;
  color: #ffffff;
  font-weight: 600;
  border-radius: 6px 0 0 0;
  position: absolute;
  bottom: 0;
  left: 0;
}

.news-feed-section .page-navigation {
  margin-top: 10px;
}

.news-feed-section .page-navigation .pagination {
  margin-bottom: 60px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media (max-width: 400px) {
  .news-feed-section .page-navigation .pagination {
    margin-bottom: 40px;
  }
}

.news-feed-section .page-navigation .pagination .page-item {
  margin-left: 15px;
}

.news-feed-section .page-navigation .pagination .page-item.active>a {
  color: #fff;
  background-color: #000;
}

.news-feed-section .page-navigation .pagination .page-item a {
  position: relative;
  display: block;
  color: #000;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  border-radius: 5px;
}

.news-feed-section .page-navigation .pagination .page-item a:hover {
  background-color: var(--theme-color);
  color: #fff;
}

.news-feed-section .page-navigation .pagination .page-item1 {
  margin-left: 15px;
}

@media (max-width: 450px) {
  .news-feed-section .page-navigation .pagination .page-item1 {
    display: none;
  }
}

.news-feed-section .page-navigation .pagination .page-item1 a {
  position: relative;
  display: block;
  color: #000;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  border-radius: 5px;
}

.news-feed-section .page-navigation .pagination .page-item1 a:hover {
  background-color: var(--theme-color);
  color: #fff;
}

.news-left2 .news-top .icon-text {
  margin-bottom: 30px;
}

@media (max-width: 450px) {
  .news-left2 .news-top .icon-text .viewers {
    display: block;
  }
}

.news-left2 .news-top .icon-text .viewers a {
  color: #666666;
  font-size: 14px;
  font-weight: 300;
  padding-right: 25px;
  line-height: 0;
  border-right: 2px solid #d4d4d4;
}

@media (max-width: 500px) {
  .news-left2 .news-top .icon-text .viewers a {
    padding: 0 10px;
  }
}

@media (max-width: 450px) {
  .news-left2 .news-top .icon-text .viewers a {
    border: none;
    padding-bottom: 10px;
  }
}

.news-left2 .news-top .icon-text .viewers a i {
  padding-right: 5px;
}

@media (max-width: 450px) {
  .news-left2 .news-top .icon-text .comment {
    display: block;
  }
}

.news-left2 .news-top .icon-text .comment a {
  font-size: 14px;
  color: #666666;
  font-weight: 300;
  line-height: 0;
  padding: 0 25px;
  border-right: 2px solid #d4d4d4;
}

@media (max-width: 500px) {
  .news-left2 .news-top .icon-text .comment a {
    padding: 0 10px;
  }
}

@media (max-width: 450px) {
  .news-left2 .news-top .icon-text .comment a {
    border: none;
    padding-bottom: 10px;
  }
}

.news-left2 .news-top .icon-text .comment a i {
  padding-right: 5px;
}

@media (max-width: 450px) {
  .news-left2 .news-top .icon-text .date {
    display: block;
  }
}

.news-left2 .news-top .icon-text .date a {
  font-size: 14px;
  color: #666666;
  font-weight: 300;
  padding-left: 25px;
}

@media (max-width: 500px) {
  .news-left2 .news-top .icon-text .date a {
    padding: 0 10px;
  }
}

.news-left2 .news-top .icon-text .date a i {
  padding-right: 5px;
}

@media (max-width: 768px) {
  .news-left2 .news-top .icon-text {
    margin-bottom: 15px;
  }
}

@media (max-width: 451px) {
  .news-left2 .news-top .icon-text span a {
    padding: 0 !important;
    margin-bottom: 10px;
  }
}

.news-left2 .news-top .description {
  color: #666666;
  font-size: 16px;
  line-height: 28px;
}

@media (max-width: 991px) {
  .news-left2 .news-top .image-section .image-1 {
    margin-bottom: 20px;
  }
}

.news-left2 .news-top .image-section img {
  border-radius: 5px;
}

.news-left2 .feature-section {
  padding-top: 60px;
}

.news-left2 .feature-section .section-title {
  font-size: 18px;
  font-weight: 500;
}

.news-left2 .feature-section .title-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .news-left2 .feature-section .title-inner {
    display: block;
  }
}

.news-left2 .feature-section .title-inner .sect-title {
  width: 50%;
  padding: 0 25px;
  margin-bottom: 35px;
  position: relative;
}

@media (max-width: 768px) {
  .news-left2 .feature-section .title-inner .sect-title {
    width: 100%;
  }
}

.news-left2 .feature-section .title-inner .sect-title::before {
  content: "";
  position: absolute;
  background: #000;
  width: 25px;
  height: 1px;
  left: 0;
  top: 12px;
  z-index: 1;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.news-left2 .feature-section .title-inner .sect-title h3 {
  color: #666666;
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  margin-left: 10px;
}

.news-left2 .quote-section {
  margin: 20px 0 40px 0;
  background-color: #000;
  border-radius: 7px;
  padding: 55px 100px;
}

@media (max-width: 450px) {
  .news-left2 .quote-section {
    padding: 40px 20px;
  }
}

.news-left2 .quote-section .icon {
  padding-bottom: 35px;
}

.news-left2 .quote-section .text h3 {
  color: #fff;
  font-size: 22px;
  font-weight: 200;
  line-height: 32px;
  padding-bottom: 20px;
}

@media (max-width: 450px) {
  .news-left2 .quote-section .text h3 {
    font-size: 16px;
  }
}

.news-left2 .quote-section .author2 .name {
  color: #fff;
  font-size: 14px;
  font-weight: 300;
}

.news-left2 .quote-section .author2 .intro {
  color: #fff;
  font-size: 14px;
  font-weight: 300;
}

.news-left2 .description2 {
  padding: 10px 0 30px 0;
  color: #666666;
  font-size: 16px;
  line-height: 28px;
}

.news-left2 .bottom-area {
  padding-top: 30px;
}

.news-left2 .bottom-area .blog-actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media (max-width: 500px) {
  .news-left2 .bottom-area .blog-actions {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .news-left2 .bottom-area .blog-actions .tag-area {
    margin-bottom: 20px;
    text-align: center;
  }

  .news-left2 .bottom-area .blog-actions .social-area {
    text-align: center;
  }

  .news-left2 .bottom-area .blog-actions .social-area .social-title h4 {
    margin-bottom: 10px;
  }

  .news-left2 .bottom-area .blog-actions .social-area li {
    padding-top: 0 !important;
  }

  .news-left2 .bottom-area .blog-actions .social-area li:last-child {
    padding-right: 0;
  }
}

.news-left2 .bottom-area .tag-area h3 {
  font-size: 20px;
  margin-bottom: 25px;
}

.news-left2 .bottom-area .tag-area .button-tag ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.news-left2 .bottom-area .tag-area .button-tag ul li {
  padding: 5px 15px;
  font-size: 12px;
  border: 1px solid #f4f4f4;
  margin-right: 5px;
  font-family: "Roboto";
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
}

.news-left2 .bottom-area .tag-area .button-tag ul li:hover {
  background-color: var(--theme-color);
}

.news-left2 .bottom-area .tag-area .button-tag ul li:hover a {
  color: #fff;
}

.news-left2 .bottom-area .tag-area .button-tag ul li a {
  color: #111111;
}

.news-left2 .bottom-area .social-area .social-title h3 {
  font-size: 20px;
  margin-bottom: 16px;
}

.news-left2 .bottom-area .social-area .social-icon ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: end;
}

@media (max-width: 576px) {
  .news-left2 .bottom-area .social-area .social-icon ul {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: start;
  }
}

.news-left2 .bottom-area .social-area .social-icon ul li {
  padding: 10px 20px 0 0;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
}

.news-left2 .bottom-area .social-area .social-icon ul li:hover a {
  color: var(--theme-color);
}

.news-left2 .bottom-area .social-area .social-icon ul li a {
  color: #b9b9b9;
}

.news-left2 .bottom-area .post-area {
  padding: 40px 0;
  border-top: 1px solid #f4f4f4;
  border-bottom: 1px solid #f4f4f4;
}

.news-left2 .bottom-area .post-area .quick-post {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.news-left2 .bottom-area .post-area .quick-post .post-text .sub-title {
  font-size: 14px;
  line-height: 26px;
  color: #777777;
  font-weight: 400;
  font-family: "Roboto";
  margin-bottom: 5px;
}

.news-left2 .bottom-area .post-area .quick-post .post-text .sect-title {
  font-size: 28px;
  letter-spacing: -1px;
  line-height: 26px;
  color: #111111;
  font-weight: 600;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .news-left2 .bottom-area .post-area .quick-post .post-text .sect-title {
    font-size: 16px;
  }
}

@media (max-width: 576px) {
  .news-left2 .bottom-area .post-area .quick-post .post-text {
    text-align: center;
  }
}

.news-left2 .bottom-area .post-area .previous-post {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 576px) {
  .news-left2 .bottom-area .post-area .previous-post {
    margin-bottom: 30px;
  }
}

.news-left2 .bottom-area .post-area .icon-area {
  text-align: center;
}

@media (max-width: 576px) {
  .news-left2 .bottom-area .post-area .icon-area {
    margin-bottom: 30px;
  }
}

.news-left2 .bottom-area .post-area .next-post {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: end;
}

@media (max-width: 576px) {
  .news-left2 .bottom-area .post-area .next-post {
    -webkit-box-pack: left;
    -ms-flex-pack: left;
    justify-content: left;
  }
}

.news-left2 .bottom-area .blog-author-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #f3f3f3;
  padding: 40px;
}

.news-left2 .bottom-area .blog-author-box .content {
  padding: 0 40px;
}

.news-left2 .bottom-area .blog-author-box span {
  font-size: 12px;
  line-height: 26px;
  color: #777777;
  font-weight: 400;
  font-family: "Roboto";
  margin-bottom: 5px;
}

.news-left2 .bottom-area .blog-author-box .author-name {
  font-size: 36px;
  letter-spacing: -1px;
  line-height: 46px;
  color: #111111;
  font-weight: 600;
  margin-bottom: 15px;
}

.news-left2 .bottom-area .blog-author-box .author-description {
  font-size: 14px;
  line-height: 26px;
  color: #777777;
  font-weight: 400;
  max-width: 494px;
}

@media (max-width: 768px) {
  .news-left2 .bottom-area .blog-author-box .author-name {
    font-size: 19px;
    line-height: 30px;
    margin-bottom: 15px;
  }
}

.news-left2 .bottom-area .comment-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0;
  padding-top: 0;
  margin-bottom: 40px;
}

.news-left2 .bottom-area .comment-header .comment {
  font-size: 20px;
}

.news-left2 .bottom-area .comment-header .icon a {
  color: #999999;
  font-size: 20px;
}

.news-left2 .bottom-area .comment-section.first-child .comment-text {
  border-top: none;
  padding-top: 0;
}

.news-left2 .bottom-area .comment-section .comment-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: self-start;
  -ms-flex-align: self-start;
  align-items: self-start;
  padding-top: 40px;
  margin-bottom: 40px;
  border-top: 1px solid #f3f3f3;
}

.news-left2 .bottom-area .comment-section .comment-text .commentator a {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.news-left2 .bottom-area .comment-section .comment-text .commentator a img {
  width: 100%;
}

.news-left2 .bottom-area .comment-section .comment-text .text {
  padding-left: 30px;
}

.news-left2 .bottom-area .comment-section .comment-text .text .section-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0;
  margin-bottom: 20px;
}

.news-left2 .bottom-area .comment-section .comment-text .text .section-title .title .sub-title {
  font-size: 16px;
  color: #000;
  margin-bottom: 3px;
}

.news-left2 .bottom-area .comment-section .comment-text .text .section-title .title .sect-title {
  line-height: 25px;
}

.news-left2 .bottom-area .comment-section .comment-text .text .section-title .title .sect-title a {
  font-size: 14px;
  color: #666666;
  font-weight: 400;
  font-family: "Roboto";
}

.news-left2 .bottom-area .comment-section .comment-text .text .section-title .title .sect-title a i {
  margin-right: 4px;
  color: var(--theme-color);
}

.news-left2 .bottom-area .comment-section .comment-text .text .section-title .button {
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
}

.news-left2 .bottom-area .comment-section .comment-text .text .section-title .button a {
  font-size: 14px;
  color: #111111;
  font-family: "Roboto";
  border: 1px solid #f3f3f3;
  padding: 7px 22px;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
  -webkit-transition: background 400ms;
  transition: background 400ms;
}

.news-left2 .bottom-area .comment-section .comment-text .text .section-title .button a:hover {
  background-color: var(--theme-color);
  border: 2px solid var(--theme-color);
  color: #fff;
}

.news-left2 .bottom-area .comment-section .comment-text .text .description {
  font-size: 16px;
  color: #999999;
  font-weight: 400;
  margin-bottom: 0;
}

.news-left2 .bottom-area .rating-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 15px 15px 15px 0;
  border-bottom: 1px solid #f4f4f4;
}

.news-left2 .bottom-area .rating-area .rating-text h2 {
  font-size: 20px;
  color: #000;
}

@media (max-width: 450px) {
  .news-left2 .bottom-area .rating-area .rating-text h2 {
    font-size: 16px;
  }
}

@media (max-width: 450px) {
  .news-left2 .bottom-area .rating-area .rating-icon {
    font-size: 16px;
  }
}

.news-left2 .bottom-area .rating-area .rating-icon .one a {
  color: var(--theme-color);
}

.news-left2 .bottom-area .rating-area .rating-icon .two a {
  color: var(--theme-color);
}

.news-left2 .bottom-area .rating-area .rating-icon .three a {
  color: var(--theme-color);
}

.news-left2 .bottom-area .comment-form .post-title {
  font-size: 26px;
  margin-bottom: 35px;
  padding-top: 30px;
}

@media (max-width: 450px) {
  .news-left2 .bottom-area .comment-form .post-title {
    font-size: 20px;
  }
}

.news-left2 .bottom-area .comment-form .contact-form {
  padding: 40px;
  background: #f7f7f7;
}

.news-left2 .bottom-area .comment-form .contact-form .input-box {
  position: relative;
  z-index: 1;
  margin-bottom: 25px;
}

.news-left2 .bottom-area .comment-form .contact-form .input-box.text-input::before {
  content: "\f303";
  font-weight: 700;
  color: #000;
}

.news-left2 .bottom-area .comment-form .contact-form .input-box.name-input::before {
  content: "\f406";
  font-weight: 700;
  color: #000;
}

.news-left2 .bottom-area .comment-form .contact-form .input-box.mail-input::before {
  content: "\f0e0";
  font-weight: 700;
  color: #000;
}

.news-left2 .bottom-area .comment-form .contact-form .input-box.sub-input::before {
  content: "\f0ac";
  font-weight: 700;
  color: #000;
}

.news-left2 .bottom-area .comment-form .contact-form .input-box::before {
  content: "\f007";
  left: auto;
  right: 30px;
  font-size: 14px;
  font-family: "Font awesome 5 Pro";
  position: absolute;
  font-size: 16px;
  color: #083d59;
  top: 17px;
  z-index: 2;
}

.news-left2 .bottom-area .comment-form .contact-form .input-box textarea {
  -webkit-box-shadow: none;
  box-shadow: none;
  padding-left: 30px;
  color: #000;
  font-size: 14px;
  background-color: #ffffff;
  width: 100%;
  height: 315px;
  padding-top: 16px;
  resize: none;
  position: relative;
  z-index: 1;
  font-size: 14px;
  line-height: 24px;
  color: #777777;
  font-weight: 500;
  font-family: "Roboto";
  max-height: 160px;
  border: none;
}

.news-left2 .bottom-area .comment-form .contact-form .input-box textarea:focus {
  outline: 1px solid var(--theme-color);
}

.news-left2 .bottom-area .comment-form .contact-form .input-box input {
  -webkit-box-shadow: none;
  box-shadow: none;
  padding-left: 30px;
  height: 60px;
  color: #000;
  font-size: 14px;
  background-color: #ffffff;
  position: relative;
  width: 100%;
  z-index: 1;
}

.news-left2 .bottom-area .comment-form .contact-form .input-box input:-moz-placeholder-shown {
  color: #777777;
  font-weight: 500;
  font-family: "Roboto";
}

.news-left2 .bottom-area .comment-form .contact-form .input-box input:-ms-input-placeholder {
  color: #777777;
  font-weight: 500;
  font-family: "Roboto";
}

.news-left2 .bottom-area .comment-form .contact-form .input-box input:placeholder-shown {
  color: #777777;
  font-weight: 500;
  font-family: "Roboto";
}

.news-left2 .bottom-area .comment-form .contact-form .input-box input:focus {
  outline: 1px solid var(--theme-color);
}

.news-left2 .bottom-area .comment-form .contact-form .form-btn {
  padding: 15px 30px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background: #1a1a1a;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  text-transform: uppercase;
  position: relative;
  -webkit-transition-property: color;
  border: none;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  letter-spacing: 2px;
  transition-duration: 0.3s;
}

.news-left2 .bottom-area .comment-form .contact-form .form-btn::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  background: var(--theme-color);
}

.news-left2 .bottom-area .comment-form .contact-form .form-btn:hover {
  color: #fff;
}

.news-left2 .bottom-area .comment-form .contact-form .form-btn:hover::before {
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}

@media (max-width: 768px) {
  .news-left2 .bottom-area .comment-form .contact-form {
    padding: 20px;
    border-radius: 6px;
  }
}

@media (max-width: 576px) {
  .news-left2 .bottom-area .comment-form .contact-form textarea {
    padding: 10px !important;
  }

  .news-left2 .bottom-area .comment-form .contact-form input {
    padding: 15px !important;
  }
}

.news-feed-area {
  padding-right: 50px;
  margin-right: 0;
}

@media (max-width: 1200px) {
  .news-feed-area {
    padding-right: 0;
  }
}

.feed-blog-item {
  overflow: hidden;
  margin-bottom: 40px;
}

.feed-blog-item .blog-picture {
  width: 100%;
  position: relative;
}

.feed-blog-item .blog-picture img {
  width: 100%;
}

.feed-blog-item .contents {
  position: relative;
  padding: 50px;
  border: 1px solid #eeeeee;
  border-top: none;
}

.feed-blog-item .contents.border-top-on {
  border: 1px solid #eeeeee;
}

.feed-blog-item .flex-wrapper {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: absolute;
  top: -30px;
  padding-right: 100px;
  z-index: 1;
}

.feed-blog-item .catagory-tag {
  padding: 7px 15px;
  border-radius: 0;
  background: var(--theme-color);
  font-size: 14px;
  letter-spacing: 1px;
  color: #fff;
  font-weight: 600;
}

.feed-blog-item .blog-author {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 7px;
  padding-right: 30px;
  border-radius: 3px;
  background: #fff;
  -webkit-filter: drop-shadow(0px 16px 16px rgba(0, 0, 0, 0.08));
  filter: drop-shadow(0px 16px 16px rgba(0, 0, 0, 0.08));
  font-size: 16px;
  letter-spacing: 2px;
  line-height: 26px;
  color: #111111;
  font-weight: 600;
}

.feed-blog-item .blog-author .author-dp {
  margin-right: 10px;
}

.feed-blog-item .blog-title {
  font-size: 34px;
  letter-spacing: -1px;
  line-height: 46px;
  color: #111111;
  font-weight: 600;
  margin-bottom: 15px;
}

.feed-blog-item p {
  font-size: 16px;
  line-height: 26px;
  color: #777777;
  font-weight: 400;
  padding-bottom: 30px;
  margin-bottom: 25px;
  border-bottom: 1px solid #e7e7e7;
}

.feed-blog-item .blog-bottom-action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.feed-blog-item .blog-bottom-action span {
  font-size: 14px;
  color: #777777;
  font-weight: 300;
  font-family: "Roboto";
}

.feed-blog-item .blog-bottom-action span i {
  margin-right: 5px;
  color: #111111;
}

.feed-blog-item .blog-bottom-action .separator {
  width: 1px;
  height: 11px;
  margin: 0 25px;
  background: #d0d0d0;
}

.feed-blog-item .slider-navigation {
  width: 100%;
  padding: 0 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.feed-blog-item .slider-navigation .slide-btn i {
  color: #fff;
  font-size: 30px;
}

@media (max-width: 1200px) {
  .feed-blog-item {
    padding-right: 0;
    margin-right: 0;
    border: none;
  }

  .feed-blog-item .blog-title {
    font-size: 30px;
    line-height: 42px;
  }
}

@media (max-width: 900px) {
  .feed-blog-item .blog-title {
    font-size: 25px;
    line-height: 39px;
  }
}

@media (max-width: 600px) {
  .feed-blog-item .contents {
    padding: 20px;
    padding-top: 50px;
  }

  .feed-blog-item .flex-wrapper {
    padding-right: 40px;
  }

  .feed-blog-item .blog-author {
    padding: 5px;
    padding-right: 10px;
  }

  .feed-blog-item .blog-author .author-name {
    font-size: 14px;
  }

  .feed-blog-item .blog-title {
    font-size: 20px;
    line-height: 34px;
  }

  .feed-blog-item .play-btn .popup-video {
    width: 60px;
    height: 60px;
  }
}

@media (max-width: 510px) {
  .feed-blog-item .blog-bottom-action .separator {
    margin: 0 10px;
  }
}

@media (max-width: 450px) {
  .feed-blog-item .flex-wrapper {
    display: none;
  }

  .feed-blog-item .blog-title {
    font-size: 18px;
    line-height: 28px;
  }

  .feed-blog-item .blog-bottom-action {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: -10px;
  }

  .feed-blog-item .blog-bottom-action .item {
    margin-bottom: 10px;
  }

  .feed-blog-item .blog-bottom-action .separator {
    background: transparent;
  }

  .feed-blog-item .slider-navigation {
    top: 30%;
  }

  .feed-blog-item .slider-navigation .slide-btn i {
    font-size: 14px;
  }
}

.quote-box {
  /* background-image: url("../public/images/background/quote-bg.jpg"); */
  background-size: cover;
  padding: 60px 40px;
  width: 100%;
  margin-bottom: 40px;
}

.quote-box .quote-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.quote-box .quote {
  font-size: 34px;
  letter-spacing: -1px;
  line-height: 46px;
  color: #111111;
  font-weight: 600;
  max-width: 600px;
}

.quote-box .quote-icon {
  margin-right: 25px;
}

@media (max-width: 900px) {
  .quote-box .quote {
    font-size: 25px;
    line-height: 35px;
  }
}

@media (max-width: 768px) {
  .quote-box {
    padding: 50px 25px;
  }

  .quote-box .quote {
    font-size: 23px;
    line-height: 33px;
  }
}

@media (max-width: 768px) {
  .quote-box {
    padding: 50px 25px;
  }

  .quote-box .quote {
    font-size: 18px;
    line-height: 30px;
  }

  .quote-box .quote-icon {
    margin-right: 20px;
  }
}

.rts-blog-details .left-news-col {
  padding-right: 50px;
}

@media (max-width: 1200px) {
  .rts-blog-details .left-news-col {
    padding-right: 15px;
  }
}

.rts-blog-details .blog-details {
  border: 1px solid #ececec;
  padding: 45px;
}

.rts-blog-details .news-top {
  margin-bottom: 60px;
  border-bottom: 1px solid #ececec;
}

.rts-blog-details .icon-text span {
  font-size: 14px;
  font-weight: 400;
  color: #777777;
  font-family: "Roboto";
}

.rts-blog-details .icon-text span i {
  color: #111111;
}

.rts-blog-details .blog-point-text {
  margin-bottom: 30px;
}

.rts-blog-details .description {
  font-size: 16px;
  line-height: 26px;
  color: #777777;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 60px;
}

.rts-blog-details .blog-image {
  width: 100%;
  margin-bottom: 50px;
}

.rts-blog-details .blog-image img {
  width: 100%;
}

.rts-blog-details .blog-part2 .flex-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.rts-blog-details .blog-part2 .blog-part-image {
  margin-right: 30px;
  min-width: 190px;
}

.rts-blog-details .blog-part2 .blog-part-image img {
  width: 100%;
}

.rts-blog-details .blog-part2 p {
  max-width: 580px;
  margin-bottom: 10px;
  margin-top: -5px;
}

.rts-blog-details .part-gap {
  margin-bottom: 50px;
}

.rts-blog-details .blog-quote-box {
  border: 1px solid #e5272e;
  padding: 40px 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.rts-blog-details .blog-quote-box .author-box {
  background: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
  margin-bottom: 15px;
}

.rts-blog-details .blog-quote-box .author-box .author {
  display: block;
  margin-left: 25px;
}

.rts-blog-details .blog-quote-box .author-box .author .blog-author {
  font-size: 16px;
  margin-bottom: 0;
}

.rts-blog-details .blog-quote-box .author-box .author .role {
  text-align: start;
  font-size: 12px;
  font-weight: 500;
  color: #777777;
}

.rts-blog-details .blog-quote-box .blog-quote {
  font-size: 24px;
  letter-spacing: -1px;
  line-height: 38px;
  color: #111111;
  font-weight: 600;
  max-width: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.rts-blog-details .blog-quote-box .blog-author {
  font-size: 20px;
  line-height: 24px;
  color: #111111;
  font-weight: 600;
  margin-bottom: 5px;
}

.rts-blog-details .blog-quote-box .role {
  display: block;
  font-size: 14px;
  line-height: 24px;
  color: var(--theme-color);
  font-weight: 700;
  font-family: "Roboto";
}

.rts-blog-details .news-right-widget {
  padding-left: 35px;
  position: sticky;
  top: 0;
}

@media (max-width: 1200px) {
  .rts-blog-details .news-right-widget {
    padding: 0;
    margin-top: 50px;
  }
}

@media (max-width: 1200px) {
  .rts-blog-details .blog-details {
    margin: 0;
  }
}

@media (max-width: 768px) {
  .rts-blog-details .blog-details {
    padding: 20px;
  }

  .rts-blog-details .description {
    margin-bottom: 35px;
  }

  .rts-blog-details .news-top {
    margin-bottom: 25px;
  }

  .rts-blog-details .button-area {
    padding-top: 0;
  }

  .rts-blog-details .blog-part2 .flex-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .rts-blog-details .blog-part2 .blog-part-image {
    margin: 0;
    margin-bottom: 20px;
  }

  .rts-blog-details .blog-quote-box {
    padding: 60px 50px;
  }

  .rts-blog-details .blog-quote-box .blog-quote {
    font-size: 21px;
    letter-spacing: -1px;
    line-height: 36px;
    margin-bottom: 18px;
  }

  .rts-blog-details .bottom-area .blog-author-box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 30px 20px;
  }

  .rts-blog-details .bottom-area .blog-author-box .author-dp {
    margin-bottom: 15px;
  }

  .rts-blog-details .bottom-area .blog-author-box .content {
    text-align: center;
    padding: 0 20px;
  }

  .rts-blog-details .part-gap {
    margin-bottom: 30px;
  }

  .rts-blog-details .part-gap.mb--50 {
    margin-bottom: 30px !important;
  }

  .rts-blog-details .comment-section .commentator {
    margin-bottom: 20px;
  }

  .rts-blog-details .comment-section .comment-text {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .rts-blog-details .comment-section .comment-text .text {
    padding-left: 0 !important;
  }

  .rts-blog-details .comment-section .comment-text .text .section-title .button a {
    padding: 6px 18px !important;
  }
}

@media (max-width: 576px) {
  .rts-blog-details .blog-details {
    padding: 0;
    border: none;
  }

  .rts-blog-details .blog-quote-box {
    padding: 40px 20px;
  }

  .rts-blog-details .blog-quote-box .blog-quote {
    font-size: 18px;
    letter-spacing: -1px;
    line-height: 28px;
    margin-bottom: 18px;
  }
}

.rts-about-us-section .about-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.rts-about-us-section .about-picture {
  margin-right: 40px;
}

.rts-about-us-section .section-title-area {
  margin-bottom: 40px;
}

.rts-about-us-section .section-title-area .section-title {
  margin-bottom: 15px;
}

.rts-about-us-section .section-title-area p {
  font-size: 16px;
  line-height: 26px;
  color: #777777;
  font-weight: 400;
}

.rts-about-us-section .about-plan {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 40px;
}

.rts-about-us-section .about-plan .plan-item:first-child {
  margin-right: 45px;
}

.rts-about-us-section .about-plan .plan-item h4 {
  font-size: 20px;
  letter-spacing: -1px;
  line-height: 34px;
  color: #111111;
  font-weight: 600;
  margin-bottom: 7px;
}

.rts-about-us-section .about-plan .plan-item p {
  font-size: 16px;
  line-height: 26px;
  color: #777777;
  font-weight: 400;
  max-width: 300px;
}

.rts-about-us-section .more-btn {
  font-size: 14px;
  padding: 0;
  width: 150px;
  height: 50px;
  line-height: 50px;
  border-radius: 0;
  font-weight: 500;
  color: #fff;
  background: #111111;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  text-transform: uppercase;
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  letter-spacing: 2px;
}

@media (max-width: 1100px) and (min-width: 900px) {
  .rts-about-us-section .more-btn {
    display: block;
  }
}

@media (max-width: 450px) {
  .rts-about-us-section .more-btn {
    display: block;
    margin: 0 auto;
  }
}

.rts-about-us-section .more-btn::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  background: var(--theme-color);
}

.rts-about-us-section .more-btn:hover {
  color: #fff;
}

.rts-about-us-section .more-btn:hover::before {
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}

@media (max-width: 1200px) {
  .rts-about-us-section .section-title-area {
    margin-bottom: 15px;
  }

  .rts-about-us-section .section-title-area .section-title {
    font-size: 40px;
    margin-bottom: 10px;
  }

  .rts-about-us-section .about-plan {
    margin-bottom: 20px;
  }
}

@media (max-width: 991px) {
  .rts-about-us-section .about-inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .rts-about-us-section .about-inner .about-picture {
    margin-right: 0;
    margin-bottom: 30px;
    width: 100%;
  }

  .rts-about-us-section .about-inner .about-picture img {
    width: 100%;
  }
}

.rts-champion-section {
  /* background: url(../public/images/about/about-bg2.jpg); */
  background-repeat: no-repeat;
  position: relative;
  background-size: cover;
  z-index: 1;
  padding-top: 125px;
  padding-bottom: 125px;
}

.rts-champion-section .player-image {
  position: absolute;
  top: 0;
  z-index: -1;
  -webkit-animation: hero-move2 40s infinite linear;
  animation: hero-move2 40s infinite linear;
}

.rts-champion-section .top-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: -60px;
}

@media (max-width: 991px) {
  .rts-champion-section .top-wrap {
    margin-bottom: 60px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

.rts-champion-section .filter-button-group {
  -webkit-filter: drop-shadow(-15px 15px 20px rgb(190, 190, 190));
  filter: drop-shadow(-15px 15px 20px rgb(190, 190, 190));
}

.rts-champion-section .filter-button-group .filter-btn {
  margin-right: 15px;
  background: rgb(255, 255, 255);
  height: 60px;
  padding: 0 35px;
  border-radius: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 16px;
  letter-spacing: 2px;
  color: #111111;
  font-weight: 600;
}

.rts-champion-section .filter-button-group .filter-btn:hover,
.rts-champion-section .filter-button-group .filter-btn.active {
  background: var(--theme-color);
  color: #fff;
}

.rts-champion-section .champion-details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  pointer-events: none;
}

.rts-champion-section .champion-details .contents {
  max-width: 610px;
  margin-top: 60px;
}

.rts-champion-section .champion-details .contents .details-title {
  font-size: 48px;
  letter-spacing: -2px;
  color: #111111;
  font-weight: 600;
  margin-bottom: 30px;
}

.rts-champion-section .champion-details .contents p {
  font-size: 16px;
  line-height: 26px;
  color: #777777;
  font-weight: 400;
}

.rts-champion-section .champion-details .contents p.p1 {
  margin-bottom: 20px;
}

.rts-champion-section .champion-details .details-video {
  position: relative;
  margin-left: 40px;
}

@media (max-width: 1200px) {
  .rts-champion-section .champion-details .contents .details-title {
    font-size: 40px;
    margin-bottom: 15px;
  }

  .rts-champion-section .champion-details .contents p.p1 {
    margin-bottom: 10px;
  }

  .rts-champion-section .champion-details .details-video {
    margin-left: 30px;
  }
}

@media (max-width: 991px) {
  .rts-champion-section .champion-details {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .rts-champion-section .champion-details .contents p.p1 {
    margin-bottom: 15px;
  }

  .rts-champion-section .champion-details .details-video {
    margin-left: 0;
    margin-bottom: 30px;
    width: 100%;
  }

  .rts-champion-section .champion-details .details-video img {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .rts-champion-section .top-wrap {
    margin-bottom: 30px;
  }

  .rts-champion-section .filter-button-group .filter-btn {
    height: 50px;
    padding: 0 20px;
  }

  .rts-champion-section .champion-details .contents .details-title {
    font-size: 30px;
    line-height: 40px;
  }
}

@media (max-width: 510px) {
  .rts-champion-section .filter-button-group .filter-btn {
    height: 40px;
    padding: 0 14px;
    font-size: 14px;
  }
}

@media (max-width: 450px) {
  .rts-champion-section .top-wrap {
    margin-bottom: 15px;
  }

  .rts-champion-section .filter-button-group {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .rts-champion-section .filter-button-group .filter-btn {
    height: 40px;
    padding: 0 14px;
    font-size: 14px;
    margin-bottom: 10px;
  }
}

.rts-about-section {
  background-image: linear-gradient(524deg, #075686, #000);
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.rts-about-section .shape1 {
  position: absolute;
  top: 0;
  z-index: -1;
}

.rts-about-section .shape2 {
  position: absolute;
  bottom: 30px;
  right: 50px;
  z-index: -1;
  -webkit-animation: hero-move2 40s infinite linear;
  animation: hero-move2 40s infinite linear;
}

@media (max-width: 450px) {
  .rts-about-section .shape2 {
    display: none;
  }
}

.rts-about-section .container-1 {
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
}

.rts-about-section .about-inner {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.rts-about-section .about-inner .about-thumb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.rts-about-section .about-inner .about-thumb .img1 {
  margin-right: 25px;
}

.rts-about-section .about-inner .contents {
  padding: 0 30px;
}

@media (max-width: 576px) {
  .rts-about-section .about-inner .contents {
    padding: 0;
    margin-top: 20px;
  }
}

.rts-about-section .about-inner .contents .section-title-area {
  position: relative;
  margin-bottom: 30px;
}

.rts-about-section .about-inner .contents .section-title-area .pretitle {
  font-size: 210px;
  font-family: "Oswald";
  -webkit-text-fill-color: #0c0c0c;
  -webkit-text-stroke-color: rgba(133, 133, 133, 0.231372549);
  font-weight: bold;
  text-transform: uppercase;
  line-height: 0.155;
  text-align: center;
  text-shadow: 7.5px 12.99px 29px rgba(21, 21, 21, 0.153);
  position: absolute;
  top: 40px;
  left: 70px;
}

.rts-about-section .about-inner .contents .section-title-area .section-title span {
  font-size: 36px;
  color: #fff;
}

.rts-about-section .about-inner .contents .section-title-area p {
  max-width: 100%;
  margin-bottom: 0;
}

@media (max-width: 1150px) and (min-width: 991px) {
  .rts-about-section .about-inner .contents .section-title-area p br {
    display: none;
  }
}

@media (max-width: 700px) {
  .rts-about-section .about-inner .contents .section-title-area p br {
    display: none;
  }
}

.rts-about-section .about-inner .contents ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-bottom: 50px;
}

@media (max-width: 576px) {
  .rts-about-section .about-inner .contents ul {
    display: block;
  }
}

.rts-about-section .about-inner .contents ul .player {
  position: relative;
  padding-left: 55px;
  margin-right: 55px;
}

@media (max-width: 1200px) and (min-width: 991px) {
  .rts-about-section .about-inner .contents ul .player {
    margin-right: 18px;
  }
}

@media (max-width: 700px) {
  .rts-about-section .about-inner .contents ul .player {
    margin-right: 18px;
  }
}

@media (max-width: 576px) {
  .rts-about-section .about-inner .contents ul .player {
    margin-bottom: 15px;
  }
}

.rts-about-section .about-inner .contents ul .player::before {
  content: "\f00c";
  position: absolute;
  background: #fff;
  font-family: "Font Awesome 5 Pro";
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  display: block;
  border-radius: 50%;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.rts-about-section .about-inner .contents ul .player .sub {
  color: #fff;
  font-size: 12px;
  margin-bottom: 0;
}

.rts-about-section .about-inner .contents ul .player .title {
  color: #fff;
  font-size: 20px;
}

.rts-about-section .about-inner .contents .more-btn {
  border-radius: 0;
}

.rts-about-section.about {
  background: #FFFFFF !important;
}

.rts-about-section.about .about-inner .contents .section-title {
  color: #111111 !important;
}

.rts-about-section.about .about-inner .contents .section-title span {
  color: #111111 !important;
}

.rts-about-section.about .about-inner .contents ul .player .sub {
  color: #111111;
}

.rts-about-section.about .about-inner .contents ul .player .title {
  color: #111111;
}

.contact-area {
  padding: 100px 0 100px 0;
}

.contact-area .container .address-box {
  margin-bottom: 80px;
}

.contact-area .container .address-box .address {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  border: 1px solid #e7e7e7;
  padding: 35px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

@media (max-width: 1200px) and (min-width: 991px) {
  .contact-area .container .address-box .address {
    padding: 20px;
  }
}

@media (max-width: 991px) {
  .contact-area .container .address-box .address {
    margin-bottom: 30px;
  }
}

@media (max-width: 450px) {
  .contact-area .container .address-box .address {
    display: block;
  }
}

.contact-area .container .address-box .address:hover {
  background: var(--theme-color);
}

.contact-area .container .address-box .address:hover .icon {
  background: #fff;
}

.contact-area .container .address-box .address:hover .content .heading {
  color: #fff;
}

.contact-area .container .address-box .address:hover .content .heading::before {
  background: #fff;
}

.contact-area .container .address-box .address:hover .content .desc {
  color: #fff;
}

.contact-area .container .address-box .address .icon {
  color: var(--theme-color);
  background: #fff2f3;
  width: 62px;
  height: 50px;
  line-height: 50px;
  display: block;
  text-align: center;
  font-size: 20px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

@media (max-width: 1200px) and (min-width: 991px) {
  .contact-area .container .address-box .address .icon {
    width: 80px;
  }
}

.contact-area .container .address-box .address .content {
  margin-left: 20px;
}

@media (max-width: 450px) {
  .contact-area .container .address-box .address .content {
    margin-left: 0;
    margin-top: 20px;
  }
}

.contact-area .container .address-box .address .content .heading {
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 20px;
  margin-bottom: 20px;
  position: relative;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.contact-area .container .address-box .address .content .heading::before {
  content: "";
  position: absolute;
  width: 55px;
  height: 3px;
  background-color: var(--theme-color);
  bottom: 0;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.contact-area .container .contact-area-inner {
  background: #111111;
  padding: 80px;
}

@media (max-width: 576px) {
  .contact-area .container .contact-area-inner {
    padding: 20px;
  }
}

.contact-area .container .contact-form .post-title {
  font-size: 40px;
  margin-bottom: 25px;
  color: #fff;
  line-height: 1;
  text-transform: uppercase;
}

@media (max-width: 450px) {
  .contact-area .container .contact-form .post-title {
    font-size: 20px;
  }
}

.contact-area .container .contact-form .sub-title {
  font-size: 18px;
  color: #666666;
  font-weight: 300;
  padding-bottom: 40px;
  font-family: "Roboto", sans-serif;
}

.contact-area .container .contact-form .info-form {
  padding: 0;
}

.contact-area .container .contact-form .info-form .input-box {
  position: relative;
  z-index: 1;
  margin-bottom: 25px;
  position: relative;
}

.contact-area .container .contact-form .info-form .input-box.name::before {
  content: "\f007";
  position: absolute;
  color: #fff;
  font-family: "Font Awesome 5 Pro";
  left: 18px;
  top: 18px;
  z-index: 2;
}

.contact-area .container .contact-form .info-form .input-box.email::before {
  content: "\f0e0";
  position: absolute;
  color: #fff;
  font-family: "Font Awesome 5 Pro";
  left: 18px;
  top: 18px;
  z-index: 2;
}

.contact-area .container .contact-form .info-form .input-box.message::before {
  content: "\f02d";
  position: absolute;
  color: #fff;
  font-family: "Font Awesome 5 Pro";
  left: 18px;
  top: 18px;
  z-index: 2;
}

@media (max-width: 576px) {
  .contact-area .container .contact-form .info-form .input-box {
    margin-bottom: 10px;
  }
}

.contact-area .container .contact-form .info-form .input-box textarea {
  border: 2px solid #1a1a1a;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding-left: 50px;
  color: #fff;
  font-size: 14px;
  background-color: #1a1a1a;
  width: 100%;
  height: 175px;
  padding-top: 16px;
  resize: none;
  position: relative;
  z-index: 1;
}

.contact-area .container .contact-form .info-form .input-box textarea::-webkit-input-placeholder {
  font-weight: 400;
  font-size: 16px;
  font-family: "Roboto";
}

.contact-area .container .contact-form .info-form .input-box textarea::-moz-placeholder {
  font-weight: 400;
  font-size: 16px;
  font-family: "Roboto";
}

.contact-area .container .contact-form .info-form .input-box textarea:-ms-input-placeholder {
  font-weight: 400;
  font-size: 16px;
  font-family: "Roboto";
}

.contact-area .container .contact-form .info-form .input-box textarea::-ms-input-placeholder {
  font-weight: 400;
  font-size: 16px;
  font-family: "Roboto";
}

.contact-area .container .contact-form .info-form .input-box textarea::placeholder {
  font-weight: 400;
  font-size: 16px;
  font-family: "Roboto";
}

.contact-area .container .contact-form .info-form .input-box textarea:focus {
  outline: 1px solid var(--theme-color);
}

.contact-area .container .contact-form .info-form .input-box input {
  -webkit-box-shadow: none;
  box-shadow: none;
  padding-left: 50px;
  height: 60px;
  color: #fff;
  font-size: 14px;
  background-color: #1a1a1a;
  position: relative;
  width: 100%;
  z-index: 1;
}

.contact-area .container .contact-form .info-form .input-box input::-webkit-input-placeholder {
  font-weight: 400;
  font-size: 16px;
  font-family: "Roboto";
}

.contact-area .container .contact-form .info-form .input-box input::-moz-placeholder {
  font-weight: 400;
  font-size: 16px;
  font-family: "Roboto";
}

.contact-area .container .contact-form .info-form .input-box input:-ms-input-placeholder {
  font-weight: 400;
  font-size: 16px;
  font-family: "Roboto";
}

.contact-area .container .contact-form .info-form .input-box input::-ms-input-placeholder {
  font-weight: 400;
  font-size: 16px;
  font-family: "Roboto";
}

.contact-area .container .contact-form .info-form .input-box input::placeholder {
  font-weight: 400;
  font-size: 16px;
  font-family: "Roboto";
}

.contact-area .container .contact-form .info-form .input-box input:focus {
  outline: 1px solid var(--theme-color);
}

.contact-area .container .contact-form .info-form .form-btn {
  padding: 15px 30px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background: #1a1a1a;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  text-transform: uppercase;
  position: relative;
  -webkit-transition-property: color;
  border: none;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  letter-spacing: 2px;
  transition-duration: 0.3s;
}

.contact-area .container .contact-form .info-form .form-btn::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  background: var(--theme-color);
}

.contact-area .container .contact-form .info-form .form-btn:hover {
  color: #fff;
}

.contact-area .container .contact-form .info-form .form-btn:hover::before {
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}

.contact-area .container .right-side {
  padding: 0 30px;
}

@media (max-width: 991px) {
  .contact-area .container .right-side {
    padding: 0;
  }
}

.contact-area .container .right-side .get-in-touch {
  background-color: #f9f9f9;
  padding: 50px 25px 50px 50px;
  border-radius: 5px;
}

@media (max-width: 1200px) {
  .contact-area .container .right-side .get-in-touch {
    padding: 50px 0 50px 25px;
  }
}

@media (max-width: 576px) {
  .contact-area .container .right-side .get-in-touch {
    padding: 25px 0 25px 15px;
  }
}

.contact-area .container .right-side .get-in-touch h3 {
  font-size: 26px;
  margin-bottom: 30px;
  line-height: 1;
}

.contact-area .container .right-side .get-in-touch .contact {
  padding: 0 35px;
}

.contact-area .container .right-side .get-in-touch .contact ul li {
  position: relative;
  padding: 12px 0;
  font-size: 15px;
  color: #666666;
  font-weight: 300;
  font-family: "Roboto", sans-serif;
}

.contact-area .container .right-side .get-in-touch .contact ul li a {
  font-size: 15px;
  color: #666666;
  font-weight: 300;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.contact-area .container .right-side .get-in-touch .contact ul li a:hover {
  color: var(--theme-color);
}

.contact-area .container .right-side .get-in-touch .contact ul li.one::before {
  content: "\f3c5";
  left: -35px;
  top: 12px;
  color: #000;
  right: auto;
  font-family: "Font awesome 5 Pro";
  position: absolute;
  font-size: 16px;
  font-weight: 300;
  z-index: 2;
}

.contact-area .container .right-side .get-in-touch .contact ul li.two::before {
  content: "\f095";
  left: -35px;
  top: 11px;
  color: #000;
  right: auto;
  font-family: "Font awesome 5 Pro";
  position: absolute;
  font-size: 16px;
  font-weight: 300;
  z-index: 2;
}

.contact-area .container .right-side .get-in-touch .contact ul li.three::before {
  content: "\f017";
  left: -35px;
  top: 11px;
  color: #000;
  right: auto;
  font-family: "Font awesome 5 Pro";
  position: absolute;
  font-size: 16px;
  font-weight: 300;
  z-index: 2;
}

.contact-area .container .right-side .section-button {
  text-align: center;
}

@media (max-width: 991px) {
  .contact-area .container .right-side .section-button {
    margin-bottom: 50px;
  }
}

.contact-area .container .right-side .section-button .btn-1 {
  margin: 25px 0 10px 0;
  border: 2px solid #f4f4f4;
  border-radius: 5px;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

@media (max-width: 400px) {
  .contact-area .container .right-side .section-button .btn-1 {
    font-size: 14px;
  }
}

.contact-area .container .right-side .section-button .btn-1:hover {
  background-color: var(--theme-color);
  border: 2px solid var(--theme-color);
}

.contact-area .container .right-side .section-button .btn-1:hover a {
  color: #fff;
}

.contact-area .container .right-side .section-button .btn-1 a {
  padding: 15px 20px;
  display: block;
}

.contact-area .container .right-side .section-button .btn-1 a i {
  margin-left: 10px;
  font-weight: 500;
}

.contact-area .container .right-side .section-button .btn-2 {
  border: 2px solid #f4f4f4;
  border-radius: 5px;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

@media (max-width: 400px) {
  .contact-area .container .right-side .section-button .btn-2 {
    font-size: 14px;
  }
}

.contact-area .container .right-side .section-button .btn-2:hover {
  background-color: var(--theme-color);
  border: 2px solid var(--theme-color);
}

.contact-area .container .right-side .section-button .btn-2:hover a {
  color: #fff;
}

.contact-area .container .right-side .section-button .btn-2 a {
  padding: 15px 20px;
  display: block;
}

.contact-area .container .right-side .section-button .btn-2 a i {
  margin-left: 10px;
  font-weight: 500;
}

.contact-area .map p iframe {
  width: 100%;
  height: 500px;
}

.thanks-area {
  padding: 150px 0;
}

@media (max-width: 991px) {
  .thanks-area {
    padding: 100px 0;
  }
}

@media (max-width: 576px) {
  .thanks-area {
    padding: 75px 0;
  }
}

.thanks-area .container .section-inner {
  text-align: center;
}

.thanks-area .container .section-inner .section-icon {
  margin: 0 auto;
  width: 90px;
  height: 90px;
  line-height: 90px;
  font-size: 30px;
  display: block;
  border: 2px solid #ebe9e9;
  text-align: center;
  border-radius: 50%;
}

.thanks-area .container .section-inner .section-icon i {
  margin: 0 auto;
  font-size: 30px;
  color: #99cc33;
}

.thanks-area .container .section-inner .section-title {
  margin: 35px 0;
}

@media (max-width: 450px) {
  .thanks-area .container .section-inner .section-title {
    margin: 25px 0;
  }
}

.thanks-area .container .section-inner .section-title .sub-title {
  padding-bottom: 25px;
  font-size: 48px;
  font-weight: 700;
}

@media (max-width: 450px) {
  .thanks-area .container .section-inner .section-title .sub-title {
    font-size: 30px;
    padding-bottom: 15px;
  }
}

.thanks-area .container .section-inner .section-title .sect-title {
  color: #666666;
  font-size: 18px;
  font-weight: 300;
  line-height: 28px;
}

@media (max-width: 450px) {
  .thanks-area .container .section-inner .section-title .sect-title {
    font-size: 16px;
    line-height: 28px;
  }
}

.thanks-area .container .section-inner .section-button .btn-1 {
  font-size: 14px;
  padding: 15px 30px;
  font-weight: 500;
  color: #fff;
  background: #111111;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  text-transform: uppercase;
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  letter-spacing: 2px;
  margin-bottom: 10px;
}

@media (max-width: 1100px) and (min-width: 900px) {
  .thanks-area .container .section-inner .section-button .btn-1 {
    display: block;
  }
}

@media (max-width: 450px) {
  .thanks-area .container .section-inner .section-button .btn-1 {
    display: block;
    margin: 0 auto;
  }
}

.thanks-area .container .section-inner .section-button .btn-1::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  background: var(--theme-color);
}

.thanks-area .container .section-inner .section-button .btn-1:hover {
  color: #fff;
}

.thanks-area .container .section-inner .section-button .btn-1:hover::before {
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}

.thanks-area .container .section-inner .section-button h3 {
  color: #666666;
  font-size: 14px;
  font-weight: 300;
}

.thanks-area .container .section-inner .section-button h3 .btn-2 {
  font-size: 14px;
  font-weight: 700;
  text-decoration: underline;
  text-decoration-color: #999999;
  text-decoration-thickness: 2px;
}

.thanks-area .container .section-inner .section-button h3 .btn-2:hover {
  color: var(--theme-color);
  text-decoration-color: var(--theme-color);
}

.login-area {
  padding: 80px 0;
}

@media (max-width: 450px) {
  .login-area {
    padding: 50px 0;
  }
}

.login-area .container .login-form {
  margin-right: 20px;
}

.login-area .container .login-form .section-title {
  margin-bottom: 40px;
}

@media (max-width: 450px) {
  .login-area .container .login-form .section-title {
    margin: 30px 0 10px 0;
  }
}

.login-area .container .login-form .section-title h2 {
  font-size: 28px;
  line-height: 32px;
  font-weight: 700;
}

.login-area .container .login-form .card .card-body {
  padding: 50px 40px;
}

.login-area .container .login-form .card .card-body .form {
  margin-bottom: 30px;
}

.login-area .container .login-form .card .card-body .form .form-label {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: #444;
}

.login-area .container .login-form .card .card-body .form .form-control {
  color: #000;
  border: 2px solid #f4f4f4;
  padding: 18px 15px 18px 30px;
  border-radius: 5px;
}

.login-area .container .login-form .card .card-body .form .form-control::-webkit-input-placeholder {
  color: #666666;
}

.login-area .container .login-form .card .card-body .form .form-control::-moz-placeholder {
  color: #666666;
}

.login-area .container .login-form .card .card-body .form .form-control:-ms-input-placeholder {
  color: #666666;
}

.login-area .container .login-form .card .card-body .form .form-control::-ms-input-placeholder {
  color: #666666;
}

.login-area .container .login-form .card .card-body .form .form-control::placeholder {
  color: #666666;
}

.login-area .container .login-form .card .card-body .form .password-input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
}

.login-area .container .login-form .card .card-body .form .password-input .show-password-input {
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.login-area .container .login-form .card .card-body .form .password-input .show-password-input::after {
  content: "\f06e";
  font-weight: 500;
  color: #777777;
  font-family: "Font awesome 5 Pro";
}

.login-area .container .login-form .card .card-body .form .btn {
  text-align: center;
  width: 200px;
  height: 60px;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  background-color: #000;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.login-area .container .login-form .card .card-body .form .btn:hover i {
  -webkit-transform: translateX(10px);
  transform: translateX(10px);
}

.login-area .container .login-form .card .card-body .form .btn i {
  margin-left: 10px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.login-area .container .login-form .card .card-body .forgot-password {
  font-size: 16px;
  color: #444;
  font-weight: 500;
  margin-top: 18px;
}

.login-area .container .register-form {
  margin-left: 20px;
}

.login-area .container .register-form .section-title {
  margin-bottom: 40px;
}

@media (max-width: 450px) {
  .login-area .container .register-form .section-title {
    margin: 30px 0 10px 0;
  }
}

.login-area .container .register-form .section-title h2 {
  font-size: 28px;
  line-height: 32px;
  font-weight: 700;
}

.login-area .container .register-form .card .card-body {
  padding: 50px 40px;
}

.login-area .container .register-form .card .card-body .form {
  margin-bottom: 30px;
}

.login-area .container .register-form .card .card-body .form .form-label {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: #444;
}

.login-area .container .register-form .card .card-body .form .form-control {
  color: #666666;
  border: 2px solid #f4f4f4;
  padding: 18px 15px 18px 30px;
  border-radius: 5px;
}

.login-area .container .register-form .card .card-body .form .password-input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
}

.login-area .container .register-form .card .card-body .form .password-input .show-password-input {
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.login-area .container .register-form .card .card-body .form .password-input .show-password-input::after {
  content: "\f06e";
  font-weight: 500;
  color: #777777;
  font-family: "Font awesome 5 Pro";
}

.login-area .container .register-form .card .card-body .form .btn {
  font-size: 14px;
  padding: 0;
  width: 150px;
  height: 50px;
  line-height: 50px;
  border-radius: 0;
  border: none;
  font-weight: 500;
  color: #fff;
  background: #111111;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  text-transform: uppercase;
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

@media (max-width: 1100px) and (min-width: 900px) {
  .login-area .container .register-form .card .card-body .form .btn {
    display: block;
  }
}

@media (max-width: 450px) {
  .login-area .container .register-form .card .card-body .form .btn {
    display: block;
    margin: 0 auto;
  }
}

.login-area .container .register-form .card .card-body .form .btn::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  background: var(--theme-color);
}

.login-area .container .register-form .card .card-body .form .btn:hover {
  color: #fff;
}

.login-area .container .register-form .card .card-body .form .btn:hover::before {
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}

.login-area .container .register-form .card .card-body .forgot-password {
  font-size: 16px;
  color: #444;
  font-weight: 500;
  margin-top: 18px;
}

.faq-area {
  padding: 120px 0;
  background-color: black;
  color: white;
}

.faq-area .container .faq-que-list .accordion-item {
  background-color: black;
  margin-bottom: 25px;
  color: white;
}

.faq-area .container .faq-que-list .accordion-item button:focus,
.faq-area .container .faq-que-list .accordion-item input:focus,
.faq-area .container .faq-que-list .accordion-item input:focus,
.faq-area .container .faq-que-list .accordion-item textarea,
.faq-area .container .faq-que-list .accordion-item textarea:focus {
  outline: 0;

}

.faq-area .container .faq-que-list .accordion-item .accordion-button {
  transition: unset;
  color: white;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1;
  border: 0;
  background-color: #FFFFFF;
  border-radius: 0;
  padding: 20px 25px 18px 25px;
  position: relative;
  -webkit-box-shadow: 0;
  box-shadow: 0;
  -webkit-transition: unset;
  -moz-transition: unset;
  -ms-transition: unset;
  -o-transition: unset;
  word-wrap: normal;
  border: 1px solid rgb(231, 231, 231);
  background-color: black;
}

.faq-area .container .faq-que-list .accordion-item .accordion-button.collapsed {
  -webkit-box-shadow: 0 10px 10px 0 rgba(255, 255, 255, 0.2);
  box-shadow: 0 10px 10px 0 rgba(255, 255, 255, 0.2);
  border: 1px solid rgb(231, 231, 231);
  background-color: black;
  color: white;
}

@media (max-width: 991px) and (min-width: 768px) {
  .faq-area .container .faq-que-list .accordion-item .accordion-button {
    padding: 20px 40px 20px 20px;
    font-size: 15px;
    display: block;

    line-height: 16px;
  }
}

@media (max-width: 767px) {
  .faq-area .container .faq-que-list .accordion-item .accordion-button {
    padding: 20px 40px 20px 20px;
    font-size: 15px;
    display: block;
    line-height: 16px;
  }
}

.faq-area .container .faq-que-list .accordion-item .accordion-button:not(.collapsed) {
  color: unset;
  -webkit-box-shadow: unset;
  box-shadow: unset;
  margin-bottom: 10px;
}

.faq-area .container .faq-que-list .accordion-item .accordion-button:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0;
  color: white;
}

.faq-area .container .faq-que-list .accordion-item .accordion-button::before {
  content: "\f068";
  position: absolute;
  font-family: "Font awesome 5 Pro";
  font-size: 18px;
  font-weight: 300;
  /* color: #FFFFFF !important; */
  right: 30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
  background: #02B23D;
  width: 30px;
  height: 30px;
  line-height: 30px;
  display: block;
  text-align: center;
  color: white;
}

@media (max-width: 991px) and (min-width: 768px) {
  .faq-area .container .faq-que-list .accordion-item .accordion-button::before {
    right: 10px;
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .faq-area .container .faq-que-list .accordion-item .accordion-button::before {
    right: 10px;
    font-size: 16px;
  }
}

.faq-area .container .faq-que-list .accordion-item .accordion-button::after {
  display: none;
}

.faq-area .container .faq-que-list .accordion-item .collapsed {
  border: 0;
  background: #FFFFFF;
}

.faq-area .container .faq-que-list .accordion-item .collapsed::before {
  content: "\f067";
  background: #EAEAEA;
  color: white;
}

.faq-area .container .faq-que-list .accordion-item .accordion-body {
  padding: 0;
  padding: 0 30px 0 25px;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;

}

.faq-area .container .faq-que-list .accordion-item .accordion-body p {
  font-size: 16px;
  color: #666666;
  font-weight: 300;
  line-height: 28px;
  color: white;
}

@media (max-width: 767px) {
  .faq-area .container .faq-que-list .accordion-item .accordion-body {
    padding: 0 15px 24px 15px;
  }
}

.faq-area .container .faq-bg-area {
  position: relative;
  z-index: 1;
}

.faq-area .container .faq-bg-area::before {
  content: "";
  position: absolute;
  background-image: -ms-linear-gradient(0deg, rgb(8, 106, 216) 0%, rgb(55, 140, 255) 100%);
  opacity: 0.941;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.faq-area .container .faq-bg-area .faq-que-list .accordion-item {
  border: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.faq-area .container .faq-bg-area .faq-que-list .accordion-item:nth-child(1) {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.faq-area .container .faq-bg-area .faq-que-list .accordion-item .accordion-button {
  color: #fff;
  padding-left: 0;
  padding-right: 0;
}

@media (max-width: 767px) {
  .faq-area .container .faq-bg-area .faq-que-list .accordion-item .accordion-button {
    padding-left: 0;
    padding-right: 15px;
  }
}

.faq-area .container .faq-bg-area .faq-que-list .accordion-item .accordion-button::before {
  color: #fff;
  right: 0;
}

.faq-area .container .faq-bg-area .faq-que-list .accordion-item .accordion-body {
  padding: 0 30px 20px 0;
}

.faq-area .container .faq-bg-area .faq-que-list .accordion-item .accordion-body p {
  font-size: 16px;
  color: #666666;
  font-weight: 300;
  line-height: 28px;
}

@media (max-width: 767px) {
  .faq-area .container .faq-bg-area .faq-que-list .accordion-item .accordion-body {
    padding: 0 15px 20px 0;
  }
}

.faq-area .container .faq-bg-area .faq-que-list .accordion-item .accordion-body p {
  color: rgba(255, 255, 255, 0.8);
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .faq-content-wrapper {
    padding-right: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .faq-content-wrapper {
    padding-right: 0;
  }
}

@media (max-width: 767px) {
  .faq-content-wrapper {
    padding-right: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .faq-content-wrapper .sect-title {
    padding-right: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .faq-content-wrapper .sect-title {
    padding-right: 0;
  }
}

@media (max-width: 767px) {
  .faq-content-wrapper .sect-title {
    padding-right: 0;
  }
}

.faq-que-list .accordion-button:not(.collapsed) {
  color: #02B23D !important;
  background-color: #02B23D;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0);
}

.faq-que-list .accordion-button:not(.collapsed) span {
  /* color: var(--theme-color) !important; */
  color: #02B23D !important;
}

.faq-que-list .accordion-item {
  margin-bottom: 15px;
  border: 0;
}

.faq-que-list .accordion-item:first-of-type {
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border: 0;
}

.faq-que-list .accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.faq-que-list .accordion-item .accordion-button {
  color: #000;
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  border: 0;
  background-color: transparent;
  border-radius: 0;
  padding: 20px 30px;
  padding-bottom: 0;
  position: relative;
  -webkit-box-shadow: 0;
  box-shadow: 0;
}

.faq-que-list .accordion-item .accordion-button span {
  color: #000;
  margin-right: 14px;
}

.faq-que-list .accordion-item .accordion-button::before {
  content: "\f068";
  position: absolute;
  font-family: "Font awesome 5 Pro";
  font-size: 16px;
  font-weight: 300;
  /* color: var(--theme-color) !important; */
  right: 30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
}

.faq-que-list .accordion-item .accordion-button::after {
  display: none;
}

.faq-que-list .accordion-item .accordion-button:focus {
  z-index: 3;
  border-color: transparent;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
}

@media (max-width: 767px) {
  .faq-que-list .accordion-item .accordion-button {
    padding: 26px 10px;
    font-size: 14px;
  }

  .faq-que-list .accordion-item .accordion-button::before {
    right: 15px;
    font-size: 14px;
  }

  .faq-que-list .accordion-item .accordion-button span {
    margin-right: 5px;
  }
}

.faq-que-list .accordion-item .accordion-button:not(.collapsed) {
  -webkit-box-shadow: 0;
  box-shadow: 0;
}

.faq-que-list .accordion-item .collapsed {
  color: var(--theme-color);
  background-color: var(--theme-color);
  padding-bottom: 24px;
  padding-top: 20px;
}

.faq-que-list .accordion-item .collapsed::before {
  content: "\f067";
  color: #000 !important;
}

.faq-que-list .accordion-item .accordion-body {
  padding: 18px 30px 24px 30px;
  -webkit-box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
  line-height: 24px;

}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .faq-que-list .accordion-item .accordion-body {
    padding: 15px 0 5px;
  }

  .faq-que-list .accordion-item .accordion-body p {
    line-height: 1.5;
  }
}

@media (max-width: 767px) {
  .faq-que-list .accordion-item .accordion-body {
    padding: 18px 10px 20px 10px;
  }
}

.faq-img-wrapper {
  position: relative;
  z-index: 1;
}

/* .faq-content-wrapper2 {
  margin-left: 35px;
} */
.faq-content-wrapper2 ul li {
  margin: 20px 0px;
  font-size: 17px;
  font-weight: 300;
}

@media (max-width: 767px) {
  .faq-content-wrapper2 {
    padding-right: 0;
  }
}

.faq-content-wrapper2 .section-title .sect-title {
  font-size: 40px;
}

.faq-content-wrapper2 .semi-title2::after {
  display: none;
}

.faq-content-wrapper2 .sect-title {
  color: #000;
  font-size: 48px;
  margin-bottom: 30px;
  text-transform: uppercase;
}

@media (max-width: 1100px) {
  .faq-content-wrapper2 .sect-title {
    font-size: 50px;
  }
}

@media (max-width: 991px) {
  .faq-content-wrapper2 .sect-title {
    margin-top: 30px;
  }
}

.faq-content-wrapper2 .faq-que-list .accordion-item {
  -webkit-box-shadow: 0;
  box-shadow: 0;
  margin-bottom: 0;
}

.faq-content-wrapper2 .faq-que-list .accordion-item:first-of-type .collapsed {
  border-top: 1px solid rgb(239, 239, 239);
}

.faq-content-wrapper2 .faq-que-list .accordion-item .accordion-button {
  font-size: 20px;
  border: 0;
  padding-left: 0;
  padding-bottom: 26px;
  padding-top: 24px;
  text-transform: uppercase;
  font-weight: 600;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .faq-content-wrapper2 .faq-que-list .accordion-item .accordion-button {
    font-size: 17px;
  }
}

@media (max-width: 767px) {
  .faq-content-wrapper2 .faq-que-list .accordion-item .accordion-button {
    font-size: 15px;
  }
}

.faq-content-wrapper2 .faq-que-list .accordion-item .accordion-button::before {
  color: #000;
  right: 0;
}

.faq-content-wrapper2 .faq-que-list .accordion-item .accordion-button span {
  color: white;
  margin-right: 5px;
}

.faq-content-wrapper2 .faq-que-list .accordion-item .collapsed {
  color: var(--theme-color);
  background: transparent;
  border-bottom: 1px solid rgb(239, 239, 239);
}

.faq-content-wrapper2 .faq-que-list .accordion-item .collapsed::before {
  color: #000;
}

.faq-content-wrapper2 .faq-que-list .accordion-item .collapsed span {
  color: white;
}

.faq-content-wrapper2 .faq-que-list .accordion-item .accordion-body {
  padding-top: 0;
  -webkit-box-shadow: 0;
  box-shadow: 0;
  border-bottom: 1px solid rgb(239, 239, 239);
  color: white;
}

.grey-bg4 {
  background: #f5f5f5;
  margin-top: 120px;
  padding: 120px 0;
}

.grey-bg4 .faq-content-wrapper {
  margin-right: 35px;
}

@media (max-width: 991px) {
  .grey-bg4 .faq-content-wrapper {
    margin-bottom: 50px;
  }
}

.grey-bg4 .faq-content-wrapper .section-title .semi-title {
  margin-bottom: 10px;
}

.grey-bg4 .faq-content-wrapper .section-title .sect-title {
  color: #000;
  font-size: 48px;
  margin-bottom: 30px;
  text-transform: uppercase;
}

@media (max-width: 1100px) {
  .grey-bg4 .faq-content-wrapper .section-title .sect-title {
    font-size: 50px;
  }
}

.grey-bg4 .faq-img-wrapper img {
  border-radius: 5px;
}

.error-area {
  position: relative;
  width: 100%;
  height: 100vh;
}

.error-area .container .section-inner {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.error-area .container .section-inner .page-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 65px;
}

.error-area .container .section-inner .page-title .text {
  font-size: 140px;
}

.error-area .container .section-inner .page-title .text.zero {
  color: var(--theme-color);
}

.error-area .container .section-inner .title {
  margin-bottom: 40px;
}

@media (max-width: 450px) {
  .error-area .container .section-inner .title {
    margin-bottom: 20px;
  }
}

.error-area .container .section-inner .title .sub-title {
  font-size: 48px;
  font-weight: bold;
  padding-bottom: 30px;
}

@media (max-width: 450px) {
  .error-area .container .section-inner .title .sub-title {
    padding-bottom: 15px;
  }
}

.error-area .container .section-inner .title .sect-title {
  font-size: 18px;
  font-weight: 300;
  color: #666666;
  line-height: 28px;
  font-family: "Roboto";
}

.error-area .container .section-inner .section-button a {
  font-size: 14px;
  padding: 17px 30px;
  font-weight: 500;
  color: #fff;
  background: #111111;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  text-transform: uppercase;
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  letter-spacing: 2px;
}

@media (max-width: 1100px) and (min-width: 900px) {
  .error-area .container .section-inner .section-button a {
    display: block;
  }
}

@media (max-width: 450px) {
  .error-area .container .section-inner .section-button a {
    display: block;
    margin: 0 auto;
  }
}

.error-area .container .section-inner .section-button a::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  background: var(--theme-color);
}

.error-area .container .section-inner .section-button a:hover {
  color: #fff;
}

.error-area .container .section-inner .section-button a:hover::before {
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}

.rts-product-details-section {
  padding-bottom: 0;
}

@media (max-width: 768px) {
  .rts-product-details-section .product-thumb-area {
    margin-right: 30px;
  }

  .rts-product-details-section .contents {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
  }
}

@media (max-width: 900px) {
  .rts-product-details-section .details-product-area {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .rts-product-details-section .details-product-area .product-thumb-area {
    width: 100%;
    margin: 0;
    margin-bottom: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .rts-product-details-section .details-product-area .product-thumb-area .thumb-wrapper {
    width: 100%;
  }

  .rts-product-details-section .details-product-area .product-thumb-area .product-thumb {
    margin-bottom: 20px;
  }

  .rts-product-details-section .details-product-area .product-thumb-area .product-thumb-filter-group {
    margin-left: 0;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .rts-product-details-section .details-product-area .product-thumb-area .product-thumb-filter-group .thumb-filter {
    margin-right: 20px;
  }
}

@media (max-width: 768px) {
  .rts-product-details-section {
    padding-top: 50px;
  }
}

.rts-product-details-section .product-thumb-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: 70px;
}

.rts-product-details-section .product-thumb-filter-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-right: 20px;
}

.rts-product-details-section .product-thumb-filter-group .thumb-filter {
  max-width: 70px;
  max-height: 70px;
  margin-bottom: 20px;
  padding: 15px;
  background: #f6f6f6;
  margin-bottom: 15px;
  cursor: pointer;
  -webkit-transition: all 400ms;
  transition: all 400ms;
}

.rts-product-details-section .product-thumb-filter-group .thumb-filter img {
  max-height: 70px;
}

.rts-product-details-section .product-thumb-filter-group .thumb-filter.active {
  border: 2px solid var(--theme-color);
}

.rts-product-details-section .product-row {
  padding-bottom: 10px;
  margin-bottom: 70px;
  border-bottom: 1px solid #f0f0f0;
}

.rts-product-details-section .col-lg-25 {
  width: 20%;
}

@media (max-width: 1200px) {
  .rts-product-details-section .col-lg-25 {
    display: none;
  }
}

.rts-product-details-section .side-products .product-item .product-image {
  max-width: 225px !important;
}

.rts-product-details-section .details-product-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

@media (max-width: 576px) {
  .rts-product-details-section .details-product-area {
    margin-bottom: 30px;
  }
}

.rts-product-details-section .details-product-area table {
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .rts-product-details-section .details-product-area table {
    margin-top: 30px;
  }
}

@media (max-width: 576px) {
  .rts-product-details-section .details-product-area table {
    margin-bottom: 0;
  }
}

.rts-product-details-section .details-product-area table tr {
  border-bottom: 1px solid #f4f4f4;
}

@media (max-width: 768px) {
  .rts-product-details-section .details-product-area table tr {
    border: none;
    margin-bottom: 40px;
  }
}

.rts-product-details-section .details-product-area table tr .first-child {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}

@media (max-width: 768px) {
  .rts-product-details-section .details-product-area table tr .first-child {
    display: block;
    text-align: center;
  }
}

.rts-product-details-section .details-product-area table tr .first-child .image-section {
  width: 20%;
}

@media (max-width: 768px) {
  .rts-product-details-section .details-product-area table tr .first-child .image-section {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .rts-product-details-section .details-product-area table tr .first-child .image-section img {
    width: 50%;
  }
}

.rts-product-details-section .details-product-area table tr .first-child .text {
  display: -ms-grid;
  display: grid;
  margin-left: 20px;
}

@media (max-width: 768px) {
  .rts-product-details-section .details-product-area table tr .first-child .text {
    margin: 10px 0 0 0;
  }
}

.rts-product-details-section .details-product-area table tr .first-child .text a {
  font-size: 14px;
  color: #333333;
}

@media (max-width: 768px) {
  .rts-product-details-section .details-product-area table tr .first-child .text a {
    font-size: 16px;
  }
}

.rts-product-details-section .details-product-area table tr .first-child .text .color {
  color: #222222;
  font-size: 12px;
  line-height: 1.6;
  padding: 5px 0;
}

@media (max-width: 768px) {
  .rts-product-details-section .details-product-area table tr .first-child .text .color {
    font-size: 14px;
  }
}

.rts-product-details-section .details-product-area table tr .first-child .text .price {
  color: #000;
  font-size: 13px;
}

@media (max-width: 768px) {
  .rts-product-details-section .details-product-area table tr .first-child .text .price {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .rts-product-details-section .details-product-area table tr .cart-edit {
    margin: 10px 0 0 0;
  }
}

.rts-product-details-section .details-product-area table tr .cart-edit .quantity-edit {
  width: 110px;
  height: 50px;
}

@media (max-width: 768px) {
  .rts-product-details-section .details-product-area table tr .cart-edit .quantity-edit {
    width: 50%;
    margin: 0 auto;
  }
}

.rts-product-details-section .details-product-area .product-status {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
}

.rts-product-details-section .details-product-area .product-catagory {
  background: var(--theme-color);
  padding: 2px 10px;
  font-size: 14px;
  color: #fff;
  margin-right: 20px;
  font-family: "Roboto";
  font-weight: 400;
}

.rts-product-details-section .details-product-area .rating-stars-group .rating-star i {
  color: var(--theme-color);
  font-size: 15px;
}

.rts-product-details-section .details-product-area .rating-stars-group span {
  font-size: 14px;
  font-weight: 400;
  color: #777777;
}

.rts-product-details-section .details-product-area .product-title {
  font-size: 26px;
  margin-bottom: 7px;
}

.rts-product-details-section .details-product-area .stock {
  font-size: 12px;
  font-weight: 600;
  color: #98bd25;
  padding: 2px 7px;
  border: 1px solid #ededed;
  border-radius: 4px;
  margin-left: 5px;
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
}

.rts-product-details-section .details-product-area .stock1 {
  font-size: 12px;
  font-weight: 600;
  color: #ff0000;
  padding: 3px 7px;
  border: 1px solid #ededed;
  border-radius: 4px;
  margin-left: 5px;
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
}

.rts-product-details-section .details-product-area .product-price {
  margin-bottom: 30px;
  font-size: 36px;
  color: var(--theme-color);
}

.rts-product-details-section .details-product-area .product-price .old-price {
  font-size: 20px;
  font-weight: 500;
  color: #cfcfcf;
  text-decoration: line-through;
}

.rts-product-details-section .details-product-area p {
  font-size: 16px;
  line-height: 26px;
  color: #777777;
  font-weight: 400;
  font-family: "Roboto";
  max-width: 540px;
  margin-bottom: 30px;
}

.rts-product-details-section .details-product-area .action-item2 {
  padding: 10px 0;
  border-radius: 6px;
  margin-bottom: 10px;
}

.rts-product-details-section .details-product-area .action-item2 .action-top {
  padding-bottom: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.rts-product-details-section .details-product-area .action-item2 .action-top .action-title {
  font-size: 16px;
}

.rts-product-details-section .details-product-area .action-item2 .action-top .down-icon i {
  font-size: 17px;
}

.rts-product-details-section .details-product-area .action-item2 .category-item:last-child .category-item-inner {
  margin-bottom: 0;
}

.rts-product-details-section .details-product-area .action-item2 .category-item .category-item-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 10px;
}

.rts-product-details-section .details-product-area .action-item2 .category-item .category-title-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.rts-product-details-section .details-product-area .action-item2 .category-item .point {
  background: #e3e3e3;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
}

.rts-product-details-section .details-product-area .action-item2 .category-item .category-title {
  font-size: 14px;
  color: #777777;
  font-weight: 500;
}

.rts-product-details-section .details-product-area .action-item2 .category-item i {
  font-size: 14px;
  color: #999999;
}

.rts-product-details-section .details-product-area .action-item2 .category-item .sub-categorys {
  margin-left: 20px;
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height 500ms;
  transition: max-height 500ms;
}

.rts-product-details-section .details-product-area .action-item2 .category-item .sub-categorys .sub-categorys-inner {
  padding-bottom: 15px;
}

.rts-product-details-section .details-product-area .action-item2 .category-item .sub-categorys a {
  font-size: 14px;
  color: #777777;
  font-weight: 500;
}

.rts-product-details-section .details-product-area .action-item2 .category-item .sub-categorys a:hover {
  color: #040404;
}

.rts-product-details-section .details-product-area .action-item2 .category-item.show .sub-categorys {
  max-height: 500px;
}

.rts-product-details-section .details-product-area .action-item2 .category-item.show .category-item-inner .down-icon i {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.rts-product-details-section .details-product-area .action-item2 .color-item2 {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
}

.rts-product-details-section .details-product-area .action-item2 .color-item2:hover .color-arrow {
  color: #040404;
}

.rts-product-details-section .details-product-area .action-item2 .color-item2:last-child {
  margin-bottom: 0;
}

.rts-product-details-section .details-product-area .action-item2 .color-item2.selected .color i {
  display: block;
}

.rts-product-details-section .details-product-area .action-item2 .color-item2 .color {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: 10px;
}

.rts-product-details-section .details-product-area .action-item2 .color-item2 .color i {
  display: none;
  color: white;
  font-size: 8px;
  -webkit-transform: translateX(3px) translateY(2px);
  transform: translateX(3px) translateY(2px);
}

.rts-product-details-section .details-product-area .action-item2 .color-item2 .color i::before {
  content: "\f00c";
}

.rts-product-details-section .details-product-area .action-item2 .color-item2 .color.black {
  background: #000;
}

.rts-product-details-section .details-product-area .action-item2 .color-item2 .color.blue {
  background: #0084ff;
}

.rts-product-details-section .details-product-area .action-item2 .color-item2 .color.gray {
  background: #d4d4d4;
}

.rts-product-details-section .details-product-area .action-item2 .color-item2 .color.green {
  background: #61b163;
}

.rts-product-details-section .details-product-area .action-item2 .color-item2 .color.red {
  background: #ff6262;
}

.rts-product-details-section .details-product-area .action-item2 .color-item2 .color.yellow {
  background: #ffc000;
}

.rts-product-details-section .details-product-area .action-item2 .color-item2 .color-name {
  font-size: 14px;
  color: #777777;
}

.rts-product-details-section .details-product-area .action-item2 .color-item2 .color-arrow {
  margin-left: auto;
  color: #b9b9b9;
}

.rts-product-details-section .details-product-area .action-item2 .product-brands .brands-inner {
  max-height: 400px;
  overflow-y: scroll;
}

.rts-product-details-section .details-product-area .action-item2 .product-brands .brands-inner::-webkit-scrollbar {
  width: 3px;
}

.rts-product-details-section .details-product-area .action-item2 .product-brands .brands-inner::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.rts-product-details-section .details-product-area .action-item2 .product-brands .brands-inner::-webkit-scrollbar-thumb {
  background-color: #111111;
  border-radius: 0;
  border: none;
}

.rts-product-details-section .details-product-area .action-item2 .product-brands a {
  font-size: 14px;
  color: #777777;
  margin-bottom: 10px;
  font-weight: 500;
}

.rts-product-details-section .details-product-area .action-item2 .product-brands a:hover {
  color: #040404;
}

.rts-product-details-section .details-product-area .action-item3 {
  padding: 10px 0;
  border-radius: 6px;
  margin-bottom: 30px;
}

.rts-product-details-section .details-product-area .action-item3 .action-top {
  padding-bottom: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.rts-product-details-section .details-product-area .action-item3 .action-top .action-title {
  font-size: 16px;
}

.rts-product-details-section .details-product-area .action-item3 .action-top .down-icon i {
  font-size: 17px;
}

.rts-product-details-section .details-product-area .action-item3 .category-item:last-child .category-item-inner {
  margin-bottom: 0;
}

.rts-product-details-section .details-product-area .action-item3 .category-item .category-item-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 10px;
}

.rts-product-details-section .details-product-area .action-item3 .category-item .category-title-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.rts-product-details-section .details-product-area .action-item3 .category-item .point {
  background: #e3e3e3;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
}

.rts-product-details-section .details-product-area .action-item3 .category-item .category-title {
  font-size: 14px;
  color: #777777;
  font-weight: 500;
}

.rts-product-details-section .details-product-area .action-item3 .category-item i {
  font-size: 14px;
  color: #999999;
}

.rts-product-details-section .details-product-area .action-item3 .category-item .sub-categorys {
  margin-left: 20px;
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height 500ms;
  transition: max-height 500ms;
}

.rts-product-details-section .details-product-area .action-item3 .category-item .sub-categorys .sub-categorys-inner {
  padding-bottom: 15px;
}

.rts-product-details-section .details-product-area .action-item3 .category-item .sub-categorys a {
  font-size: 14px;
  color: #777777;
  font-weight: 500;
}

.rts-product-details-section .details-product-area .action-item3 .category-item .sub-categorys a:hover {
  color: #040404;
}

.rts-product-details-section .details-product-area .action-item3 .category-item.show .sub-categorys {
  max-height: 500px;
}

.rts-product-details-section .details-product-area .action-item3 .category-item.show .category-item-inner .down-icon i {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.rts-product-details-section .details-product-area .action-item3 .color-item2 {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.rts-product-details-section .details-product-area .action-item3 .color-item2:hover .color-arrow {
  color: #040404;
}

.rts-product-details-section .details-product-area .action-item3 .color-item2:last-child {
  margin-bottom: 0;
}

.rts-product-details-section .details-product-area .action-item3 .color-item2.selected .color i {
  display: block;
}

.rts-product-details-section .details-product-area .action-item3 .color-item2 .size {
  padding: 2px 10px;
  background-color: #f5f5f5;
  margin-right: 10px;
  border: 1px solid #f4f4f4;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.rts-product-details-section .details-product-area .action-item3 .color-item2 .size:hover {
  border: 1px solid #000;
}

.rts-product-details-section .details-product-area .action-item3 .color-item2 .color-name {
  font-size: 14px;
  color: #777777;
}

.rts-product-details-section .details-product-area .action-item3 .color-item2 .color-arrow {
  margin-left: auto;
  color: #b9b9b9;
}

.rts-product-details-section .details-product-area .action-item3 .product-brands .brands-inner {
  max-height: 400px;
  overflow-y: scroll;
}

.rts-product-details-section .details-product-area .action-item3 .product-brands .brands-inner::-webkit-scrollbar {
  width: 3px;
}

.rts-product-details-section .details-product-area .action-item3 .product-brands .brands-inner::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.rts-product-details-section .details-product-area .action-item3 .product-brands .brands-inner::-webkit-scrollbar-thumb {
  background-color: #111111;
  border-radius: 0;
  border: none;
}

.rts-product-details-section .details-product-area .action-item3 .product-brands a {
  font-size: 14px;
  color: #777777;
  margin-bottom: 10px;
  font-weight: 500;
}

.rts-product-details-section .details-product-area .action-item3 .product-brands a:hover {
  color: #040404;
}

.rts-product-details-section .details-product-area .product-bottom-action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 30px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media (max-width: 500px) {
  .rts-product-details-section .details-product-area .product-bottom-action {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .rts-product-details-section .details-product-area .product-bottom-action .action-item {
    margin-bottom: 20px;
    padding: 0 17px;
    height: 50px;
  }

  .rts-product-details-section .details-product-area .product-bottom-action .wishlist {
    margin-bottom: 0;
  }
}

.rts-product-details-section .details-product-area .product-bottom-action2 {
  margin: 30px 0 30px 0;
}

.rts-product-details-section .details-product-area .product-bottom-action2 .form-btn {
  background: var(--theme-color);
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  width: 100%;
  padding: 21px 40px;
  border: 1px solid #000;
  border-radius: 0;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.rts-product-details-section .details-product-area .product-bottom-action2 .form-btn:hover {
  background-color: #fff;
  color: #000;
}

.rts-product-details-section .details-product-area .product-bottom-action3 {
  margin: 30px 0 30px 0;
}

.rts-product-details-section .details-product-area .product-bottom-action3 .cart-edit2 {
  margin-bottom: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.rts-product-details-section .details-product-area .product-bottom-action3 .cart-edit2 .quantity-edit {
  width: 110px;
  height: 45px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 3px 10px;
  border-radius: 5px;
}

.rts-product-details-section .details-product-area .product-bottom-action3 .cart-edit2 .quantity-edit input {
  text-align: center;
  max-width: 55px;
  font-size: 16px;
  font-weight: 700;
  color: #040404;
}

.rts-product-details-section .details-product-area .product-bottom-action3 .cart-edit2 .quantity-edit button {
  background: none;
  font-size: 0;
}

.rts-product-details-section .details-product-area .product-bottom-action3 .cart-edit2 .quantity-edit button i {
  font-size: 16px;
  color: #d3d3d3;
}

.rts-product-details-section .details-product-area .product-bottom-action3 .cart-edit2 .quantity-edit button i:hover {
  color: #ff0000;
}

.rts-product-details-section .details-product-area .product-bottom-action3 .cart-edit2 span {
  margin-left: 20px;
  font-size: 14px;
  font-weight: 500;
}

.rts-product-details-section .details-product-area .product-bottom-action3 .cart-edit2 span i {
  color: #ff0000;
}

.rts-product-details-section .details-product-area .product-bottom-action3 .form-btn {
  background: #ff0000;
  color: #ffffff;
  font-size: 20px;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  pointer-events: none;
  width: 100%;
  padding: 18px 40px;
  border: 0;
  border-radius: 0;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.rts-product-details-section .details-product-area .product-bottom-action3 .form-btn:hover i {
  -webkit-transform: translateX(10px);
  transform: translateX(10px);
}

.rts-product-details-section .details-product-area .product-bottom-action3 .form-btn i {
  margin-left: 10px;
  padding-top: 5px;
}

.rts-product-details-section .details-product-area .product-bottom-action4 {
  margin-bottom: 40px;
}

.rts-product-details-section .details-product-area .product-bottom-action4 .button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.rts-product-details-section .details-product-area .product-bottom-action4 .button .form-btn1 {
  background: #fff;
  color: #000;
  font-size: 20px;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  width: 100%;
  padding: 18px 40px;
  margin-right: 10px;
  border: 1px solid #000;
  border-radius: 0;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.rts-product-details-section .details-product-area .product-bottom-action4 .button .form-btn1:hover {
  background-color: #000;
  color: #fff;
}

.rts-product-details-section .details-product-area .product-bottom-action4 .button .form-btn1 i {
  margin-right: 10px;
  -webkit-transition: all 50ms;
  transition: all 50ms;
}

.rts-product-details-section .details-product-area .action-item {
  border-style: solid;
  border-width: 1px;
  border-color: #e2e2e2;
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.rts-product-details-section .details-product-area .cart-edit {
  margin-right: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.rts-product-details-section .details-product-area .cart-edit .quantity-edit {
  width: 123px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 3px 10px;
  border-radius: 0;
}

.rts-product-details-section .details-product-area .cart-edit .quantity-edit input {
  text-align: center;
  max-width: 55px;
  font-size: 16px;
  font-weight: 700;
  color: #040404;
}

.rts-product-details-section .details-product-area .cart-edit .quantity-edit button {
  background: none;
  font-size: 0;
}

.rts-product-details-section .details-product-area .cart-edit .quantity-edit button i {
  font-size: 16px;
  color: #d3d3d3;
}

.rts-product-details-section .details-product-area .cart-edit span {
  margin-left: 20px;
  font-size: 14px;
  font-weight: 500;
}

.rts-product-details-section .details-product-area .cart-edit span i {
  color: #ff0000;
}

.rts-product-details-section .details-product-area .addto-cart-btn {
  padding: 15px 30px;
  font-size: 16px;
  font-weight: 600;
  margin-right: 20px;
  color: #fff;
  background: #000000;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  text-transform: uppercase;
  position: relative;
  -webkit-transition-property: color;
  border: none;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  letter-spacing: 2px;
  transition-duration: 0.3s;
}

.rts-product-details-section .details-product-area .addto-cart-btn::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  background: var(--theme-color);
}

.rts-product-details-section .details-product-area .addto-cart-btn:hover {
  color: #fff;
}

.rts-product-details-section .details-product-area .addto-cart-btn:hover::before {
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}

.rts-product-details-section .details-product-area .wishlist-btn {
  padding: 0 20px;
}

.rts-product-details-section .details-product-area .wishlist-btn:hover {
  border: 1px solid var(--theme-color);
}

.rts-product-details-section .details-product-area .wishlist-btn:hover i {
  color: var(--theme-color);
}

.rts-product-details-section .details-product-area .wishlist-btn i {
  color: #e2e2e2;
}

.rts-product-details-section .details-product-area .product-uniques {
  margin-bottom: 20px;
}

.rts-product-details-section .details-product-area .product-unipue {
  font-size: 16px;
  color: #777777;
  font-weight: 400;
  display: block;
  margin-bottom: 5px;
  font-family: "Roboto";
}

.rts-product-details-section .details-product-area .product-unipue span {
  display: inline-block;
  font-weight: 700;
  font-family: "Roboto";
}

.rts-product-details-section .details-product-area .share-social span {
  margin-right: 3px;
}

.rts-product-details-section .details-product-area .share-social .platform {
  font-size: 16px;
  margin-right: 10px;
  color: #999999;
}

.rts-product-details-section .details-product-area .share-social .platform:hover {
  color: var(--theme-color);
}

.rts-product-details-section .product-full-details-area .details-filter-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: 15px;
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 30px;
}

.rts-product-details-section .product-full-details-area .details-filter-bar .details-filter {
  font-size: 16px;
  margin-right: 50px;
  font-weight: 700;
  color: #111111;
  font-family: "Roboto";
  border-radius: 6px;
}

.rts-product-details-section .product-full-details-area .details-filter-bar .details-filter.active {
  color: var(--theme-color);
}

.rts-product-details-section .product-full-details-area .details-filter-bar .details-filter:last-child {
  margin-right: 0;
}

.rts-product-details-section .product-full-details-area .details-filter-bar2 {
  display: block;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: 15px;
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 30px;
}

.rts-product-details-section .product-full-details-area .details-filter-bar2 .details-filter {
  font-size: 16px;
  margin-right: 50px;
  font-weight: 700;
  color: #040404;
  font-family: "Roboto";
}

@media (max-width: 768px) {
  .rts-product-details-section .product-full-details-area .details-filter-bar2 .details-filter {
    margin-right: 20px;
  }
}

.rts-product-details-section .product-full-details-area .details-filter-bar2 .details-filter.active {
  color: #040404;
}

.rts-product-details-section .product-full-details-area .details-filter-bar2 .details-filter:last-child {
  margin-right: 0;
}

.rts-product-details-section .product-full-details-area .full-details-inner {
  margin-bottom: -20px;
}

.rts-product-details-section .product-full-details-area .full-details-inner p {
  -webkit-transition: height 500ms;
  transition: height 500ms;
  max-width: unset;
  font-size: 16px;
  line-height: 26px;
  color: #777777;
  font-weight: 400;
  font-family: "Roboto";
}

@media (max-width: 1200px) {
  .rts-product-details-section .product-full-details-area .details-filter-bar {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

@media (max-width: 768px) {
  .rts-product-details-section .product-full-details-area .details-filter-bar .details-filter {
    font-size: 14px;
    margin-right: 30px;
  }
}

@media (max-width: 507px) {
  .rts-product-details-section .product-full-details-area .details-filter-bar .details-filter {
    font-size: 14px;
    margin-right: 30px;
    margin-bottom: 10px;
  }
}

.rts-product-details-section .product-full-details-area .full-details.dls-three.filterd-items {
  width: 100%;
}

.rts-product-details-section .product-full-details-area .full-details.dls-three.filterd-items .full-details-inner p {
  font-size: 18px;
  color: #777777;
  margin: 0 0 15px;
}

.rts-product-details-section .product-full-details-area .full-details.dls-three.filterd-items .full-details-inner .reveiw-form .section-title {
  color: #000;
  margin-bottom: 20px;
}

.rts-product-details-section .product-full-details-area .full-details.dls-three.filterd-items .full-details-inner .reveiw-form .section-title a {
  color: #000;
}

.rts-product-details-section .product-full-details-area .full-details.dls-three.filterd-items .full-details-inner .reveiw-form .section-title a:hover {
  color: #000;
  text-decoration: underline;
}

.rts-product-details-section .product-full-details-area .full-details.dls-three.filterd-items .full-details-inner .reveiw-form .sect-title {
  font-weight: 400;
}

.rts-product-details-section .product-full-details-area .full-details.dls-three.filterd-items .full-details-inner .reveiw-form-main .post-title {
  font-size: 26px;
  margin-bottom: 35px;
  padding-top: 30px;
}

@media (max-width: 450px) {
  .rts-product-details-section .product-full-details-area .full-details.dls-three.filterd-items .full-details-inner .reveiw-form-main .post-title {
    font-size: 20px;
  }
}

.rts-product-details-section .product-full-details-area .full-details.dls-three.filterd-items .full-details-inner .reveiw-form-main .contact-form {
  padding: 30px 40px 35px 0;
}

.rts-product-details-section .product-full-details-area .full-details.dls-three.filterd-items .full-details-inner .reveiw-form-main .contact-form .input-box {
  position: relative;
  z-index: 1;
  margin-bottom: 25px;
}

.rts-product-details-section .product-full-details-area .full-details.dls-three.filterd-items .full-details-inner .reveiw-form-main .contact-form .input-box.text-input::before {
  content: "\f303";
}

.rts-product-details-section .product-full-details-area .full-details.dls-three.filterd-items .full-details-inner .reveiw-form-main .contact-form .input-box.mail-input::before {
  content: "\f0e0";
}

.rts-product-details-section .product-full-details-area .full-details.dls-three.filterd-items .full-details-inner .reveiw-form-main .contact-form .input-box.sub-input::before {
  content: "\f0ac";
}

.rts-product-details-section .product-full-details-area .full-details.dls-three.filterd-items .full-details-inner .reveiw-form-main .contact-form .input-box::before {
  content: "\f007";
  left: auto;
  right: 30px;
  font-size: 14px;
  font-family: "Font awesome 5 Pro";
  position: absolute;
  font-size: 16px;
  color: #083d59;
  top: 17px;
  z-index: 2;
}

.rts-product-details-section .product-full-details-area .full-details.dls-three.filterd-items .full-details-inner .reveiw-form-main .contact-form .input-box textarea {
  border: 2px solid #f4f4f4;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding-left: 30px;
  color: #000;
  font-size: 14px;
  background-color: #ffffff;
  width: 100%;
  height: 281px;
  border-radius: 0;
  padding-top: 16px;
  resize: none;
  position: relative;
  z-index: 1;
}

.rts-product-details-section .product-full-details-area .full-details.dls-three.filterd-items .full-details-inner .reveiw-form-main .contact-form .input-box textarea:focus {
  outline: 1px solid var(--theme-color);
}

@media (max-width: 450px) {
  .rts-product-details-section .product-full-details-area .full-details.dls-three.filterd-items .full-details-inner .reveiw-form-main .contact-form .input-box textarea {
    height: 150px;
  }
}

.rts-product-details-section .product-full-details-area .full-details.dls-three.filterd-items .full-details-inner .reveiw-form-main .contact-form .input-box input {
  border: 2px solid #f4f4f4;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding-left: 30px;
  height: 60px;
  border-radius: 0;
  color: #000;
  font-size: 14px;
  background-color: #ffffff;
  position: relative;
  width: 100%;
  z-index: 1;
}

.rts-product-details-section .product-full-details-area .full-details.dls-three.filterd-items .full-details-inner .reveiw-form-main .contact-form .input-box input:focus {
  outline: 1px solid var(--theme-color);
}

.rts-product-details-section .product-full-details-area .full-details.dls-three.filterd-items .full-details-inner .reveiw-form-main .contact-form .rating {
  margin: 25px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.rts-product-details-section .product-full-details-area .full-details.dls-three.filterd-items .full-details-inner .reveiw-form-main .contact-form .rating p {
  margin: 0;
}

.rts-product-details-section .product-full-details-area .full-details.dls-three.filterd-items .full-details-inner .reveiw-form-main .contact-form .rating .rating-icon {
  margin-left: 30px;
}

.rts-product-details-section .product-full-details-area .full-details.dls-three.filterd-items .full-details-inner .reveiw-form-main .contact-form .rating .rating-icon a {
  position: relative;
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

.rts-product-details-section .product-full-details-area .full-details.dls-three.filterd-items .full-details-inner .reveiw-form-main .contact-form .rating .rating-icon a::after {
  display: none;
  content: "\f005";
  left: auto;
  right: 0;
  font-size: 14px;
  font-family: "Font awesome 5 Pro";
  position: absolute;
  font-size: 16px;
  color: #ff0000;
  top: 0;
  z-index: 2;
}

.rts-product-details-section .product-full-details-area .full-details.dls-three.filterd-items .full-details-inner .reveiw-form-main .contact-form .rating .rating-icon a:hover::after {
  display: block;
}

.rts-product-details-section .product-full-details-area .full-details.dls-three.filterd-items .full-details-inner .reveiw-form-main .form-btn {
  padding: 15px 30px;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background: #000000;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  text-transform: uppercase;
  position: relative;
  -webkit-transition-property: color;
  border: none;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  letter-spacing: 2px;
  transition-duration: 0.3s;
}

.rts-product-details-section .product-full-details-area .full-details.dls-three.filterd-items .full-details-inner .reveiw-form-main .form-btn::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  background: var(--theme-color);
}

.rts-product-details-section .product-full-details-area .full-details.dls-three.filterd-items .full-details-inner .reveiw-form-main .form-btn:hover {
  color: #fff;
}

.rts-product-details-section .product-full-details-area .full-details.dls-three.filterd-items .full-details-inner .reveiw-form-main .form-btn:hover::before {
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}

@media (max-width: 1024px) {
  .product-area {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .product-area .product-thumb-area {
    margin: 0 auto;
    margin-bottom: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .product-area .product-thumb-area .product-thumb {
    margin-bottom: 30px;
  }

  .product-area .product-thumb-filter-group {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .product-area .product-thumb-filter-group .details-filter {
    margin-bottom: 0;
    margin-right: 20px;
  }

  .product-area .product-bottom-action .action-item {
    height: 50px;
    padding: 15px;
  }
}

.rts-product-details-section2 .product-thumb-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.rts-product-details-section2 .product-thumb-area .product-thumb-wrapper {
  width: 560px;
  height: 560px;
  background: #f6f6f6;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.rts-product-details-section2 .product-thumb-area .product-thumb {
  margin-bottom: 30px;
}

.rts-product-details-section2 .product-thumb-area .product-thumb-filter-group {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

@media (max-width: 1200px) {
  .rts-product-details-section2 .details-product-area {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .rts-product-details-section2 .details-product-area .product-thumb-area {
    width: 100%;
    margin: 0;
    margin-bottom: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .rts-product-details-section2 .details-product-area .product-thumb-area .thumb-wrapper {
    width: 100%;
  }

  .rts-product-details-section2 .details-product-area .product-thumb-area .product-thumb {
    margin-bottom: 20px;
  }

  .rts-product-details-section2 .details-product-area .product-thumb-area .product-thumb-filter-group {
    margin-left: 0;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .rts-product-details-section2 .details-product-area .product-thumb-area .product-thumb-filter-group .thumb-filter {
    margin-right: 20px;
  }
}

@media (max-width: 1310px) {
  .rts-product-details-section2 .product-thumb-area {
    margin-right: 45px;
  }

  .rts-product-details-section2 .product-thumb-area .product-thumb-wrapper {
    width: 500px;
    height: 500px;
  }
}

@media (max-width: 1200px) {
  .rts-product-details-section2 .product-thumb-area {
    margin-right: 45px;
  }

  .rts-product-details-section2 .product-thumb-area .product-thumb-filter-group {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-right: 0;
  }

  .rts-product-details-section2 .product-thumb-area .product-thumb-filter-group .thumb-filter:last-child {
    margin-right: 0;
  }

  .rts-product-details-section2 .product-thumb-area .product-thumb-wrapper {
    width: 100%;
    height: 500px;
  }
}

.product-full-details-area.product-full-details-area2 {
  display: block;
}

.product-full-details-area.product-full-details-area2 .details-filter-bar {
  margin-right: 30px;
  border: none;
  -webkit-box-orient: unset;
  -webkit-box-direction: unset;
  -ms-flex-direction: unset;
  flex-direction: unset;
}

.product-full-details-area.product-full-details-area2 .details-filter-bar .details-filter {
  width: 220px;
  height: 60px;
  margin-right: 20px !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin: 0;
  margin-bottom: 10px;
  border: 1px solid #ebebeb;
  border-radius: 0;
}

.product-full-details-area.product-full-details-area2 .details-filter-bar .details-filter.active {
  background: var(--theme-color);
  color: #fff !important;
  border: 1px solid transparent;
}

@media (max-width: 1200px) {
  .product-full-details-area.product-full-details-area2 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .product-full-details-area.product-full-details-area2 .details-filter-bar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: 0;
  }

  .product-full-details-area.product-full-details-area2 .details-filter-bar .details-filter {
    margin-bottom: 0;
    margin-right: 10px;
  }
}

@media (max-width: 950px) {
  .product-full-details-area.product-full-details-area2 .details-filter-bar .details-filter {
    margin-bottom: 10px;
  }
}

@media (max-width: 768px) {
  .product-full-details-area.product-full-details-area2 .details-filter-bar {
    margin-right: 0 !important;
  }

  .product-full-details-area.product-full-details-area2 .details-filter-bar .details-filter {
    margin-bottom: 10px;
    width: 170px;
    height: 70px;
    font-size: 14px;
  }
}

@media (max-width: 450px) {
  .product-full-details-area.product-full-details-area2 .details-filter-bar .details-filter {
    width: 100%;
    margin-right: 0 !important;
  }
}

.rts-featured-product-section-inner {
  padding: 80px 0;
}

.rts-featured-product-section-inner .go-btn {
  color: #040404;
}

.product-details-popup-wrapper {
  width: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 99;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all 500ms;
  transition: all 500ms;
  pointer-events: none;
}

.product-details-popup-wrapper.popup {
  visibility: visible;
  opacity: 1;
}

.product-details-popup-wrapper.popup .product-details-popup {
  pointer-events: all !important;
}

.rts-product-details-section.product-details-popup-section {
  position: relative;
  border: none;
}

@media (max-width: 1200px) {
  .rts-product-details-section.product-details-popup-section .details-product-area .contents {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    text-align: left;
    margin: 0;
  }
}

@media (max-width: 900px) {
  .rts-product-details-section.product-details-popup-section .details-product-area {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .rts-product-details-section.product-details-popup-section .details-product-area .contents {
    max-height: 450px;
    overflow-y: scroll;
  }
}

@media (max-width: 768px) {
  .rts-product-details-section.product-details-popup-section .product-details-popup {
    margin-top: 100px;
    padding: 20px;
    width: 92%;
    height: 90vh;
    overflow-y: scroll;
  }

  .rts-product-details-section.product-details-popup-section .details-product-area {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .rts-product-details-section.product-details-popup-section .details-product-area .product-thumb-area {
    width: 100%;
    margin: 0 !important;
    margin-bottom: 30px !important;
  }

  .rts-product-details-section.product-details-popup-section .details-product-area .product-thumb-area .product-thumb {
    width: 100%;
  }

  .rts-product-details-section.product-details-popup-section .details-product-area .contents {
    max-height: unset;
    overflow: visible;
  }
}

.product-details-popup {
  pointer-events: none;
  margin: 0 auto;
  height: 520px;
  border-radius: 7px;
  background: #fff;
  padding: 30px !important;
  position: relative;
  z-index: 999;
}

.product-details-popup .product-thumb-area {
  margin-right: 40px !important;
}

.product-details-popup .product-thumb {
  width: 303px;
}

.product-details-popup .product-details-close-btn {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  right: -50px;
  top: -50px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #fff;
}

.product-details-popup .product-details-close-btn:hover i {
  color: red;
}

.product-details-popup .product-details-close-btn i {
  font-size: 20px;
  color: #040404;
}

@media (max-width: 1200px) {
  .product-details-popup .product-details-close-btn {
    right: 0;
  }
}

@media (max-width: 1200px) {
  .product-details-popup {
    width: 90%;
  }
}

.rts-related-product-section {
  padding: 120px 0 55px 0;
}

.rts-related-product-section .product-item {
  background: none;
}

.rts-related-product-section .product-item .product-thumb {
  border: none;
  border-radius: 0;
}

.rts-related-product-section .product-item:hover .social-area {
  opacity: 1;
  bottom: 130px;
}

.rts-related-product-section .product-item .product-tag-area .sale-tag {
  background: #111111;
}

.rts-related-product-section .product-item .social-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #111111;
  width: 220px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 12px 0;
  position: absolute;
  opacity: 0;
  bottom: 110px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.rts-related-product-section .product-item .social-area li a {
  color: #fff;
  margin: 0 10px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.rts-related-product-section .product-item .social-area li a:hover {
  color: var(--theme-color);
}

.rts-related-product-section .team-section-inner {
  position: relative;
}

.rts-related-product-section .team-section-inner .slider-button {
  position: absolute;
  top: -95px;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 1;
}

@media (max-width: 500px) {
  .rts-related-product-section .team-section-inner .slider-button {
    display: none;
  }
}

.rts-related-product-section .team-section-inner .slider-button .slide-prev {
  background: #fff;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  display: block;
  border: 1px solid #e7e7e7;
  margin-right: 20px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.rts-related-product-section .team-section-inner .slider-button .slide-prev:hover {
  color: #fff;
  background: var(--theme-color);
}

.rts-related-product-section .team-section-inner .slider-button .slide-next {
  background: var(--theme-color);
  color: #fff;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  display: block;
  border: 1px solid var(--theme-color);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.rts-related-product-section .team-section-inner .slider-button .slide-next:hover {
  color: #111111;
  background: none;
  border: 1px solid #e7e7e7;
}

.rts-checkout-section {
  margin: 120px 0 90px 0;
}

@media (max-width: 768px) {
  .rts-checkout-section {
    margin: 50px 0;
  }
}

.rts-checkout-section .coupon-area {
  margin-bottom: 40px;
}

.rts-checkout-section .coupon-ask {
  padding: 20px 30px;
  background: #f5f5f5;
  margin-bottom: 10px;
}

.rts-checkout-section .coupon-ask span {
  font-size: 16px;
  color: #777777;
  font-weight: 400;
}

.rts-checkout-section .coupon-ask button {
  color: #040404;
  font-weight: 700;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.rts-checkout-section .coupon-ask button:hover {
  color: var(--theme-color);
}

.rts-checkout-section .coupon-input-area {
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height 300ms;
  transition: max-height 300ms;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.rts-checkout-section .coupon-input-area input {
  width: 80%;
  padding: 0 30px;
  height: 50px;
  border: 1px solid #e3e3e3;
  outline: none;
  -webkit-transition: -webkit-box-shadow 300ms;
  transition: -webkit-box-shadow 300ms;
  transition: box-shadow 300ms;
  transition: box-shadow 300ms, -webkit-box-shadow 300ms;
  font-size: 16px;
  color: #777777;
}

.rts-checkout-section .coupon-input-area input:focus {
  border: 1px solid var(--theme-color);
}

.rts-checkout-section .coupon-input-area .apply-btn {
  width: 18%;
  height: 50px;
  background: var(--theme-color);
  color: #fff;
}

.rts-checkout-section .coupon-input-area .apply-btn:hover {
  background: #111111;
}

.rts-checkout-section .coupon-input-area.show {
  max-height: 100px;
}

.rts-checkout-section .checkout-form input,
.rts-checkout-section .checkout-form select,
.rts-checkout-section .checkout-form textarea {
  width: 100%;
  padding: 17px 30px;
  border: 1px solid #e3e3e3;
  margin-bottom: 25px;
  outline: none;
  -webkit-transition: -webkit-box-shadow 300ms;
  transition: -webkit-box-shadow 300ms;
  transition: box-shadow 300ms;
  transition: box-shadow 300ms, -webkit-box-shadow 300ms;
  font-size: 16px;
  color: #777777;
}

.rts-checkout-section .checkout-form input:focus,
.rts-checkout-section .checkout-form select:focus,
.rts-checkout-section .checkout-form textarea:focus {
  border: 1px solid var(--theme-color);
}

.rts-checkout-section .check-options {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
}

.rts-checkout-section .check-options .form-group {
  margin-right: 15px;
}

.rts-checkout-section .check-options .form-group label {
  font-size: 16px;
  color: #777777;
}

.rts-checkout-section .check-options .form-group label::before {
  top: -1px;
  border-radius: 50%;
  margin-right: 7px;
}

.rts-checkout-section .check-options .form-group label::after {
  top: 7px;
}

.rts-checkout-section .action-item {
  padding: 30px;
  border: 1px solid #f0f0f0;
  border-radius: 6px;
  margin-bottom: 30px;
}

@media (max-width: 500px) {
  .rts-checkout-section .action-item {
    padding: 20px;
  }
}

.rts-checkout-section .action-item .action-top {
  padding-bottom: 10px;
  margin-bottom: 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #f0f0f0;
}

.rts-checkout-section .action-item .action-top .action-title {
  font-size: 16px;
  color: #777777;
}

.rts-checkout-section .action-item .action-top .down-icon i {
  font-size: 17px;
}

.rts-checkout-section .action-item .action-middle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.rts-checkout-section .action-item .checkout-options {
  padding-bottom: 10px;
}

.rts-checkout-section .action-item .checkout-options .shipping {
  font-size: 16px;
  margin-bottom: 5px;
}

.rts-checkout-section .action-item .checkout-options .form-group label::before {
  border-radius: 50%;
}

.rts-checkout-section .action-item .checkout-options .form-group label::after {
  top: 9px;
  left: 5px;
}

.rts-checkout-section .action-item .checkout-options input:checked+label:before {
  background: var(--theme-color);
}

.rts-checkout-section .action-item .checkout-options input:checked+label:after {
  border: solid #fff;
  border-width: 0 2px 2px 0;
}

.rts-checkout-section .action-item .payment-options {
  padding-bottom: 0;
}

.rts-checkout-section .action-item .payment-options .form-group.last-child {
  margin-bottom: 0;
}

.rts-checkout-section .action-item .payment-options .form-group .check-title a {
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.rts-checkout-section .action-item .payment-options .form-group .check-title a:hover {
  color: var(--theme-color);
}

.rts-checkout-section .action-item .action-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-top: 1px solid #f0f0f0;
  padding-top: 10px;
}

.rts-checkout-section .action-item .category-item:last-child .category-item-inner {
  margin-bottom: 0;
}

.rts-checkout-section .action-item .category-item .category-item-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 10px;
}

.rts-checkout-section .action-item .category-item .category-title-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.rts-checkout-section .action-item .category-item .point {
  background: #e3e3e3;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
}

.rts-checkout-section .action-item .category-item .category-title {
  font-size: 14px;
  color: #777777;
  font-weight: 500;
}

.rts-checkout-section .action-item .category-item i {
  font-size: 14px;
  color: #999999;
}

.rts-checkout-section .action-item .category-item .sub-categorys {
  margin-left: 20px;
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height 500ms;
  transition: max-height 500ms;
}

.rts-checkout-section .action-item .category-item .sub-categorys .sub-categorys-inner {
  padding-bottom: 15px;
}

.rts-checkout-section .action-item .category-item .sub-categorys a {
  font-size: 14px;
  color: #777777;
  font-weight: 500;
}

.rts-checkout-section .action-item .category-item .sub-categorys a:hover {
  color: #040404;
}

.rts-checkout-section .action-item .category-item.show .sub-categorys {
  max-height: 500px;
}

.rts-checkout-section .action-item .category-item.show .category-item-inner .down-icon i {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.rts-checkout-section .action-item .color-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
}

.rts-checkout-section .action-item .color-item:hover .color-arrow {
  color: #040404;
}

.rts-checkout-section .action-item .color-item:last-child {
  margin-bottom: 0;
}

.rts-checkout-section .action-item .color-item.selected .color i {
  display: block;
}

.rts-checkout-section .action-item .color-item .color {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  margin-right: 10px;
}

.rts-checkout-section .action-item .color-item .color i {
  display: none;
  color: white;
  font-size: 8px;
  -webkit-transform: translateX(3px) translateY(2px);
  transform: translateX(3px) translateY(2px);
}

.rts-checkout-section .action-item .color-item .color-name {
  font-size: 14px;
  color: #777777;
}

.rts-checkout-section .action-item .color-item .color-arrow {
  margin-left: auto;
  color: #b9b9b9;
}

.rts-checkout-section .action-item .product-brands .brands-inner {
  max-height: 400px;
  overflow-y: scroll;
}

.rts-checkout-section .action-item .product-brands .brands-inner::-webkit-scrollbar {
  width: 3px;
}

.rts-checkout-section .action-item .product-brands .brands-inner::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.rts-checkout-section .action-item .product-brands .brands-inner::-webkit-scrollbar-thumb {
  background-color: #111111;
  border-radius: 0;
  border: none;
}

.rts-checkout-section .action-item .product-brands a {
  font-size: 14px;
  color: #777777;
  margin-bottom: 10px;
  font-weight: 500;
}

.rts-checkout-section .action-item .product-brands a:hover {
  color: #040404;
}

.place-order-btn {
  padding: 15px 30px;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background: #000000;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  text-transform: uppercase;
  position: relative;
  -webkit-transition-property: color;
  border: none;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.place-order-btn::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  background: var(--theme-color);
}

.place-order-btn:hover {
  color: #fff;
}

.place-order-btn:hover::before {
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}

.rts-cart-section {
  padding-bottom: 80px;
}

.rts-cart-section .pretitle {
  font-size: 14px;
  color: #777777;
  font-weight: 500;
}

.rts-cart-section .section-title {
  margin-bottom: 40px;
}

.rts-cart-section tr:first-child {
  border-style: solid;
}

.rts-cart-section .table-bordered> :not(caption)>* {
  border-top-width: 1px;
}

.rts-cart-section .product-thumb {
  max-width: 150px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px;
}

.rts-cart-section .product-title {
  font-size: 16px;
  font-weight: 500;
}

.rts-cart-section .product-price {
  color: #040404;
  font-size: 14px;
}

.rts-cart-section .cart-edit .quantity-edit {
  border: 1px solid #e7e7e7;
  height: 60px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 0;
}

.rts-cart-section .remove-btn {
  font-size: 14px;
  color: #777777;
  font-weight: 500;
}

.rts-cart-section .remove-btn:hover {
  color: #040404;
}

.rts-cart-section .coupon-apply {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

.rts-cart-section .coupon-apply .coupon-text {
  font-size: 18px;
  margin-right: 20px;
}

.rts-cart-section .coupon-apply .apply-input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.rts-cart-section .coupon-apply .apply-input input {
  width: 280px;
  border: 1px solid #e7e7e7;
  border-radius: 0;
  padding: 0 20px;
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.rts-cart-section .coupon-apply .apply-input input::-webkit-input-placeholder {
  font-size: 14px;
  color: #999999;
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
}

.rts-cart-section .coupon-apply .apply-input .apply-btn {
  padding: 18px 30px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background: #000000;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  text-transform: uppercase;
  position: relative;
  -webkit-transition-property: color;
  border: none;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.rts-cart-section .coupon-apply .apply-input .apply-btn::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  background: var(--theme-color);
}

.rts-cart-section .coupon-apply .apply-input .apply-btn:hover {
  color: #fff;
}

.rts-cart-section .coupon-apply .apply-input .apply-btn:hover::before {
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}

@media (max-width: 1200px) {
  .rts-cart-section .cart-table-area {
    margin-bottom: 40px;
  }
}

@media (max-width: 768px) {
  .rts-cart-section tr {
    padding: 30px 0;
  }

  .rts-cart-section .product-thumb {
    margin-bottom: 15px;
  }

  .rts-cart-section .product-title-area {
    margin-bottom: 10px;
  }

  .rts-cart-section .product-price {
    margin-bottom: 10px;
  }

  .rts-cart-section .cart-edit {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .rts-cart-section .cart-edit .quantity-edit {
    margin: 0 auto;
  }

  .rts-cart-section .coupon-apply {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0 20px;
  }

  .rts-cart-section .coupon-apply .coupon-text {
    margin-bottom: 10px;
  }

  .rts-cart-section .coupon-apply .apply-input input {
    width: unset !important;
  }
}

.checkout-box .checkout-box-inner {
  padding: 40px;
  border: 1px solid #f1f1f1;
  border-radius: 6px;
  margin-bottom: 20px;
}

.checkout-box .checkout-box-inner .subtotal-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-bottom: 5px;
  margin-bottom: 30px;
  border-bottom: 1px solid #f1f1f1;
}

.checkout-box .checkout-box-inner .subtotal-area .title {
  font-size: 16px;
  color: #777777;
}

.checkout-box .checkout-box-inner .subtotal-area .subtotal-price {
  font-size: 16px;
  font-weight: 700;
}

.checkout-box .checkout-box-inner .shipping-check .title {
  font-size: 16px;
  margin-bottom: 7px;
}

.checkout-box .checkout-box-inner .shipping-check .check-options {
  margin-bottom: 20px;
}

.checkout-box .checkout-box-inner .shipping-check .check-options .check-title {
  font-size: 14px;
  color: #777777;
}

.checkout-box .checkout-box-inner .shipping-location {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 30px;
}

.checkout-box .checkout-box-inner .shipping-location span {
  font-size: 14px;
}

.checkout-box .checkout-box-inner .shipping-location span span {
  color: #985034;
}

.checkout-box .checkout-box-inner .shipping-location span i {
  font-size: 14px;
  color: #985034;
}

.checkout-box .checkout-box-inner .total-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 10px;
  border-top: 1px solid #f1f1f1;
}

.checkout-box .checkout-box-inner .total-area .title {
  font-size: 16px;
  color: #777777;
}

.checkout-box .checkout-box-inner .total-area .total-price {
  font-size: 16px;
  font-weight: 700;
}

.checkout-box .procced-btn {
  padding: 15px 30px;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background: #000000;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  text-transform: uppercase;
  position: relative;
  -webkit-transition-property: color;
  border: none;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.checkout-box .procced-btn::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  background: var(--theme-color);
}

.checkout-box .procced-btn:hover {
  color: #fff;
}

.checkout-box .procced-btn:hover::before {
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}

.checkout-box .continue-shopping {
  font-size: 16px;
  font-weight: 700;
}

.checkout-box .continue-shopping:hover {
  color: var(--theme-color);
}

.checkout-box .continue-shopping:hover i {
  -webkit-transform: translateX(-5px);
  transform: translateX(-5px);
}

.checkout-box .continue-shopping i {
  margin-right: 5px;
}

.form-group {
  display: block;
  margin-bottom: 10px;
}

.form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-group label {
  position: relative;
  cursor: pointer;
}

.form-group label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  background: #e3e3e3;
  padding: 7px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 10px;
}

.form-group input:checked+label:after {
  content: "";
  display: block;
  position: absolute;
  top: 6px;
  left: 6px;
  width: 4px;
  height: 8px;
  border: solid #040404;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

@media (max-width: 1200px) {
  .rts-shop-section .product-pagination-area {
    margin-bottom: 30px;
  }
}

@media (max-width: 500px) {
  .rts-shop-section .shop-product-topbar {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin-bottom: 20px;
  }

  .rts-shop-section .shop-product-topbar .items-onlist {
    margin-bottom: 10px;
  }

  .rts-shop-section .shop-product-topbar .filter-area {
    margin-left: 0;
  }

  .rts-shop-section .shop-product-topbar .separator {
    margin: 0 10px !important;
  }

  .rts-shop-section .shop-product-topbar .grid-btn {
    margin-left: auto;
  }

  .rts-shop-section .shop-product-topbar .list-btn {
    margin-right: 0;
  }
}

.shop-product-topbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 40px;
}

.shop-product-topbar .items-onlist {
  font-size: 18px;
  line-height: 26px;
  color: #111111;
  font-weight: 600;
}

.shop-product-topbar .items-onlist span {
  color: var(--theme-color);
}

.shop-product-topbar .list-btn {
  width: 22px;
}

.shop-product-topbar .filter-area {
  margin-left: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.shop-product-topbar .filter-area .product-filter {
  font-size: 16px;
  color: #111111;
  font-weight: 500;
}

.shop-product-topbar .filter-area .product-filter i {
  margin-right: 5px;
  color: var(--theme-color);
}

.shop-product-topbar .filter-area .separator {
  position: relative;
  top: unset;
  right: unset;
  margin: 0 25px;
  width: 2px;
  height: 14px;
  background: #f0f0f0;
}

.shop-product-topbar .filter-area .product-show {
  font-size: 16px;
  color: #111111;
  font-weight: 500;
  margin-right: 40px;
}

.shop-product-topbar .filter-area .product-show select {
  border: none;
  font-weight: 700;
  cursor: pointer;
}

.shop-product-topbar .filter-area .product-show select option {
  background: #040404;
  color: #fff;
}

.shop-product-topbar .filter-area .product-show span {
  color: #040404;
}

.shop-product-topbar .filter-area .product-show i {
  font-size: 10px;
  margin-left: 5px;
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
}

.shop-product-topbar .filter-area .grid-btn {
  margin-right: 15px;
}

@media (max-width: 768px) {
  .shop-product-topbar {
    margin-bottom: 20px;
  }
}

@media (max-width: 540px) {
  .shop-product-topbar {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .shop-product-topbar .items-onlist {
    margin-bottom: 7px;
  }

  .shop-product-topbar .filter-area {
    margin-left: 0;
  }

  .shop-product-topbar .filter-area .separator {
    margin: 0 15px;
  }
}

.shop-side-action .action-item {
  padding: 30px;
  border: 1px solid #f0f0f0;
  border-radius: 6px;
  margin-bottom: 30px;
}

.shop-side-action .action-item .action-top {
  padding-bottom: 10px;
  margin-bottom: 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #f0f0f0;
}

.shop-side-action .action-item .action-top .action-title {
  font-size: 16px;
}

.shop-side-action .action-item .action-top .down-icon i {
  font-size: 17px;
}

.shop-side-action .action-item .category-item:last-child .category-item-inner {
  margin-bottom: 0;
}

.shop-side-action .action-item .category-item .category-item-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 10px;
}

.shop-side-action .action-item .category-item .category-title-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.shop-side-action .action-item .category-item .point {
  background: #e3e3e3;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
}

.shop-side-action .action-item .category-item .category-title {
  font-size: 14px;
  color: #777777;
  font-weight: 500;
}

.shop-side-action .action-item .category-item i {
  font-size: 14px;
  color: #999999;
}

.shop-side-action .action-item .category-item .sub-categorys {
  margin-left: 20px;
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height 500ms;
  transition: max-height 500ms;
}

.shop-side-action .action-item .category-item .sub-categorys .sub-categorys-inner {
  padding-bottom: 15px;
}

.shop-side-action .action-item .category-item .sub-categorys a {
  font-size: 14px;
  color: #777777;
  font-weight: 500;
}

.shop-side-action .action-item .category-item .sub-categorys a:hover {
  color: #040404;
}

.shop-side-action .action-item .category-item.show .sub-categorys {
  max-height: 500px;
}

.shop-side-action .action-item .category-item.show .category-item-inner .down-icon i {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.shop-side-action .action-item .color-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
}

.shop-side-action .action-item .color-item:hover .color-arrow {
  color: #040404;
}

.shop-side-action .action-item .color-item:last-child {
  margin-bottom: 0;
}

.shop-side-action .action-item .color-item.selected .color i {
  display: block;
}

.shop-side-action .action-item .color-item .color {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  margin-right: 10px;
}

.shop-side-action .action-item .color-item .color i {
  display: none;
  color: white;
  font-size: 8px;
  -webkit-transform: translateX(3px) translateY(2px);
  transform: translateX(3px) translateY(2px);
}

.shop-side-action .action-item .color-item .color.black {
  background: #000;
}

.shop-side-action .action-item .color-item .color.blue {
  background: #0084ff;
}

.shop-side-action .action-item .color-item .color.gray {
  background: #d4d4d4;
}

.shop-side-action .action-item .color-item .color.green {
  background: #61b163;
}

.shop-side-action .action-item .color-item .color.red {
  background: #ff6262;
}

.shop-side-action .action-item .color-item .color.yellow {
  background: #ffc000;
}

.shop-side-action .action-item .color-item .color-name {
  font-size: 14px;
  color: #777777;
}

.shop-side-action .action-item .color-item .color-arrow {
  margin-left: auto;
  color: #b9b9b9;
}

.shop-side-action .action-item .product-brands .brands-inner {
  max-height: 400px;
  overflow-y: scroll;
}

.shop-side-action .action-item .product-brands .brands-inner::-webkit-scrollbar {
  width: 3px;
}

.shop-side-action .action-item .product-brands .brands-inner::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.shop-side-action .action-item .product-brands .brands-inner::-webkit-scrollbar-thumb {
  background-color: #111111;
  border-radius: 0;
  border: none;
}

.shop-side-action .action-item .product-brands a {
  font-size: 14px;
  color: #777777;
  margin-bottom: 10px;
  font-weight: 500;
}

.shop-side-action .action-item .product-brands a:hover {
  color: #040404;
}

.shop-side-action .banner-item {
  background: #ce3259;
  border-radius: 6px;
  display: block;
}

.shop-side-action .banner-item .banner-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
  height: 370px;
}

.shop-side-action .banner-item .banner-inner .pretitle {
  font-size: 14px;
  color: #fff;
  font-weight: 400;
  margin-bottom: 7px;
}

.shop-side-action .banner-item .banner-inner .title {
  font-size: 40px;
  color: #fff;
}

.product-pagination-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

@media (max-width: 767px) {
  .product-pagination-area {
    margin-bottom: 50px;
  }
}

.product-pagination-area button {
  width: 50px;
  height: 50px;
  border: 1px solid #f0f0f0;
  border-radius: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
  font-family: "Roboto";
  font-weight: 700;
  margin-right: 7px;
}

.product-pagination-area button.active,
.product-pagination-area button:hover {
  background: var(--theme-color);
  color: #fff;
}

.rts-merch-section .rts-merch-section-inner .search-area {
  margin-bottom: 60px;
}

.rts-merch-section .rts-merch-section-inner .search-area .title {
  margin-bottom: 40px;
}

.rts-merch-section .rts-merch-section-inner .search-area form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.rts-merch-section .rts-merch-section-inner .search-area form .form {
  width: 78%;
}

.rts-merch-section .rts-merch-section-inner .search-area form .form input {
  background: #f6f6f6;
  border: none;
  border-radius: 0;
  padding: 15px;
}

.rts-merch-section .rts-merch-section-inner .search-area form .form input:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 1px solid var(--theme-color);
}

.rts-merch-section .rts-merch-section-inner .search-area form .button {
  width: 75px;
}

.rts-merch-section .rts-merch-section-inner .search-area form .button button {
  background: var(--theme-color);
  color: #fff;
  border-radius: 0;
  padding: 15px;
  width: 100%;
}

.rts-merch-section .rts-merch-section-inner .wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 60px;
}

.rts-merch-section .rts-merch-section-inner .wrapper .title {
  margin-bottom: 20px;
}

.rts-merch-section .rts-merch-section-inner .wrapper .filter-price {
  width: 220px;
  border: 0;
  padding: 0;
  /* Reset style for input range */
  /* Style toddler input range */
  /* Style track input range */
  /* Style for input value block */
  /* Style for active state input */
}

.rts-merch-section .rts-merch-section-inner .wrapper .filter-price .price-title {
  position: relative;
  color: #111111;
  font-family: "Roboto";
  font-size: 14px;
  line-height: 1.2em;
  font-weight: 400;
}

.rts-merch-section .rts-merch-section-inner .wrapper .filter-price .filter-btn .btn {
  background: #111111;
  color: #fff;
  border-radius: 0;
  padding: 8px 25px;
}

.rts-merch-section .rts-merch-section-inner .wrapper .filter-price .price-field {
  position: relative;
  width: 100%;
  height: 36px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: rgba(248, 247, 244, 0.2);
  padding-top: 15px;
  border-radius: 3px;
  margin-bottom: 15px;
}

.rts-merch-section .rts-merch-section-inner .wrapper .filter-price .price-field input[type=range] {
  position: absolute;
}

.rts-merch-section .rts-merch-section-inner .wrapper .filter-price .price-field input[type=range] {
  width: 280px;
  height: 2px;
  border: 0;
  outline: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 5px;
  pointer-events: none;
  -webkit-appearance: none;
}

@media (max-width: 1300px) and (min-width: 991px) {
  .rts-merch-section .rts-merch-section-inner .wrapper .filter-price .price-field input[type=range] {
    width: 220px;
  }
}

.rts-merch-section .rts-merch-section-inner .wrapper .filter-price .price-field input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

.rts-merch-section .rts-merch-section-inner .wrapper .filter-price .price-field input[type=range]:active,
.rts-merch-section .rts-merch-section-inner .wrapper .filter-price .price-field input[type=range]:focus {
  outline: 0;
}

.rts-merch-section .rts-merch-section-inner .wrapper .filter-price .price-field input[type=range]::-ms-track {
  width: 188px;
  height: 4px;
  background: #111111;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  border-radius: 5px;
  pointer-events: none;
  border-radius: 5px;
}

.rts-merch-section .rts-merch-section-inner .wrapper .filter-price .price-field input[type=range]::-webkit-slider-thumb {
  /* WebKit/Blink */
  position: relative;
  -webkit-appearance: none;
  margin: 0;
  border: 0;
  outline: 0;
  height: 10px;
  width: 10px;
  margin-top: -4px;
  background-color: #111111;
  cursor: pointer;
  cursor: pointer;
  pointer-events: all;
  z-index: 100;
}

.rts-merch-section .rts-merch-section-inner .wrapper .filter-price .price-field input[type=range]::-moz-range-thumb {
  /* Firefox */
  position: relative;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
  border: 0;
  outline: 0;
  height: 12px;
  width: 10px;
  margin-top: -5px;
  background-color: #111111;
  cursor: pointer;
  cursor: pointer;
  pointer-events: all;
  z-index: 100;
}

.rts-merch-section .rts-merch-section-inner .wrapper .filter-price .price-field input[type=range]::-ms-thumb {
  /* IE */
  position: relative;
  appearance: none;
  margin: 0;
  border: 0;
  outline: 0;
  border-radius: 50%;
  height: 12px;
  width: 10px;
  margin-top: -5px;
  background-color: #111111;
  cursor: pointer;
  cursor: pointer;
  pointer-events: all;
  z-index: 100;
}

.rts-merch-section .rts-merch-section-inner .wrapper .filter-price .price-field input[type=range]::-webkit-slider-runnable-track {
  /* WebKit/Blink */
  width: 188px;
  height: 3px;
  cursor: pointer;
  background: #111111;
  border-radius: 5px;
}

.rts-merch-section .rts-merch-section-inner .wrapper .filter-price .price-field input[type=range]::-moz-range-track {
  /* Firefox */
  width: 188px;
  height: 3px;
  cursor: pointer;
  background: #777777;
  border-radius: 5px;
}

.rts-merch-section .rts-merch-section-inner .wrapper .filter-price .price-field input[type=range]::-ms-track {
  /* IE */
  width: 188px;
  height: 2px;
  cursor: pointer;
  background: #777777;
  border-radius: 5px;
}

.rts-merch-section .rts-merch-section-inner .wrapper .filter-price .price-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #111111;
  font-size: 14px;
  line-height: 1.2em;
  font-weight: 400;
  margin-bottom: 30px;
}

.rts-merch-section .rts-merch-section-inner .wrapper .filter-price .price-wrap-1,
.rts-merch-section .rts-merch-section-inner .wrapper .filter-price .price-wrap-2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.rts-merch-section .rts-merch-section-inner .wrapper .filter-price .price-title {
  margin-right: 5px;
  backgrund: #d58e32;
}

.rts-merch-section .rts-merch-section-inner .wrapper .filter-price .price-wrap_line {
  margin: 0 10px;
}

.rts-merch-section .rts-merch-section-inner .wrapper .filter-price .price-wrap #one,
.rts-merch-section .rts-merch-section-inner .wrapper .filter-price .price-wrap #two {
  width: 30px;
  text-align: right;
  margin: 0;
  padding: 0;
  margin-right: 2px;
  font-family: "Roboto";
  background: 0;
  border: 0;
  outline: 0;
  color: #111111;
  font-size: 14px;
  line-height: 1.2em;
  font-weight: 400;
}

.rts-merch-section .rts-merch-section-inner .wrapper .filter-price .price-wrap label {
  text-align: right;
  font-family: "Roboto";
}

.rts-merch-section .rts-merch-section-inner .wrapper .filter-price .price-field input[type=range]:hover::-webkit-slider-thumb {
  -webkit-box-shadow: 0 0 0 0.5px #fff;
  box-shadow: 0 0 0 0.5px #fff;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.rts-merch-section .rts-merch-section-inner .wrapper .filter-price .price-field input[type=range]:active::-webkit-slider-thumb {
  -webkit-box-shadow: 0 0 0 0.5px #fff;
  box-shadow: 0 0 0 0.5px #fff;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.rts-merch-section .rts-merch-section-inner .catagories {
  margin-bottom: 60px;
}

.rts-merch-section .rts-merch-section-inner .catagories .title {
  margin-bottom: 20px;
}

.rts-merch-section .rts-merch-section-inner .catagories .item-list {
  padding: 0;
  margin: 0;
}

.rts-merch-section .rts-merch-section-inner .catagories .item-list .item {
  position: relative;
  color: #777777;
  font-size: 14px;
  font-weight: 400;
  font-family: "Roboto";
  margin-bottom: 15px;
  padding-left: 15px;
}

.rts-merch-section .rts-merch-section-inner .catagories .item-list .item::before {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #777777;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.rts-merch-section .rts-merch-section-inner .best-seller {
  margin-bottom: 70px;
}

.rts-merch-section .rts-merch-section-inner .best-seller .title {
  margin-bottom: 30px;
}

.rts-merch-section .rts-merch-section-inner .best-seller .full-wrapper .wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: unset;
  -webkit-box-direction: unset;
  -ms-flex-direction: unset;
  flex-direction: unset;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: start;
  margin-bottom: 30px;
}

.rts-merch-section .rts-merch-section-inner .best-seller .full-wrapper .wrapper .content {
  margin-left: 20px;
}

.rts-merch-section .rts-merch-section-inner .best-seller .full-wrapper .wrapper .content .star-rating {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 5px;
}

.rts-merch-section .rts-merch-section-inner .best-seller .full-wrapper .wrapper .content .star-rating li {
  color: var(--theme-color);
}

.rts-merch-section .rts-merch-section-inner .best-seller .full-wrapper .wrapper .content .star-rating li.last {
  color: #dfdfdf;
}

.rts-merch-section .rts-merch-section-inner .best-seller .full-wrapper .wrapper .content .title {
  margin-bottom: 5px;
}

.rts-merch-section .rts-merch-section-inner .best-seller .full-wrapper .wrapper .content .price {
  color: var(--theme-color);
  font-size: 14px;
  font-weight: 700;
}

.rts-merch-section .rts-merch-section-inner .tags .title {
  margin-bottom: 20px;
}

.rts-merch-section .rts-merch-section-inner .tags .tags-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.rts-merch-section .rts-merch-section-inner .tags .tags-inner li {
  background: #f3f3f3;
  padding: 5px 8px;
  margin: 5px 7px 5px 0;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.rts-merch-section .rts-merch-section-inner .tags .tags-inner li a {
  font-weight: 400;
  font-family: "Roboto";
  font-size: 12px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  line-height: 1;
}

.rts-merch-section .rts-merch-section-inner .tags .tags-inner li:hover {
  background: var(--theme-color);
}

.rts-merch-section .rts-merch-section-inner .tags .tags-inner li:hover a {
  color: #fff;
}

@media (max-width: 991px) {
  .rts-merch-section .rts-merch-section-inner .tags {
    margin-bottom: 50px;
  }
}

.rts-merch-section .rts-merch-section-inner .product-area .product-item {
  background: none;
}

.rts-merch-section .rts-merch-section-inner .product-area .product-item:hover .social-area {
  opacity: 1;
  bottom: 130px;
}

.rts-merch-section .rts-merch-section-inner .product-area .product-item .product-thumb {
  height: 275px;
}

.rts-merch-section .rts-merch-section-inner .product-area .product-item .product-thumb img {
  width: auto;
}

.rts-merch-section .rts-merch-section-inner .product-area .product-item .product-tag-area {
  right: 30px;
  left: unset;
}

.rts-merch-section .rts-merch-section-inner .product-area .product-item .product-tag-area .sale-tag {
  background: #111111;
}

.rts-merch-section .rts-merch-section-inner .product-area .product-item .social-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #111111;
  width: 220px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 12px 0;
  position: absolute;
  opacity: 0;
  bottom: 110px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.rts-merch-section .rts-merch-section-inner .product-area .product-item .social-area li a {
  color: #fff;
  margin: 0 10px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.rts-merch-section .rts-merch-section-inner .product-area .product-item .social-area li a:hover {
  color: var(--theme-color);
}

.account-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media (max-width: 1200px) {
  .account-inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .account-inner .account-side-navigation {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 40px;
    margin-right: -10px;
  }

  .account-inner .account-side-navigation .filter-btn {
    margin-right: 10px;
  }
}

.account-side-navigation {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-right: 60px;
}

@media (max-width: 1300px) {
  .account-side-navigation {
    margin-right: 20px;
  }
}

@media (max-width: 500px) {
  .account-side-navigation .filter-btn {
    width: 100% !important;
  }
}

.account-side-navigation .filter-btn {
  width: 220px;
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 30px;
  font-size: 16px;
  font-weight: 500;
  color: #999999;
  border: 1px solid #ebebeb;
  margin-bottom: 10px;
}

.account-side-navigation .filter-btn:last-child {
  margin-bottom: 0;
}

.account-side-navigation .filter-btn.active {
  color: #fff;
  background: var(--theme-color);
  border: 1px solid transparent;
}

.account-side-navigation .filter-btn i {
  margin-right: 10px;
}

.account-main-area {
  width: 100%;
}

.dashboard .account-profile-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 15px;
}

.dashboard .account-profile-area .profile-dp {
  margin-right: 20px;
  max-width: 60px;
  border-radius: 50%;
  overflow: hidden;
}

.dashboard .account-profile-area .profile-name {
  font-size: 20px;
}

.dashboard .account-profile-area .profile-name span {
  color: #c3c3c3;
  font-weight: 400;
}

.dashboard .account-profile-area .profile-date {
  font-size: 14px;
  color: #040404;
}

.dashboard p {
  margin-bottom: 40px;
}

.dashboard .activity-box {
  padding: 50px 60px;
  border: 1px solid #f4f4f4;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.dashboard .activity-box .activity-item {
  text-align: center;
}

.dashboard .activity-box .activity-item .icon {
  margin-bottom: 15px;
}

.dashboard .activity-box .activity-item .icon i {
  font-size: 37px;
  color: var(--theme-color);
}

.dashboard .activity-box .activity-item .title {
  font-size: 18px;
  margin-bottom: 5px;
}

.dashboard .activity-box .activity-item .value {
  font-size: 14px;
  color: #777777;
  display: block;
}

@media (max-width: 1300px) {
  .dashboard .activity-box {
    padding: 30px 20px;
  }

  .dashboard .activity-box .activity-item .icon i {
    font-size: 30px;
  }

  .dashboard .activity-box .activity-item .title {
    font-size: 16px;
  }
}

@media (max-width: 900px) {
  .dashboard .activity-box {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .dashboard .activity-box .activity-item {
    margin: 0 20px;
    margin-bottom: 30px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

@media (max-width: 768px) {

  .dashboard .activity-box .activity-item {
    width: 100%;
    margin: 0;
    margin-bottom: 30px;
  }
}

.orders tr th:last-child {
  text-align: end;
  padding-right: 0;
}

.orders tr td:last-child {
  text-align: end;
}

@media (max-width: 768px) {
  .orders h2 {
    text-align: center;
  }

  .orders .top-tr {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .orders tr td:last-child {
    text-align: center;
  }
}

@media (max-width: 768px) {
  .address .billing-address {
    margin-bottom: 30px;
  }
}

.login-form {
  margin-right: 20px;
}

.login-form .section-title {
  margin-bottom: 40px;
}

@media (max-width: 450px) {
  .login-form .section-title {
    margin: 30px 0 10px 0;
  }
}

.login-form .section-title h2 {
  font-size: 28px;
  line-height: 32px;
  font-weight: 700;
}

.login-form .card .card-body {
  padding: 50px 40px;
}

.login-form .card .card-body .form {
  margin-bottom: 30px;
}

.login-form .card .card-body .form .form-label {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: #444;
}
.login-form .card .card-body .form .form-control {
  color: #000;
  border: 1px solid #f4f4f4;
  padding: 18px 15px 18px 30px;
  border-radius: 0;
}

.login-form .card .card-body .form .form-control::-webkit-input-placeholder {
  color: #666666;
}

.login-form .card .card-body .form .form-control::-moz-placeholder {
  color: #666666;
}

.login-form .card .card-body .form .form-control:-ms-input-placeholder {
  color: #666666;
}

.login-form .card .card-body .form .form-control::-ms-input-placeholder {
  color: #666666;
}

.login-form .card .card-body .form .form-control::placeholder {
  color: #666666;
}

.login-form .card .card-body .form .form-control:focus {
  border: 1px solid var(--theme-color);
  -webkit-box-shadow: none;
  box-shadow: none;
}

.login-form .card .card-body .form .password-input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
}

.login-form .card .card-body .form .password-input .show-password-input {
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.login-form .card .card-body .form .password-input .show-password-input::after {
  content: "\f06e";
  font-weight: 500;
  color: #777777;
  font-family: "Font awesome 5 Pro";
}

.login-form .card .card-body .form .btn {
  font-size: 14px;
  width: 150px;
  height: 50px;
  padding: 0;
  border-radius: 0;
  line-height: 50px;
  font-weight: 500;
  color: #fff;
  background: #111111;
  display: inline-block;
  text-align: center;
  border: none;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  text-transform: uppercase;
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

@media (max-width: 1100px) and (min-width: 900px) {
  .login-form .card .card-body .form .btn {
    display: block;
  }
}

@media (max-width: 450px) {
  .login-form .card .card-body .form .btn {
    display: block;
    margin: 0 auto;
  }
}

.login-form .card .card-body .form .btn::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  background: var(--theme-color);
}

.login-form .card .card-body .form .btn:hover {
  color: #fff;
}

.login-form .card .card-body .form .btn:hover::before {
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}

.login-form .card .card-body .forgot-password {
  font-size: 16px;
  color: #444;
  font-weight: 500;
  margin-top: 18px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.login-form .card .card-body .forgot-password:hover {
  color: var(--theme-color);
}

.rts-wishlist-section {
  padding: 100px 0;
}

@media (max-width: 900px) {
  .rts-wishlist-section {
    padding: 50px 0;
  }
}

@media (max-width: 576px) {
  .rts-wishlist-section {
    padding: 25px 0;
  }
}

@media (max-width: 900px) {
  .rts-wishlist-section .table-bordered .heading {
    display: none;
  }
}

.rts-wishlist-section .table-bordered .heading th {
  border: none;
  font-size: 18px;
  padding: 10px 0;
}

@media (max-width: 900px) {
  .rts-wishlist-section tr {
    display: block;
    text-align: center;
  }
}

@media (max-width: 900px) {
  .rts-wishlist-section tr {
    padding: 30px 0;
  }
}

@media (max-width: 900px) {
  .rts-wishlist-section tr td {
    display: block;
    text-align: center;
    padding: 0 !important;
  }
}

.rts-wishlist-section .first-child {
  width: 37%;
}

@media (max-width: 900px) {
  .rts-wishlist-section .first-child {
    width: 100%;
    display: -ms-grid;
    display: grid;
    text-align: center;
  }
}

.rts-wishlist-section .first-td button {
  margin-right: 15px;
}

@media (max-width: 900px) {
  .rts-wishlist-section .first-td button {
    margin: 0 0 15px 0;
  }
}

.rts-wishlist-section .pretitle {
  font-size: 14px;
  color: var(--theme-color);
  font-weight: 500;
  padding-left: 40px;
}

@media (max-width: 991px) {
  .rts-wishlist-section .pretitle {
    padding-left: 10px;
  }
}

@media (max-width: 900px) {
  .rts-wishlist-section .pretitle {
    margin: 0 0 15px;
  }
}

.rts-wishlist-section .product-title {
  font-size: 16px;
  font-weight: 500;
}

@media (max-width: 900px) {
  .rts-wishlist-section .product-title {
    margin: 0 0 10px;
  }
}

.rts-wishlist-section .product-price {
  color: #040404;
  font-size: 14px;
}

@media (max-width: 900px) {
  .rts-wishlist-section .product-price {
    margin: 0 0 10px;
  }
}

.rts-wishlist-section .stock {
  color: #99cc33;
  font-weight: bold;
}

@media (max-width: 900px) {
  .rts-wishlist-section .stock {
    margin: 0 0 10px;
  }
}

.rts-wishlist-section .stock1 {
  color: #cc0033;
  font-weight: bold;
}

@media (max-width: 900px) {
  .rts-wishlist-section .stock1 {
    margin: 0 0 10px;
  }
}

.rts-wishlist-section .last-td {
  width: 15%;
  text-align: center;
}

@media (max-width: 900px) {
  .rts-wishlist-section .last-td {
    width: 100%;
    text-align: center;
  }
}

.rts-wishlist-section .last-td .cart-btn {
  font-size: 14px;
  font-weight: 700;
  width: 150px;
  height: 45px;
  border-radius: 7px;
  border: 1px solid #e7e7e7;
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

@media (max-width: 1200px) {
  .rts-wishlist-section .last-td .cart-btn {
    padding: 0 18px;
  }
}

.rts-wishlist-section .last-td .cart-btn:hover {
  background: #000;
  color: #fff;
}

.rts-wishlist-section .last-td .cart-btn i {
  -webkit-transition: all 50ms;
  transition: all 50ms;
}

.rts-wishlist-section .last-td .cart-btn1 {
  font-size: 14px;
  background-color: #ececec;
  color: #ccc;
  font-weight: 700;
  width: 150px;
  height: 45px;
  border-radius: 7px;
  border: 1px solid #e7e7e7;
}

.rts-wishlist-section .wishlist-social {
  margin-top: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 900px) {
  .rts-wishlist-section .wishlist-social {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

@media (max-width: 576px) {
  .rts-wishlist-section .wishlist-social {
    margin: 0;
  }
}

.rts-wishlist-section .wishlist-social .text {
  margin-right: 10px;
}

.rts-wishlist-section .wishlist-social .text a {
  font-size: 16px;
  font-weight: 700;
  text-decoration: underline;
}

.rts-wishlist-section .wishlist-social .icon ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.rts-wishlist-section .wishlist-social .icon ul li {
  margin: 0 10px;
  padding: 2px 5px;
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

@media (max-width: 400px) {
  .rts-wishlist-section .wishlist-social .icon ul li {
    margin: 0 5px;
  }
}

.rts-wishlist-section .wishlist-social .icon ul li:hover {
  background: #000;
}

.rts-wishlist-section .wishlist-social .icon ul li:hover a {
  color: #fff;
}

.rts-wishlist-section .wishlist-social .icon ul li a {
  font-size: 16px;
  color: #999999;
  font-weight: bold;
  -webkit-transition: color 0ms;
  transition: color 0ms;
}

/**------------------------------------------------------------------
 * Buttons Start Here
 */
button,
.btn {
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

.btn {
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

.btn:hover i {
  -webkit-transform: translateX(5px);
  transform: translateX(5px);
}

.more-btn {
  padding: 8px 135px 8px 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  max-height: 60px;
  border-radius: 6px;
  font-size: 26px;
  font-weight: 600;
  color: #01bf61;
  border: 1px solid #efefef;
  -webkit-transition: all 400ms;
  transition: all 400ms;
}
@media (max-width: 900px) {
  .more-btn {
    padding: 8px 25px; /* Adjusted padding */
    font-size: 20px;
    white-space: nowrap;
  }
}

.more-btn:hover {
  background: #111111;
  color: #fff;
  border: 1px solid transparent;
}

.more-btn:hover i {
  -webkit-transform: translateX(5px);
  transform: translateX(5px);
}

.load-more-btn {
  margin: 0 auto;
  width: 180px;
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 6px;
  background-color: #F5F5F5;
  font-size: 16px;
  letter-spacing: 2px;
  color: #111111;
  font-weight: 600;
  text-align: center;
  margin-top: 30px;
}

.load-more-btn:hover i {
  -webkit-transform: scale(110%);
  transform: scale(110%);
}

.load-more-btn i {
  margin-left: 10px;
  margin-bottom: 2px;
}

.shop-now-btn {
  padding: 10px 25px;
  background: #fff;
  color: #040404;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  padding: 10px 20px;
  padding-bottom: 12px;
  border-radius: 6px;
  -webkit-transition: background 500ms;
  transition: background 500ms;
  border: 1px solid transparent;
}

.shop-now-btn i {
  margin-left: 5px;
}

.shop-now-btn:hover {
  background: #040404;
  color: var(--theme-color);
  border: 1px solid rgb(65, 65, 65);
}

.arrow-btn-hover {
  overflow: hidden;
}

.arrow-btn-hover:hover {
  color: #fff;
  background: transparent;
  border: 1px solid transparent;
}

.arrow-btn-hover:hover::before {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.arrow-btn-hover:hover::after {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.arrow-btn-hover i {
  margin-left: 5px;
  font-size: 17px;
}

.arrow-btn-hover::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-clip-path: polygon(97% 0, 100% 50%, 97% 100%, 0 100%, 0 0);
  clip-path: polygon(97% 0, 100% 50%, 97% 100%, 0 100%, 0 0);
  background: #FF1856;
  -webkit-transition: all 500ms;
  transition: all 500ms;
  z-index: -1;
}

.arrow-btn-hover::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 10%;
  height: 100%;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-clip-path: polygon(70% 50%, 0 0, 0 100%);
  clip-path: polygon(70% 50%, 0 0, 0 100%);
  background: #fff;
  -webkit-transition: all 300ms;
  transition: all 300ms;
  z-index: -1;
}

.rts-post-2-medium .post-title {
  max-width: 350px;
}

.rts-post-2-medium .post-title a {
  font-size: 20px;
  line-height: 24px;
  background-size: 200% 1px;
}

.all-products-link {
  color: #040404;
  font-size: 14px;
  line-height: 26px;
  padding: 15px 40px;
  border: 2px solid #EFEFEF;
  border-radius: 7px;
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

.all-products-link:hover {
  background: var(--theme-color);
  border: 2px solid var(--theme-color);
  color: #fff;
}

.newsletter-btn {
  color: #fff;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  background: #1D72FF;
  padding: 12px 25px;
  -webkit-transition: background 500ms;
  transition: background 500ms;
}

.newsletter-btn img {
  margin-right: 2px;
  margin-top: -1px;
  -webkit-filter: hue-rotate(30deg) brightness(5);
  filter: hue-rotate(30deg) brightness(5);
}

.newsletter-btn:hover {
  background: #5295ff;
  color: #fff;
}

.slider-btn {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #fff;
  color: #040404;
}

.slider-btn:hover {
  background: var(--theme-color);
  color: #fff;
}

.slider-btn::after {
  display: none;
}

.catagory-menu-icon {
  cursor: pointer;
}

.play-btn a {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #fff;
  -webkit-transition: all 400ms;
  transition: all 400ms;
  color: #111111;
  font-size: 18px;
  pointer-events: all;
}

.play-btn a:hover {
  background: var(--theme-color);
  color: #fff;
}

/**------------------------------------------------------------------
 * Buttons End Here
 */
.floating-elements .floating-item {
  position: absolute;
}

.banner5.floating-elements {
  z-index: 10;
}

.banner5.floating-elements .item1 {
  left: 20px;
  bottom: -110px;
}

.banner5.floating-elements .item2 {
  right: 50px;
  top: 100px;
}

.banner5.floating-elements .item3 {
  right: 80px;
  bottom: -20px;
}


.privacy_head {
  padding-top: 250px;
}

.privacy-heading {
  margin-bottom: 30px;
}

.privacy-para {
  margin-bottom: 30px;
  margin-left: 30px;
}

.list-style {
  list-style: disc;
}

.privacy-ul {
  margin-left: 85px;
  margin-bottom: 30px;
}

.privacy-sub-heading {
  margin-bottom: 30px;
  margin-left: 45px;
}

.privacy-sub-heading-para {
  margin-bottom: 30px;
  margin-left: 65px;
}
